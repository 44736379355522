import React from 'react';

/*
 * Some Styling Options
 */
const OPTION_P = 'text-md mr-6 mb-2';
const OPTION_B = '';

/*
 * Entity Type Options
 */

const entityTypeEnum = {
  INDIVIDUAL: 'INDIVIDUAL',
  JOINT: 'JOINT',
  EBP: 'EBP',
  ENTITY: 'ENTITY',
  TRUST: 'TRUST',
};

const entityTypeOptions = [
  {
    title: 'I\'m investing as an individual in my own name',
    description: [
      <p className={OPTION_P}>
        Select this option if you&apos;re investing in your own name (EG. &quot;John Smith&quot;).
      </p>,
    ],
    id: entityTypeEnum.INDIVIDUAL,
  },
  {
    title: 'I\'m investing with my spouse together in both of our names',
    description: [
      <p className={OPTION_P}>
        Select this option if you&apos;re investing with together with your spouse in
        both of your legal names (EG &quot;John Smith &amp; Jane Smith&quot;).
      </p>,
    ],
    id: entityTypeEnum.JOINT,
  },
  {
    title: 'I\'m investing through a retirement plan (401k, IRA, etc.)',
    description: [
      <p className={OPTION_P}>
        Select this option if you&apos;re investing through a retirement account
        such as an IRA or 401k.
      </p>,
    ],
    id: entityTypeEnum.EBP,
  },
  {
    title: 'I\'m investing through an LLC, company, or other business entity',
    description: [
      <p className={OPTION_P}>
        Select this option if you&apos;re investing through a legal entity
        (EG a family office, investment fund, bank, or broker-dealership).
      </p>,
    ],
    id: entityTypeEnum.ENTITY,
  },
  {
    title: 'I\'m investing through a trust',
    description: [
      <p className={OPTION_P}>
        Select this option if you&apos;re investing through a trust.
      </p>,
    ],
    id: entityTypeEnum.TRUST,
  },
];

/*
 * Method options
 */

const methodEnum = {
  // LETTER: 'LETTER',
  INCOME: 'INCOME',
  FINANCE_PROFESSIONAL: 'FINANCE_PROFESSIONAL',
  NET_WORTH: 'NET_WORTH',
  NONE_OF_THE_ABOVE: 'NONE_OF_THE_ABOVE',

  // JOINT Methods (Same as as individual, but different copy)
  // JOINT_LETTER: 'JOINT_LETTER',
  JOINT_INCOME: 'JOINT_INCOME',
  JOINT_NET_WORTH: 'JOINT_NET_WORTH',
  JOINT_NONE_OF_THE_ABOVE: 'JOINT_NONE_OF_THE_ABOVE',

  // EBP Methods (Retirement plan specific options)
  EBP_NET_WORTH: 'EBP_NET_WORTH',
  EBP_FIDUCIARY: 'EBP_FIDUCIARY',
  EBP_SELF_DIRECTED: 'EBP_SELF_DIRECTED',

  // ENTITY Methods (Same as as individual, but different copy)
  // ENTITY_LETTER: 'ENTITY_LETTER',
  ENTITY_NET_WORTH: 'ENTITY_NET_WORTH',
  ENTITY_STATUS: 'ENTITY_STATUS',
  ENTITY_SPECIAL: 'ENTITY_SPECIAL',
  ENTITY_ACCREDITED_INDIVIDUALS: 'ENTITY_ACCREDITED_INDIVIDUALS',
  ENTITY_NONE_OF_THE_ABOVE: 'ENTITY_NONE_OF_THE_ABOVE',

  // TRUST methods
  // TRUST_LETTER: 'TRUST_LETTER',
  TRUST_NET_WORTH: 'TRUST_NET_WORTH',
  TRUST_ACCREDITED_INDIVIDUALS: 'TRUST_ACCREDITED_INDIVIDUALS',
  TRUST_NONE_OF_THE_ABOVE: 'TRUST_NONE_OF_THE_ABOVE',
};

const requiredDocumentation = {
  // LETTER: [
  //   `A signed letter from a qualified professional stating that they have verified
  //   your status as an accredited investor within the last 90 days`,
  // ],
  INCOME: [
    'Tax forms (W-2, K-1, or 1099 forms) OR tax returns for the past two years',
    'Confirmation that you have a reasonable belief that you will make enough money this year to qualify as an accredited investor',
  ],
  FINANCE_PROFESSIONAL: [
    'Your CRD number',
    'Your firm name (if qualifying through series 7 or 82)',
  ],
  // DIRECTOR: [
  //   "Evidence of your claimed position (EG Incumbancy Certificate or Officer's Certificate)",
  // ],
  // EMPLOYEE: [
  //   'Name of the investment, fund, or deal you are qualifying for',
  //   'Evidence of your claimed position (Incumbancy Certificate, Officer\'s Certificate, etc.)',
  //   'Description of your role and responsibilities at the issuing company',
  //   'Contact information for regulatory and compliance team at the issuing company',
  // ],
  NET_WORTH: [
    'A credit report from the last 90 days',
    'Estimated value of primary residence',
    'Debts secured against primary residence',
    'Evidence of all assets contributing to net worth',
  ],
  NONE_OF_THE_ABOVE: [
    'Explanation of how you qualify as an accredited investor',
    'Explanation of extentuaing circumstance surrounding your accreditation',
    'Explanation of why you believe an attorney can verify your accreditation status',
    'Supporting documentation proving your accreditation status',
  ],
  // Joint Requirements
  // JOINT_LETTER: [
  //   `A signed letter from a qualified professional stating that they have verified
  //   you and your spouse's status as accredited investors within the last 90 days`,
  // ],
  JOINT_INCOME: [
    'Tax forms (W-2, K-1, or 1099 forms) OR tax returns for you and your spouse for the past two years',
    'Confirmation that you have a reasonable belief that you will make enough money this year to qualify as accredited investors',
  ],
  JOINT_NET_WORTH: [
    'Credit reports for you and your spouse from the last 90 days',
    'Estimated value of primary residence',
    'Debt secured against primary residence',
    'Evidence of all assets contributing to net worth',
  ],
  JOINT_NONE_OF_THE_ABOVE: [
    'Explanation of how you and your spouse qualify as an accredited investor',
    'Explanation of extentuaing circumstance surrounding your accreditation',
    'Explanation of why you believe an attorney can verify your accreditation status',
    'Supporting documentation proving your accreditation status',
  ],
  // EBPs
  EBP_NET_WORTH: [
    'A financial statement or appraisal from the last 90 days showing assets greater than $5M in value',
    'Confirmation that the retirement plan was not formed specifically to purchase this security',
  ],
  EBP_SELF_DIRECTED: [
    'Formation documents for the employee benefit plan',
    'Individual accreditation of each member self-directing the plan',
  ],
  EBP_FIDUCIARY: [
    'Documentation that the retirement plan is managed by a qualified fiduciary',
  ],
  // Entity Requirements
  // ENTITY_LETTER: [
  //   `A signed letter from a qualified professional stating that they have verified
  //   the entity's accredited status within the last 90 days`,
  // ],
  ENTITY_NET_WORTH: [
    'A financial statement or appraisal from the last 90 days showing assets greater than $5M in value',
    'Confirmation that the entity was not formed specifically to purchase this security',
  ],
  ENTITY_STATUS: [
    'Selection of a type of entity status exemption',
    'Evidence of the exempt entity status claimed',
    'Confirmation that the entity was not formed specifically to purchase this security',
  ],
  ENTITY_SPECIAL: [
    'Selection of a type of entity status exemption',
    'Evidence of the exempt entity status claimed',
    'A financial statement or appraisal from the last 90 days showing assets greater than $5M in value',
    'Confirmation that the entity was not formed specifically to purchase this security',
  ],
  ENTITY_ACCREDITED_INDIVIDUALS: [
    'Separate accreditation of each of equity owners of this entity',
  ],
  ENTITY_NONE_OF_THE_ABOVE: [
    'Explanation of how the entity qualifies as an accredited investor',
    'Explanation of extentuaing circumstance surrounding the entity\'s accreditation',
    'Explanation of why you believe an attorney can verify the entity\'s accreditation status',
    'Supporting documentation proving the entity\'s accreditation status',
  ],
  // Trust Requirements
  // TRUST_LETTER: [
  //   `A signed letter from a qualified professional stating that they have verified
  //   the trust's accredited status within the last 90 days`,
  // ],
  TRUST_NET_WORTH: [
    'A financial statement or appraisal from the last 90 days showing assets greater than $5M in value',
    'Confirmation that the trust was not formed specifically to purchase this security',
    'Evidence of the director\'s business and financial qualifications',
  ],
  TRUST_REVOCABLE: [
    'Trust agreement or declaration showing that the trust is revocable',
    'Separate accreditation of the garantors',
  ],
  TRUST_ACCREDITED_INDIVIDUALS: [
    'Trust agreement or declaration documenting the equity owners of the trust',
    'Separate accreditation of each of the equity owners of the trust',
  ],
  TRUST_NONE_OF_THE_ABOVE: [
    'Explanation of how the trust qualifies as an accredited investor',
    'Explanation of extentuaing circumstance surrounding the trust\'s accreditation',
    'Explanation of why you believe an attorney can verify the trust\'s accreditation status',
    'Supporting documentation proving the trust\'s accreditation status',
  ],
};

/*
 * Verification Options for Individuals
 */
const individualVerificationOptions = [
  // {
  //   title: 'I have a letter verifying my accreditation from a qualified professional.',
  //   description: [
  //     <p className={OPTION_P}>
  //       Choose this option if you have a signed letter from a qualified professional
  //       {' '}
  //       <span className={OPTION_B}>dated within the last 3 months</span>
  //       {' '}
  //       verifying that the professional has determined your accreditation status.
  //     </p>,
  //     <p className={OPTION_P}>
  //       The uploaded letter must be signed by
  //       {' '}
  //       <span className={OPTION_B}>
  //         a registered broker-dealer, a licensed attorney,
  //         a certified public accountant, or a registered investment advisor.
  //       </span>
  //     </p>,
  //   ],
  //   id: methodEnum.LETTER,
  // },
  {
    title: 'I have an annual income greater than $200K',
    description: [
      <p className={OPTION_P}>
        Choose this option if you can document that you&apos;ve earned
        {' '}
        <span className={OPTION_B}>
          more than $200,000 per year for the last two years
        </span>
        {' '}
        and you have a reasonable expectation that you will make more than
        $200,000 in the current year.
      </p>,
    ],
    id: methodEnum.INCOME,
  },
  {
    title: 'I am a qualified financial professional.',
    description: [
      <p className={OPTION_P}>
        Choose this option if you hold a
        {' '}
        <span className={OPTION_B}>Series 7</span>
        {' '}
        (General Securities Representative),
        {' '}
        <span className={OPTION_B}>Series 82</span>
        {' '}
        (Private Securities Offerings Representative),
        or
        {' '}
        <span className={OPTION_B}>Series 65</span>
        {' '}
        (Licensed Investment Advisor).
      </p>,
      <p className={OPTION_P}>
        To qualify,
        {' '}
        <span className={OPTION_B}>your license must be in good standing,</span>
        {' '}
        and to qualify with a Series 7 or Series 82, you will have to provide the name of
        your firm.
      </p>,
    ],
    id: methodEnum.FINANCE_PROFESSIONAL,
  },
  {
    title: 'I have a net worth greater than $1M (excluding my primary residence).',
    description: [
      <p className={OPTION_P}>
        Choose this option if your net worth is
        {' '}
        <span className={OPTION_B}>greater than $1M</span>
        {' '}
        and
        {' '}
        <span className={OPTION_B}>
          you have recent documention of your
          assets and liabilities
        </span>
        .
      </p>,
      <p className={OPTION_P}>
        Your primary residence is not counted as an asset. Debt secured against your
        primary residence will not be counted as a liability provided it is less than
        the estimated Fair Market Value of your residence, and it is not greater than
        such debt was 60 days ago.
      </p>,
      <p className={OPTION_P}>
        Note that documentation (including a
        {' '}
        <span className={OPTION_B}>credit report</span>
        ) generally must be from within the
        {' '}
        <span className={OPTION_B}>last 90 days</span>
        ,
        with exceptions for tax returns (which must be from within the last year returns were
        available) and documentation of ownership.
      </p>,
      <p className={OPTION_P}>
        <span className={OPTION_B}>
          This option requires the most extensive documentation of any verification
          approach.
        </span>
        {' '}
        If you can qualify by an easier option listed above (EG income), it is recommended
        that you do so.
      </p>,
    ],
    id: methodEnum.NET_WORTH,
  },
  {
    title: 'None of the above scenarios apply, but I believe I can still prove my accreditation.',
    description: [
      <p className={OPTION_P}>
        If you qualify by the legal definition of an accredited investor but cannot document it
        by a method listed above, we may still be able to verify your accreditation status.
      </p>,
      <p className={OPTION_P}>
        You will need to upload evidence of your accreditation status
        and an explanation for why you believe an attorney can reasonably verify your
        accreditation status.
      </p>,
      <p className={OPTION_P}>
        Please note that this a special case and it is exceptionally rare that this selection
        applies. If you select this, you should already be absolutely certain that this
        is the only scenario that applies to you, and that you can prove your status
        as an accredited investor through non-traditional means.
      </p>,
    ],
    id: methodEnum.NONE_OF_THE_ABOVE,
  },
];

/*
 * Verification Options for Individuals
 */
const jointVerificationOptions = [
  // {
  //   title: 'We have a letter verifying our accreditation from a qualified professional.',
  //   description: [
  //     <p className={OPTION_P}>
  //       Choose this option if you have a signed letter from a qualified professional
  //       {' '}
  //       <span className={OPTION_B}>dated within the last 3 months</span>
  //       {' '}
  //       verifying that the professional has determined you and spouse&apos;
  //       accreditation status.
  //     </p>,
  //     <p className={OPTION_P}>
  //       The uploaded letter must be signed by
  //       {' '}
  //       <span className={OPTION_B}>
  //         a registered broker-dealer, a licensed attorney,
  //         a certified public accountant, or a registered investment advisor.
  //       </span>
  //     </p>,
  //   ],
  //   id: methodEnum.JOINT_LETTER,
  // },
  {
    title: 'We have a combined annual income greater than $300k.',
    description: [
      <p className={OPTION_P}>
        Choose this option if you can document that you and your spouse
        have a joint income totaling
        {' '}
        <span className={OPTION_B}>
          more than $300,000 per year for the last two years
        </span>
        {' '}
        and you have a reasonable expectation that you will make more than
        $300,000 in the current year.
      </p>,
    ],
    id: methodEnum.JOINT_INCOME,
  },
  {
    title: 'We have a net worth greater than $1M (excluding our primary residence).',
    description: [
      <p className={OPTION_P}>
        Choose this option if your net worth is
        {' '}
        <span className={OPTION_B}>greater than $1M</span>
        {' '}
        and
        {' '}
        <span className={OPTION_B}>
          you have recent documention of your
          assets and liabilities
        </span>
        .
      </p>,
      <p className={OPTION_P}>
        Your primary residence is not counted as an asset. Debt secured against your
        primary residence will not be counted as a liability provided it is less than
        the estimated Fair Market Value of your residence, and it is not greater than
        such debt was 60 days ago.
      </p>,
      <p className={OPTION_P}>
        Note that documentation (including
        {' '}
        <span className={OPTION_B}>credit reports</span>
        ) generally must be from within the
        {' '}
        <span className={OPTION_B}>last 90 days</span>
        ,
        with exceptions for tax returns (which must be from within the last year returns were
        available) and documentation of ownership.
      </p>,
      <p className={OPTION_P}>
        <span className={OPTION_B}>
          This option requires the most extensive documentation of any verification
          approach.
        </span>
        {' '}
        If you can qualify by an easier option listed above (EG income), it is recommended
        that you do so.
      </p>,
    ],
    id: methodEnum.JOINT_NET_WORTH,
  },
  // TODO(Carter): Link to legal definition
  {
    title: 'None of the above scenarios apply, but I believe we can still prove our accreditation.',
    description: [
      <p className={OPTION_P}>
        If you and your spouse qualify by the legal definition of an accredited investor but
        cannot document it
        by a method listed above, we may still be able to verify your accreditation status.
      </p>,
      <p className={OPTION_P}>
        You will need to upload evidence of your accreditation status
        and an explanation for why you believe an attorney can reasonably verify your
        accreditation status.
      </p>,
      <p className={OPTION_P}>
        Please note that this a special case and it is exceptionally rare that this selection
        applies. If you select this, you should already be absolutely certain that this
        is the only scenario that applies to you, and that you can prove your status
        as an accredited investor through non-traditional means.
      </p>,
    ],
    id: methodEnum.JOINT_NONE_OF_THE_ABOVE,
  },
];

const trustVerificationOptions = [
  // {
  //   title: 'I have a letter verifying the trust\'s accreditation from a qualified professional.',
  //   description: [
  //     <p className={OPTION_P}>
  //       Choose this option if you have a signed letter from a qualified professional
  //       {' '}
  //       <span className={OPTION_B}>dated within the last 3 months</span>
  //       {' '}
  //       verifying that the professional has determined the trust&apos;s
  //       accreditation status.
  //     </p>,
  //     <p className={OPTION_P}>
  //       The uploaded letter must be signed by
  //       {' '}
  //       <span className={OPTION_B}>
  //         a registered broker-dealer, a licensed attorney,
  //         a certified public accountant, or a registered investment advisor.
  //       </span>
  //     </p>,
  //   ],
  //   id: methodEnum.TRUST_LETTER,
  // },
  {
    title: 'The trust has more than $5M in assets and is directed by a "sophisticated person".',
    description: [
      <p className={OPTION_P}>
        Choose this option if you can document that your trust has more than $5M in
        assets. You will also have to confirm that the entity was not formed with
        the special purpose of buying this security.
      </p>,
      <p className={OPTION_P}>
        Additionally, the director (either alone or together with their purchaser
        representative)
        must qualify as a &quot;sophisticated person&quot; &mdash; someone with such
        knowledge and experience in financial and business matters as to be capable of
        evaluating the risks and merits of the prospective investment.
      </p>,
    ],
    id: methodEnum.TRUST_NET_WORTH,
  },
  {
    title: 'The equity owners of the trust are all accredited investors.',
    description: [
      <p className={OPTION_P}>
        Choose this option if none of the above circumstances apply but you believe that
        the equity owners of the trust are all accredited investors.
      </p>,
      <p className={OPTION_P}>
        Note that this method of verification involves completting a separate accreditation
        process for
        each of the equity owners.

      </p>,
      <p className={OPTION_P}>
        In a revocable trust, the equity owners are generally the grantors.
        In an irrevocable trust, determining the equity owners can be more complicated. We are
        happy to help you make that determination. You can reach us at
        {' '}
        <a
          className="font-semibold text-orange-500 underline hover:text-orange-800"
          href="mailto:support@checkinvestorstatus.com"
        >
          support@checkinvestorstatus.com
        </a>
        .
      </p>,
    ],
    id: methodEnum.TRUST_ACCREDITED_INDIVIDUALS,
  },
  {
    title: 'None of the above scenarios apply, but I believe we can still prove the trust\'s accreditation.',
    description: [
      <p className={OPTION_P}>
        If the trust qualifies by the legal definition of an accredited investor but
        cannot document it
        by a method listed above, we may still be able to verify your accreditation status.
      </p>,
      <p className={OPTION_P}>
        You will need to upload evidence of the trust&apos;s accreditation status
        and an explanation for why you believe an attorney can reasonably verify your
        accreditation status.
      </p>,
      <p className={OPTION_P}>
        Please note that this a special case and it is exceptionally rare that this selection
        applies. If you select this, you should already be absolutely certain that this
        is the only scenario that applies to you, and that you can prove your status
        as an accredited investor through non-traditional means.
      </p>,
    ],
    id: methodEnum.TRUST_NONE_OF_THE_ABOVE,
  },
];

const ebpVerificationOptions = [
  {
    title: 'The retirement plan has more than $5M in assets.',
    description: [
      <p className={OPTION_P}>
        Choose this option if the entity can document its status as an
        employee benefit plan directed by a plan fiduciary.
      </p>,
    ],
    id: methodEnum.EBP_NET_WORTH,
  },
  {
    title: 'The retirement plan has less than $5M in assets, but is directed by a qualifying plan fiduciary.',
    description: [
      <p className={OPTION_P}>
        Choose this option if the entity can document its status as an
        employee benefit plan directed by a plan fiduciary.
      </p>,
    ],
    id: methodEnum.EBP_FIDUCIARY,
  },
  {
    title: 'The retirement plan is self-directed by an accredited investor.',
    description: [
      <p className={OPTION_P}>
        Choose this option if the entity is an employee beneift plan that is self-directed by
        one or more accredited investors (an individual or a couple).
      </p>,
      <p className={`${OPTION_P} ${OPTION_B}`}>
        Note that you will have to go through a separate accreditation process for any individuals
        self-directing the benefit plan.
      </p>,
    ],
    id: methodEnum.EBP_SELF_DIRECTED,
  },
];

const entityVerificationOptions = [
  {
    title: 'The entity has more than $5M in assets and was not formed for the special purpose of buying this security.',
    description: [
      <p className={OPTION_P}>
        Choose this option if you can document that your entity has more than $5M in
        assets. You will also have to attest that the entity was not formed with
        the special purpose of buying this security.
      </p>,
      <p className={OPTION_P}>
        Entities that can qualify through this exemption include but are not limited to
        corporations, partnerships, and
        LLCs.
      </p>,
      <p className={OPTION_P}>
        This is the recommended verification method for entities that do not have
        a current letter from a qualified professional.
      </p>,
    ],
    id: methodEnum.ENTITY_NET_WORTH,
  },
  {
    title: 'The entity is owned solely by accredited individuals and entities.',
    description: [
      <p className={OPTION_P}>
        Choose this option if the equity owners of this entity are all
        individually accredited investors.
      </p>,
      <p className={`${OPTION_P} ${OPTION_B}`}>
        Note that you will have to verify the accreditation status of each equity owner
        individually.
      </p>,
    ],
    id: methodEnum.ENTITY_ACCREDITED_INDIVIDUALS,
  },
  {
    title: 'The entity is a charitable organization, foreign entity, family office, or family client of a family office.',
    description: [

      <p className={OPTION_P}>
        Choose this option if you can document that the entity qualifies by
        nature of its status as well as it&apos;s net worth net worth requirements.
      </p>,
      <p className={OPTION_P}>
        Examples of ogranizations that qualify are
        tax-exempt charitable organizations, foreign entities
        (eg foreign companies, governmental entities, indigenous governments), family
        offices, and family clients of family offices.
      </p>,
      <p className={OPTION_P}>
        Note that companies qualifying by this method are registered with and regulated
        by much more stringent regulatory organizations. If one of these classifications
        applies to your
        entity, it&apos;s likely you will already know.
      </p>,
    ],
    id: methodEnum.ENTITY_SPECIAL,
  },
  {
    title: 'The entity is a registered financial or investment institution.',
    description: [
      <p className={OPTION_P}>
        Choose this option if you can document that the entity qualifies by
        nature of its status without having to demonstrate asset values.
      </p>,
      <p className={OPTION_P}>
        Examples of qualifying entity types include registered banks, insurance companies,
        investment companies, broker-dealerships, Small Business Investment Companies, and
        Rural Business Investment Companies.
      </p>,
      <p className={OPTION_P}>
        Note that companies qualifying by this method are registered with and regulated
        by much more stringent regulatory organizations. If one of these classifications
        applies to your
        entity, it&apos;s likely you will already know.
      </p>,
    ],
    id: methodEnum.ENTITY_STATUS,
  },
  {
    title: 'None of the above scenarios apply, but I believe we can still prove our entity\'s accreditation.',
    description: [
      <p className={OPTION_P}>
        Choose this option if you have a signed letter from a qualified professional
        {' '}
        <span className={OPTION_B}>dated within the last 3 months</span>
        {' '}
        verifying that the professional has determined your entity&apos;s
        accreditation status.
      </p>,
      <p className={OPTION_P}>
        The uploaded letter must be signed by
        {' '}
        <span className={OPTION_B}>
          a registered broker-dealer, a licensed attorney,
          a certified public accountant, or a registered investment advisor.
        </span>
      </p>,
    ],
    id: methodEnum.ENTITY_NONE_OF_THE_ABOVE,
  },
];

const getVerificationOptions = (ENTITY_TYPE) => {
  switch (ENTITY_TYPE) {
    case 'INDIVIDUAL':
      return individualVerificationOptions;
    case 'JOINT':
      return jointVerificationOptions;
    case 'EBP':
      return ebpVerificationOptions;
    case 'ENTITY':
      return entityVerificationOptions;
    case 'TRUST':
      return trustVerificationOptions;
    default:
      throw Error('Unexpected option..');
  }
};

/*
 * Confirmation Content
 */
const entityTypeConfirmation = (entityType) => {
  switch (entityType) {
    case 'INDIVIDUAL':
      return (
        <li className="list-disc list-inside mb-2">
          You are
          {' '}
          <span className={OPTION_B}>investing as an individual under your own name</span>
          .
        </li>
      );
    case 'JOINT':
      return (
        <li className="list-disc list-inside mb-2">
          You are
          {' '}
          <span className={OPTION_B}>
            investing with your spouse (or spousal equivalent) under both of your names
          </span>
          .
        </li>
      );
    case 'EBP':
      return (
        <li className="list-disc list-inside mb-2">
          You are
          {' '}
          <span className={OPTION_B}>
            investing through a retirement account
          </span>
          .
        </li>
      );
    case 'ENTITY':
      return (
        <li className="list-disc list-inside mb-2">
          You are
          {' '}
          <span className={OPTION_B}>investing as a legal entity</span>
          .
        </li>
      );
    case 'TRUST':
      return (
        <li className="list-disc list-inside mb-2">
          You are
          {' '}
          <span className={OPTION_B}>investing as a trust</span>
          .
        </li>
      );
    default:
      return <div />;
  }
};

const legalNameConfirmation = (entityType, legalName) => {
  switch (entityType) {
    case 'INDIVIDUAL':
      return (
        <li className="list-disc list-inside mb-2">
          Your full, legal name is
          {' '}
          <span className={OPTION_B}>{legalName}</span>
          .
        </li>
      );
    case 'JOINT':
      return (
        <li className="list-disc list-inside mb-2">
          The full, legal names of your and your spouse are
          {' '}
          <span className={OPTION_B}>{legalName}</span>
          .
        </li>
      );
    case 'EBP':
      return (
        <li className="list-disc list-inside mb-2">
          The full, legal name of your retirement plan (as it appears in legal documents) is
          {' '}
          <span className={OPTION_B}>{legalName}</span>
          .
        </li>
      );
    case 'ENTITY':
      return (
        <li className="list-disc list-inside mb-2">
          The full, legal name of your entity (as it appears in legal documents) is
          {' '}
          <span className={OPTION_B}>{legalName}</span>
          .
        </li>
      );
    case 'TRUST':
      return (
        <li className="list-disc list-inside mb-2">
          The full, legal name of your trust (as it appears in legal documents) is
          {' '}
          <span className={OPTION_B}>{legalName}</span>
          .
        </li>
      );
    default:
      return <div />;
  }
};

export {
  // Q1 – Entity Type
  entityTypeEnum,
  entityTypeOptions,
  // Q3 - Verification Method
  methodEnum,
  requiredDocumentation,
  getVerificationOptions,
  // Q4 – Confirmation Copy
  entityTypeConfirmation,
  legalNameConfirmation,
};
