import React, { useState } from 'react';
import propTypes from 'prop-types';

import { MdAdd, MdRemove } from 'react-icons/md';
import faqs from './faqContent';

const FAQ = function () {
  return (
    <div className="container-fluid flex flex-row justify-between w-full">
      <Sidebar />
      <div className="container flex flex-col w-9/12 xl:8/12 py-8 px-24">
        <h1 className="text-4xl font-semibold py-10 text-left">Frequently Asked Questions</h1>
        {
            faqs.map((faq) => (
              <QuestionSection
                heading={faq.heading}
                questions={faq.questions}
                subId={faq.id}
              />
            ))
        }
      </div>
    </div>
  );
};

const QuestionSection = function ({ heading, questions, subId }) {
  const [activeIdxs, setActiveIdxs] = useState([]);

  const handleClick = (idx) => {
    if (activeIdxs.includes(idx)) {
      setActiveIdxs(activeIdxs.filter((i) => i !== idx));
    } else {
      setActiveIdxs(activeIdxs.concat([idx]));
    }
  };

  return (
    <div className="flex flex-col mb-10" id={subId} name={subId}>
      <h2 className="text-2xl font-bold text-gray-500 mb-5">{heading}</h2>
      <div className="flex flex-col divide-y">
        {
                questions.map(({ q, a, id }, idx) => (
                  <div
                    className="flex flex-row items-center"
                    id={id}
                    name={id}
                  >
                    <div
                      className="w-16 cursor-pointer self-start py-5"
                      role="button"
                      tabIndex={0}
                      onClick={() => handleClick(idx)}
                      onKeyDown={() => {}}
                    >
                      {activeIdxs.includes(idx)
                        ? <MdRemove className="mx-auto" size={27} />
                        : <MdAdd className="mx-auto" size={27} />}
                    </div>
                    <div className="w-2/3">
                      <div
                        className="text-xl cursor-pointer font-semibold my-5"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleClick(idx)}
                        onKeyDown={() => {}}
                      >
                        {q}

                      </div>
                      {activeIdxs.includes(idx) && a }
                    </div>
                  </div>
                ))
            }
        <div />

      </div>

    </div>
  );
};
QuestionSection.propTypes = {
  heading: propTypes.string.isRequired,
  subId: propTypes.string.isRequired,
  questions: propTypes.arrayOf(propTypes.any).isRequired,
};

const Sidebar = function () {
  return (
    <div className="sticky top-20 h-fit flex flex-col w-3/12 xl:w-2/12  border-blue-500 py-28 mx-auto">
      <h2 className="text-xl font-bold mb-4">FAQ Contents</h2>
      {
        faqs.map(({ heading, id }) => (
          // Make it scroll to the right sub-heading
          <a
            href={`#${id}`}
            className="text-base cursor-pointer font-semibold mb-4"
            onClick={(e) => {
              e.preventDefault();
              document.querySelector(`#${id}`).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }}
          >
            {heading}

          </a>
        ))
      }
    </div>
  );
};

export default FAQ;
