import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import AppContext from '../AppContext';
import { LG_ERR_TXT, B_COLORS } from '../AppStyles';
import { resendConfirmation } from '../api/confirmEmail';

import confirm from '../assets/confirm.svg';

const ConfirmEmail = function () {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Redirect if user isn't logged in
  if (!token || !user) {
    return <Navigate to="/login" />;
  }

  // Redirect if we're not in the confirmation stage
  if (user && user.userStage !== 'CONFIRMATION') {
    return <Navigate to="/" />;
  }

  // Handle Submission of the form
  const handleResend = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error: newError } = await resendConfirmation(token);

    // Save the token in localstorage and in the app state
    if (newError) {
      setError(newError);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center container container-fluid w-full mx-auto">
      <div className="container container-fluid divide-x divide-gray-200 flex flex-row w-full ">
        <div className="w-9/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 ">

          <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
            Confirm your Email to Continue
          </h2>
          <div className="flex items-center justify-between flex-wrap mb-20">
            <div className="w-full md:w-5/12">
              <img src={confirm} alt="Review" />
            </div>
            <div className="w-full md:w-6/12 px-16">
              <p className="text-xl font-semibold text-gray-800 mb-8">
                We&apos;ve sent a confirmation link to your email address.
              </p>
              <p className="text-lg text-gray-800 mb-5">
                If you don&apos;t see the confirmation link in your inbox,
                {' '}
                <span className="font-bold">
                  be sure to check your spam folder.
                </span>
                {' '}
                If you find the email in spam, be sure to move it to your primary
                inbox.
              </p>
              <p className="text-lg text-gray-800 mb-8">
                If you don&apos;t see the email, you can re-send your confirmation link below.
              </p>
              { error && (
              <div className={`mb-3 ${LG_ERR_TXT}`}>{error}</div>
              )}
              <div className="py-2 w-full xl:w-2/3 ">
                <button
                  className={`py-2 cursor-pointer w-full font-semibold rounded-xl border ${B_COLORS}`}
                  type="button"
                  onClick={handleResend}
                  onKeyDown={() => {}}
                  tabIndex={0}
                >
                  { loading ? (
                    <div className="flex flex-row items-center">
                      <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                        <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      <div className="-ml-7 w-full">Resend Verification</div>
                    </div>
                  ) : (
                    <div className="w-full">Resend Verification</div>
                  )}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
