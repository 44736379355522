import React from 'react';

import health from '../../assets/check_docs.svg';
import report from '../../assets/upload.svg';
import sync from '../../assets/check_doc_colab.svg';

const Features = function () {
  return (
    <div className="container-fluid mx-auto px-10 py-10">

      <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
        How It Works
      </h2>

      <div className="flex items-center flex-wrap mb-20">
        <div className="w-full md:w-1/2">
          <h2 className="text-lg font-semibold text-gray-500">
            Step 1:
          </h2>
          <h4 className="text-3xl text-gray-800 font-bold mb-3">Choose Verification Method</h4>
          <p className="text-gray-600 mb-8 pr-8">
            We use a three-question survey to help you decide how you&apos;d like to be accredited.
            Whether you&apos;re investing as an individual, an couple, a legal entity, or a trust,
            we&apos;ve got you covered.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src={health} alt="Monitoring" />
        </div>
      </div>

      <div className="flex items-center flex-wrap mb-20">
        <div className="w-full md:w-1/2">
          <img src={report} alt="Reporting" />
        </div>
        <div className="w-full md:w-1/2 pl-10">
          <h2 className="text-lg font-semibold text-gray-500">
            Step 2:
          </h2>
          <h4 className="text-3xl text-gray-800 font-bold mb-3">Upload Documentation</h4>
          <p className="text-gray-600 mb-8 pr-8">
            We&apos;ll walk you through the evidence you need to upload for our
            legal team to verify your status as an accredited investor.
          </p>
        </div>
      </div>

      <div className="flex items-center flex-wrap mb-20">
        <div className="w-full md:w-1/2">
          <h2 className="text-lg font-semibold text-gray-500">
            Step 3:
          </h2>
          <h4 className="text-3xl text-gray-800 font-bold mb-3">Legal Review</h4>
          <p className="text-gray-600 mb-8 pr-8">
            A licensed security attorney from our legal team reviews the information
            and documentation you&apos;ve provided. Then we issue you a certificate of
            accreditation verifying that you are an accredited investor.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src={sync} alt="Syncing" />
        </div>
      </div>

    </div>
  );
};

export default Features;
