import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    What is your legal name?
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-4">
    Enter the name that your investment will be under.
  </p>
);

const NamePage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const [{ user, token }, dispatch] = useContext(AppContext);

  let typeSpecificInstruction;
  let typeSpecificLabel;
  switch (user.investorType) {
    case 'INDIVIDUAL': {
      typeSpecificInstruction = (
        <p className="text-black text-md mb-6">
          You&apos;re investing as an individual, so this is your
          personal legal name. It must match the name on your
          legal and tax
          documentation.
        </p>
      );
      typeSpecificLabel = 'Your personal legal name';
      break;
    }
    case 'JOINT': {
      typeSpecificInstruction = (
        <p className="text-black text-md mb-6">
          You&apos;re investing as a spousal couple, so this is you and
          your spouse&apos;s
          legal names (&quot;Joe Smith &amp; Jane Smith&quot;).
          It must match the names on your legal and tax
          documentation.
        </p>
      );
      typeSpecificLabel = 'You and your spouse\'s legal names';
      break;
    }
    case 'ENTITY': {
      typeSpecificInstruction = (
        <p className="text-black text-md mb-6">
          You&apos;re investing through a business entity,
          so this is the name of the LLC, corporation, or legal
          entity you are investing through (not your personal name).
          It must match the
          name on your incorporation or formation documents
          (EG. &quot;Smith Investments, LLC&quot;).
        </p>
      );
      typeSpecificLabel = 'Your entity\'s legal name';
      break;
    }
    case 'TRUST': {
      typeSpecificInstruction = (
        <p className="text-black text-md mb-6">
          You&apos;re investing through a trust,
          so this is the name of the trust you are
          investing through (not your personal name).
          It must match the
          name on your formation documents
          (EG. &quot;Smith Family Trust&quot;).
        </p>
      );
      typeSpecificLabel = 'Your trust\'s legal name';
      break;
    }
    default: {
      console.log('Uh oh! Error');
    }
  }

  const updateVal = (e) => {
    e.preventDefault();
    setVal(e.target.value);
    onChange(e.target.value);
  };

  // Short input for name
  const nameSelector = (
    <div className="flex flex-col">
      <label className="flex-grow" htmlFor="legal-name-in">
        <p className="text-md font-semibold">{ typeSpecificLabel }</p>
        <input
          className="border border-[#DCE1E7] w-full px-1 mt-2 rounded-[.3REM]"
          type="text"
          id="legal-name-in"
          onChange={updateVal}
          value={val}
        />
      </label>
    </div>
  );

  const infoCol = (
    <div className="w-4/12 py-4 px-4" />
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { typeSpecificInstruction }
        { nameSelector }
      </div>
      { infoCol }
    </div>
  );
};
NamePage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
NamePage.defaultProps = {
  passedVal: '',
};

export default NamePage;
