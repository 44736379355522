import React, { useEffect, useContext, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';

import AppContext from '../../AppContext';

const AdminDashboard = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);

  if (!token || !user) {
    return (
      <Navigate to="/" />
    );
  }
  if (user.userType !== 'ADMIN') {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <div className="container-fluid flex flex-col w-7/12 mx-auto justify-center items-start">
      <h1 className="text-2xl font-bold mt-10 mb-2 w-10/12 mx-auto text-center">
        Admin Portal
      </h1>
      <p
        className="text-lg my-10 text-left"
      >
        Welcome admin! You can use the linked tools below to view and edit
        data. Please be careful, as many of the things you can do are non-reversable.
      </p>
      <Link
        className="text-xl text-blue-800 font-semibold hover:text-orange-500 hover:underline mb-8 ml-4"
        to="/admin/queue"
      >
        Accreditation Review Queue
      </Link>
      <Link
        className="text-xl text-blue-800 font-semibold hover:text-orange-500 hover:underline mb-8 ml-4"
        to="/admin/browse"
      >
        Browse or Search Accounts
      </Link>
      <Link
        className="text-xl text-blue-800 font-semibold hover:text-orange-500 hover:underline mb-8 ml-4"
        to="/admin/discount-codes"
      >
        Manage Discount Codes
      </Link>
      <Link
        className="text-xl text-blue-800 font-semibold hover:text-orange-500 hover:underline mb-8 ml-4"
        to="/admin/platforms"
      >
        Manage Platform Accounts
      </Link>
    </div>
  );
};

export default AdminDashboard;
