import React, { useState, useContext } from 'react';

import { Navigate } from 'react-router-dom';

import AppContext from '../../AppContext';
import done from '../../assets/done.svg';

const DoneStatusPage = function () {
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';
  const retstr = returnUrl ? `?returnUrl=${returnUrl}` : '';

  // Use Token State

  const [{ token, user }, dispatch] = useContext(AppContext);

  console.log(user);
  if (!user || user.userType === 'ADMIN') {
    return (
      <Navigate to={`/${retstr}`} />
    );
  }
  if (user.userStage !== 'IN_REVIEW') {
    return <Navigate to={`/${retstr}`} />;
  }

  const returnButton = (
    <a
      className="flex cursor-pointer flex-row w-11/12 items-center bg-[#5963F4] rounded-[.3REM] py-3 px-6 text-white font-semibold hover:brightness-75 "
      href={returnUrl}
    >
      <span className="mx-auto">Continue Investing</span>
    </a>
  );

  const content = (
    <div className="w-11/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 border-blue-500">

      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        You&apos;re done for now!
      </h2>

      <div className="flex items-center justify-between flex-wrap mb-20">
        <div className="w-full md:w-6/12 px-16">
          <h4 className="text-2xl text-gray-800 font-bold mb-10">
            No further action is required from you.
          </h4>
          <p className="text-lg text-gray-800 mb-5 font-semibold">
            You can close this tab and we&apos;ll handle the rest.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            You&apos;ll get an
            email notification and an accreditation letter
            as soon as we
            verify your accreditation or if we need any additional
            information.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            We&apos;ll automatically let your sponsor know when your accreditation status
            has been verified.
          </p>
          { returnUrl ? returnButton : (
            <p className="text-lg text-gray-800 mb-8 font-semibold">
              Happy investing!
            </p>
          )}
        </div>

        <div className="w-full md:w-1/2 p-5">
          <img src={done} alt="Done" />
        </div>
      </div>
    </div>

  );

  return (
    <div className="container-fluid divide-x divide-gray-200 flex flex-row w-screen">
      <div className="container-fluid flex flex-col w-full xl:w-10/12 mx-auto">
        { content }
      </div>

    </div>
  );
};

export default DoneStatusPage;
