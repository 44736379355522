/*
 * Set up state in the app
 */
function getUser() {
  const localUser = localStorage.getItem('user');
  return localUser && JSON.parse(localUser);
}

const getToken = () => {
  const tokenString = localStorage.getItem('token');
  return tokenString && JSON.parse(tokenString);
};

const getInitialState = () => ({
  token: getToken(),
  user: getUser(),
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TOKEN': {
      const newToken = action.token;
      if (newToken) {
        localStorage.setItem('token', JSON.stringify(newToken));
      } else {
        localStorage.removeItem('token');
      }
      return { ...state, token: newToken };
    }
    case 'SET_USER': {
      const newUser = action.user;
      if (newUser) {
        localStorage.setItem('user', JSON.stringify(newUser));
      } else {
        localStorage.removeItem('user');
      }
      return { ...state, user: newUser };
    }
    default: {
      return state;
    }
  }
};

export {
  reducer,
  getInitialState,
};
