import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';

import AppContext from '../../AppContext';
import { DISABLED_B_COLORS, B_COLORS, LG_ERR_TXT } from '../../AppStyles';

import { fetchUser } from '../../api/fetchUser';

import { downloadPdf } from '../../api/downloadPdf';
import { startNewAccreditation } from '../../api/accounts';

const FileDownload = require('js-file-download');

const InvestorAccount = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');
  const [acknowledged, setAcknowledged] = useState(false);
  const [restartError, setRestartError] = useState('');

  // Fetch the user from the DB to see how to render
  useEffect(async () => {
    // Update the user

    const { user: newUser, error: newError } = await fetchUser(token);
    if (newError) {
      setError(newError);
    } else {
      dispatch({ type: 'SET_USER', user: newUser });
    }
  }, []);

  if (done) {
    return (
      <Navigate to="/" />
    );
  }

  const handleChange = (e) => {
    // Allow the default so that the checkbox updates with state change
    setAcknowledged(!acknowledged);
  };
  const handleDownload = async (e, idx) => {
    e.preventDefault();
    // eslint-disable-next-line no-underscore-dangle
    const b = await downloadPdf(token, user._id, idx);
    FileDownload(b, 'accreditation.pdf');
  };

  const handleStartNewButton = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error: newError, user: newUser } = await startNewAccreditation(token);
    if (newError) {
      setRestartError(newError);
    } else {
      dispatch({ type: 'SET_USER', user: newUser });
      setDone(true);
    }

    setLoading(false);
  };

  const yourInfo = (
    <div>
      <h2 className="text-2xl underline font-semibold mb-5 mt-5">Your Account Info</h2>
      <p className="text-lg mb-3">
        <span className="font-semibold">Email:</span>
        {' '}
        { user.email }
      </p>
      <p className="text-lg mb-3">
        At this time, we cannot change the email addresses on file for an account. If you need
        to use a different email address, either create a new account or reach out
        to
        {' '}
        <a className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" href="mailto:support@checkinvestorstatus.com">support@checkinvestorstatus.com</a>
        .
      </p>
      <p className="text-lg mb-3">
        <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/reset-password">Click Here</Link>
        {' '}
        to go to reset your password.
      </p>
    </div>
  );

  const pastAccreditations = (
    <div>
      <h2 className="text-2xl underline font-semibold mb-5 mt-10">Completed Accreditations</h2>
      <p className="text-lg mb-3">
        Below are all of your accreditations. Click any of the links below to download
        that Certificate of Accreditation.
      </p>
      <ul>
        {
        (user.accreditations.length > 0) ? (
          user.accreditations.map((a, idx) => {
            const issued = dayjs(a.dateCreated).format('MM/DD/YYYY');
            const expiration = dayjs(a.expirationDate).format('MM/DD/YYYY');
            const expStr = new Date(a.expirationDate) < new Date() ? `(expired on ${expiration})` : `(expires on ${expiration})`;

            return (
              <li className="list-disc ml-10">
                <span
                  className="text-blue-800 cursor-pointer font-bold hover:text-orange-500 hover:underline"
                  role="button"
                  onClick={(e) => handleDownload(e, idx)}
                  onKeyDown={() => {}}
                  tabIndex={0}
                >
                  Accreditation issued on
                  {' '}
                  {issued}
                  {' '}
                  {expStr}
                </span>
              </li>
            );
          })
        ) : (
          <p className="text-lg">You don&apos;t have any accreditations at this time</p>
        )
      }
      </ul>
    </div>
  );

  const confirmationCheckbox = (
    <div className="my-7 mx-2">
      <div className="flex items-start">
        <div className="flex items-center">
          &#8203;
          <input
            type="checkbox"
            checked={acknowledged}
            className="form-checkbox border-gray-400"
            onChange={handleChange}
            id="verify-selections"
          />
        </div>
        <div className="ml-3 font-semibold" htmlFor="verify-selections">
          I understand that resetting my account will clear all data uploaded so far
          and that any discounts or payments made by an issuer on my behalf will be removed.
        </div>
      </div>
    </div>
  );

  const startNewButton = (
    acknowledged ? (
      <div
        className={`w-3/4 cursor-pointer md-1/2 xl:w-1/3 rounded-xl font-semibold text-center text-white py-3 ${B_COLORS}`}
        role="button"
        onClick={handleStartNewButton}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        { loading ? (
          <div className="flex flex-row items-center">
            <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
              <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <div className="-ml-7 w-full">Clear Progress and Start New Accreditation</div>
          </div>
        ) : (
          <div className="w-full">Clear Progress and Start New Accreditation</div>
        )}
      </div>
    ) : (
      <div
        className={`w-3/4 cursor-pointer md-1/2 xl:w-1/3 rounded-xl font-semibold text-center text-white py-3 ${DISABLED_B_COLORS}`}
        role="button"
        onClick={() => {}}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        { loading ? (
          <div className="flex flex-row items-center">
            <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
              <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <div className="-ml-7 w-full">Clear Progress and Start New Accreditation</div>
          </div>
        ) : (
          <div className="w-full">Clear Progress and Start New Accreditation</div>
        )}
      </div>
    )
  );

  const startNew = (
    <div className="mt-10">
      <h2 className="text-2xl underline font-semibold mb-5">Start a new Accreditation</h2>
      <p className="text-lg mb-3">
        Click below to restart the accreditation process. You cannot do this if your
        accreditation is processing or currently in legal review.
      </p>
      <p className="text-lg font-semibold mb-5">
        Note that this will clear any information or uploads that you&apos;ve provided so far.
        This will also clear any discount codes applied.
      </p>
      <p className="text-lg font-bold mb-5 text-red-600">
        If an issuer has paid for your current accreditation, do not click this button or
        you will have
        to pay for your own accreditation!
      </p>
      { confirmationCheckbox }
      { startNewButton }
      { restartError && <div className={`${LG_ERR_TXT} mb-3 mt-2 ml-10`}>{restartError}</div>}
    </div>
  );

  const content = (
    <div className="w-9/12 xl:w-10/12 container-fluid mx-auto pr-10 pl-20 py-10 border-blue-500">
      <h1 className="font-bold text-3xl text-center">Your Account</h1>
      { yourInfo }
      { pastAccreditations }
      { startNew }
    </div>
  );

  return content;
};

export default InvestorAccount;
