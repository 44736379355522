import { PREFIX } from './prefix';

async function downloadPdf(token, userId, idx) {
  return fetch(`${PREFIX}/api/get-accreditation-letter/${userId}/${idx}`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/pdf',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.blob())
    .catch((err) => ({ error: err }));
}

async function sendAccreditationByEmail(token, emailToSend) {
  return fetch(`${PREFIX}/api/send-accreditation-letter`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({ email: emailToSend }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  downloadPdf,
  sendAccreditationByEmail,
};
