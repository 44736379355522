import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Notes for Reviewer (Optional)
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-8">
    Add any notes you would like to provide to your legal reviewer.
  </p>
);

const NotesPage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const [{ user, token }, dispatch] = useContext(AppContext);

  const updateVal = (e) => {
    e.preventDefault();
    setVal(e.target.value);
    onChange(e.target.value);
  };

  // long input for name
  const textArea = (
    <div className="flex flex-col">
      <label className="flex-grow" htmlFor="legal-name-in">
        <p className="text-md font-semibold">Optional notes for the reviewer</p>
        <textarea
          className="border border-[#DCE1E7] w-11/12 px-2 py-1 mt-2 rounded-[.3REM] h-32"
          type="text"
          maxLength="500"
          id="text-in"
          onChange={updateVal}
          value={val}
        />
      </label>
      <p className="text-[#6A6E74] text-sm">
        { (val || '').length }
        {' '}
        / 500 character maximum
      </p>
    </div>
  );

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="text-md">
        Please provide your legal reviewer with any information you think will be helpful in
        reviewing your accreditation documentation.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { textArea }
      </div>
      { infoCol }
    </div>
  );
};
NotesPage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
NotesPage.defaultProps = {
  passedVal: '',
};

export default NotesPage;
