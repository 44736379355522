/*
 * Set up state in the app
 */
const B_COLORS = 'text-white border-indigo-600 bg-indigo-600 hover:bg-indigo-800 hover:border-indigo-800';
const DISABLED_B_COLORS = 'text-white opacity-30 border-indigo-600 bg-indigo-600 hover:bg-indigo-600 hover:border-indigo-600';
const L_COLORS = 'text-blue-800 hover:underline hover:text-orange-500';

const ERR_TXT = 'text-xs text-red-600 font-semibold mt-1';
const SUCCESS_TXT = 'text-xs text-green-600 font-semibold mt-1';
const LG_ERR_TXT = 'text-sm text-red-600 font-semibold mt-1';
const LG_SUCCESS_TXT = 'text-sm text-green-600 font-semibold mt-1';

const INVALID_FORM = 'text-red-600 border-red-600';

const FAQ_P = 'text-black text-md mb-4';
const FAQ_B = 'font-semibold';
const FAQ_SUBHEADING = 'text-black font-bold text-md mt-8 mb-4';
const FAQ_OL = 'mb-8 ml-10';
const FAQ_OLI = 'mb-3 list-decimal';
const FAQ_UL = 'mb-8 ml-10';
const FAQ_ULI = 'mb-3 list-disc';
const FAQ_L = 'font-semibold text-blue-800 hover:underline hover:text-orange-500';

export {
  B_COLORS,
  DISABLED_B_COLORS,
  L_COLORS,
  INVALID_FORM,
  ERR_TXT,
  SUCCESS_TXT,
  LG_ERR_TXT,
  LG_SUCCESS_TXT,
  FAQ_SUBHEADING,
  FAQ_P,
  FAQ_B,
  FAQ_OL,
  FAQ_OLI,
  FAQ_UL,
  FAQ_ULI,
  FAQ_L,
};
