import React from 'react';
import review from '../../assets/review.svg';

const PaymentProcessingStatusPage = function () {
  return (
    <div className="w-9/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 border-blue-500">

      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Thank you for your purchase!
      </h2>

      <div className="flex items-center justify-between flex-wrap mb-20">
        <div className="w-full md:w-6/12 px-16">
          <h4 className="text-3xl text-gray-800 font-bold mb-10">We&apos;re processing your order</h4>
          <p className="text-lg text-gray-800 mb-5">
            Your order is processing, and you will receive an
            {' '}
            <span className="font-semibold">
              email receipt
            </span>
            {' '}
            as soon as your
            payment goes through.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            Review generally takes
            {' '}
            <span className="font-semibold">
              1-2 business days
            </span>
            {' '}
            from time of purchase.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            We really value your business and hope that your exprience using our
            accreditation service was a positive one!
          </p>
          <p className="text-lg text-gray-800 mb-8">
            If you have any questions, or if you think you&apos;re seeing this in error, please
            reach out to our support email &mdash;
            <a className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" href="mailto:support@checkinvestorstatus.com">support@checkinvestorstatus.com</a>
          </p>
        </div>

        <div className="w-full md:w-1/2 p-5">
          <img src={review} alt="Review" />
        </div>
      </div>
    </div>
  );
};

export default PaymentProcessingStatusPage;
