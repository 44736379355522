import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';
import { entityTypeOptions } from './content';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    How will you be investing?
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-6">
    Choose the type of legal or business entity you&apos;ll be investing through.
  </p>
);

const TypePage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const [{ user, token }, dispatch] = useContext(AppContext);

  const clickOption = (e, newVal) => {
    e.preventDefault();
    setVal(newVal);
    onChange(newVal);
  };

  // Get qualification options based on user state.

  const optionSelects = entityTypeOptions.map(({ title, description, id }, idx) => (
    <div
      className={`border cursor-pointer border-[#DCE1E7] rounded-[.3REM] w-full flex flex-row pt-3 pb-1 ${
        val === id && 'border-[#5963F4] bg-[#EFF0FE]'

      }`}
      onClick={(e) => clickOption(e, id)}
      onKeyDown={() => {}}
      tabIndex={idx}
      role="option"
      aria-selected={false}
    >
      {/* Radio Button */}
      <div
        className={(
          `flex flex-row items-center justify-around w-6 h-6 mx-4 rounded-full border-2 flex-shrink-0 ${
            val === id ? 'border-[#5963F4]' : 'border-[#A1A5AD]'}`
          )}
      >
        {
          val === id && (
            <div className="w-3 h-3 rounded-full mx-auto my-auto bg-[#5963F4] flex-shrink-0" />
          )
        }
      </div>
      {/* Title and description */}
      <div className="flex flex-col">
        <h3 className={`mb-2 font-regular ${val === id && 'font-bold'}`}>
          { title }
        </h3>
        { val === id && (
          <p className="">
            { description }
          </p>
        )}

      </div>
    </div>
  ));
  const optionSelector = (
    <div className="flex flex-col gap-4">
      { optionSelects }
    </div>
  );

  const infoCol = (
    <div className="w-4/12 py-4 px-4" />
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { optionSelector }
      </div>
      { infoCol }
    </div>
  );
};
TypePage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
TypePage.defaultProps = {
  passedVal: '',
};

export default TypePage;
