import { PREFIX } from './prefix';

async function setPaymentProcessing(token) {
  return fetch(`${PREFIX}/api/start-payment-processing`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function unsetPaymentProcessing(token) {
  return fetch(`${PREFIX}/api/fail-payment-processing`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function applyDiscountCode(token, code) {
  return fetch(`${PREFIX}/api/apply-code`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({ code }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function fetchCharges(token) {
  return fetch(`${PREFIX}/api/charges`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  setPaymentProcessing,
  unsetPaymentProcessing,
  applyDiscountCode,
  fetchCharges,
};
