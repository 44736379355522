import React from 'react';
import review from '../../assets/review.svg';
import roadmap from '../../assets/roadmap.svg';

const About = function () {
  return (
    <div className="w-9/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 border-blue-500">

      <h2 className="text-4xl font-bold text-center text-gray-800 mb-36">
        About Check Investor Status
      </h2>

      <div className="flex flex-row items-center justify-between flex-wrap mb-36">
        <div className="w-full md:w-6/12 px-16">
          <h4 className="text-3xl text-gray-800 font-bold mb-10">A Modern Accreditation Service</h4>
          <p className="text-lg text-gray-800 mb-5">
            Our mission is to make investor accreditation bulletproof for issuers and seamless for
            investors.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            Investor accreditation is a major hangup for issuers and investors alike.
            Not only is accreditation a complicated branch of securities law, the consequences of
            getting it wrong can be deal-breaking.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            Check Investor Status leverages modern technology to make the investor accreditation
            process faster, easier, and less expensive all without jeopardizing security or quality.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src={review} alt="Review" />
        </div>
      </div>
      <div className="flex flex-col items-center flex-wrap mb-36 w-full">
        <h4 className="text-3xl text-gray-800 font-bold mb-10">What sets us apart?</h4>
        <div className="flex flex-row justify around w-full px-16 mx-auto justify-between ">
          <div className="w-1/4 flex flex-col items-center text-lg">
            <h4 className="font-bold text-2xl mb-6 underline">Simplicity</h4>
            <p className="text-lg text-gray-800 mb-3 pl-5">
              No onle likes confusing interfaces
              or reading paragraphs of legal details. We&apos;ve designed
              each step of our process to be easy and intuitive.
            </p>
            <p className="text-lg text-gray-800 mb-5 pl-5">
              We handle
              the complexity so that your accreditation experience
              is seamless and bulletproof.
            </p>
          </div>
          <div className="w-1/4 flex flex-col items-center text-lg">
            <h4 className="font-bold text-2xl mb-6 underline">Service</h4>
            <p className="text-lg text-gray-800 mb-3 pl-5">
              We can confidently boast best-in-class customer service.
            </p>
            <p className="text-lg text-gray-800 mb-5 pl-5">
              Our legal team works quickly, our support team is responsive, and
              our primary commitment is to provide issuers and investors with the best
              possible accreditation experience.
            </p>
          </div>
          <div className="w-1/4 flex flex-col items-center text-lg">
            <h4 className="font-bold text-2xl mb-6 underline">Cost</h4>
            <p className="text-lg text-gray-800 mb-3 pl-5">
              We&apos;ve used cutting edge automation to reduce the cost to our
              issuers and investors.
            </p>
            <p className="text-lg text-gray-800 mb-5 pl-5">
              Despite our lower cost, every certificate of accreditation is reviewed and signed
              by a licensed attorney from our legal team.
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between flex-wrap mb-20">
        <div className="w-full md:w-5/12">
          <img src={roadmap} alt="Roadmap" />
        </div>
        <div className="w-full md:w-7/12 px-16">
          <h4 className="text-3xl text-gray-800 font-bold mb-10">We&apos;re in Active Development</h4>
          <p className="text-lg text-gray-800 mb-5">
            We are always on the hunt for new features and ways to improve.
          </p>
          <p className="text-lg text-gray-800 mb-8">
            If you have any ideas for ways we can make your life easier,
            we would love to hear from you &mdash;
            {' '}
            <a className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" href="mailto:support@checkinvestorstatus.com">support@checkinvestorstatus.com</a>
            .
          </p>
          <h4 className="font-semibold mb-4 text-xl">Upcoming features include:</h4>
          <ul className="ml-8 my-4">
            <li className="list-disc mb-2 text-lg">Pulling credit reports and tax documents for investors (Feb)</li>
            <li className="list-disc mb-2 text-lg">Support for &quot;Qualified Purchaser&quot; accreditation (Feb)</li>
            <li className="list-disc mb-2 text-lg">Self-certification service for 506b (March)</li>
            <li className="list-disc mb-2 text-lg">Self-certification service for Reg A (March)</li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default About;
