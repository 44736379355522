import React from 'react';

const STRONG_STYLE = 'font-bold my-4';
const B_STYLE = 'font-semibold mb-2 ml-5';
const P_STYLE = 'mb-4 ml-5';
const LI_STYLE = 'ml-10 list-disc';

const PrivacyPolicy = function () {
  return (
    <div className="flex flex-col text-lg px-10 w-9/12 py-20">
      <h1 className="font-bold my-8 text-3xl">
        Privacy Policy
      </h1>
      <p className={P_STYLE}>
        Protecting
        your private information is our priority. This Statement of Privacy applies
        to
        {' '}
        <a
          href="http://www.checkinvestorstatus.com"
        >
          www.checkinvestorstatus.com
        </a>
        {' '}
        ,
        and Check Investor Status, LLC and governs data collection and usage. For
        the purposes of this Privacy Policy, unless otherwise noted, all references
        to Check Investor Status, LLC include
        {' '}
        <a
          href="http://www.checkinvestorstatus.com"
        >
          www.checkinvestorstatus.com
        </a>
        {' '}
        .
        The Check Investor Status, LLC website is a investor accreditation site. By
        using the Check Investor Status, LLC website, you consent to the data
        practices described in this statement.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Collection_of_your_Personal_Information_4">
        Collection of your
        Personal Information
      </h2>
      {' '}
      <p className={P_STYLE}>
        In order to better provide you with products
        and services offered, Check Investor Status, LLC may collect personally
        identifiable information, such as your:
      </p>
      {' '}
      <ul className="mb-8">
        {' '}
        <li className={LI_STYLE}>
          First and Last
          Name
        </li>
        {' '}
        <li className={LI_STYLE}>Mailing Address</li>
        {' '}
        <li className={LI_STYLE}>E-mail Address</li>
        {' '}
        <li className={LI_STYLE}>
          Phone
          Number
        </li>
        {' '}
        <li className={LI_STYLE}>Job Title</li>
        {' '}
        <li className={LI_STYLE}>Credit Check</li>
        <li className={LI_STYLE}>Asset and Liability Information</li>
        <li className={LI_STYLE}>Income data</li>
        <li className={LI_STYLE}>Net worth data</li>
        <li className={LI_STYLE}>
          Any other information or data required to take reasonable steps to
          check and investor&apos;s status as an accredited investor pursuant to
          the definition of Accredited Investor in rule 501 of Regulation D of
          the Securites Act of 1933.
        </li>
      </ul>
      {' '}
      <p className={P_STYLE}>
        If you purchase Check Investor Status,
        LLC&apos;s products and services, we collect billing and credit card information.
        This information is used to complete the purchase transaction.
      </p>
      {' '}
      <p className={P_STYLE}>
        Check
        Investor Status, LLC may also collect anonymous demographic information,
        which is not unique to you, such as your:
      </p>
      {' '}
      <ul className="mb-8">
        <li className={LI_STYLE}>Age</li>
        <li className={LI_STYLE}>Gender</li>
        <li className={LI_STYLE}>Household Income</li>
      </ul>
      {' '}
      <p className={P_STYLE}>
        We do not collect any
        personal information about you unless you voluntarily provide it to us.
        However, you may be required to provide certain personal information to us
        when you elect to use certain products or services. These may include: (a)
        registering for an account; (b) entering a sweepstakes or contest sponsored
        by us or one of our partners; © signing up for special offers from selected
        third parties; (d) sending us an email message; (e) submitting your credit
        card or other payment information when ordering and purchasing products and
        services. To wit, we will use your information for, but not limited to,
        communicating with you in relation to services and/or products you have
        requested from us. We also may gather additional personal or non-personal
        information in the future.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Use_of_your_Personal_Information_24">
        Use of your Personal Information
      </h2>
      {' '}
      <p className={P_STYLE}>
        {' '}
        Check Investor Status, LLC collects and uses your personal
        information to operate and deliver the services you have requested.

        Check Investor Status, LLC may also use your personally identifiable
        information to inform you of other products or services available from Check
        Investor Status, LLC and its affiliates.

      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Sharing_Information_with_Third_Parties_29">
        Sharing Information with Third Parties
      </h2>
      <p className={P_STYLE}>
        Check Investor Status, LLC does not
        sell, rent or lease its customer lists to third parties.
      </p>
      <p className={P_STYLE}>
        Check Investor Status, LLC may, from time to time, contact you on behalf of
        external business partners about a particular offering that may be of
        interest to you. In those cases, your unique personally identifiable
        information (e-mail, name, address, telephone number) is transferred to the
        third party. Check Investor Status, LLC may share data with trusted partners
        to help perform statistical analysis, send you email or postal mail, provide
        customer support, or arrange for deliveries. All such third parties are
        prohibited from using your personal information except to provide these
        services to Check Investor Status, LLC, and they are required to maintain
        the confidentiality of your information.
      </p>
      <p className={P_STYLE}>
        Check Investor Status, LLC may disclose your personal information, without
        notice, if required to do so by law or in the good faith belief that such
        action is necessary to: (a) conform to the edicts of the law or comply with
        legal process served on Check Investor Status, LLC or the site; (b) protect
        and defend the rights or property of Check Investor Status, LLC; and/or ©
        act under exigent circumstances to protect the personal safety of users of
        Check Investor Status, LLC, or the public.
      </p>
      <p className={P_STYLE}>
        In the event of an acquisition or transfer of ownership of Check Investor Status,
        LLC by another entity, we will share your information with that company. We may
        also retain a copy of that information before and answer tranfering ownership of
        the company or the data.
      </p>
      <h2 className={STRONG_STYLE} id="OptOut_of_Disclosure_of_Personal_Information_to_Third_Parties_36">
        Disclosure of Personal Information to Issuers
      </h2>
      <p className={P_STYLE}>
        This section uses the entities and terms defined in our Terms of Service
        including &quot;Issuer&quot;,
        &quot;Certificate of Accreditation&quot;, and &quot;Investor.&quot; See the Terms
        of Service for Check Investor Status, LLC for definitions surrounding
        these entities.
      </p>
      <p className={P_STYLE}>
        We reserve the right to disclose information generated by
        investors&apos; use of the service to relevant issuers. When an issuer
        pays for an investor&apos;s use of the service, they are attesting that
        they have that investor&apos;s permission to do so. Check Investor
        Status, LLC does not verify the accuracy of that representation.
      </p>
      <p className={P_STYLE}>
        When an issuer makes that representation and pays for an investor&apos;s
        use of the service, we disclose to them the following information about
        the investor:
      </p>
      <ul className="mb-8">
        <li className={LI_STYLE}>
          Details of use of the service by that investor past and present.
        </li>
        <li className={LI_STYLE}>
          Whether an accreditation certificate has been issued to that investor.
        </li>
        <li className={LI_STYLE}>
          The reasonable steps taken by the company or its reviewers to determine
          the Accredited Investor status of that investor.
        </li>
        <li className={LI_STYLE}>
          The determination of Accreditation status reached during review, as well
          as the methods of review used, and any relevant evidence or types of evidence
          used in making that determination.
        </li>
        <li className={LI_STYLE}>
          Creation and expiration dates of any determinations that have been made.
        </li>
        <li className={LI_STYLE}>
          Downloadable Certificates of Accreditation for this user, and therebay all information
          and details those certificates include.
        </li>
      </ul>
      <h2 className={STRONG_STYLE} id="OptOut_of_Disclosure_of_Personal_Information_to_Third_Parties_36">
        Opt-Out of Disclosure of Personal Information to Third Parties
      </h2>
      {' '}
      <p className={P_STYLE}>
        {' '}
        In
        connection with any personal information we may disclose to a third party
        for a business purpose, you have the right to know: • The categories of
        personal information that we disclosed about you for a business purpose.

        You have the right under the California Consumer Privacy Act of 2018 (CCPA)
        and certain other privacy and data protection laws, as applicable, to
        opt-out of the disclosure of your personal information. If you exercise your
        right to opt-out of the disclosure of your personal information, we will
        refrain from disclosing your personal information, unless you subsequently
        provide express authorization for the disclosure of your personal
        information. To opt-out of the disclosure of your personal information,
        email
        {' '}
        <a
          href="/cdn-cgi/l/email-protection#285b5d5858475a5c684b404d4b4341465e4d5b5c475a5b5c495c5d5b064b4745"
        >
          <span
            data-cfemail="97e4e2e7e7f8e5e3d7f4fff2f4fcfef9e1f2e4e3f8e5e4e3f6e3e2e4b9f4f8fa"
          >
            [email&#160;protected]
          </span>
        </a>
        .

      </p>
      <h2 className={STRONG_STYLE} id="Tracking_User_Behavior_42">
        Tracking User Behavior
      </h2>
      <p className={P_STYLE}>
        Check Investor Status, LLC may keep track of the websites and pages our
        users visit within Check Investor Status, LLC, in order to determine what
        Check Investor Status, LLC services are the most popular. This data is used
        to deliver customized content and advertising within Check Investor Status,
        LLC to customers whose behavior indicates that they are interested in a
        particular subject area.

      </p>
      <h2 className={STRONG_STYLE} id="Automatically_Collected_Information_45">
        Automatically Collected
        Information
      </h2>
      <p className={P_STYLE}>
        Information about your computer hardware and software
        may be automatically collected by Check Investor Status, LLC. This
        information can include: your IP address, browser type, domain names, access
        times and referring website addresses. This information is used for the
        operation of the service, to maintain quality of the service, and to provide
        general statistics regarding use of the Check Investor Status, LLC
        website.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Use_of_Cookies_48">
        Use of Cookies
        {' '}
      </h2>
      {' '}
      <p className={P_STYLE}>
        {' '}
        The
        Check Investor Status, LLC website may use “cookies” to help you personalize
        your online experience. A cookie is a text file that is placed on your hard
        disk by a web page server. Cookies cannot be used to run programs or deliver
        viruses to your computer. Cookies are uniquely assigned to you, and can only
        be read by a web server in the domain that issued the cookie to you.

        One of the primary purposes of cookies is to provide a convenience feature
        to save you time. The purpose of a cookie is to tell the Web server that you
        have returned to a specific page. For example, if you personalize Check
        Investor Status, LLC pages, or register with Check Investor Status, LLC site
        or services, a cookie helps Check Investor Status, LLC to recall your
        specific information on subsequent visits. This simplifies the process of
        recording your personal information, such as billing addresses, shipping
        addresses, and so on. When you return to the same Check Investor Status, LLC
        website, the information you previously provided can be retrieved, so you
        can easily use the Check Investor Status, LLC features that you customized.

        You have the ability to accept or decline cookies. Most Web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. If you choose to decline cookies,
        you may not be able to fully experience the interactive features of the
        Check Investor Status, LLC services or websites you visit.

      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Links_55">
        Links
        {' '}
      </h2>
      {' '}
      <p className={P_STYLE}>
        This website contains links to
        other sites. Please be aware that we are not responsible for the content or
        privacy practices of such other sites. We encourage our users to be aware
        when they leave our site and to read the privacy statements of any other
        site that collects personally identifiable information.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Security_of_your_Personal_Information_58">
        Security of your Personal
        Information
      </h2>
      <p className={P_STYLE}>
        No method of protecting personal data is fully secure. While Check Investor Status, LLC
        makes reasonable efforts to secure your personal data, we cannot guarantee the security
        or confidentiality of your data.
      </p>
      <p className={P_STYLE}>
        Check Investor Status, LLC secures your personal
        information from unauthorized access, use, or disclosure. Check Investor
        Status, LLC uses the following methods for this purpose:
      </p>
      <h3 className={B_STYLE} id="SSL_Protocol_61">
        SSL Protocol
      </h3>
      {' '}
      <p className={P_STYLE}>
        {' '}
        When personal information
        (such as a credit card number) is transmitted to other websites, it is
        protected through the use of encryption, such as the Secure Sockets Layer
        (SSL) protocol.

        We strive to take appropriate security measures to protect against
        unauthorized access to or alteration of your personal information.
        Unfortunately, no data transmission over the Internet or any wireless
        network can be guaranteed to be 100% secure. As a result, while we strive to
        protect your personal information, you acknowledge that: (a) there are
        security and privacy limitations inherent to the Internet which are beyond
        our control; and (b) security, integrity, and privacy of any and all
        information and data exchanged between you and us through this Site cannot
        be guaranteed.

        Right to Deletion Subject to certain exceptions set out below, on receipt of
        a verifiable request from you, we will:

      </p>
      {' '}
      <ul className="mb-8">
        {' '}
        <li className={LI_STYLE}>Delete your personal information from our records; and</li>
        <li className={LI_STYLE}>
          Direct any service providers to delete your personal information from
          their records.
        </li>
      </ul>
      {' '}
      <p className={P_STYLE}>
        Please note that we may not be able to comply
        with requests to delete your personal information if it is necessary to:
      </p>
      <ul className="mb-8">
        {' '}
        <li className={LI_STYLE}>
          Complete the transaction for which the personal information was
          collected, fulfill the terms of a written warranty or product recall
          conducted in accordance with federal law, provide a good or service
          requested by you, or reasonably anticipated within the context of our
          ongoing business relationship with you, or otherwise perform a contract
          between you and us;
        </li>
        {' '}
        <li className={LI_STYLE}>
          Detect security incidents, protect against
          malicious, deceptive, fraudulent, or illegal activity; or prosecute those
          responsible for that activity;
        </li>
        {' '}
        <li className={LI_STYLE}>
          Debug to identify and repair errors
          that impair existing intended functionality;
        </li>
        {' '}
        <li className={LI_STYLE}>
          Exercise free speech,
          ensure the right of another consumer to exercise his or her right of free
          speech, or exercise another right provided for by law;
        </li>
        {' '}
        <li className={LI_STYLE}>
          Comply with
          the California Electronic Communications Privacy Act;
        </li>
        {' '}
        <li className={LI_STYLE}>
          Engage in
          public or peer-reviewed scientific, historical, or statistical research in
          the public interest that adheres to all other applicable ethics and privacy
          laws, when our deletion of the information is likely to render impossible or
          seriously impair the achievement of such research, provided we have obtained
          your informed consent;
        </li>
        {' '}
        <li className={LI_STYLE}>
          Enable solely internal uses that are
          reasonably aligned with your expectations based on your relationship with
          us;
        </li>
        {' '}
        <li className={LI_STYLE}>Comply with an existing legal obligation; or</li>
        {' '}
        <li className={LI_STYLE}>
          Otherwise
          use your personal information, internally, in a lawful manner that is
          compatible with the context in which you provided the information.
        </li>
      </ul>
      {' '}
      <h2 className={STRONG_STYLE} id="Children_Under_Thirteen_83">
        Children Under Thirteen
      </h2>
      {' '}
      <p className={P_STYLE}>
        Check Investor Status, LLC does not knowingly collect personally
        identifiable information from children under the age of thirteen. If you are
        under the age of thirteen, you must ask your parent or guardian for
        permission to use this website.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Disconnecting_your_Check_Investor_Status_LLC_Account_from_Third_Party_Websites_86">
        Disconnecting your Check Investor Status, LLC Account from Third Party
        Websites
      </h2>
      {' '}
      <p className={P_STYLE}>
        {' '}
        You will be able to connect your Check Investor Status,
        LLC account to third party accounts. BY CONNECTING YOUR CHECK INVESTOR
        STATUS, LLC ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE
        THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU
        TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY
        SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY
        IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS
        FEATURE. You may disconnect your account from a third party account at any
        time. Users may disconnect their accounts from third-party websites by
        visiting their “My Account” page, or by emailing
        {' '}
        <a
          href="/cdn-cgi/l/email-protection#364543464659444276555e53555d5f584053454259444542574243451855595b"
        >
          <span
            data-cfemail="5b282e2b2b34292f1b38333e383032352d3e282f3429282f3a2f2e2875383436"
          >
            [email&#160;protected]
          </span>
        </a>
        .
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="OptOut__Unsubscribe_from_Third_Party_Communications_89">
        Opt-Out &amp; Unsubscribe from Third Party Communications
      </h2>
      {' '}
      <p className={P_STYLE}>
        We
        respect your privacy and give you an opportunity to opt-out of receiving
        announcements of certain information. Users may opt-out of receiving any or
        all communications from third-party partners of Check Investor Status, LLC
        by contacting us here:
      </p>
      {' '}
      <ul className="mb-8">
        {' '}
        <li className={LI_STYLE}>
          {' '}
          Web page:
          {' '}
          <a
            href="http://www.checkinvestorstatus.com/contact"
          >
            www.checkinvestorstatus.com/contact
          </a>
        </li>
        {' '}
        <li className={LI_STYLE}>
          {' '}
          Email:
          {' '}
          <a
            href="/cdn-cgi/l/email-protection#a3d0d6d3d3ccd1d7e3c0cbc6c0c8cacdd5c6d0d7ccd1d0d7c2d7d6d08dc0ccce"
          >
            <span
              data-cfemail="23505653534c515763404b4640484a4d554650574c515057425756500d404c4e"
            >
              [email&#160;protected]
            </span>
          </a>
        </li>
        {' '}

      </ul>
      {' '}
      <h2 className={STRONG_STYLE} id="Email_Communications_94">
        E-mail Communications
      </h2>
      {' '}
      <p className={P_STYLE}>
        {' '}
        From time to time, Check Investor Status, LLC may contact you via
        email for the purpose of providing announcements, promotional offers,
        alerts, confirmations, surveys, and/or other general communication. In order
        to improve our Services, we may receive a notification when you open an
        email from Check Investor Status, LLC or click on a link therein.  If you
        would like to stop receiving marketing or promotional communications via
        email from Check Investor Status, LLC, you may opt out of such
        communications by clicking on the “unsubscribe” link in a promotional email
        or by requesting explicit removal from our mailing list via email to
        {' '}
        <a
          href="/cdn-cgi/l/email-protection#73000603031c010733101b1610181a1d051600071c010007120706005d101c1e"
        >
          <span
            data-cfemail="e99a9c9999869b9da98a818c8a8280879f8c9a9d869b9a9d889d9c9ac78a8684"
          >
            [email&#160;protected]
          </span>
        </a>
        …

      </p>
      <p className={P_STYLE}>
        Please note that from time to time, we may send non-commercial, transactional emails
        with important information about us, our service, or your usage of our service.
        Opting out of emails as described above will not apply to such transactional emails.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="External_Data_Storage_Sites_99">
        External Data Storage
        Sites
      </h2>
      {' '}
      <p className={P_STYLE}>
        We may store your data on servers provided by third party
        hosting vendors with whom we have contracted.
      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Changes_to_this_Statement_102">
        Changes to this Statement
      </h2>
      <p className={P_STYLE}>
        Check Investor Status, LLC reserves the right to change this Privacy
        Policy from time to time. We will notify you about significant changes in
        the way we treat personal information by sending a notice to the primary
        email address specified in your account, by placing a prominent notice on
        our website, and/or by updating any privacy information. Your continued use
        of the website and/or Services available after such modifications will
        constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
        agreement to abide and be bound by that Policy.

      </p>
      {' '}
      <h2 className={STRONG_STYLE} id="Contact_Information_105">
        Contact Information
      </h2>
      {' '}
      <p className={P_STYLE}>
        {' '}
        Check Investor
        Status, LLC welcomes your questions or comments regarding this Statement of
        Privacy. If you believe that Check Investor Status, LLC has not adhered to
        this Statement, please contact Check Investor Status, LLC at:
      </p>
      <p className={B_STYLE}>
        Check Investor Status, LLC
      </p>
      <p className={B_STYLE}>
        612 w main ave
      </p>
      <p className={B_STYLE}>
        Spokane, Wa 99201
      </p>
      <p className={B_STYLE}>
        Email Address:
        {' '}
        <a
          href="mailto:support@checkinvestorstatus.com"
        >
          support@checkinvestorstatus.com
        </a>
      </p>

      <p className={B_STYLE}>
        Effective as of January 17, 2022
      </p>
    </div>
  );
};

export default PrivacyPolicy;
