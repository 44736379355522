import React, { useState, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { B_COLORS, DISABLED_B_COLORS } from '../../AppStyles';

// import propTypes from 'prop-types';
import AppContext from '../../AppContext';
import {
  adminQuery,
  adminSearch,
} from '../../api/admin';

const AdminBrowse = function () {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [foundUsers, setFoundUsers] = useState([]);

  // Query Params
  const [pageNumber, setPageNumber] = useState(0);
  const [filterToStatus, setFilterToStatus] = useState('None');
  const [sortBy, setSortBy] = useState('CREATED_AT');
  const [queryStr, setQueryStr] = useState('');
  const [searchBarVal, setSearchBarVal] = useState('');

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Load in the user from the API
  useEffect(async () => {
    setLoading(true);

    if (queryStr) {
      const { error: newError, users: newUsers } = await adminSearch(token, queryStr);

      // Save the token in localstorage and in the app state
      if (newError) {
        setError(newError);
      }

      // Set not loading anymore
      setFoundUsers(newUsers);
      setLoading(false);
    } else {
      const { error: newError, users: newUsers } = await adminQuery(token, {
        pageNumber,
        filterToStatus,
        sortBy,
      });

      // Save the token in localstorage and in the app state
      if (newError) {
        setError(newError);
      }

      // Set not loading anymore
      setFoundUsers(newUsers);
      setLoading(false);
    }
  }, [pageNumber, filterToStatus, sortBy, queryStr]);

  const handleChangeSortBy = (e) => {
    e.preventDefault();
    setQueryStr('');
    setSearchBarVal('');
    setSortBy(e.target.value);
    setPageNumber(0);
  };
  const handleChangeFilter = (e) => {
    e.preventDefault();
    setQueryStr('');
    setSearchBarVal('');
    setFilterToStatus(e.target.value);
    setPageNumber(0);
  };

  const handleConfirmQueryStr = (e) => {
    e.preventDefault();
    // Clear the other params and only search
    setQueryStr(searchBarVal);
    setPageNumber(0);
    setSortBy('CREATED_AT');
    setSearchBarVal('');
    setFilterToStatus('None');
  };

  const controls = (
    <div className="flex flex-row justify-around mb-5">
      <div className="w-1/2 flex justify-center">
        <select
          className="border w-1/2 mt-1"
          id="sort-select"
          name="sort-select"
          onChange={handleChangeSortBy}
        >
          <option value="RECENTLY_CREATED" selected={sortBy === 'RECENTLY_CREATED'}>
            Created Recently
          </option>
          <option value="RECENTLY_UPDATED" selected={sortBy === 'RECENTLY_UPDATED'}>
            Updated Recently
          </option>
        </select>
      </div>
      <div className="w-1/2 flex justify-center">
        <select
          className="border w-1/2 mt-1"
          id="filter-select"
          name="filter-select"
          onChange={handleChangeFilter}
        >
          <option value={null} selected={!filterToStatus}>
            None
          </option>
          <option value="CONFIRMATION" selected={filterToStatus === 'CONFIRMATION'}>
            CONFIRMATION
          </option>
          <option value="SURVEY" selected={filterToStatus === 'SURVEY'}>
            SURVEY
          </option>
          <option value="UPLOAD" selected={filterToStatus === 'UPLOAD'}>
            UPLOAD
          </option>
          <option value="CHECKOUT" selected={filterToStatus === 'CHECKOUT'}>
            CHECKOUT
          </option>
          <option value="PROCESSING_PAYMENT" selected={filterToStatus === 'PROCESSING_PAYMENT'}>
            PROCESSING_PAYMENT
          </option>
          <option value="WAITING" selected={filterToStatus === 'WAITING'}>
            WAITING
          </option>
          <option value="IN_REVIEW" selected={filterToStatus === 'IN_REVIEW'}>
            IN_REVIEW
          </option>
          <option value="MORE_INFO" selected={filterToStatus === 'MORE_INFO'}>
            MORE_INFO
          </option>
          <option value="APPROVED" selected={filterToStatus === 'APPROVED'}>
            APPROVED
          </option>
          <option value="FAILED" selected={filterToStatus === 'FAILED'}>
            FAILED
          </option>
          <option value="ARCHIVED" selected={filterToStatus === 'ARCHIVED'}>
            ARCHIVED
          </option>
        </select>
      </div>
    </div>
  );
  const pageControls = (
    <div className="flex flex-row justify-around mb-5">
      <div
        className="text-blue-800 font-bold hover:text-orange-500 hover:underline"
        role="button"
        onClick={(e) => setPageNumber(pageNumber > 0 ? pageNumber - 1 : pageNumber)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Prev Page
      </div>
      <span className="font-semibold">{`Page ${pageNumber}`}</span>
      <div
        className="text-blue-800 font-bold hover:text-orange-500 hover:underline"
        role="button"
        onClick={(e) => setPageNumber(pageNumber + 1)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Next Page
      </div>
    </div>
  );

  const confirmSearchButton = (
    searchBarVal !== queryStr ? (
      <div
        className={`w-1/12 rounded-lg font-semibold text-center ml-5 ${B_COLORS}`}
        role="button"
        onClick={handleConfirmQueryStr}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        {
          // Active button (maybe loading)
          loading ? (
            <div className="flex flex-row items-center">
              <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              <div className="-ml-7 w-full font-semibold">Search</div>
            </div>
          ) : (
            <div className="w-full font-semibold">Search</div>
          )
          }
      </div>
    ) : (
      <div
        className={`ml-5 w-1/12 rounded-lg font-semibold text-center ${DISABLED_B_COLORS}`}
        role="button"
        onClick={() => {}}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <div className="w-full font-semibold">Search</div>
      </div>
    )
  );
  const searchBar = (
    <div className="flex flex-row mb-10 justify-center">
      <input
        className="border px-1 w-1/2"
        type="text"
        id="search-bar-in"
        value={searchBarVal}
        onChange={(e) => setSearchBarVal(e.target.value)}
      />
      { confirmSearchButton }
    </div>
  );

  // Redirect once we log the user in
  if (!token && !user) {
    return <Navigate to="/" />;
  }
  // Redirect once we log the user in
  if (user.userType !== 'ADMIN') {
    return <Navigate to="/" />;
  }

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Something went wrong ...
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        Could not get users from the database. Call Carter.
      </h2>
    </div>
  );

  return (
    <div className="container-fluid min-h-screen">
      { error && errorScreen }
      { !loading && !error && (
        <div className="border border-gray-200 rounded-xl shadow-md w-10/12 xl:w-8/12 mx-auto my-10 py-16 px-20">
          <h1 className="text-2xl underline font-bold mb-10 text-center mx-auto">
            Recent Users:
          </h1>
          { searchBar }
          { controls }
          { pageControls }
          <div className="flex flex-col divide-y gap-5">
            <div className="flex flex-row ">
              <div className="w-5/12 font-md font-semibold">
                Username
              </div>
              <div className="w-3/12 font-md font-semibold">
                Legal Name
              </div>
              <div className="w-1/12 font-md font-semibold">
                Created
              </div>
              <div className="w-1/12 font-md font-semibold">
                Updated
              </div>
              <div className="w-2/12 font-md font-semibold">
                User Stage
              </div>
            </div>
            {
              foundUsers.map((u, idx) => {
                const {
                  userId,
                  username,
                  legalName,
                  userStage,
                  createdAt,
                  updatedAt,
                } = u;

                return (
                  <div className="flex flex-row">
                    <a
                      className="w-5/12 font-sm hover:underline text-blue-800 hover:text-orange-500"
                      href={`/admin/view/${userId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      { username }
                    </a>
                    <div className="w-3/12 font-sm">
                      { legalName }
                    </div>
                    <div className="w-1/12 font-sm">
                      { (new Date(createdAt)).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) }
                    </div>
                    <div className="w-1/12 font-sm">
                      { (new Date(updatedAt)).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) }
                    </div>
                    <div className="w-2/12 font-sm">
                      { userStage }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      ) }
    </div>
  );
};

export default AdminBrowse;
