import React from 'react';

const docTypeEnum = {
  UPLOAD: 'UPLOAD',
  CHECK: 'CHECK',
  INPUT: 'INPUT',
  LONG_INPUT: 'LONG_INPUT',
  DROP_DOWN: 'DROP_DOWN',
  ISSUER_CONVERSION: 'ISSUER_CONVERSION',
  NOTES_FOR_REVIEWER: 'NOTES_FOR_REVIEWER',
};

const requiredDocumentation = {
  LETTER: [
    {
      id: 'LETTER_UPLOAD',
      description: `A signed letter from a qualified professional stating that they have verified
        your status as an accredited investor within the last 90 days.`,
      reviewInstructions: [
        `Verify that the letter uploaded is in the investor's legal name,
        that the letter is dated within the last 90 days.`,
        `Your must also verify that the
        letter is signed by a registered broker dealer, attorney, CPA, or 
        RIA. To verify this, look up the person who signed the letter to
        verify their legitimacy.`,
        `If there is any question as to the legitimacy of the letter,
        you must call the signing firm to confirm its authenticity.`,
      ],
      reviewChecklist: [
        'Is the letter from a qualified professional?',
        'Is the letter in the exact legal name provided?',
        'Is the letter from within the last 90 days?',
        `Does the letter affirm that the qualified professional has verified this
        investor's accreditation status?`,
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  INCOME: [
    {
      id: 'INCOME_UPLOAD',
      description: 'Tax forms (W-2, K-1, or 1099 forms) OR tax returns for the past two years',
      reviewInstructions: [
        `Verify that tax returns provided verify that the investor's income
          has been greater than $200k for each of the past two years.`,
        `The tax documents provided must be from the last two tax years, and they must
          be in the investor's exact provided legal name`,
        'Acceptable tax documents include W-2s, K-1s, 1099 forms, and tax returns (at least the first two pages).',
      ],
      reviewChecklist: [
        'Are there two sets of tax documents representing the two most recent tax seasons?',
        'Are the tax documents in the legal name of the investor?',
        `Do both sets of tax documents verify that the investor's income was
        greater than $200k per year?`,
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'INCOME_CHECK',
      description:
        'Confirmation that you have a reasonable belief that you will make enough money this year to qualify as an accredited investor',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  FINANCE_PROFESSIONAL: [
    {
      id: 'FINANCE_PROFESSIONAL_CRD',
      description: 'Your CRD number',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'FINANCE_PROFESSIONAL_FIRM_NAME',
      description: 'Your firm name (if qualifying through series 7 or 82)',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'FINANCE_PROFESSIONAL_CONFIRMATION',
      description: 'Confirmation that the information regarding your credentials is true and current.',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  DIRECTOR: [
    {
      id: 'DIRECTOR_ISSUER_NAME',
      description: 'Name of the issuer for which you are a director, executive, or general partner',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'DIRECTOR_UPLOAD',
      description: "Evidence of your claimed position (EG Incumbancy Certificate or Officer's Certificate)",
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  EMPLOYEE: [
    {
      id: 'EMPLOYEE_FUND_NAME',
      description: 'Name of the investment, fund, or deal you are qualifying for',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'EMPLOYEE_ISSUER_NAME',
      description: 'Name of the issuer creating the above investment, fund, or deal',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'EMPLOYEE_ROLE_DESCRIPTION',
      description: 'Explanation of your role in the issuing company, focusing on your management and organizational decision-making responsibilities.',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.LONG_INPUT,
    },
    {
      id: 'EMPLOYEE_UPLOAD',
      description: 'Evidence of your claimed position (Incumbancy Certificate, Officer\'s Certificate, etc.)',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'EMPLOYEE_CONTACT',
      optional: true,
      description: 'Contact info for a member of your team\'s compliance or regulatory team',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.LONG_INPUT,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  NET_WORTH: [
    {
      id: 'NET_WORTH_ESTIMATED_RESIDENCE_VAL',
      description: 'Estimated value of primary residence',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'NET_WORTH_ESTIMATED_RESIDENCE_DEBT',
      description: 'Estimated debt against primary residence',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'NET_WORTH_RESIDENCE_DEBT_RECENCY',
      description: 'Was the debt taken out in the last 6 months?',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      options: [
        'More than 6 months ago',
        'Less than 6 months ago',
        'There is no debt against my primary residence',
      ],
      type: docTypeEnum.DROP_DOWN,
    },
    {
      id: 'NET_WORTH_CREDIT_REPORT',
      description: 'A credit report from the last 90 days',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NET_WORTH_ASSETS',
      description: 'Evidence of all assets contributing to net worth',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'CONFIRMATION_NO_OTHER_LIABILITIES',
      description: 'Confirmation that you\'ve disclosed all liabilities and debts.',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  NONE_OF_THE_ABOVE: [
    {
      id: 'NONE_OF_THE_ABOVE_EXPLANATION',
      description: 'Explanation of how you qualify as an accredited investor',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.LONG_INPUT,
    },
    {
      id: 'NONE_OF_THE_ABOVE_UPLOAD',
      optional: true,
      description: 'Supporting documentation proving your accreditation status',
      reviewInstructions: [
        'TODO',
      ],
      reviewChecklist: [
        'TODO',
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  /*
   * Content Requirements for Joint Verification with Spouse
   */
  JOINT_LETTER: [
    {
      id: 'JOINT_LETTER_UPLOAD',
      description: `A signed letter from a qualified professional stating that they have verified
        you and your spouse's status as accredited investors within the last 90 days.`,
      reviewInstructions: [
        `Verify that the letter uploaded is in the investor's legal name,
        that the letter is dated within the last 90 days.`,
        `Your must also verify that the
        letter is signed by a registered broker dealer, attorney, CPA, or 
        RIA. To verify this, look up the person who signed the letter to
        verify their legitimacy.`,
        `If there is any question as to the legitimacy of the letter,
        you must call the signing firm to confirm its authenticity.`,
      ],
      reviewChecklist: [
        'Is the letter from a qualified professional?',
        'Is the letter in the exactl legal name provided?',
        'Is the letter from within the last 90 days?',
        `Does the letter affirm that the qualified professional has verified this
        investor's accreditation status?`,
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  JOINT_INCOME: [
    {
      id: 'JOINT_INCOME_UPLOAD',
      description: 'Tax forms (W-2, K-1, or 1099 forms) OR tax returns for the past two years',
      reviewInstructions: [
        `Verify that tax returns provided verify that the investor's joint income
          with spouse has been greater than $300k for each of the past two years.`,
        `The tax documents provided must be from the last two tax years, and they must
          be in the investor's exact provided legal name`,
        'Acceptable tax documents include W-2s, K-1s, 1099 forms, and tax returns (at least the first two pages).',
      ],
      reviewChecklist: [
        'Are there two sets of tax documents representing the two most recent tax seasons?',
        'Are the tax documents in the legal name of the investor (and/or their spouse)?',
        'If the tax documents are filed jointly, does the total income for each year reach $300k?',
        'If the tax documents are filed individually, does the sum of the incomes on each return for each year reach $300k?',
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'JOINT_INCOME_CHECK',
      description:
        'Confirmation that you have a reasonable belief that you will make enough money this year to qualify as an accredited investor',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  JOINT_NET_WORTH: [
    {
      id: 'NET_WORTH_ESTIMATED_RESIDENCE_VAL',
      description: 'Estimated value of primary residence',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'NET_WORTH_ESTIMATED_RESIDENCE_DEBT',
      description: 'Estimated debt against primary residence',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.INPUT,
    },
    {
      id: 'NET_WORTH_RESIDENCE_DEBT_RECENCY',
      description: 'Was the debt taken out in the last 6 months?',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      options: [
        'More than 6 months ago',
        'Less than 6 months ago',
        'There is no debt against my primary residence',
      ],
      type: docTypeEnum.DROP_DOWN,
    },
    {
      id: 'JOINT_NET_WORTH_CREDIT_REPORT',
      description: 'Credit reports for you and your spouse from the last 90 days',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'JOINT_NET_WORTH_ASSETS',
      description: 'Evidence of all assets contributing to net worth',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'JOINT_CONFIRMATION_NO_OTHER_LIABILITIES',
      description: 'Confirmation that you\'ve disclosed all liabilities and debts.',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  JOINT_NONE_OF_THE_ABOVE: [
    {
      id: 'NONE_OF_THE_ABOVE_EXPLANATION',
      description: 'Explanation of how you qualify as an accredited investor',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.LONG_INPUT,
    },
    {
      id: 'JOINT_NONE_OF_THE_ABOVE_UPLOAD',
      optional: true,
      description: 'Supporting documentation proving you and your spouse\'s accreditation status',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  /*
   * Content Requirements for EBP Verification
   */
  EBP_NET_WORTH: [
    {
      id: 'EBP_NET_WORTH_FINANCIAL_STATEMENTS',
      description: `A financial statement or appraisal from the last 90 days showing assets greater than
        $5M in value in the plan`,
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'EBP_ATTEST',
      description: 'Confirmation that the retirement plan was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  EBP_FIDUCIARY: [
    {
      id: 'ENTITY_FIDUCIARY_EBP_FIDUCIARY_EVIDENCE',
      description: 'Evidence that investment decisions are made by a plan fiduciary',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'EBP_ATTEST',
      description: 'Confirmation that the retirement plan was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  EBP_SELF_DIRECTED: [
    {
      id: 'ENTITY_SELF_DIRECTED_EBP_OFFICERS_CERTIFICATE',
      description: 'Officer\'s certificate stating which individuals make investment decisions',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_SELF_DIRECTED_EBP_INDIVIDUAL_ACCREDITATIONS',
      description: 'Email addresses for each of the accredited individuals making investment decisions',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.ISSUER_CONVERSION,
    },
    {
      id: 'EBP_ATTEST',
      description: 'Confirmation that the retirement plan was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  /*
   * Content Requirements for Entity Verification
   */
  ENTITY_LETTER: [
    {
      id: 'ENTITY_LETTER_UPLOAD',
      description: `A signed letter from a qualified professional stating that they have verified
        the entity's status as an accredited investor within the last 90 days`,
      reviewInstructions: [
        `Verify that the letter uploaded is in the legal name of the entity,
        and that the letter is dated within the last 90 days.`,
        `Your must also verify that the
        letter is signed by a registered broker dealer, attorney, CPA, or 
        RIA. To verify this, look up the person who signed the letter to
        verify their legitimacy.`,
        `If there is any question as to the legitimacy of the letter,
        you must call the signing firm to confirm its authenticity.`,
      ],
      reviewChecklist: [
        'Is the letter from a qualified professional?',
        'Is the letter in the entity\'s exact legal name provided?',
        'Is the letter from within the last 90 days?',
        `Does the letter affirm that the qualified professional has verified this
        entity's accreditation status?`,
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_NET_WORTH: [
    {
      id: 'ENTITY_NET_WORTH_FINANCIAL_STATEMENTS',
      description: `A financial statement or appraisal from the last 90 days showing assets greater than
        $5M in value`,
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_ATTEST',
      description: 'Confirmation that the entity was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_STATUS: [
    {
      id: 'ENTITY_STATUS_SELECTION',
      description: 'Selection of the type of entity status exemption',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      options: [
        'Bank or savings and loan association acting in an individual or fiduciary capacity.',
        'Registered broker or dealer.',
        'Registered investment advisor.',
        'Investment advisor exempt from registering.',
        'Insurance company.',
        'Investment company or business development company.',
        'Licensed small business investment company.',
        'Rural business investment company.',
        'Private business development company.',
        'Other (specify in notes to legal reviewer)',
      ],
      type: docTypeEnum.DROP_DOWN,
    },
    {
      id: 'ENTITY_STATUS_EVIDENCE',
      description: 'Evidence of the entity status being claimed',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_ATTEST',
      description: 'Confirmation that the entity was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_SPECIAL: [
    {
      id: 'ENTITY_SPECIAL_SELECTION',
      description: 'Selection of the type of entity status exemption',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      options: [
        'Tax-exempt charitable organization',
        'Foreign entity',
        'Family office or family client',
        'Other (specify in notes to legal reviewer)',
      ],
      type: docTypeEnum.DROP_DOWN,
    },
    {
      id: 'ENTITY_SPECIAL_EVIDENCE',
      description: 'Evidence of the entity status being claimed',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_SPECIAL_FINANCIAL_STATEMENTS',
      description: `A financial statement or appraisal from the last 90 days showing assets greater than
        $5M in value`,
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_ATTEST',
      description: 'Confirmation that the entity was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_FIDUCIARY_EBP: [ // DEPRECATED
    {
      id: 'ENTITY_FIDUCIARY_EBP_FIDUCIARY_EVIDENCE',
      description: 'Evidence that investment decisions are made by a plan fiduciary',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_SELF_DIRECTED_EBP: [ // DEPRECATED
    {
      id: 'ENTITY_SELF_DIRECTED_EBP_OFFICERS_CERTIFICATE',
      description: 'Officer\'s certificate stating which individuals make investment decisions',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_SELF_DIRECTED_EBP_INDIVIDUAL_ACCREDITATIONS',
      description: 'Email addresses for each of the accredited individuals making investment decisions',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.ISSUER_CONVERSION,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_ACCREDITED_INDIVIDUALS: [
    {
      id: 'ENTITY_ACCREDITED_INDIVIDUALS_LISTING_OF_EQUITY_OWNERS',
      description: 'Evidence listing equity owners',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'ENTITY_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS',
      description: 'Email addresses for each of the equity owners',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.ISSUER_CONVERSION,
    },
    {
      id: 'ENTITY_ATTEST',
      description: 'Confirmation that the entity was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  ENTITY_NONE_OF_THE_ABOVE: [
    {
      id: 'NONE_OF_THE_ABOVE_EXPLANATION',
      description: 'Explanation of how you qualify as an accredited investor',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.LONG_INPUT,
    },
    {
      id: 'ENTITY_NONE_OF_THE_ABOVE_UPLOAD',
      optional: true,
      description: 'Supporting documentation proving this entity\'s accreditation status',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  /*
   * Verification methods for trusts
   */
  TRUST_LETTER: [
    {
      id: 'TRUST_LETTER_UPLOAD',
      description: `A signed letter from a qualified professional stating that they have verified
        the trust's status as an accredited investor within the last 90 days`,
      reviewInstructions: [
        `Verify that the letter uploaded is in the legal name of the trust,
        and that the letter is dated within the last 90 days.`,
        `Your must also verify that the
        letter is signed by a registered broker dealer, attorney, CPA, or 
        RIA. To verify this, look up the person who signed the letter to
        verify their legitimacy.`,
        `If there is any question as to the legitimacy of the letter,
        you must call the signing firm to confirm its authenticity.`,
      ],
      reviewChecklist: [
        'Is the letter from a qualified professional?',
        'Is the letter in the entity\'s exact legal name provided?',
        'Is the letter from within the last 90 days?',
        `Does the letter affirm that the qualified professional has verified this
        entity's accreditation status?`,
      ],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  TRUST_NET_WORTH: [
    {
      id: 'TRUST_NET_WORTH_FINANCIAL_STATEMENTS',
      description: `A financial statement or appraisal from the last 90 days showing assetts greater than
        $5M in value`,
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'TRUST_NET_WORTH_SOPHISTICATION_EVIDENCE',
      description: 'Evidence of the director\'s business and financial qualifications',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'TRUST_ATTEST',
      description: 'Confirmation that the trust was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  TRUST_ACCREDITED_INDIVIDUALS: [
    {
      id: 'TRUST_ACCREDITED_INDIVIDUALS_EQUITY_OWNERSHIP_EVIDENCE',
      description: 'Trust agreement or declaration documenting the equity owners of the trust',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'TRUST_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS',
      description: 'Email addresses for each of the trust\'s equity owners',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.ISSUER_CONVERSION,
    },
    {
      id: 'TRUST_ATTEST',
      description: 'Confirmation that the trust was not formed specifically to purchase this security',
      reviewInstructions: [
        'No Review necessary – this is an attestation that the undersigned is true.',
      ],
      reviewChecklist: [
        'No review necessary',
      ],
      type: docTypeEnum.CHECK,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
  TRUST_NONE_OF_THE_ABOVE: [
    {
      id: 'NONE_OF_THE_ABOVE_EXPLANATION',
      description: 'Explanation of how you qualify as an accredited investor',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.LONG_INPUT,
    },
    {
      id: 'TRUST_NONE_OF_THE_ABOVE_UPLOAD',
      optional: true,
      description: 'Supporting documentation proving this trust\'s accreditation status',
      reviewInstructions: ['TODO'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.UPLOAD,
    },
    {
      id: 'NOTES_TO_REVIEWER',
      optional: true,
      description: 'Any notes you would like to provide to your legal reviewer',
      reviewInstructions: ['Read these notes from the investor and consider them in your accreditation.'],
      reviewChecklist: ['TODO'],
      type: docTypeEnum.NOTES_FOR_REVIEWER,
    },
  ],
};

export {
  requiredDocumentation,
  docTypeEnum,
};
