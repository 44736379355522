import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import { FiCheck } from 'react-icons/fi';

import AppContext from '../../AppContext';
import legalDetails from '../_Upload/legalDetailsContent';

import { requiredDocumentation } from '../_Upload/uploadContent';
import { crumbToEvidenceId } from './evidence-helpers';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Confirmation
  </h1>
);

const ConfirmationPage = function (props) {
  const {
    onChange,
    passedVal,
    variant,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, [variant]);

  const [{ user, token }, dispatch] = useContext(AppContext);

  // Get copy for this check box
  const evidenceId = crumbToEvidenceId(user, `CONFIRMATION.${variant}`);
  const {
    description: descriptionCopy,
  } = requiredDocumentation[user.verificationMethod].filter(({ id }) => id === evidenceId)[0];
  const checkboxCopy = legalDetails[evidenceId] || '';

  const pageDescription = (
    <p className="text-black text-md mb-6">
      { descriptionCopy || 'No Description'}
    </p>
  );

  const toggleOption = (e) => {
    e.preventDefault();
    const updatedVal = !val;
    setVal(updatedVal);
    onChange(updatedVal);
  };

  const checkboxWidget = (
    <div className="w-full flex flex-row gap-4">
      {
        val ? (
          /* Checked Box */
          <div
            className="w-5 h-5 mt-0.5 flex flex-col cursor-pointer flex-shrink-0 justify-center items-center bg-[#5963F4] border-2 border-[#5963F4] rounded-[.2REM]"
            onClick={toggleOption}
            onKeyDown={() => {}}
            tabIndex={0}
            role="checkbox"
            aria-checked="false"
            aria-labelledby="none"
          >
            <FiCheck size={20} color="#FFFFFF" style={{ 'stroke-width': '3px' }} />
          </div>
        ) : (
          /* Un-Checked Box */
          <div
            className="w-5 h-5 mt-0.5 flex-shrink-0 border-2 border-[#A1A5AD] rounded-[.2REM] hover:bg-[#DCE1E7] cursor-pointer"
            onClick={toggleOption}
            onKeyDown={() => {}}
            tabIndex={0}
            role="checkbox"
            aria-checked="false"
            aria-labelledby="none"
          />

        )
      }
      <div className="flex-shrink text-black">
        { checkboxCopy }
      </div>

    </div>
  );

  const infoCol = (
    <div className="flex flex-col w-4/12" />
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { checkboxWidget }
      </div>
      { infoCol }
    </div>
  );
};
ConfirmationPage.propTypes = {
  onChange: propTypes.func.isRequired,
  variant: propTypes.string.isRequired,
  // TODO(Carter): This is probably bad but it is important
  // eslint-disable-next-line react/forbid-prop-types
  passedVal: propTypes.any,
};
ConfirmationPage.defaultProps = {
  passedVal: false,
};

export default ConfirmationPage;
