import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';
import { requiredDocumentation } from '../_Upload/uploadContent';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Professional Qualifications
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-8">
    Enter your firm name and CRD number so that we can verify
    that you are an active Series 7, Series 82, or Series 65 holder
    in good standing.
  </p>
);

const ProfessionalCertPage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const updateFirm = (e) => {
    e.preventDefault();
    const newVal = [e.target.value, val[1]];
    setVal(newVal);
    onChange(newVal);
  };
  // Short input for residence Val
  const firmInput = (
    <div className="flex flex-col">
      <label className="flex-grow w-11/12 mb-8" htmlFor="firm-name-in">
        <p className="text-md font-semibold">What is the name of your sponsoring firm?</p>
        <p className="text-sm">
          It is ok to write &apos;none&apos; if qualifying through a series 65.
        </p>
        <input
          className="border border-[#DCE1E7] w-full px-1 mt-2 rounded-[.3REM]"
          type="text"
          id="firm-name-in"
          onChange={updateFirm}
          placeholder="Your firm name"
          value={val[0] || ''}
        />
      </label>
    </div>
  );

  const updateCrd = (e) => {
    e.preventDefault();
    const newVal = [val[0], e.target.value];
    setVal(newVal);
    onChange(newVal);
  };
  // Short input for residence Val
  const crdInput = (
    <div className="flex flex-col">
      <label className="flex-grow w-11/12 mb-8" htmlFor="crd-name-in">
        <p className="text-md font-semibold">Enter your CRD number</p>
        <input
          className="border border-[#DCE1E7] w-full px-1 mt-2 rounded-[.3REM]"
          type="text"
          id="crd-name-in"
          onChange={updateCrd}
          placeholder="Your CRD number"
          value={val[1] || ''}
        />
      </label>
    </div>
  );

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="text-black mb-4">
        If you are unsure about your CRD or firm name, you can find this information
        by logging in to FINRA&apos;s website, or by looking yourself up on
        FINRA&apos;s BrokerCheck.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { firmInput }
        { crdInput }
      </div>
      { infoCol }
    </div>
  );
};
ProfessionalCertPage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
ProfessionalCertPage.defaultProps = {
  passedVal: '',
};

export default ProfessionalCertPage;
