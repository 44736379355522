import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';

import FadeLoader from 'react-spinners/FadeLoader';

import AppContext from '../AppContext';
import { loadEmailLink } from '../api/platforms';

const EmailLink = function () {
  const { mlId } = useParams();
  const [error, setError] = useState();
  const [done, setDone] = useState(false);

  const [{ user, token }, dispatch] = useContext(AppContext);

  // Hit the endpoint to load this portal and go to the right place
  useEffect(async () => {
    const { error: newError, user: newUser, token: newToken } = await loadEmailLink(mlId);

    // Save the token in localstorage and in the app state
    if (!newError) {
      console.log('Finished correctly setting the token');
      console.log(newUser);
      console.log(newToken);
      dispatch({ type: 'SET_TOKEN', token: newToken });
      dispatch({ type: 'SET_USER', user: newUser });
      setDone(true);
    } else {
      setError(newError);
      // TODO(Carter): Error display
    }
  }, []);

  // If we successfully loaded the portal, use a redirect chain based on the
  // userStage of the user
  if (done) {
    // If not a platform user, use the main redirect chain
    return <Navigate to="/" />;
  }

  const loadScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <div className="w-5/12 mx-auto text-block flex flex-row items-center justify-center">
        <div className="-ml-16 mb-20">
          <FadeLoader color="#4f46e5" loading size={20} margin={5} width={7} radius={5} speedMultiplier={0.8} />
        </div>
      </div>
      <h2 className="text-4xl font-bold text-center text-gray-800 mt-8 mb-16">
        Loading your Accreditation Portal
      </h2>
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-16">
        We&apos;ll automatically redirect you in one moment.
      </h2>
    </div>
  );

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Oh no &mdash; something went wrong!
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        That email link you used is invalid or expired.
      </h2>
      <p className="text-xl font-bold mb-4">
        Check your inbox for a more recent email from Check Investor Status.
      </p>
      <p className="text-xl font-bold">
        You can also contact
        {' '}
        <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/contact">
          Check Investor Status support
        </Link>
        {' '}
        if the issue persists.
      </p>
    </div>
  );

  return (
    <div className="container container-fluid">
      { error ? errorScreen : loadScreen }
    </div>
  );
};

export default EmailLink;
