import { PREFIX } from './prefix';

async function loginUser(credentials) {
  return fetch(`${PREFIX}/api/login`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

const noop = async () => {};

export {
  noop,
  loginUser,
};
