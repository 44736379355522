import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import validator from 'validator';
import { submitHelpRequest } from '../../api/contact';
import {
  B_COLORS, LG_SUCCESS_TXT, INVALID_FORM, ERR_TXT, LG_ERR_TXT,
} from '../../AppStyles';

// import PropTypes from 'prop-types';

const Contact = function () {
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [investorType, setInvestorType] = useState();
  const [helpReq, setHelpReq] = useState('');

  // Error states
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [investorTypeInvalid, setInvestorTypeInvalid] = useState(false);
  const [helpReqInvalid, setHelpReqInvalid] = useState(false);

  // Global State
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const validateInputs = () => {
    let isValid = true;
    if (validator.isEmpty(firstName || '', { ignore_whitespace: true })) {
      setFirstNameInvalid(true);
      isValid = false;
    }
    if (validator.isEmpty(lastName || '', { ignore_whitespace: true })) {
      setLastNameInvalid(true);
      isValid = false;
    }
    if (!validator.isEmail(email || '')) {
      setEmailInvalid(true);
      isValid = false;
    }
    if (!investorType) {
      setInvestorTypeInvalid(true);
      isValid = false;
    }
    if (validator.isEmpty(helpReq || '', { ignore_whitespace: true })) {
      setHelpReqInvalid(true);
      isValid = false;
    }

    return isValid;
  };

  // Handle Submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Hit the API endpoint to send the email?
    const valid = validateInputs();
    if (!valid) {
      setError('Please complete all the fields to submit a help request.');
      setLoading(false);
      return;
    }

    // Hit the endpoint
    const { success, error: newError } = await submitHelpRequest({
      firstName, lastName, email, investorType, helpReq,
    });

    if (newError) {
      setError(newError.toString());
    } else {
      setError('');
      setDone(true);
    }

    setLoading(false);
  };

  return (
    <div className="container-fluid flex flex-row w-full">
      <div className="container flex flex-col w-5/12 ml-10 px-10 items-center justify-center text-center">
        <h1 className="text-4xl mb-12">Contact Our Team</h1>
        <h2 className="text-2xl mb-8">
          If you have a question that isn&apos;t covered in our
          {' '}
          <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/faq">FAQ Section</Link>
          ,
          {' '}
          <span className="font-bold">reach out and we&apos;ll be happy to help.</span>
        </h2>
        <h2 className="text-2xl">
          Use the form to the right or send us an email at
          {' '}
          <a className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" href="mailto:support@checkinvestorstatus.com">support@checkinvestorstatus.com</a>
          {' '}
          and we&apos;ll get back to you within 1-2 business days.

        </h2>
      </div>
      <div className="container flex flex-col w-1/2 lg:w-1/2 xl:w-5/12 mx-auto my-10 py-8 px-24 border border-gray-200 rounded-xl shadow-lg">
        <div className="pb-10">
          <h1 className="text-2xl text-center">Contact Us</h1>
          { error && (
            <div className={`mt-3 ${LG_ERR_TXT} text-center`}>{error}</div>
          )}
          { done && (
            <div className={`mt-3 ${LG_SUCCESS_TXT} text-center`}>
              Thanks for submiting a support request. We&apos;ll get back to you within
              1-2 business days.
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-row justify-between gap-10 mb-5">
            <label className="flex-grow" htmlFor="first-name-in">
              <p className="text-base font-bold">First Name</p>
              <input
                className={`border border-gray-200 w-full px-1 mt-1 ${firstNameInvalid && INVALID_FORM}`}
                type="text"
                id="first-name-in"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                onBlur={(e) => {
                // Validate first Name. If it's invalid, set disabled state
                  if (!validator.isEmpty(firstName, { ignore_whitespace: true })) {
                    setFirstNameInvalid(false);
                  } else {
                    setFirstNameInvalid(true);
                  }
                }}
              />
            </label>
            <label className="flex-grow" htmlFor="last-name-in">
              <p className="text-base font-bold">Last Name</p>
              <input
                className={`border border-gray-200 w-full px-1 mt-1  ${lastNameInvalid && INVALID_FORM}`}
                type="text"
                id="last-name-in"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                onBlur={(e) => {
                // Validate last name. If it's invalid, set disabled state
                  if (!validator.isEmpty(lastName, { ignore_whitespace: true })) {
                    setLastNameInvalid(false);
                  } else {
                    setLastNameInvalid(true);
                  }
                }}
              />
            </label>
          </div>
          <div className="w-full mb-5">
            <label htmlFor="email-in">
              <p className="text-base font-bold">Email</p>
              <input
                className={`w-full border border-gray-200 px-1 mt-1 ${emailInvalid && INVALID_FORM}`}
                type="text"
                id="email-in"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onBlur={(e) => {
                  // Validate Email. If it's invalid, set disabled state
                  if (validator.isEmail(email)) {
                    setEmailInvalid(false);
                  } else {
                    setEmailInvalid(true);
                  }
                }}
              />
            </label>
            { emailInvalid && (
            <div className={ERR_TXT}>Please enter a valid email address.</div>
            )}
          </div>
          <div className="w-full mb-5">
            <label htmlFor="investorType">
              <p className="text-base font-bold">Which best describes you?</p>
              <select
                className={`border w-1/2 mt-1 ${investorTypeInvalid && INVALID_FORM}`}
                id="investor-type"
                name="investor-type"
                onChange={(e) => {
                  setInvestorType(e.target.value);
                  setInvestorTypeInvalid(false);
                }}
                value={investorType}
                onBlur={(e) => {
                // Validate Email. If it's invalid, set disabled state
                  if (investorType) {
                    setInvestorTypeInvalid(false);
                  } else {
                    setInvestorTypeInvalid(true);
                  }
                }}
              >
                <option value="none" selected disabled hidden>Select an Option</option>
                <option value="investor">Investor</option>
                <option value="issuer">Issuer</option>
              </select>
            </label>
          </div>
          <div className="w-full">
            <label htmlFor="support-form-input">
              <p className="text-base font-bold">How Can We Help?</p>
              <textarea
                className={`border mb-5 w-11/12 h-[8rem] px-2 py-1 mt-1 ${helpReqInvalid && INVALID_FORM}`}
                type="text"
                id="support-form-input"
                placeholder="Your response"
                maxLength="500"
                onChange={(e) => setHelpReq(e.target.value)}
                value={helpReq}
                onBlur={(e) => {
                // Validate last name. If it's invalid, set disabled state
                  if (!validator.isEmpty(lastName, { ignore_whitespace: true })) {
                    setHelpReqInvalid(false);
                  } else {
                    setHelpReqInvalid(true);
                  }
                }}
              />
            </label>
          </div>
          <div className="py-2 w-1/2 mx-auto">
            <button
              className={`py-1 w-full text-white font-semibold rounded border ${B_COLORS} `}
              type="submit"
            >
              { loading ? (
                <div className="flex flex-row items-center">
                  <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                    <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  <div className="-ml-7 w-full">Submit Request</div>
                </div>
              ) : (
                <div className="w-full">Submit Request</div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
