/* eslint-disable no-unreachable */
import { PREFIX } from './prefix';

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
async function uploadDocuments(token, uploads, docType, verificationId) {
  const body = JSON.stringify({
    uploads,
    documentationMetadata: {
      type: docType,
      verificationId,
    },
  });

  return fetch(`${PREFIX}/api/upload`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body,
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function confirmUploads(token) {
  return fetch(`${PREFIX}/api/confirm-uploads`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function removeUpload(token, url) {
  const body = JSON.stringify({
    url,
  });

  return fetch(`${PREFIX}/api/remove-upload`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body,
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function uploadValue(token, value, docType, verificationId) {
  const body = JSON.stringify({
    value,
    documentationMetadata: {
      type: docType,
      verificationId,
    },
  });

  return fetch(`${PREFIX}/api/upload-value`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body,
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function uploadDependentInvestors(token, dependentInputs, docType, verificationId) {
  const body = JSON.stringify({
    dependentInputs,
    documentationMetadata: {
      type: docType,
      verificationId,
    },
  });

  return fetch(`${PREFIX}/api/upload-dependent-investors`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body,
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  uploadDocuments,
  confirmUploads,
  removeUpload,
  uploadValue,
  uploadDependentInvestors,
};
