/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';

import ReactGA from 'react-ga';

import { Navigate } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { AiOutlinePlus } from 'react-icons/ai';
import SyncLoader from 'react-spinners/SyncLoader';

import {
  applyDiscountCode, fetchCharges, setPaymentProcessing,
} from '../../api/payment';

import AppContext from '../../AppContext';
import { B_COLORS, LG_ERR_TXT } from '../../AppStyles';

import CheckoutForm from './CheckoutForm';

import { PREFIX } from '../../api/prefix';

const stripeEnv = (process.env.REACT_APP_STRIPE_PUBLIC_KEY
  || 'pk_test_51KAiaBHRHRHnhCG7oUttuhxyg98ioVZ1sFZRucY6JhBpNNtddgtzG3giydBaVSLYuHyC39Z2pVmj1ZzX5lq3KYix00bQsnDWqG');
const stripePromise = loadStripe(stripeEnv);

function formatNumberAsDollars(num) {
  return `$${num.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
}

const Checkout = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);
  const [clientSecret, setClientSecret] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [charges, setCharges] = useState();
  const [notCharging, setNotCharging] = useState(false);

  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState('');

  const [discountLoading, setDiscountLoading] = useState(false);
  const [discountError, setDiscountError] = useState('');

  const [shouldShowPromo, setShouldShowPromo] = useState(false);

  useEffect(async () => {
    // Asynchronously also fetch data from the API
    const { error: newError, charges: newCharges } = await fetchCharges(token);

    if (!newError) {
      setCharges(newCharges);
    }

    if (newCharges && newCharges.total && newCharges.total.value === 0) {
      // Don't work with Stripe - use a manual button instead
      setNotCharging(true);
    } else {
    // Create PaymentIntent as soon as the page loads if we're trying to charge them
      fetch(`${PREFIX}/api/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-ACCESS-TOKEN': token,
        },
        body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, [user]);

  if (!token || !user) {
    return (
      <Navigate to="/" />
    );
  }

  // Not an investor
  if (user.userType === 'ADMIN') {
    return (
      <Navigate to="/" />
    );
  }

  // Should only be able to get to checkout if we're in the checkout stage
  if (user.userStage !== 'CHECKOUT') {
    return (
      <Navigate to="/" />
    );
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  // Handle checkout for total discounts
  const handleManualCheckout = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Tell the API we're processing a payment for this user
    const { error: setProcessingErr, user: setUser } = await setPaymentProcessing(token);
    if (setProcessingErr) {
      setError(setProcessingErr);
      return;
    }
    setError('');
    // Track google event
    ReactGA.event({
      category: 'User',
      action: 'Completed Manual Checkout',
      label: setUser.userType,
    });

    // We have to do this after so that we don't reload the page before
    // hitting the stripe confirmPayment (elements needs to be mounted)
    dispatch({ type: 'SET_USER', user: setUser });
    setIsLoading(false);
  };

  const handleApplyCode = async (e) => {
    setDiscountLoading(true);
    const {
      error: newError,
      charges: newCharges,
      user: newUser,
    } = await applyDiscountCode(token, discountCode);

    setDiscountCode('');
    if (newError) {
      setDiscountError(newError);
    } else {
      // Update the state and context with new charges and user
      setDiscountError('');
      setCharges(newCharges);
      dispatch({ type: 'SET_USER', user: newUser });
    }
    setDiscountLoading(false);
  };

  const promoCode = shouldShowPromo ? (
    <div className="flex flex-col px-5 items-end">
      <div className="flex flex-row w-[90%] px-4 items-center">
        <input
          className="border my-5 w-3/4 pl-1"
          placeholder="Promo Code"
          type="text"
          id="legal-name-in"
          onChange={(e) => setDiscountCode(e.target.value)}
          value={discountCode}
        />
        <div
          className={`px-3 cursor-pointer ml-5 my-5 rounded flex-grow text-center font-semibold items-center border ${B_COLORS}`}
          role="button"
          onClick={handleApplyCode}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          Apply
        </div>
        { discountLoading && (
        <svg className="animate-spin h-5 w-5 ml-3 -mr-10 text-white position-absolute" viewBox="0 0 24 24">
          <circle className="opacity-0" fill="#4f46e5" cx="12" cy="12" r="10" strokeWidth="4" />
          <path className="opacity-75" fill="#4f46e5" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
        )}
      </div>
      { discountError && (
        <div className={`text-center ${LG_ERR_TXT}`}>{discountError}</div>
      )}
    </div>

  ) : (
    <div
      className="flex cursor-pointer flex-row ml-16 w-[90%] px-4 items-center my-2 fill-blue-800 text-blue-800 hover:fill-orange-800 hover:text-orange-500  hover:underline"
      role="button"
      onClick={() => setShouldShowPromo(true)}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <AiOutlinePlus className="stroke-0 hover:stroke-2" size={20} />
      <h4 className="text-md font-semibold ml-1">Promo Code</h4>
    </div>
  );

  return (
    <div className="container-fluid divide-x divide-gray-200 flex flex-row w-screen">
      <div className="w-10/12 mx-auto">
        {/* Full-width parent container */}
        <div className="container-fluid w-full xl:w-10/12 flex flex-col items-center py-10 mx-auto">
          {/* Header and section description */}
          <h1 className="text-2xl font-bold mb-2 w-10/12 text-center">Checkout</h1>
          <p className="text-lg mb-10 text-center w-10/12">
            We should have everything we need to verify your accreditation status.
            After you checkout, our legal team will review your documentation and email you
            once we&apos;ve verified your accreditation.
          </p>
          {/* The box of the two components */}
          <div className="container-fluid w-full divide-x divide-gray-200 flex flex-row gap-4">
            {
              notCharging ? (
                <div className="w-full py-16">
                  <h1 className="text-xl font-semibold text-center w-full mb-4">
                    Your Accreditation is paid for!
                  </h1>
                  <h2 className="text-lg text-center w-full mb-4">
                    Click below to complete your checkout.
                  </h2>
                  { error && (
                  <div className={`mx-auto text-center ${LG_ERR_TXT}`}>{error}</div>
                  )}

                  <div
                    className={`mx-auto w-1/2 cursor-pointer rounded-xl font-semibold text-center my-8 py-3 ${B_COLORS}`}
                    role="button"
                    onClick={handleManualCheckout}
                    onKeyDown={() => {}}
                    tabIndex={0}
                  >
                    { isLoading ? (
                      <div className="flex flex-row items-center">
                        <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                          <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        <div className="-ml-7 w-full">Complete Checkout</div>
                      </div>
                    ) : (
                      <div className="w-full">Complete Checkout</div>
                    )}
                  </div>
                </div>
              ) : (
                clientSecret ? (
                  <div className="w-full">
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm
                        email={user.email}
                        value={charges && charges.total && charges.total.value ? formatNumberAsDollars(charges.total.value) : ''}
                      />

                    </Elements>
                  </div>
                ) : (
                  <div className="w-full mx-auto text-block flex flex-row items-center justify-center">
                    <SyncLoader color="#4f46e5" loading size={20} margin={5} speedMultiplier={0.8} />
                  </div>
                )
              )
              }
            <div className="w-full flex flex-col  ml-4 pl-1 justify-end">
              <ChargesSummary charges={charges} />
              {
                promoCode
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

const ChargesSummary = function ({ charges }) {
  // Display loading if no charges are passed in
  if (!charges || !(charges.items && charges.items.length)) {
    return (
      <div className="w-[90%] border rounded-xl bg-gray-50 border-gray-100 py-5">
        <h2 className="text-xl font-bold ml-5 mb-5">Your Order</h2>
        <div className="flex flex-row justify-between mx-5 my-2">
          <p>Loading</p>
          <p>...</p>
        </div>
        <div className="mx-5 my-3 w-[90%] border-t border-dashed border-gray-400" />
        <div className="flex flex-row justify-between mx-5 my-2">
          <p className="font-bold">Total</p>
          <p className="font-bold">...</p>
        </div>
      </div>
    );
  }

  // Otherwise build it from the ground up
  const {
    items,
    subtotal,
    discount,
    tax,
    total,
  } = charges;

  return (
    <div className="w-[90%] border rounded-xl bg-gray-50 border-gray-100 py-5 mx-auto">
      <h2 className="text-xl font-bold ml-5 mb-5">Your Order</h2>
      {
        items.map(({ title, value }) => (
          <div className="flex flex-row justify-between mx-5 my-2">
            <p>{title}</p>
            <p>{formatNumberAsDollars(value)}</p>
          </div>
        ))
      }
      <div className="mx-5 my-3 w-[90%] border-t border-dashed border-gray-400" />
      <div className="flex flex-row justify-between mx-5 my-2">
        <p>{subtotal.title}</p>
        <p>{formatNumberAsDollars(subtotal.value)}</p>
      </div>
      {
        discount && (
        <div className="flex flex-row justify-between mx-5 my-2">
          <p>{discount.title}</p>
          <p>
            -
            {' '}
            {formatNumberAsDollars(discount.value)}
          </p>
        </div>
        )
      }
      <div className="flex flex-row justify-between mx-5 my-2">
        <p>{tax.title}</p>
        <p>{formatNumberAsDollars(tax.value)}</p>
      </div>
      <div className="mx-5 my-3 w-[90%] border-t border-dashed border-gray-400" />
      <div className="flex flex-row justify-between mx-5 my-2">
        <p className="font-bold">{total.title}</p>
        <p className="font-bold">{formatNumberAsDollars(total.value)}</p>
      </div>
    </div>
  );
};

ChargesSummary.propTypes = {
  charges: propTypes.objectOf(propTypes.any).isRequired,
};
