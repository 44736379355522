import React, { useState, useContext, useEffect } from 'react';
import {
  Navigate, Link, useParams, useSearchParams,
} from 'react-router-dom';

import {
  IoChevronBack,
  IoChevronForward,
  IoWarningOutline,
  IoCheckmarkDoneSharp,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import SyncLoader from 'react-spinners/SyncLoader';

import AppContext from '../../AppContext';

import { loadPortal, confirmPlatformUploads } from '../../api/platforms';
import { confirmUploads } from '../../api/upload';
import { addInvestorInfo } from '../../api/platformRebuild';

// Sub-pages
import TypePage from './TypePage';
import NamePage from './NamePage';
import InfoPage from './InfoPage';
import DocumentationUploadPage from './DocumentationUploadPage';
import ConfirmationPage from './ConfirmationPage';
import SelectionPage from './SelectionPage';
import NAExplanationPage from './NAExplanationPage';
import PersonalResidencePage from './PersonalResidencePage';
import ProfessionalCertPage from './ProfessionalCertPage';
import EquityOwnersPage from './EquityOwnersPage';

import NotesPage from './NotesPage';
import SubmitPage from './SubmitPage';

// Helper functionality
import {
  getCurrentVal, crumbToEvidenceId, crumbIsDone, checkUpdateIsNoop, getCurrentReview,
} from './evidence-helpers';

import {
  CRUMBS,
  crumbToReadable,
  userToBreadCrumbs,
} from './state-machine';

const NewPlatform = function () {
  const { portalId } = useParams();

  // Get the user from the
  const [{ user, token }, dispatch] = useContext(AppContext);

  // What stage in the process are we in?
  const [allCrumbs, setAllCrumbs] = useState([]);
  const [currCrumb, setCurrCrumb] = useState(); // Where we are
  const [maxCrumb, setMaxCrumb] = useState(); // Where we could be
  const [isSigner, setIsSigner] = useState(false); // Should we remind the user who they are?

  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';
  const retstr = returnUrl ? `?returnUrl=${returnUrl}` : '';

  // State for Moving around
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [done, setDone] = useState(false);

  // State for current value
  const [updateVal, setUpdateVal] = useState(null); // Current stored value

  // Set the current crumb for rendering and load current the value from the API
  const navigateToCrumb = (newCrumb) => {
    setCurrCrumb(newCrumb);
    setUpdateVal(getCurrentVal(user, newCrumb));
  };

  // Hit the endpoint to load this portal and go to the right place
  useEffect(async () => {
    const {
      error: newError,
      user: newUser,
      token: newToken,
      isSigner: newIsSigner,
    } = await loadPortal(portalId);

    // Save the token in localstorage and in the app state
    if (!newError) {
      dispatch({ type: 'SET_TOKEN', token: newToken });
      dispatch({ type: 'SET_USER', user: newUser });

      // Compute what step we're in based on the user
      const {
        curr: cCrumb,
        crumbs: aCrumbs,
      } = userToBreadCrumbs(newUser);
      setMaxCrumb(cCrumb);
      setAllCrumbs(aCrumbs);
      navigateToCrumb(cCrumb);
      setIsSigner(newIsSigner);

      setDone(true);
    } else {
      console.log(newError);
      setError(newError);
    }
  }, []);

  // If we don't have a user, redirect to home page
  if (!user) {
    return <Navigate to="/" />;
  }

  // Redirect if we're already finished
  if (user && !['SURVEY', 'UPLOAD', 'MORE_INFO'].includes(user.userStage)) {
    return <Navigate to={`/${retstr}`} />;
  }

  // Instead of redirecting, build a breadcrumb and render based on current state
  const maxCrumbIdx = allCrumbs.indexOf(maxCrumb);

  // If we're using a documentation standin page, just add an extra 2 on average
  const stageCount = allCrumbs.length + (allCrumbs.includes(CRUMBS.DOCUMENTATION) ? 2 : 0);
  const curIdx = allCrumbs.indexOf(currCrumb) + 1;
  console.log(`Val: ${(curIdx / stageCount) * 100.0}`);
  const widthStr = `${(curIdx / stageCount) * 100.0}%`;
  const progBar = (
    <div className="w-full bg-[#DCE1E7] h-1 sticky top-20 z-50">
      <div
        className="bg-[#5964F8] rounded-r-full h-1"
        style={{ width: widthStr }}
      />
    </div>
  );

  const breadcrumbs = (
    <div
      className="w-full mb-6 flex flex-row gap-3 items-center"
    >
      {
        allCrumbs.reduce((acc, crumb, idx) => {
          const navIndex = allCrumbs.indexOf(crumb);
          const isNavigable = (navIndex <= maxCrumbIdx);
          if (idx > 0) {
            acc.push(
              <IoChevronForward size={20} color="#A1A5AD" />,
            );
          }
          acc.push(
            isNavigable ? (
              <div
                className={`font-semibold cursor-pointer ${crumb === currCrumb ? 'text-black' : 'text-[#6A6E74]'}`}
                onClick={(e) => {
                  e.preventDefault();
                  navigateToCrumb(crumb);
                }}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
              >
                { crumbToReadable(crumb) }
              </div>
            ) : (
              <div className="font-semibold text-[#A1A5AD]">
                { crumbToReadable(crumb) }
              </div>
            ),
          );
          return acc;
        }, [])
      }
    </div>
  );

  /*
   * Flags for review or clarification
   */
  const currentReviews = getCurrentReview(user, currCrumb);
  console.log(currentReviews);
  const failedReviews = (currentReviews || []).filter(({ success }) => !success);
  const successfulReviews = (currentReviews || []).filter(({ success }) => !!success);
  const banners = (
    <div className="flex flex-col mb-4 gap-2">
      {/* Context reminder for people accredited as individual after entity */}
      {
        isSigner && (
          <div className="flex flex-row w-full bg-[#E1EFFD] rounded-[.2REM] items-start py-4 pl-6 pr-8 gap-6">
            <IoInformationCircleOutline size={40} color="#1C59DC" style={{ 'stroke-width': '3px' }} />
            <div className="flex flex-col">
              <h1 className="font-bold">Important reminder</h1>
              <p className="text-md">
                We have the information on your entity and now you&apos;re being
                accredited as an individual investor. Be sure to
                answer all questions as yourself, not on behalf of your entity.
              </p>
            </div>
          </div>
        )
      }
      {/* Failed Review Banners */}
      {
        failedReviews.map(({ note }) => (
          <div className="flex flex-row w-full bg-[#FBECDF] rounded-[.2REM] items-start py-4 pl-6 pr-8 gap-6">
            <IoWarningOutline size={40} color="#FE9C2C" style={{ 'stroke-width': '3px' }} />
            <div className="flex flex-col">
              <h1 className="font-bold">Important note from reviewer</h1>
              <p className="text-md">
                { note }
              </p>
            </div>
          </div>
        ))
      }
      {/* Success Review Banners */}
      {
        // Don't include success reviews if there's already a failed review
        failedReviews.length === 0 && successfulReviews.length > 0 && (
          <div className="flex flex-row w-full bg-[#DEF7EC] rounded-[.2REM] items-start py-4 pl-6 pr-8 gap-6">
            <IoCheckmarkDoneSharp size={40} color="#156E51" style={{ 'stroke-width': '3px' }} />
            <div className="flex flex-col">
              <h1 className="font-bold">This piece of evidence passed legal review</h1>
              <p className="text-md">
                No action is needed here &mdash; you can continue to the next step.
              </p>
            </div>
          </div>
        )
      }
    </div>
  );

  // Function for setting the update value in our subPages
  const onChange = (val) => {
    // TODO(Carter): Validations
    setUpdateVal(val);
  };

  // On Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Hit the confirmation endpoint
    const confirmWithApi = user.isPlatformUser ? confirmPlatformUploads : confirmUploads;
    const { error: newError, user: newUser } = await confirmWithApi(token);

    // Save the token in localstorage and in the app state, which will cause a re-direct
    if (!newError) {
      dispatch({ type: 'SET_USER', user: newUser });
    } else {
      setError(newError);
    }

    // Finished
    setLoading(false);
    setDone(true);
  };

  // On Update
  const onUpdate = async (e) => {
    e.preventDefault();

    // Set loading
    setLoading(true);

    // If we're not changing anything, just go to the next page
    const isNoop = checkUpdateIsNoop(user, currCrumb, updateVal);
    if (isNoop) {
      console.log('Skipping!');
      const currIdx = allCrumbs.indexOf(currCrumb);
      navigateToCrumb(allCrumbs[currIdx + 1]);
    } else {
      // Send the update to the API and wait for the result
      console.log('Updating');
      const prevIdx = allCrumbs.indexOf(currCrumb);
      console.log(`prevIdx: ${prevIdx}`);
      const evidenceId = crumbToEvidenceId(user, currCrumb);
      const {
        error: newError,
        user: newUser,
      } = await addInvestorInfo(token, currCrumb, updateVal, evidenceId);

      if (newError) {
        console.log('Got an error:');
        console.log(newError);
        setError(newError);
      } else {
        // Calculate previous index _before_ updating anything
        console.log(prevIdx);
        dispatch({ type: 'SET_USER', user: newUser });
        // Recompute where we are in the state based on the new user
        const {
          curr: cCrumb,
          crumbs: aCrumbs,
        } = userToBreadCrumbs(newUser);
        setMaxCrumb(cCrumb);
        setAllCrumbs(aCrumbs);

        // Navigate to the next crumb no matter what chronologically
        // So we don't skip stages even if they're "done"
        console.log(`Moving to ${aCrumbs[prevIdx + 1]}`);
        navigateToCrumb(aCrumbs[prevIdx + 1]);
        setError('');
      }
    }

    // Clear and reset the state.
    setLoading(false);
  };

  // Compute the current value of this crumb based on the user model
  const currentVal = getCurrentVal(user, currCrumb);
  console.log(allCrumbs);
  console.log(currCrumb);

  const components = (currCrumb || '').split('.');
  const crumbRoot = components[0];
  const variant = components.length ? components[1] : '';

  let content;
  switch (crumbRoot) {
    case CRUMBS.ENTITY_TYPE: {
      content = <TypePage onChange={onChange} passedVal={currentVal} />;
      break;
    }
    case CRUMBS.NAME: {
      content = <NamePage onChange={onChange} passedVal={currentVal} />;
      break;
    }
    case CRUMBS.INFO: {
      content = <InfoPage onChange={onChange} passedVal={currentVal} />;
      break;
    }
    case 'DOCUMENTATION': {
      content = (
        <DocumentationUploadPage
          variant={variant}
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case 'CONFIRMATION': {
      content = (
        <ConfirmationPage
          variant={variant}
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case 'SELECTION': {
      content = (
        <SelectionPage
          variant={variant}
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case CRUMBS.WILDCARD: {
      content = (
        <NAExplanationPage
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case CRUMBS.PERSONAL_RESIDENCE: {
      content = (
        <PersonalResidencePage
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case CRUMBS.PROFESSIONAL_CERT: {
      content = (
        <ProfessionalCertPage
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case 'EQUITY_OWNERS': {
      content = (
        <EquityOwnersPage
          onChange={onChange}
          passedVal={currentVal}
          // passedVal={[
          //   {
          //     email: 'email@example.com',
          //     legalName: 'John Doe',
          //     investorType: 'INDIVIDUAL',
          //     isSigningInvestor: true,
          //   },
          //   {
          //     email: 'carterb@gmail.com',
          //     legalName: 'carter b',
          //     investorType: 'INDIVIDUAL',
          //     isSigningInvestor: false,
          //   },
          // ]}
          variant={variant}
        />
      );
      break;
    }
    case CRUMBS.NOTES: {
      content = (
        <NotesPage
          onChange={onChange}
          passedVal={currentVal}
        />
      );
      break;
    }
    case CRUMBS.REVIEW: {
      content = (
        <SubmitPage crumbs={allCrumbs} />
      );
      break;
    }
    default: {
      console.log('crumbRoot: ', crumbRoot);
      content = (
        <div className="flex flex-row w-full justify-between py-4 items-start">
          <div className="flex flex-col w-6/12 items-center mt-4 py-16 opacity-90">
            <SyncLoader color="#5963F4" loading size={20} margin={5} speedMultiplier={0.8} />
          </div>
        </div>
      );
    }
  }

  const currIdx = allCrumbs.indexOf(currCrumb);
  const canGoBack = currIdx > 0;
  const maybeGoBack = () => {
    if (canGoBack) {
      navigateToCrumb(allCrumbs[currIdx - 1]);
    }
  };

  const canGoForward = crumbIsDone(user, currCrumb, updateVal);
  const bottomNav = (
    <div
      className="flex flex-col w-6/12 py-12"
    >
      {/* Divider */}
      <div className="w-full h-px bg-[#DCE1E7] mb-4 " />
      {/* Bottom Nav Buttons */}
      <div className="flex flex-row justify-between items-center ">
        {/* Back */}
        <div
          className={`flex flex-row items-center gap-2 cursor-pointer ${
            canGoBack ? 'hover:brightness-75' : 'opacity-50'
          }`}
          onClick={maybeGoBack}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          <IoChevronBack size={20} color="#5963F4" />
          <div className="text-[#5963F4] font-semibold">Back</div>
        </div>
        {/* Continue */}
        <div
          className={`flex flex-row cursor-pointer items-center bg-[#5963F4] rounded-[.3REM] py-3 px-6 text-white font-semibold ${
            canGoForward ? 'hover:brightness-75' : 'opacity-50'
          }`}
          onClick={canGoForward ? (e) => onUpdate(e) : () => {}}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          {
            loading && (
              <svg className="animate-spin h-3 w-3 -ml-4 mr-1 text-white position-absolute" viewBox="0 0 24 24">
                <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            )
          }
          <p>Continue</p>
        </div>
      </div>
      {/* Errror Message */}
      {
        error && (
          <div className="mt-6 text-sm text-red-600 font-semibold text-center">
            {`Server Error: "${error}"`}
          </div>
        )
      }
    </div>
  );
  const submitNav = (
    <div
      className="flex flex-col w-6/12 py-8"
    >
      {/* Divider */}
      <div className="w-full h-px bg-[#DCE1E7] mb-4 " />
      {/* Bottom Nav Buttons */}
      <div className="flex flex-row justify-between items-center ">
        {/* Back */}
        <div
          className={`flex flex-row items-center gap-2 cursor-pointer ${
            canGoBack ? 'hover:brightness-75' : 'opacity-50'
          }`}
          onClick={maybeGoBack}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          <IoChevronBack size={20} color="#5963F4" />
          <div className="text-[#5963F4] font-semibold">Back</div>
        </div>
        {/* Continue */}
        <div
          className="flex flex-row items-center bg-[#5963F4] rounded-[.3REM] py-3 px-6 text-white font-semibold hover:brightness-75 w-3/12 cursor-pointer"
          onClick={handleSubmit}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          {
            loading && (
              <svg className="animate-spin h-3 w-3 -ml-4 mr-1 text-white position-absolute" viewBox="0 0 24 24">
                <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            )
          }
          <p className="mx-auto">Submit</p>
        </div>
      </div>
      {/* Errror Message */}
      {
        error && (
          <div className="mt-6 text-sm text-red-600 font-semibold text-center">
            {`Server Error: "${error}"`}
          </div>
        )
      }
    </div>
  );

  return (
    <div className="w-full">
      { progBar }
      <div className="flex flex-col w-10/12 mx-auto py-4">
        { breadcrumbs }
        { banners }
        { content }
        { currCrumb === CRUMBS.REVIEW ? submitNav : bottomNav }
      </div>
    </div>
  );
};

export default NewPlatform;
