import { PREFIX } from './prefix';

async function getPlatform(platformId, sponsorId) {
  return fetch(`${PREFIX}/api/platforms/${platformId}/${sponsorId}`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function signupPlatformUser(credentials) {
  return fetch(`${PREFIX}/api/platforms/signup`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function confirmPlatformUploads(token) {
  return fetch(`${PREFIX}/api/platforms/confirm-uploads`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function getWaitingState(token) {
  return fetch(`${PREFIX}/api/platforms/waiting-on`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function loadPortal(portalId) {
  return fetch(`${PREFIX}/api/platforms/load-portal/${portalId}`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

// TODO(Carter): This doesn't really belong here but it's effectively the same
// as loadPortal
async function loadEmailLink(mlId) {
  return fetch(`${PREFIX}/api/email-link/${mlId}`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  signupPlatformUser,
  confirmPlatformUploads,
  getWaitingState,
  getPlatform,
  loadPortal,
  loadEmailLink,
};
