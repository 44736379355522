import dayjs from 'dayjs';
import React, { useContext, useState, useEffect } from 'react';

import { Navigate } from 'react-router-dom';
import { downloadPdf } from '../../api/downloadPdf';
import { startNewAccreditation } from '../../api/accounts';
import { B_COLORS, LG_ERR_TXT } from '../../AppStyles';

import AppContext from '../../AppContext';
import approval from '../../assets/approval.svg';

const FileDownload = require('js-file-download');

const ApprovedStatusPage = function () {
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';
  const retstr = returnUrl ? `?returnUrl=${returnUrl}` : '';

  const [{ user, token }, dispatch] = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [restartError, setRestartError] = useState('');
  const [done, setDone] = useState(false);

  if (!user || user.userType === 'ADMIN') {
    return (
      <Navigate to={`/${retstr}`} />
    );
  }
  if (user.userStage !== 'APPROVED') {
    return <Navigate to={`/${retstr}`} />;
  }
  if (done) {
    return <Navigate to={`/${retstr}`} />;
  }

  let accreditationIdx = 0;
  const mostRecent = user.accreditations.reduce((acc, cur, idx) => {
    if (cur.expirationDate > acc.expirationDate) {
      accreditationIdx = idx;
      return cur;
    }
    return acc;
  }, user.accreditations[0]);
  const {
    expirationDate,
  } = mostRecent;

  const returnButton = (
    <a
      className="flex cursor-pointer flex-row w-11/12 items-center bg-[#5963F4] rounded-[.3REM] py-3 px-6 text-white font-semibold hover:brightness-75 "
      href={returnUrl}
    >
      <span className="mx-auto">Continue Investing</span>
    </a>
  );

  const handleDownload = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-underscore-dangle
    const b = await downloadPdf(token, user._id, accreditationIdx);
    FileDownload(b, 'accreditation.pdf');
  };

  const handleStartNewButton = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error: newError, user: newUser } = await startNewAccreditation(token);
    if (newError) {
      setRestartError(newError);
    } else {
      dispatch({ type: 'SET_USER', user: newUser });
      setDone(true);
    }
    setLoading(false);
  };

  const currentContent = (
    <div className="w-full md:w-6/12 py-20">
      <h4 className="text-3xl text-gray-800 font-bold mb-10">Congratulations!</h4>
      <p className="text-lg text-gray-800 mb-5">Your accreditation status is confirmed and your verification letter is ready.</p>
      <p className="text-lg text-gray-800 mb-5">
        <span
          className="text-blue-800 cursor-pointer font-bold hover:text-orange-500 hover:underline"
          role="button"
          onClick={handleDownload}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          Click here
        </span>
        {' '}
        to download your accreditation letter. This accreditation
        will be valid until it expires on
        {' '}
        <span className="font-semibold">
          {
              dayjs(expirationDate).format('MMMM D, YYYY')
            }
        </span>
        .
      </p>
      <p className="text-lg text-gray-800 mb-8">
        If you have any questions, reach out to our support email &mdash;
        <a
          className="text-blue-800 font-semibold hover:text-orange-500 hover:underline"
          href="mailto:support@checkinvestorstatus.com"
        >
          support@checkinvestorstatus.com
        </a>
      </p>
      {
        returnUrl ? returnButton : (
          <p className="text-lg text-gray-800 mb-8 font-semibold">
            Happy investing!
          </p>
        )
      }
    </div>
  );

  const startNewButton = (
    <div
      className={`w-3/4 md-1/2 rounded-xl cursor-pointer font-semibold text-center text-white py-3 my-8 ${B_COLORS}`}
      role="button"
      onClick={handleStartNewButton}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      { loading ? (
        <div className="flex flex-row items-center">
          <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
            <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <div className="-ml-7 w-full">Start New Accreditation</div>
        </div>
      ) : (
        <div className="w-full">Start New Accreditation</div>
      )}
    </div>
  );
  const expiredContent = (
    <div className="w-full md:w-6/12 py-20">
      <h4 className="text-3xl text-gray-800 font-bold mb-10">Your Accreditation Has Expired!</h4>
      <p className="text-lg text-gray-800 mb-5">
        The SEC requires that accreditations are based on evidence from the last 90 days.
        Unfortunately, your last accreditation expired on
        {' '}
        <span className="font-semibold">
          {
              dayjs(expirationDate).format('MMMM D, YYYY')
            }
        </span>
      </p>
      <p className="text-lg text-gray-800 mb-5">
        Don&apos;t worry though &mdash; we can help you get re-accredited.
      </p>
      { startNewButton }
      { restartError && <div className={`${LG_ERR_TXT} mb-3 mt-2 ml-10`}>{restartError}</div>}
    </div>
  );

  return (
    <div className="w-9/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 border-blue-500">

      <h2 className="text-4xl font-bold text-center text-gray-800">
        You&apos;ve Been Verified
      </h2>

      <div className="flex justify-between flex-wrap mb-20">
        <div className="w-full md:w-1/2 p-5">
          <img src={approval} alt="Review" />
        </div>
        { new Date(expirationDate) > new Date() ? currentContent : expiredContent }
      </div>
    </div>
  );
};

export default ApprovedStatusPage;
