import { PREFIX } from './prefix';

async function addInvestorInfo(token, type, val, evidenceId) {
  return fetch(`${PREFIX}/api/platforms/add-investor-info`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({
      type,
      val,
      evidenceId,
    }),
  })
    .then((data) => data.json())
    .catch((error) => ({ error }));
}

async function resetSurvey(token) {
  return fetch(`${PREFIX}/api/reset-survey`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => null);
}

export {
  addInvestorInfo,
  resetSurvey,
};
