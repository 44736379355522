import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';
import { requiredDocumentation } from '../_Upload/uploadContent';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Primary residence
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-8">
    Enter the following information about the value and debts
    on your primary residence &mdash; wherever you live for
    the majority of your time.
  </p>
);

const PersonalResidencePage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const [{ user, token }, dispatch] = useContext(AppContext);

  const updateValue = (e) => {
    e.preventDefault();
    const newVal = [e.target.value, val[1], val[2]];
    setVal(newVal);
    onChange(newVal);
  };
  // Short input for residence Val
  const valueInput = (
    <div className="flex flex-col">
      <label className="flex-grow w-11/12 mb-8" htmlFor="value-name-in">
        <p className="text-md font-semibold">What is the estimated value of your primary residence?</p>
        <p className="text-sm">
          A recent estimate based on listing price, appraisal, or even a Zillow estimate is fine.
        </p>
        <input
          className="border border-[#DCE1E7] w-full px-1 mt-2 rounded-[.3REM]"
          type="text"
          id="value-name-in"
          onChange={updateValue}
          placeholder="$1,000,000"
          value={val[0] || ''}
        />
      </label>
    </div>
  );

  const updateDebt = (e) => {
    e.preventDefault();
    const newVal = [val[0], e.target.value, val[2]];
    setVal(newVal);
    onChange(newVal);
  };
  // Short input for residence Val
  const debtInput = (
    <div className="flex flex-col">
      <label className="flex-grow w-11/12 mb-8" htmlFor="value-name-in">
        <p className="text-md font-semibold">How much debt is secured against your primary residence?</p>
        <p className="text-sm">
          This includes mortgages as well as debts secured against your property
          (such as a Home Equity Line of Credit).
        </p>
        <input
          className="border border-[#DCE1E7] w-full px-1 mt-2 rounded-[.3REM]"
          type="text"
          id="debt-name-in"
          onChange={updateDebt}
          placeholder="$500,000"
          value={val[1] || ''}
        />
      </label>
    </div>
  );

  const recencyOptions = requiredDocumentation.NET_WORTH.filter(
    ({ id }) => id === 'NET_WORTH_RESIDENCE_DEBT_RECENCY',
  )[0].options;
  const updateRecency = (e) => {
    e.preventDefault();
    const newVal = [val[0], val[1], e.target.value];
    setVal(newVal);
    onChange(newVal);
  };

  // Drop Down for recency val
  const recencyDropDown = (
    <div className="flex flex-col">
      <label className="flex-grow w-11/12 mb-8" htmlFor="value-name-in">
        <p className="text-md font-semibold">When did you take out the debt on your primary residence?</p>
        <div className="my-2">
          <select
            className="border w-full"
            id="recency-dropdown"
            name="recency-dropdown"
            onChange={updateRecency}
          >
            <option value="none" disabled hidden selected={!val[2]}>Select an option</option>
            { recencyOptions.map((oText) => (
              <option value={oText} selected={val[2] === oText}>
                {oText}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>
  );

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="text-black mb-4">
        The value of your primary residence does not count
        toward the net worth requirement. However, we do have to include any liabilities
        secured against your primary residence if they exceed the value of your
        residence or if they were taken out in the last 60 days.
      </p>
      <p className="text-black mb-4">
        If you have any debts secured against your primary residence, you can
        give your reviewer more details about that in the &quot;Notes&quot; section before
        your submit your application for legal review.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { valueInput }
        { debtInput }
        { recencyDropDown }
      </div>
      { infoCol }
    </div>
  );
};
PersonalResidencePage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
PersonalResidencePage.defaultProps = {
  passedVal: '',
};

export default PersonalResidencePage;
