import React, { useState, useContext } from 'react';
import { Navigate, Link } from 'react-router-dom';

import AppContext from '../AppContext';
import { L_COLORS, B_COLORS, LG_ERR_TXT } from '../AppStyles';
import { requestPasswordReset } from '../api/resetPassword';

const Login = function () {
  const [email, setEmail] = useState();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Use Token State
  const [{ token }, dispatch] = useContext(AppContext);

  // Handle Submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Wait for it
    const { error: newError } = await requestPasswordReset(email);

    if (newError) {
      setError(newError);
    }

    setLoading(false);
    setDone(true);
  };

  return (
    <div className="container flex flex-col w-2/3 lg:w-1/2 xl:w-5/12 mx-auto my-10 py-8 px-24 border border-gray-200 rounded-xl shadow-lg">
      <div className="pb-8">
        <h1 className="text-2xl text-center">Forgot Your Password?</h1>
        { error && (
        <div className={`mt-3 text-center mx-auto ${LG_ERR_TXT} hidden`}>{error}</div>
        )}
      </div>
      <div className="flex flex-row items-center justify-center text-center mx-auto w-full mb-8">
        <p>
          Enter the email address associated with your account and we&apos;ll
          send you a email with a link to set a new password.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="w-full mb-8 mx-auto">
          <label htmlFor="email-in">
            <p className="text-sm font-bold">Email</p>
            <input
              className="w-full border border-gray-200 px-1 mt-1"
              type="text"
              id="email-in"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>
        {
            done ? (
              <div className="py-2 w-full">
                <p className="text-center">
                  If there is an account associated with that email, it should receive an
                  email with a password reset link within a few mintues.
                </p>
              </div>
            ) : (
              <div>
                <div className="flex flex-row items-center justify-between mx-auto w-full">
                  <div className="py-2 w-full">
                    <button
                      className={`py-1 w-full text-white font-semibold rounded border ${B_COLORS}`}
                      type="submit"
                    >
                      { loading ? (
                        <div className="flex flex-row items-center">
                          <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                            <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                          </svg>
                          <div className="-ml-7 w-full">Reset Password</div>
                        </div>
                      ) : (
                        <div className="w-full">Reset Password</div>
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center text-center mx-auto w-full mb-5">
                  <div className="container">
                    <Link className={`font-semibold ${L_COLORS} text-sm`} to="/login">Back to Login</Link>
                  </div>
                </div>
              </div>

            )
          }
      </form>
    </div>
  );
};

export default Login;
