import React, { useState, useContext } from 'react';
import { Navigate, Link } from 'react-router-dom';

import AppContext from '../AppContext';
import { B_COLORS, LG_ERR_TXT } from '../AppStyles';
import { loginUser } from '../api/login';

const Login = function () {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Use Token State
  const [{ token }, dispatch] = useContext(AppContext);

  // Handle Submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error: newError, token: newToken, user: newUser } = await loginUser({
      email,
      password,
    });

    // Save the token in localstorage and in the app state
    if (!newError) {
      dispatch({ type: 'SET_TOKEN', token: newToken });
      dispatch({ type: 'SET_USER', user: newUser });
    } else {
      dispatch({ type: 'SET_TOKEN', token: null });
      dispatch({ type: 'SET_USER', user: newUser });
      setError(newError);
    }
    setLoading(false);
  };

  return !token
    ? (
      <div className="container flex flex-col w-2/3 lg:w-1/2 xl:w-5/12 mx-auto my-10 py-8 px-48 border border-gray-200 rounded-xl shadow-lg">
        <div className="pb-8">
          <h1 className="text-2xl text-center mx-auto">Log In</h1>
          { error && (
          <div className={`mt-3 ${LG_ERR_TXT} text-center mx-auto`}>{error}</div>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="w-full mb-5">
            <label htmlFor="email-in">
              <p className="text-sm font-bold">Email</p>
              <input
                className="w-full border border-gray-200 px-1 mt-1"
                type="text"
                id="email-in"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div className="gap-10 w-full">
            <label className="flex-grow" htmlFor="password-in">
              <p className="text-sm font-bold">Password</p>
              <input
                className="border border-gray-200 w-full px-1 mt-1"
                type="password"
                id="password-in"
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
          </div>
          <div className="gap-10 w-full mb-8 text-right">
            <Link className="font-semibold text-blue-800 hover:underline hover:text-orange-500 text-xs" to="/reset-password">Forgot Your Password?</Link>
          </div>
          <div className="flex flex-row items-center justify-between mx-auto w-full">
            <div className="py-2 w-full">
              <button
                className={`py-1 w-full text-white font-semibold rounded border ${B_COLORS}`}
                type="submit"
              >
                { loading ? (
                  <div className="flex flex-row items-center">
                    <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                      <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <div className="-ml-7 w-full">Log In</div>
                  </div>
                ) : (
                  <div className="w-full">Log In</div>
                )}
              </button>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center text-center mx-auto w-full mb-5">
            <div className="container">
              <Link className="font-semibold text-blue-800 hover:underline hover:text-orange-500 text-sm" to="/signup">Create an Account Instead</Link>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <Navigate to="/" />
    );
};

export default Login;
