import React, { useState, useContext } from 'react';
import { Navigate, Link, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import validator from 'validator';
import AppContext from '../../AppContext';
import { signupUser } from '../../api/signup';

import {
  B_COLORS, L_COLORS, INVALID_FORM, ERR_TXT, LG_ERR_TXT,
} from '../../AppStyles';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Signup = function () {
  const query = useQuery();
  const urlProvidedEmail = query.get('fromEmail') || '';

  const [email, setEmail] = useState(urlProvidedEmail);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [passwordVerification, setPasswordVerification] = useState();
  const [investingWith, setInvestingWith] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordVerificationInvalid, setPasswordVerificationInvalid] = useState(false);
  const [isCheckedInvalid, setIsCheckedInvalid] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Use Token Global State
  const [{ token }, dispatch] = useContext(AppContext);

  const validateInputs = () => {
    let isValid = true;
    if (validator.isEmpty(firstName || '', { ignore_whitespace: true })) {
      setFirstNameInvalid(true);
      isValid = false;
    }
    if (validator.isEmpty(lastName || '', { ignore_whitespace: true })) {
      setLastNameInvalid(true);
      isValid = false;
    }
    if (!validator.isEmail(email || '')) {
      setEmailInvalid(true);
      isValid = false;
    }
    if (!validator.isStrongPassword(
      password || '',
      {
        minLength: 8, minUppercase: 0, minNumbers: 1, minSymbols: 1,
      },
    )) {
      setPasswordInvalid(true);
      isValid = false;
    }
    if (!validator.equals(password || '', passwordVerification)) {
      setPasswordVerificationInvalid(true);
      isValid = false;
    }
    if (!isChecked) {
      setIsCheckedInvalid(true);
      isValid = false;
    }

    return isValid;
  };

  // Handle Submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate inputs
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    const { error: newError, token: newToken, user: newUser } = await signupUser({
      firstName,
      lastName,
      email,
      password,
      passwordVerification,
      investingWith,
    });

    // Save the token in localstorage and in the app state
    if (!newError) {
      ReactGA.event({
        category: 'User',
        action: 'Created an Account',
        label: newUser.userType,
      });

      dispatch({ type: 'SET_TOKEN', token: newToken });
      dispatch({ type: 'SET_USER', user: newUser });
    } else {
      setError(newError);
    }
    setLoading(false);
  };

  // Check marks are special
  const handleChange = (e) => {
    setIsChecked(!isChecked);
    setIsCheckedInvalid(!(!isChecked));
  };

  return !token
    ? (
      <div className="container bg-white flex flex-col h-full w-5/12 mx-auto py-8 px-20 border border-gray-200 rounded-xl shadow-lg">
        <h1 className="text-2xl pb-10 text-center">
          Get your Accredited Investor Certificate
          { error && (
          <div className={`mt-3 ${LG_ERR_TXT}`}>{error}</div>
          )}

        </h1>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row justify-between gap-10 mb-5">
            <label className="flex-grow" htmlFor="first-name-in">
              <p className="text-sm font-bold">First Name</p>
              <input
                className={`border border-gray-200 w-full px-1 mt-1 ${firstNameInvalid && INVALID_FORM}`}
                type="text"
                id="first-name-in"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                onBlur={(e) => {
                // Validate Email. If it's invalid, set disabled state
                  if (!validator.isEmpty(firstName, { ignore_whitespace: true })) {
                    setFirstNameInvalid(false);
                  } else {
                    setFirstNameInvalid(true);
                  }
                }}
              />
            </label>
            <label className="flex-grow" htmlFor="last-name-in">
              <p className="text-sm font-bold">Last Name</p>
              <input
                className={`border border-gray-200 w-full px-1 mt-1 ${lastNameInvalid && INVALID_FORM}`}
                type="text"
                id="last-name-in"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                onBlur={(e) => {
                // Validate Email. If it's invalid, set disabled state
                  if (!validator.isEmpty(lastName, { ignore_whitespace: true })) {
                    setLastNameInvalid(false);
                  } else {
                    setLastNameInvalid(true);
                  }
                }}
              />
            </label>
          </div>
          {
            !urlProvidedEmail && (
              <div className="w-full mb-5">
                <label htmlFor="email-in">
                  <p className="text-sm font-bold">Email</p>
                  <input
                    className={`w-full border border-gray-200 px-1 mt-1 ${emailInvalid && INVALID_FORM}`}
                    type="text"
                    id="email-in"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    onBlur={(e) => {
                      // Validate Email. If it's invalid, set disabled state
                      if (validator.isEmail(email)) {
                        setEmailInvalid(false);
                      } else {
                        setEmailInvalid(true);
                      }
                    }}
                  />
                </label>
                { emailInvalid && (
                  <div className={ERR_TXT}>Please enter a valid email address.</div>
                )}
              </div>
            )
          }
          <div className="flex flex-row justify-between gap-10 w-full mb-5">
            <div className="flex flex-col w-1/2">
              <label className="flex-grow" htmlFor="password-in">
                <p className="text-sm font-bold">Password</p>
                <input
                  className={`border border-gray-200 w-full px-1 mt-1 ${passwordInvalid && INVALID_FORM}`}
                  type="password"
                  id="password-in"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  onBlur={(e) => {
                  // Validate Password. If it's invalid, set disabled state
                    if (validator.isStrongPassword(
                      password,
                      {
                        minLength: 8, minUppercase: 0, minNumbers: 1, minSymbols: 1,
                      },
                    )
                    ) {
                      setPasswordInvalid(false);
                    } else {
                      setPasswordInvalid(true);
                    }
                  }}
                />
              </label>
              { passwordInvalid && (
              <div className={ERR_TXT}>
                Password must be at least 8 characters and must contain at
                least one number and one special character.
              </div>
              )}
            </div>
            <div className="flex flex-col w-1/2">
              <label className="flex-grow" htmlFor="password-verification-in">
                <p className="text-sm font-bold">Verify Password</p>
                <input
                  className={`border border-gray-200 w-full px-1 mt-1 ${passwordVerificationInvalid && INVALID_FORM}`}
                  type="password"
                  id="password-verification-in"
                  onChange={(e) => setPasswordVerification(e.target.value)}
                  value={passwordVerification}
                  onBlur={(e) => {
                  // Validate Email. If it's invalid, set disabled state
                    if (validator.equals(password, passwordVerification)) {
                      setPasswordVerificationInvalid(false);
                    } else {
                      setPasswordVerificationInvalid(true);
                    }
                  }}
                />
              </label>
              { passwordVerificationInvalid && (
              <div className={ERR_TXT}>
                Passwords don&apos;t match.
              </div>
              )}
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="support-form-input">
              <p className="text-base font-bold">What platform or sponsor are you getting accredited for?</p>
              <textarea
                className="border mb-5 w-full h-[4rem] px-2 py-1 mt-1"
                type="text"
                id="support-form-input"
                placeholder="Your platform"
                maxLength="500"
                onChange={(e) => setInvestingWith(e.target.value)}
                value={investingWith}
              />
            </label>
          </div>
          <div className="mb-7">
            <div className="flex items-start">
              <div className="flex items-center">
                &#8203;
                <input
                  type="checkbox"
                  checked={isChecked}
                  className="form-checkbox border-gray-400 "
                  onChange={handleChange}
                  id="tos-and-pp"
                />
              </div>
              <div className="ml-3" htmlFor="tos-and-pp">
                By checking this box, I agree to the
                {' '}
                <Link
                  className={`font-semibold ${L_COLORS}`}
                  to="/tos"
                >
                  Terms of Use
                </Link>
                {' '}
                and
                {' '}
                <Link
                  className={`font-semibold ${L_COLORS}`}
                  to="/privacy"
                >
                  Privacy Policy
                </Link>
                {' '}
                for Check Investor Status.
              </div>
            </div>
            { isCheckedInvalid && (
              <div className={`ml-6 ${ERR_TXT}`}>
                Please accept our terms of service and privacy policy to continue.
              </div>
            )}
          </div>
          <div className="flex flex-row items-center justify-between mx-auto w-full mb-5">
            <div className="container">
              <Link className={`font-semibold text-sm ${L_COLORS}`} to="/login">Sign In Instead</Link>
            </div>
            <div className="py-2 w-1/2">
              <button
                className={`py-1 w-full text-white font-semibold rounded border ${B_COLORS} `}
                type="submit"
              >
                { loading ? (
                  <div className="flex flex-row items-center">
                    <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
                      <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <div className="-ml-7 w-full">Sign Up</div>
                  </div>
                ) : (
                  <div className="w-full">Sign Up</div>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
    : (
      <Navigate to="/" />
    );
};

export default Signup;
