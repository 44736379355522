import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import dayjs from 'dayjs';

// import propTypes from 'prop-types';
import AppContext from '../../AppContext';
import {
  fetchUser,
  rejectAccreditation,
  confirmAccreditation,
  failAccreditation,
  archiveUser,
} from '../../api/admin';

import { requiredDocumentation } from '../_Upload/uploadContent';

import EvidenceReviewer from './EvidenceReviewer';

const UserVerification = function () {
  const { pendingId } = useParams();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [pendingUser, setPendingUser] = useState({});
  const [evidenceReviewed, setEvidenceReviewed] = useState([]);
  const [evidenceSucceeded, setEvidenceSucceeded] = useState([]);
  const [notes, setNotes] = useState([]);
  const [expiration, setExpiration] = useState(dayjs().add(90, 'day').toDate());

  const [failureReason, setFailureReason] = useState('');

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Load in the user from the API
  useEffect(async () => {
    setLoading(true);

    const { error: newError, user: newPendingUser } = await fetchUser(token, pendingId);

    // Save the token in localstorage and in the app state
    if (newError) {
      setError(newError);
    }

    // Set not loading anymore
    setPendingUser(newPendingUser);
    const evidenceLen = newPendingUser.verificationEvidence.length;
    setEvidenceReviewed((new Array(evidenceLen)).fill(false));
    setEvidenceSucceeded((new Array(evidenceLen)).fill(false));
    setNotes((new Array(evidenceLen)).fill(''));
    setLoading(false);
  }, []);

  // Handlers for investor-level approval or rejection
  const handleAccredit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const {
      error: newError,
    } = await confirmAccreditation(token, pendingId, notes, evidenceSucceeded, expiration);

    // Handle response from the api
    if (newError) {
      setError(newError);
    } else {
      setIsDone(true);
    }
    setLoading(false);
  };

  const handleReject = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      error: newError,
    } = await rejectAccreditation(token, pendingId, notes, evidenceSucceeded);

    // Handle response from the api
    if (newError) {
      setError(newError);
    } else {
      setIsDone(true);
    }
    setLoading(false);
  };

  const handleFail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      error: newError,
    } = await failAccreditation(token, pendingId, failureReason);

    // Handle response from the api
    if (newError) {
      setError(newError);
    } else {
      setIsDone(true);
    }
    setLoading(false);
  };

  const handleArchive = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      error: newError,
    } = await archiveUser(token, pendingId);

    // Handle response from the api
    if (newError) {
      setError(newError);
    } else {
      setIsDone(true);
    }
    setLoading(false);
  };

  // Handlers for individual evidence success or failure
  const handleFailure = (e, idx, note) => {
    e.preventDefault();
    const newReviewed = [...evidenceReviewed];
    const newSuccesses = [...evidenceSucceeded];
    const newNotes = [...notes];

    newReviewed[idx] = true;
    newSuccesses[idx] = false;
    newNotes[idx] = note || '';

    setEvidenceReviewed(newReviewed);
    setEvidenceSucceeded(newSuccesses);
    setNotes(newNotes);
  };

  const handleSuccess = (e, idx, note) => {
    e.preventDefault();
    const newReviewed = [...evidenceReviewed];
    const newSuccesses = [...evidenceSucceeded];
    const newNotes = [...notes];

    newReviewed[idx] = true;
    newSuccesses[idx] = true;
    newNotes[idx] = note || '';

    setEvidenceReviewed(newReviewed);
    setEvidenceSucceeded(newSuccesses);
    setNotes(newNotes);
  };

  // Redirect once we log the user in
  if (!token && !user) {
    return <Navigate to="/" />;
  }
  // Redirect once we log the user in
  if (user.userType !== 'ADMIN') {
    return <Navigate to="/" />;
  }
  // Redirect once we've finished
  if (isDone) {
    return <Navigate to="/admin" />;
  }

  const requiredDocumentationList = (
    pendingUser && requiredDocumentation[pendingUser.verificationMethod]
  );

  const loadScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Fetching user from the Database
      </h2>
    </div>
  );

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Something went wrong ...
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        Could not get the user for the database. Call Carter.
      </h2>
    </div>
  );

  const topLevelInstructions = (pendingUser
    && (
    <div className="flex flex-col text-left mx-auto w-full border-b pb-5">
      <h2 className="text-2xl font-bold mt-2 mb-10">Legal Review Instructions:</h2>
      <p className="text-lg mb-4">
        <span className="font-semibold">
          { pendingUser.legalName }
        </span>
        {' '}
        has requested to be requested as a
        {' '}
        <span className="font-semibold">
          { pendingUser.investorType }
        </span>
        {' '}
        accredited investor.
      </p>
      <p className="text-lg mb-4">
        They have elected to use the
        {' '}
        <span className="font-semibold">
          {pendingUser.verificationMethod }
        </span>
        {' '}
        method of verification.
      </p>
      <div className="border border-blue-300 mb-10 p-5 rounded-2xl bg-blue-50">
        <h2 className="font-bold text-xl mb-3">REQUIRED DOCUMENTATION:</h2>
        <h3 className="font-bold text-md mt-5">To verify this investor&apos;s accreditation status, we have had the user upload the following evidence:</h3>
        <ol className="mx-5 my-4">
          { (requiredDocumentationList || []).map(
            ({ description }) => (
              <li className="list-decimal list-inside mb-2 text-md">{description}</li>
            ),
          )}
        </ol>
      </div>
      <p className="text-lg mb-4">
        Each piece of uploaded documentation is shown below. Each piece of documentation will have
        specific instructions on what you should look for in order to verify that piece of
        documentation.
      </p>
      <p className="text-lg mb-4">
        After verifying each piece of evidence, click to &quot;Accept&quot; or
        &quot;Reject&quot;. If you choose to &quot;Reject&quot; a piece of documentation,
        please explain why you are rejecting that documentation in full
        sentences and leave detailed instructions on what the investor should
        upload in order to get verified.
      </p>
      <p className="text-lg mb-4">
        If you make a mistake,
        {' '}
        <span className="font-semibold">
          you can refresh the page to start over on this accreditation.
        </span>
        {' '}
        If you need further help or think you are seeing a bug, contact Carter.
      </p>
    </div>
    )
  );

  const canReject = (
    !loading
    && evidenceReviewed.length > 0
    // Delete for now and let people reduce any time a piece of
    // Evidence has failed
    // TODO(Carter): figure out like with the case below in canConfirm
    // && evidenceReviewed.reduce((acc, cur) => acc && cur, true)
    && !evidenceSucceeded.reduce((acc, cur) => acc && cur, true)
  );

  const canConfirm = (
    !loading
    && evidenceSucceeded.length > 0
    // This is convoluted because there's a chance that
    // The user has uploaded unnecessary documentation
    // (EG before changing to a different verification method)
    && evidenceSucceeded.reduce((acc, cur, idx) => acc && (cur || !requiredDocumentationList.map(
      (r) => r.id,
    ).includes(pendingUser.verificationEvidence[idx].verificationId)), true)
  );

  const maybeDisableReject = canReject ? 'opacity-100' : 'opacity-30 hover:bg-red-600';
  const maybeDisableConfirm = canConfirm && expiration ? 'opacity-100' : 'opacity-30 hover:bg-green-600';

  const buttons = (
    <div className="container flex flex-row justify-between my-5 pb-5 px-5">
      <div
        className={`w-full md:w-1/2 xl:w-1/3 rounded-xl font-semibold mx-5 text-center text-white py-3 bg-red-600 hover:bg-red-700 ${maybeDisableReject}`}
        role="button"
        onClick={handleReject}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Request More Info
      </div>
      <div
        className={`w-full md:w-1/2 xl:w-1/3 rounded-xl font-semibold mx-5 text-center text-white py-3 bg-green-600 hover:bg-green-800 ${maybeDisableConfirm}`}
        role="button"
        onClick={handleAccredit}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Accredit Investor
      </div>
    </div>
  );

  const expirationDatePicker = (
    canConfirm ? (
      <div className="flex flex-col text-left mx-auto w-full border-b pb-5 py-10">
        <h2 className="text-2xl font-bold mt-2 mb-10 underline">Please Select an Expiration Date:</h2>
        <p className="text-lg mb-4">
          The Expiration Date for this accreditation should be 90 days from today, unless you
          are re-verifying an existing letter.
        </p>
        <p className="text-lg mb-4">
          For example, if the user provided a letter from a professional dated 60 days ago, then
          the expiration date you set should be 30 days from today.
        </p>
        <p className="text-lg mb-4 font-bold">
          Click to manually change expiration date
        </p>
        <div className="mx-auto border border-blue-500 rounded-sm px-2 py-2">
          <DatePicker
            selected={expiration}
            onChange={
              (date) => {
                setExpiration(new Date(date));
              }
            }
          />
        </div>
      </div>
    ) : (
      <div />
    )
  );

  const finalReview = (pendingUser
    && (
    <div className="flex flex-col text-left mx-auto w-full border-b pb-5 py-10">
      <h2 className="text-2xl font-bold mt-2 mb-10 underline">Accredit or Reject this Investor:</h2>
      <p className="text-lg mb-4">
        Once you have reviewed each of the pieces of evidence individually,
        you can reject or accredit the investor. Rejected investors will be provided with
        the notes you&apos;ve written.
      </p>
      <p className="text-xl mb-4 font-bold bg-blue-500 p-5">
        NOTE: If you make a mistake here, it is much harder to un-do. Mistakes here
        put both this
        company and yourself in legal and financial jeopardy. Please double-check
        that in your best legal opinion, this investor is accredited before
        finalizing your accreditation.
      </p>
      { buttons }
    </div>
    )
  );

  const canFail = !!failureReason;
  const maybeDisableFail = canFail ? 'opacity-100' : 'opacity-30';
  const failButton = (
    <div className="container flex flex-row justify-around my-5 pb-5 px-5">
      <div
        className={`w-full md:w-1/2 xl:w-1/3 rounded-xl font-semibold mx-5 text-center text-white py-3 bg-red-600 hover:bg-red-700 ${maybeDisableFail}`}
        role="button"
        onClick={canFail ? handleFail : () => {}}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Permanently Fail Investor
      </div>
    </div>
  );
  const failNoteInputter = (
    <textarea
      className="border my-2 w-11/12 h-[8rem] px-2 py-1 mx-auto"
      type="text"
      id="failure-note-in"
      placeholder="Your notes for the investor about why they failed accreditation."
      onChange={(e) => setFailureReason(e.target.value)}
    />
  );

  const failUser = (pendingUser
    && (
    <div className="flex flex-col text-left mx-auto w-full border-b pb-5 py-10 justify-center">
      <h2 className="text-2xl font-bold mt-2 mb-10 underline">Fail this user:</h2>
      <p className="text-lg mb-4">
        If you have determined that the user is absolutely NOT accredited, then you
        can outright &quot;Fail&quot; them.

      </p>
      <p className="text-xl mb-4 font-bold p-5">
        You should only do this if there is no possibility that they are accredited
        under the selected verification method (EG if their tax forms show income
        underneath the required amounts). If there&apos;s any possibility that
        they could be accredited, you should &quot;Request More Info&quot; above instead.
      </p>
      <p className="text-lg mb-4">
        Please also leave a detailed message explaining why you have
        determined that this investor definitively is not accredited.
        This message is shown to the user and should be polite and
        written in complete sentences.
      </p>
      <p className="text-lg mb-4">
        Alternatively, you can &quot;archive&quot; an account that
        you want to remove from the queue without actually approving,
        rejecting, or requesting more info. This is best for test
        accounts, mistakes, etc. Basically denotes that no action is
        needed.
      </p>
      { failNoteInputter }
      { failButton }
      <div
        className="text-center text-gray-700 font-semibold hover:font-bold hover:underline -my-6"
        role="button"
        onClick={(e) => handleArchive(e)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Archive User Instead
      </div>
    </div>
    )
  );
  return (
    <div className="container-fluid">
      { loading && loadScreen }
      { error && errorScreen }
      { !loading && !error && (
        <div className="border border-gray-200 rounded-xl shadow-md w-10/12 xl:w-8/12 mx-auto my-10 py-16 px-20">
          <h1 className="text-2xl underline font-bold mb-10 text-center mx-auto">
            Legal Review of Accreditation Request for
            {' '}
            { pendingUser.legalName }
            :
          </h1>
          { topLevelInstructions }
          <div className="divide-y">
            {
              pendingUser.verificationEvidence.map((evidence, idx) => {
                const requiredDoc = (requiredDocumentationList || []).filter(
                  (method) => method.id === evidence.verificationId,
                )[0];

                if (!requiredDoc) {
                  return <div />;
                }

                return (
                  <div>
                    <EvidenceReviewer
                      evidence={evidence}
                      requiredDocumentation={requiredDoc}
                      idx={idx}
                      success={(evidenceReviewed.length > idx
                        && evidenceReviewed[idx]
                        && evidenceSucceeded[idx]
                      )}
                      failure={(evidenceReviewed.length > idx
                        && evidenceReviewed[idx]
                        && !evidenceSucceeded[idx]
                      )}
                      onSuccess={handleSuccess}
                      onFailure={handleFailure}
                    />
                  </div>
                );
              })
            }
            { expirationDatePicker }
            { finalReview }
            { failUser }
          </div>
        </div>

      ) }
    </div>
  );
};

export default UserVerification;
