import React, { useEffect, useContext, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';

import { IoCloseSharp } from 'react-icons/io5';
import AppContext from '../../AppContext';

import { fetchPending, archiveUser } from '../../api/admin';

const ReviewQueue = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);
  const [error, setError] = useState('');
  const [count, setCount] = useState(0);
  const [pending, setPending] = useState([]);

  // Fetch the pending verifications from the DB
  useEffect(async () => {
  // Update the user

    const { error: newError, count: newCount, pending: newPending } = await fetchPending(token);
    if (newError) {
      setError(newError);
    } else {
      setCount(newCount);
      setPending(newPending);
    }
  }, []);

  if (!token || !user) {
    return (
      <Navigate to="/" />
    );
  }
  if (user.userType !== 'ADMIN') {
    return (
      <Navigate to="/" />
    );
  }

  // Function to archive a user
  const handleArchiveUser = async (e, userId) => {
    e.preventDefault();
    // This is shitty and could be done much better
    const {
      error: archiveError,
    } = await archiveUser(token, userId);

    if (archiveError) {
      setError(archiveError);
    } else {
      // Re-get the pending users
      const { error: fetchError, count: newCount, pending: newPending } = await fetchPending(token);
      if (fetchError) {
        setError(fetchError);
      } else {
        setCount(newCount);
        setPending(newPending);
      }
    }
  };

  // Build a table of summary of pending verifications
  let table;
  if (count === 0) {
    table = (
      <h2 className="text-xl font-semibold my-20 text-center">
        You&apos;re all caught up &mdash; check back later for more pending accreditations.
      </h2>
    );
  } else {
    const ROW_PROPS = 'flex border border-gray-200 divide-x text-lg text-left hover:bg-blue-50';
    const CELL_PROPS = 'text-sm py-3 font-semibold px-5';
    table = (
      <div>
        <h2 className="text-xl text-center font-normal py-10">
          There are
          <span className="font-bold">
            {' '}
            {count}
            {' '}
            pending verifications.
          </span>
          {' '}
          Click on any row in the table to begin verifying that user.
        </h2>
        <div className="container flex flex-col mt-8 w-full mx-auto border border-gray-200">
          <div className="flex flex-row border-b-2 border-gray-400 text-xl items-center text-left">
            <div className="text-lg font-bold px-5 my-5 w-3/12">Legal Name</div>
            <div className="text-lg font-bold px-5 my-5 w-1/12">Type</div>
            <div className="text-lg font-bold px-5 my-5 w-3/12">Method</div>
            <div className="text-lg font-bold px-5 my-5 w-2/12">Pending Since</div>
            <div className="text-lg font-bold px-5 my-5 w-2/12">Platform?</div>
            <div className="text-lg font-bold px-5 my-5 w-1/12">Archive?</div>
          </div>
          {
          pending.map(({
            _id: id, legalName, investorType, verificationMethod, paymentDate, platform,
          }) => (
            <Link to={`/verify/${id.toString()} `} className={ROW_PROPS}>
              <div className={`${CELL_PROPS} w-3/12`}>{legalName}</div>
              <div className={`${CELL_PROPS} w-1/12`}>{investorType}</div>
              <div className={`${CELL_PROPS} w-3/12`}>{verificationMethod}</div>
              <div className={`${CELL_PROPS} w-2/12`}>{dayjs(paymentDate || new Date()).format('dddd, MMM D')}</div>
              <div className={`${CELL_PROPS} w-2/12`}>{platform}</div>
              <div className={`${CELL_PROPS} w-1/12 flex flex-row justify-around items-center`}>
                <IoCloseSharp
                  className="ml-2 mt-1 text-red-500 hover:text-red-800"
                  size={20}
                  role="button"
                  onClick={(e) => handleArchiveUser(e, id)}
                  onKeyDown={() => {}}
                  tabIndex={0}
                />
              </div>
            </Link>
          ))
        }
        </div>

      </div>
    );
  }

  return (
    <div className="container-fluid flex flex-row w-screen justify-center">
      <h1 className="text-2xl font-bold mt-10 mb-2 w-full xl:w-10/12 text-center">
        Welcome
        {' '}
        { user.firstName }
        !
        { table }
      </h1>
    </div>
  );
};

export default ReviewQueue;
