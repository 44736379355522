import React, { useContext, useState } from 'react';

import { Navigate } from 'react-router-dom';
import { startNewAccreditation } from '../../api/accounts';
import { B_COLORS, LG_ERR_TXT } from '../../AppStyles';

import AppContext from '../../AppContext';
import denied from '../../assets/denied.svg';

const FailedStatusPage = function () {
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';
  const retstr = returnUrl ? `?returnUrl=${returnUrl}` : '';

  const [{ user, token }, dispatch] = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [restartError, setRestartError] = useState('');
  const [done, setDone] = useState(false);

  if (!user || user.userType === 'ADMIN') {
    return (
      <Navigate to={`/${retstr}`} />
    );
  }
  if (user.userStage !== 'FAILED') {
    return <Navigate to={`/${retstr}`} />;
  }
  if (done) {
    return <Navigate to={`/${retstr}`} />;
  }

  const {
    reason,
  } = user.failures[user.failures.length - 1];

  const handleStartNewButton = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error: newError, user: newUser } = await startNewAccreditation(token);
    if (newError) {
      setRestartError(newError);
    } else {
      dispatch({ type: 'SET_USER', user: newUser });
      setDone(true);
    }
    setLoading(false);
  };

  const startNewButton = (
    <div
      className={`w-3/4 cursor-pointer md-1/2 rounded-xl font-semibold text-center text-white py-3 my-8 ${B_COLORS}`}
      role="button"
      onClick={handleStartNewButton}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      { loading ? (
        <div className="flex flex-row items-center">
          <svg className="animate-spin h-5 w-5 ml-2 text-white position-absolute" viewBox="0 0 24 24">
            <circle className="opacity-0" fill="currentColor" cx="12" cy="12" r="10" strokeWidth="4" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <div className="-ml-7 w-full">Start New Accreditation</div>
        </div>
      ) : (
        <div className="w-full">Start New Accreditation</div>
      )}
    </div>
  );
  const content = (
    <div className="w-full md:w-6/12 py-20">
      <h4 className="text-3xl text-gray-800 font-bold mb-10">We could not verify your accreditation</h4>
      <p className="text-lg text-gray-800 mb-5">
        Our legal team reviewed your application and determined that you do not
        meet the SEC&apos;s definition of an accredited investor.
      </p>
      <p className="text-lg text-gray-800 mb-5">
        Your reviewer stated the following reason for rejecting your accreditation verification:
      </p>
      <p className="text-md text-gray-800 font-semibold px-10 mb-10">
        { reason }
      </p>
      <p className="text-lg text-gray-800 mb-10">
        If you think this was made in error, you can either reach out to our support team at
        {' '}
        <a className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" href="mailto:support@checkinvestorstatus.com">support@checkinvestorstatus.com</a>
        {' '}
        or you can click below to apply again using a different verification method.
      </p>
      { startNewButton }
      { restartError && <div className={`${LG_ERR_TXT} mb-3 mt-2 ml-10`}>{restartError}</div>}
    </div>
  );

  return (
    <div className="w-9/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 border-blue-500">

      <h2 className="text-4xl font-bold text-center text-gray-800">
        Your accreditation application was denied
      </h2>

      <div className="flex justify-between flex-wrap mb-20">
        <div className="w-full md:w-1/2 p-5">
          <img src={denied} alt="Review" />
        </div>
        {content}
      </div>
    </div>
  );
};

export default FailedStatusPage;
