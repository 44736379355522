/*
 * Functions for working with the overall state machine and
 * Bread crumb navigation for the front end.
 */

import { CRUMBS, crumbIsDone } from './evidence-helpers';

// Crumb to its readable title
// TODO(Carter): Move this to evidence-helpers
const crumbToReadable = (crumb) => {
  switch (crumb) {
    case CRUMBS.NAME:
      return 'Name';
    case CRUMBS.ENTITY_TYPE:
      return 'Investing As';
    case CRUMBS.INFO:
      return 'Accreditation Info';
    case CRUMBS.DOCUMENTATION:
      return 'Documentation';

    // Upload Crumbs
    case CRUMBS['DOCUMENTATION.INCOME']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.CREDIT_REPORT']:
      return 'Credit Report';
    case CRUMBS['DOCUMENTATION.ASSETS']:
      return 'Assets';
    case CRUMBS['DOCUMENTATION.WILDCARD']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.JOINT_INCOME']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.JOINT_CREDIT_REPORT']:
      return 'Credit Reports';
    case CRUMBS['DOCUMENTATION.JOINT_ASSETS']:
      return 'Assets';
    case CRUMBS['DOCUMENTATION.EBP_FINANCES']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.FIDUCIARY']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.EBP_OFFICERS']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.ENTITY_FINANCES']:
      return 'Assets';
    case CRUMBS['DOCUMENTATION.PROOF_OF_OWNERS']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.STATUS_EVIDENCE']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.SPECIAL_EVIDENCE']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.TRUST_FINANCES']:
      return 'Financial Statement';
    case CRUMBS['DOCUMENTATION.BUSINESS_DIRECTOR']:
      return 'Documentation';
    case CRUMBS['DOCUMENTATION.TRUST_DOCS']:
      return 'Documentation';

    // Confirmations
    case CRUMBS['CONFIRMATION.INCOME']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.PROFESSIONAL_CERT']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.NO_OTHER_LIABILITIES']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.JOINT_INCOME']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.JOINT_NO_OTHER_LIABILITIES']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.EBP']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.ENTITY']:
      return 'Confirmation';
    case CRUMBS['CONFIRMATION.TRUST']:
      return 'Confirmation';

    // Special Pages
    case CRUMBS.PERSONAL_RESIDENCE:
      return 'Primary Residence';
    case CRUMBS.WILDCARD:
      return 'Explanation';
    case CRUMBS.PROFESSIONAL_CERT:
      return 'Qualifications';

    // Selections
    case CRUMBS['SELECTION.STATUS']:
      return 'Selection';
    case CRUMBS['SELECTION.SPECIAL']:
      return 'Selection';

    // Equity Owners
    case CRUMBS['EQUITY_OWNERS.EBP']:
      return 'Owners';
    case CRUMBS['EQUITY_OWNERS.ENTITY']:
      return 'Owners';
    case CRUMBS['EQUITY_OWNERS.TRUST']:
      return 'Owners';

    case CRUMBS.NOTES:
      return 'Notes';
    case CRUMBS.REVIEW:
      return 'Review and Submit';
    default:
      return 'Unknown';
  }
};

// Verification Method -> List of Crumbs
const methodToPages = {
  // LETTER: 'LETTER',
  INCOME: [
    'DOCUMENTATION.INCOME', // => INCOME_UPLOAD
    'CONFIRMATION.INCOME', // => INCOME_CHECK
  ],
  FINANCE_PROFESSIONAL: [
    'PROFESSIONAL_CERT', // => FINANCE_PROFESSIONAL_CRD + FINANCE_PROFESSIONAL_FIRM_NAME
    'CONFIRMATION.PROFESSIONAL_CERT', // FINANCE_PROFESSIONAL_CONFIRMATION
  ],
  NET_WORTH: [
    // also: JOINT_NET_WORTH_ESTIMATED_RESIDENCE_VAL
    // + JOINT_NET_WORTH_ESTIMATED_RESIDENCE_DEBT (TODO - maybe more)
    'PERSONAL_RESIDENCE', // => NET_WORTH_ESTIMATED_RESIDENCE_VAL + NET_WORTH_ESTIMATED_RESIDENCE_DEBT (TODO - maybe more)
    'DOCUMENTATION.CREDIT_REPORT', // => NET_WORTH_CREDIT_REPORT
    'DOCUMENTATION.ASSETS', // => NET_WORTH_ASSETS
    'CONFIRMATION.NO_OTHER_LIABILITIES', // => CONFIRMATION_NO_OTHER_LIABILITIES
  ],
  NONE_OF_THE_ABOVE: [
    'WILDCARD', // => NONE_OF_THE_ABOVE_HOW (TODO - refactor out extras)
    'DOCUMENTATION.WILDCARD', // => NONE_OF_THE_ABOVE_UPLOAD
  ],

  // JOINT Methods (Same as as individual, but different copy)
  JOINT_INCOME: [
    'DOCUMENTATION.JOINT_INCOME', // => JOINT_INCOME_UPLOAD
    'CONFIRMATION.JOINT_INCOME', // => JOINT_INCOME_CHECK
  ],
  JOINT_NET_WORTH: [
    'PERSONAL_RESIDENCE',
    'DOCUMENTATION.JOINT_CREDIT_REPORT', // => JOINT_NET_WORTH_CREDIT_REPORT
    'DOCUMENTATION.JOINT_ASSETS', // => JOINT_NET_WORTH_ASSETS
    'CONFIRMATION.JOINT_NO_OTHER_LIABILITIES', // => JOINT_CONFIRMATION_NO_OTHER_LIABILITIES
  ],
  JOINT_NONE_OF_THE_ABOVE: [
    'WILDCARD', // => JOINT_NONE_OF_THE_ABOVE_HOW (TODO - refactor out extras)
    'DOCUMENTATION.WILDCARD', // => JOINT_NONE_OF_THE_ABOVE_UPLOAD
  ],

  // EBP Methods (Retirement plan specific options)
  EBP_NET_WORTH: [
    'DOCUMENTATION.EBP_FINANCES', // => EBP_NET_WORTH_FINANCIAL_STATEMENTS
    'CONFIRMATION.EBP', // => EBP_ATTEST
  ],
  EBP_FIDUCIARY: [
    'DOCUMENTATION.FIDUCIARY', // => ENTITY_FIDUCIARY_EBP_FIDUCIARY_EVIDENCE
    'CONFIRMATION.EBP', // => EBP_ATTEST
  ],
  EBP_SELF_DIRECTED: [
    'DOCUMENTATION.EBP_OFFICERS', // => ENTITY_SELF_DIRECTED_EBP_OFFICERS_CERTIFICATE
    'EQUITY_OWNERS.EBP', // => ENTITY_SELF_DIRECTED_EBP_OFFICERS_CERTIFICATE
    'CONFIRMATION.EBP', // => EBP_ATTEST
  ],

  // ENTITY Methods (Same as as individual, but different copy)
  ENTITY_NET_WORTH: [
    'DOCUMENTATION.ENTITY_FINANCES', // ENTITY_NET_WORTH_FINANCIAL_STATEMENTS
    'CONFIRMATION.ENTITY', // ENTITY_ATTEST
  ],
  ENTITY_ACCREDITED_INDIVIDUALS: [
    'DOCUMENTATION.PROOF_OF_OWNERS', // ENTITY_ACCREDITED_INDIVIDUALS_LISTING_OF_EQUITY_OWNERS
    'EQUITY_OWNERS.ENTITY', // ENTITY_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS
    'CONFIRMATION.ENTITY', // ENTITY_ATTEST
  ],
  ENTITY_STATUS: [
    'SELECTION.STATUS', // ENTITY_STATUS_SELECTION
    'DOCUMENTATION.STATUS_EVIDENCE', // ENTITY_STATUS_EVIDENCE
    'CONFIRMATION.ENTITY', // ENTITY_ATTEST
  ],
  ENTITY_SPECIAL: [
    'SELECTION.SPECIAL', // ENTITY_SPECIAL_SELECTION
    'DOCUMENTATION.ENTITY_FINANCES', // ENTITY_SPECIAL_FINANCIAL_STATEMENTS
    'DOCUMENTATION.SPECIAL_EVIDENCE', // ENTITY_SPECIAL_EVIDENCE
    'CONFIRMATION.ENTITY', // ENTITY_ATTEST
  ],
  ENTITY_NONE_OF_THE_ABOVE: [
    'WILDCARD', // => ENTITY_NONE_OF_THE_ABOVE_HOW (TODO - refactor out extras)
    'DOCUMENTATION.WILDCARD', // => ENTITY_NONE_OF_THE_ABOVE_UPLOAD
  ],

  // TRUST methods
  TRUST_NET_WORTH: [
    'DOCUMENTATION.TRUST_FINANCES', // TRUST_NET_WORTH_FINANCIAL_STATEMENTS
    'DOCUMENTATION.BUSINESS_DIRECTOR', // TRUST_NET_WORTH_SOPHISTICATION_EVIDENCE
    'CONFIRMATION.TRUST', // TRUST_ATTEST
  ],
  TRUST_ACCREDITED_INDIVIDUALS: [
    'DOCUMENTATION.TRUST_DOCS', // TRUST_ACCREDITED_INDIVIDUALS_EQUITY_OWNERSHIP_EVIDENCE
    'EQUITY_OWNERS.TRUST', // TRUST_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS
    'CONFIRMATION.TRUST', // TRUST_ATTEST
  ],
  TRUST_NONE_OF_THE_ABOVE: [
    'WILDCARD', // => TRUST_NONE_OF_THE_ABOVE_HOW (TODO - refactor out extras)
    'DOCUMENTATION.WILDCARD', // => TRUST_NONE_OF_THE_ABOVE_UPLOAD
  ],
};

function methodToCrumbs(verificationMethod) {
  return methodToPages[verificationMethod];
}

// State Machine: Map a user to the relevant list of crumbs
function userToBreadCrumbs(user) {
  let curr = null;
  let crumbs = [];

  if (!user) {
    return {
      curr,
      crumbs,
    };
  }
  // Only let users change type if it wasn't supplied by platform
  if ((!user.isPlatformUser) || user.platformOmmittedType) {
    crumbs.push(CRUMBS.ENTITY_TYPE);
  }

  // Only let users change name if it wasn't supplied by platform
  if ((!user.isPlatformUser) || user.platformOmmittedName) {
    crumbs.push(CRUMBS.NAME);
  }

  // Always include Accreditation Method
  crumbs.push(CRUMBS.INFO);

  // Figure out where we are in the documentation world
  if (user.userStage === 'UPLOAD' || user.userStage === 'MORE_INFO') {
    // We know enough to figure out the specifics

    // First get the list of steps based on the verificationMethod
    const uploadCrumbs = methodToCrumbs(user.verificationMethod);
    crumbs = crumbs.concat(uploadCrumbs);

    // If we have finished each piece of documentation, what about notes?
  } else {
    crumbs.push(CRUMBS.DOCUMENTATION);
  }
  // TODO(Carter): detect if we're here.
  crumbs.push(CRUMBS.NOTES);

  // TODO(Carter): detect if we're here.
  crumbs.push(CRUMBS.REVIEW);

  // II. Find the current crumb
  crumbs.forEach((crumb) => {
    if (!curr && !crumbIsDone(user, crumb)) {
      curr = crumb;
    }
  });
  return {
    curr,
    crumbs,
  };
}

export {
  CRUMBS,
  crumbToReadable,
  methodToCrumbs,
  userToBreadCrumbs,
};
