import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import validator from 'validator';

import { BiPencil } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoCloseOutline } from 'react-icons/io5';
import { FiCheck } from 'react-icons/fi';

import AppContext from '../../AppContext';

/*
 * Dumb component for modal to add new owner
 */
const AddOwnerModal = function (props) {
  const {
    passedAddress,
    passedName,
    passedType,
    passedIsMe,
    passedIdx,
    onClose,
    onAdd,
  } = props;
  const [address, setAddress] = useState(passedAddress);
  const [name, setName] = useState(passedName);
  const [type, setType] = useState(passedType);
  const [isMe, setIsMe] = useState(passedIsMe);

  // Static content for the page
  const modalHeading = (
    <h1 className="font-bold text-2xl">
      Add New Owner
    </h1>
  );

  const handleAdd = (e) => {
    e.preventDefault();
    onAdd({
      address,
      name,
      type,
      isMe,
    }, passedIdx);
  };

  const modalDescription = (
    <p className="text-black text-md mb-4">
      Add the equity owner&apos;s information below. We will send a link via email to the
      address specified to complete the accreditation process.
    </p>
  );

  const editAddress = (
    <div className="flex flex-col mb-2">
      <label className="flex-grow" htmlFor="address-in">
        <p className="text-md font-semibold">Email address*</p>
        <input
          className="border border-[#DCE1E7] w-full px-2 py-1 mt-2 rounded-[.2REM]"
          type="text"
          id="address-in"
          placeholder="email@example.com"
          onChange={(e) => { setAddress(e.target.value); }}
          value={address}
        />
      </label>
    </div>
  );

  const editName = (
    <div className="flex flex-col mb-2">
      <label className="flex-grow" htmlFor="name-in">
        <div className="flex flex-row justify-between">
          <p className="text-md font-semibold">Legal name</p>
          <p className="text-md text-[#A1A5AD] font-semibold">Optional</p>
        </div>
        <input
          className="border border-[#DCE1E7] w-full px-2 py-1 mt-2 rounded-[.2REM]"
          type="text"
          id="name-in"
          placeholder="John Doe"
          onChange={(e) => { setName(e.target.value); }}
          value={name}
        />
      </label>
    </div>
  );

  const editType = (
    <div className="flex flex-col">
      <label className="flex-grow mb-2" htmlFor="value-name-in">
        <div className="flex flex-row justify-between">
          <p className="text-md font-semibold">Investor type</p>
          <p className="text-md text-[#A1A5AD] font-semibold">Optional</p>
        </div>
        <div className="my-2">
          <select
            className={`border w-full rounded-[.2REM] px-2 py-1 ${
              type ? 'text-black' : 'text-gray-400'
            }`}
            id="selection-dropdown"
            name="selection-dropdown"
            onChange={(e) => { setType(e.target.value); }}
          >
            <option value="none" disabled hidden selected={!type}>Select</option>
            <option value="INDIVIDUAL" selected={type === 'INDIVIDUAL'}>Individual</option>
            <option value="JOINT" selected={type === 'JOINT'}>Spousal Couple</option>
            <option value="EBP" selected={type === 'EBP'}>Retirement Plan</option>
            <option value="ENTITY" selected={type === 'ENTITY'}>Business Entity</option>
            <option value="TRUST" selected={type === 'TRUST'}>Trust</option>
          </select>
        </div>
      </label>
    </div>
  );

  const isMeCheckbox = (
    <div className="w-full flex flex-row gap-3 mb-4">
      {
        isMe ? (
          /* Checked Box */
          <div
            className="w-5 h-5 mt-1 flex flex-col cursor-pointer flex-shrink-0 justify-center items-center bg-[#5963F4] border-2 border-[#5963F4] rounded-[.2REM]"
            onClick={() => setIsMe(false)}
            onKeyDown={() => {}}
            tabIndex={0}
            role="checkbox"
            aria-checked="true"
            aria-labelledby="none"
          >
            <FiCheck size={20} color="#FFFFFF" style={{ 'stroke-width': '3px' }} />
          </div>
        ) : (
          /* Un-Checked Box */
          <div
            className="w-5 h-5 mt-1 flex-shrink-0 cursor-pointer border-2 border-[#A1A5AD] rounded-[.2REM] hover:bg-[#DCE1E7]"
            onClick={() => setIsMe(true)}
            onKeyDown={() => {}}
            tabIndex={0}
            role="checkbox"
            aria-checked="false"
            aria-labelledby="none"
          />

        )
      }
      <div className="flex-shrink text-black flex flex-col">
        <h1 className="font-semibold text-md">
          This is me
        </h1>
        <p className="text-md">
          If you are the equity owner above, we will gather your accreditation information
          in this session.
        </p>
      </div>

    </div>
  );

  const canAdd = (
    address && validator.isEmail(address)
  );

  const modalButtons = (
    <div className="w-full flex flex-row gap-4 mb-2">
      {/* Add Owner Button */}
      <div
        className={`bg-[#5963F4] rounded-[.2REM] py-2 px-3 cursor-pointer text-white text-sm font-semibold w-3/12 text-center ${
          canAdd ? 'hover:brightness-75' : 'opacity-50'
        }`}
        onClick={canAdd ? handleAdd : () => {}}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <p>Add owner</p>
      </div>
      {/* Cancel Button */}
      <div
        className="border-[#5963F4] border cursor-pointer bg-white rounded-[.2REM] py-2 px-3 text-[#5963F4] text-sm font-semibold hover:brightness-75 w-3/12 xl:w-2/12 text-center"
        onClick={onClose}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <p>Cancel</p>
      </div>

    </div>
  );

  return (
    <div className="flex items-center justify-center fixed z-50 bg-black bg-opacity-70 w-screen h-screen inset-0">

      <div className="flex flex-col cursor-pointer py-6 px-8 gap-2 w-[40%] mx-[20%] -mt-12 rounded-[.3REM] shadow-[0_1px_3px_rgb(0,0,0,.15)] fixed z-50 bg-white border">
        <IoCloseOutline
          className="-ml-2 mb-2"
          size={35}
          color="#000000"
          onClick={onClose}
        />
        { modalHeading }
        { modalDescription }
        { editAddress }
        { editName }
        { editType }
        { isMeCheckbox }
        { modalButtons }
      </div>
    </div>
  );
};
AddOwnerModal.propTypes = {
  passedAddress: propTypes.string,
  passedName: propTypes.string,
  passedType: propTypes.string,
  passedIsMe: propTypes.bool,
  passedIdx: propTypes.number.isRequired,
  onClose: propTypes.func.isRequired,
  onAdd: propTypes.func.isRequired,
};
AddOwnerModal.defaultProps = {
  passedAddress: '',
  passedName: '',
  passedType: '',
  passedIsMe: false,
};

/*
 * Actual Equity Owners Page
 */
const EquityOwnersPage = function (props) {
  const {
    onChange,
    passedVal,
    variant,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, [variant]);

  const [newOwner, setNewOwner] = useState();
  const [modalIdx, setModalIdx] = useState(-1);

  const [{ user, token }, dispatch] = useContext(AppContext);

  // Static content for the page
  const pageHeading = (
    <h1 className="font-bold text-2xl mb-4">
      Equity Owners
    </h1>
  );

  const pageDescription = (
    <p className="text-black text-md mb-2">
      Add each of the equity owners of this entity listed in the documentation
      you provided in the previous step.
    </p>
  );

  // Dynamic content for the page
  const removeInvestor = (toRemoveIdx) => {
    const newVal = val.filter((_, idx) => idx !== toRemoveIdx);
    setVal(newVal);
    onChange(newVal);
  };
  const investorTypeToCopy = (investorType) => {
    switch (investorType) {
      case 'INDIVIDUAL':
        return 'Individual';
      case 'JOINT':
        return 'Spousal Couple';
      case 'EBP':
        return 'Retirement Plan';
      case 'ENTITY':
        return 'Business Entity';
      case 'TRUST':
        return 'Trust';
      default:
        return '';
    }
  };
  const ownerListing = (
    <div className="flex flex-col mb-6">
      {
        (val || []).map(({
          email, legalName, investorType, isSigningInvestor,
        }, idx) => (
          <div className="flex flex-row justify-between items-center py-4 border-b border-[#DCE1E7] ">
            <div className="flex flex-col gap-0.5">
              <div className="flex flex-row gap-2">
                <h3 className="text-md font-bold">{ email }</h3>
                { isSigningInvestor && <div className="text-xs font-bold border rounded-xl my-auto px-1  bg-[#E7E8EF]">This is me</div>}
              </div>
              <div className="flex flex-row gap-1 justify-start text-[#6E7277] text-sm">
                { legalName && <p className="font-semibold">{ legalName }</p> }
                { legalName && investorType && <div className="my-auto w-1 h-1 rounded-full bg-[#6E7277]" /> }
                { investorType && <p className="font-semibold">{ investorTypeToCopy(investorType) }</p> }
              </div>
            </div>
            <div className="flex flex-row cursor-pointer justify-end gap-2">
              <BiPencil
                className="my-auto hover:brightness-75"
                size={23}
                color="#A1A5AD"
                onClick={() => { setModalIdx(idx); }}
              />
              <AiOutlineDelete
                className="my-auto cursor-pointer hover:brightness-75"
                size={23}
                color="#A1A5AD"
                onClick={() => { removeInvestor(idx); }}
              />
            </div>
          </div>
        ))
      }
    </div>
  );
  const addOwnerButton = (
    <div
      className="bg-[#5963F4] rounded-[.2REM] cursor-pointer py-2 px-3 text-white text-sm font-semibold hover:brightness-75 w-3/12 xl:w-2/12 text-center"
      onClick={(e) => {
        setModalIdx((val || []).length);
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <p>Add owner</p>
    </div>
  );

  // Add a new investor from the modal!
  const addNewInvestor = (newInvestor, idx) => {
    const {
      address,
      name,
      type,
      isMe,
    } = newInvestor;

    let newVal = [...val];

    // There can only be a single "isMe" equity owner
    if (isMe) {
      newVal = newVal.map((investor) => {
        const update = { ...investor };
        update.isSigningInvestor = false;
        return update;
      });
    }
    const investorUpdate = {
      email: address,
      legalName: name || '',
      investorType: type || '',
      isSigningInvestor: !!(isMe),
    };
    if (idx < newVal.length) {
      // Replace existing
      newVal[idx] = investorUpdate;
    } else {
      newVal.push(investorUpdate);
    }
    setVal(newVal);
    onChange(newVal);
    setModalIdx(-1);
  };

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="mb-4">
        Please provide the email addresses for each of the
        equity owners of this trust listed by the documentation
        above.
      </p>
      <p className="mb-4">
        We will need to verify the accreditation of each of these
        equity owners individually. We will send a link to
        each of these emails to get started with their verification
        process.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start container-fluid">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { ownerListing }
        { addOwnerButton }
      </div>
      { infoCol }
      { modalIdx >= 0 && (
        (modalIdx < (val || []).length) ? (
          <AddOwnerModal
            passedAddress={val[modalIdx]?.email}
            passedName={val[modalIdx]?.legalName}
            passedType={val[modalIdx]?.investorType}
            passedIsMe={val[modalIdx]?.isSigningInvestor}
            passedIdx={modalIdx}
            onClose={() => { setModalIdx(-1); }}
            onAdd={addNewInvestor}
          />
        ) : (
          <AddOwnerModal
            passedIdx={(val || []).length}
            onClose={() => { setModalIdx(-1); }}
            onAdd={addNewInvestor}
          />
        )
      )}
    </div>
  );
};
EquityOwnersPage.propTypes = {
  onChange: propTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  passedVal: propTypes.any,
  variant: propTypes.string.isRequired,
};
EquityOwnersPage.defaultProps = {
  passedVal: [],
};

export default EquityOwnersPage;
