import React from 'react';

const carter = require('../../assets/carter.JPG');
const grant = require('../../assets/grant.jpg');

const Founders = function () {
  return (
    <div className="bg-gray-100">
      <div className="container-fluid px-6 py-20">
        <h2 className="text-4xl font-bold text-center mb-8 text-grey-800">
          About
        </h2>
        <h3 className="text-2xl mb-16 text-gray-600 text-center">
          Our team is here to make accreditation easy for investors and bulletproof for issuers.
        </h3>

        <div className="flex flex-wrap justify-evenly">

          {/* Founder Card */}
          <div className="w-full md:w-1/3 mb-4 bg-white shadow ">
            <div className="flex flex-col items-center justify-center py-10">
              <img className="w-64 h-64 rounded-full object-cover mb-5" src={grant} alt="Carter" />
              <h4 className="text-xl font-bold">Grant Wolf</h4>
              <h5 className="text-md font-semibold mb-5 text-gray-500">Founder &amp; Chief Legal Officer</h5>
              <p className="text-gray-800 text-base px-6 mb-3">
                Grant Wolf is a licensed attorney in Washington State who
                graduated from Gonzaga University School of Law School.
                During law school, Grant was published by the Washington State Bar
                Association Section on Antitrust and Consumer Protection. Grant&apos;s
                areas of practice include: securities regulation, corporate law,
                and complex litigation. Grant has been involved in securities
                compliance for multiple corporations, and has practiced in both
                state and federal courts.
              </p>
              <p className="text-gray-800 text-base px-6">
                Before going to law school, Grant graduated from
                Wabash College, where he double
                majored in Spanish and Rhetoric.
                He is passionate about business,
                technology, and Manchester United Football Club.
              </p>

            </div>
          </div>
          <div className="w-full md:w-1/3 mb-4 bg-white shadow ">
            <div className="flex flex-col items-center justify-center py-10">
              <img className="w-64 h-64 rounded-full object-cover mb-5" src={carter} alt="Carter" />
              <h4 className="text-xl font-bold ">Carter Bastian</h4>
              <h5 className="text-md font-semibold mb-5 text-gray-500">Founder &amp; Chief Technology Officer</h5>
              <p className="text-gray-800 text-base px-6 mb-3">
                Carter is an FinTech entrepreneur and a specialist in automation, blockchain,
                and securities. After graduation from Dartmouth with a Computer Science Degree,
                Carter moved to San Francisco to work as a software engineer. He helped develop
                Khan Academy&apos;s LSAT-Prep product from the ground up, and then went on to
                become the Director of Engineering at a venture-backed, Y Combinator startup.
              </p>
              <p className="text-gray-800 text-base px-6">
                Carter&apos;s entrepreneurial focus is on using automation to improve the
                securities space. He founded Check Investor Status because, as an issuer,
                he was frustrated with the cost, complexity, and incompleteness of existing
                solutions.

              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Founders;
