import React from 'react';
import { Link } from 'react-router-dom';

const legalLinks = [
  {
    text: 'Terms of Service',
    link: '/tos',
  },
  {
    text: 'Privacy Policy',
    link: '/privacy',
  },
  {
    text: 'Accreditation Information',
    link: '/faq#about-accreditation',
  },
];

const learnLinks = [
  {
    text: 'For Investors',
    link: '/faq#for-investors',
  },
  {
    text: 'For Issuers (Sponsors)',
    link: '/faq#for-issuers',
  },
  {
    text: 'About Us',
    link: '/about',
  },
];

const supportLinks = [
  {
    text: 'FAQ',
    link: '/faq',
  },
  {
    text: 'Contact',
    link: '/contact',
  },
];

const linkData = [
  {
    title: 'Legal',
    links: legalLinks,
  },
  {
    title: 'Learn More',
    links: learnLinks,
  },
  {
    title: 'Support',
    links: supportLinks,
  },
];

function buildSubList(title, links) {
  const res = (
    <div className="w-full md:w-1/5 text-center md:text-left">
      <h5 className="uppercase mb-6 font-bold">{title}</h5>
      <ul className="mb-4">
        {links.map((l) => (
          <li className="mt-2">
            <a href={l.link} className="hover:underline text-gray-600 hover:text-orange-500">{l.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
  return res;
}

const Footer = function () {
  return (
    <footer className="bg-gray-100">
      <div className="container-fluid mx-auto px-6 pt-10 pb-6">
        <div className="flex justify-around">
          {
            linkData.map(({ title, links }) => buildSubList(title, links))
          }
        </div>
      </div>
    </footer>
  );
};

export default Footer;
