import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';

import ReactGA from 'react-ga';
import AppContext from '../AppContext';
import { confirmEmail } from '../api/confirmEmail';

const ConfirmationCode = function () {
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Handle Submission of the form
  useEffect(async () => {
    const { error: newError, user: newUser, token: newToken } = await confirmEmail(code);

    // Save the token in localstorage and in the app state
    if (!newError) {
      ReactGA.event({
        category: 'User',
        action: 'Verified Email',
        label: newUser.userType,
      });

      dispatch({ type: 'SET_TOKEN', token: newToken });
      dispatch({ type: 'SET_USER', user: newUser });
      setLoading(false);
    } else {
      setError(newError);
      setLoading(false);
      // TODO(Carter): Error display
    }
  }, []);

  // Redirect if we're past in the confirmation stage
  if (user && user.userStage !== 'CONFIRMATION') {
    return <Navigate to="/" />;
  }

  const loadScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Confirming your email ...
      </h2>
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-16">
        We&apos;ll redirect you to the dashboard once we&apos;re done.
      </h2>
    </div>
  );

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Something went wrong ...
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        That verification link is invalid or expired.
      </h2>
      <p className="text-xl font-bold">
        Try
        {' '}
        <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/confirm">
          resending your verification code
        </Link>
        , or
        {' '}
        <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/contact">
          contact support
        </Link>
        {' '}
        if the issue persists.
      </p>
    </div>
  );

  return (
    <div className="container container-fluid">
      { loading && loadScreen }
      { error && errorScreen }
    </div>
  );
};

export default ConfirmationCode;
