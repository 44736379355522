import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Explanation of your circumstance
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-8">
    Please explain how you qualify as an accredited investor and the extenuating circumstances
    preventing you from documenting your accredited qualifications.
  </p>
);

const NAExplanationPage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const [{ user, token }, dispatch] = useContext(AppContext);

  const updateVal = (e) => {
    e.preventDefault();
    setVal(e.target.value);
    onChange(e.target.value);
  };

  // long input for name
  const textArea = (
    <div className="flex flex-col">
      <label className="flex-grow" htmlFor="legal-name-in">
        <p className="text-md font-semibold">Optional notes for the reviewer</p>
        <textarea
          className="border border-[#DCE1E7] w-11/12 px-2 py-1 mt-2 rounded-[.3REM] h-32"
          type="text"
          maxLength="500"
          id="text-in"
          onChange={updateVal}
          value={val}
        />
      </label>
      <p className="text-[#6A6E74] text-sm">
        { (val || '').length }
        {' '}
        / 500 character maximum
      </p>
    </div>
  );

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="text-black mb-4">
        If you qualify by the legal definition of an accredited investor but cannot document it
        by a method listed above, we may still be able to verify your accreditation status.
      </p>
      <p className="text-black mb-4">
        You will need to upload evidence of your accreditation status
        and an explanation for why you believe an attorney can reasonably verify your
        accreditation status.
      </p>
      <p className="text-black mb-4">
        Please note that this a special case and it is exceptionally rare that this selection
        applies. If you select this, you should already be absolutely certain that this
        is the only scenario that applies to you, and that you can prove your status
        as an accredited investor through non-traditional means.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { textArea }
      </div>
      { infoCol }
    </div>
  );
};
NAExplanationPage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
NAExplanationPage.defaultProps = {
  passedVal: '',
};

export default NAExplanationPage;
