import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import { FiCheck } from 'react-icons/fi';

import AppContext from '../../AppContext';
import legalDetails from '../_Upload/legalDetailsContent';
import { CRUMBS, crumbToEvidenceId, getCurrentVal } from './evidence-helpers';
import { crumbToReadable } from './state-machine';
import { requiredDocumentation } from '../_Upload/uploadContent';

const investorTypeToCopy = (investorType) => {
  switch (investorType) {
    case 'INDIVIDUAL':
      return 'Individual';
    case 'JOINT':
      return 'Spousal Couple';
    case 'EBP':
      return 'Retirement Plan';
    case 'ENTITY':
      return 'Business Entity';
    case 'TRUST':
      return 'Trust';
    default:
      return '';
  }
};

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-6">
    Review and Submit
  </h1>
);

function getPreviewForCrumb(user, crumb) {
  if (!crumb) {
    return <div />;
  }
  const components = crumb.split('.');
  const crumbRoot = components[0];
  const variant = components.length ? components[1] : '';

  // Get the Current value (from the API) and the legal details (if relevant)
  const val = getCurrentVal(user, crumb);

  let details;
  const evidenceId = crumbToEvidenceId(user, crumb);
  if (evidenceId) {
    const [{ description }] = requiredDocumentation[user.verificationMethod].filter(
      ({ id }) => id === evidenceId,
    );
    details = description;
  }
  console.log('INFO IN THE PREVIEW');
  console.log(details);
  console.log(evidenceId);
  console.log(val);

  const readableTitle = crumbToReadable(crumb);

  switch (crumbRoot) {
    case 'DOCUMENTATION': { // Uploaded documentation
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-2">
            { readableTitle }
          </h1>
          <p className="mb-6">{ details }</p>
          <div className="flex flex-row items-start flex-wrap gap-6">
            {
              val.map(({ url, mimetype }) => (
                <div className="container w-[200px] border">
                  {
                    (mimetype === 'application/pdf') ? (
                      <object className="-z-40 w-full object-contain h-[260px]" data={`${url}#view=FitH`} type="application/pdf">
                        <iframe className="w-full -z-30 object-contain h-[260px]" title={url} src={`https://docs.google.com/viewer?url=${url}&embedded=true`} />
                      </object>
                    ) : (
                      <img className="-z-40 w-full object-contain" src={url} alt={url} />
                    )
                  }
                </div>
              ))
            }
          </div>
        </div>
      );
    }
    case 'CONFIRMATION': { // Check Box
      const checkboxCopy = legalDetails[evidenceId] || '';
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-4">
            { readableTitle }
          </h1>
          <div className="w-full flex flex-row gap-4">
            {
                /* Checked Box */
              <div
                className="w-5 h-5 mt-1 flex flex-col flex-shrink-0 justify-center items-center bg-[#5963F4] border-2 border-[#5963F4] rounded-[.2REM]"
              >
                <FiCheck size={20} color="#FFFFFF" style={{ 'stroke-width': '3px' }} />
              </div>
            }
            <div className="flex-shrink text-black">
              { checkboxCopy }
            </div>
          </div>
        </div>
      );
    }
    case 'SELECTION': { // Drop-down values
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-4">
            Special Case Selection
          </h1>
          <h2 className="font-semibold mb-2">You selected that your entity is a(n)</h2>
          <div className="border border-[#DCE1E7] w-11/12 px-3 py-2 rounded-[.3REM] mb-2">
            { val }
          </div>
        </div>
      );
    }
    case 'EQUITY_OWNERS': { // List of equity owners
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-2">
            Equity Owners
          </h1>
          <p className="mb-6">Contact info for everyone who owns equity in your entity or trust (including yourself).</p>
          <div className="flex flex-col mb-2 w-2/3">
            {
              (val || []).map(({
                email, legalName, investorType, isSigningInvestor,
              }, idx) => (
                <div className="flex flex-row justify-between items-center py-2 border-b border-[#DCE1E7] ">
                  <div className="flex flex-col gap-0.5">
                    <div className="flex flex-row gap-2">
                      <h3 className="text-md font-bold">{ email }</h3>
                      { isSigningInvestor && <div className="text-xs font-bold border rounded-xl my-auto px-1  bg-[#E7E8EF]">This is me</div>}
                    </div>
                    <div className="flex flex-row gap-1 justify-start text-[#6E7277] text-sm">
                      { legalName && <p className="font-semibold">{ legalName }</p> }
                      { legalName && investorType && <div className="my-auto w-1 h-1 rounded-full bg-[#6E7277]" /> }
                      { investorType && <p className="font-semibold">{ investorTypeToCopy(investorType) }</p> }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      );
    }
    case CRUMBS.WILDCARD: { // Required Long Form
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-4">
            Explanation
          </h1>
          <h2 className="font-semibold mb-2">Write in response</h2>
          <div className="border border-[#DCE1E7] w-11/12 px-3 py-2 rounded-[.3REM] mb-2">
            { val }
          </div>
          <p className="text-[#6A6E74] text-sm ">
            { (val || '').length }
            {' '}
            / 500 character maximum
          </p>
        </div>
      );
    }
    case CRUMBS.PERSONAL_RESIDENCE: { // Info about personal residence
      const [value, debt, recency] = val;
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-4">
            Primary Residence
          </h1>
          <h2 className="font-semibold mb-2">
            Residence value:
            {' '}
            { value }
          </h2>
          <h2 className="font-semibold mb-2">
            Debt secured against residence:
            {' '}
            { debt }
          </h2>
          <h2 className="font-semibold mb-2">
            Debt recency (if applicable):
            {' '}
            { recency }
          </h2>
        </div>
      );
    }
    case CRUMBS.PROFESSIONAL_CERT: { // Firm name and CRD number
      const [firm, crd] = val;
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-4">
            Professional Certification
          </h1>
          <h2 className="font-semibold mb-2">
            Your firm:
            {' '}
            { firm }
          </h2>
          <h2 className="font-semibold mb-2">
            Your CRD Number:
            {' '}
            { crd }
          </h2>
        </div>
      );
    }
    case CRUMBS.NOTES: { // Reviewer notes
      return (
        <div className="flex flex-col mb-6">
          <h1 className="text-xl font-bold mb-4">
            Notes for Reviewer (Optional)
          </h1>
          <h2 className="font-semibold mb-2">Write in response</h2>
          <div className="border border-[#DCE1E7] w-11/12 px-3 py-2 rounded-[.3REM] mb-2">
            { val }
          </div>
          <p className="text-[#6A6E74] text-sm ">
            { (val || '').length }
            {' '}
            / 500 character maximum
          </p>
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
}

const SubmitPage = function (props) {
  const {
    crumbs,
  } = props;

  const [{ user, token }, dispatch] = useContext(AppContext);

  const content = crumbs.map((crumb) => getPreviewForCrumb(user, crumb));

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="mb-4">
        Be sure to double-check your documentation before continuing. Mistakes in documentation
        can require delays, and you won&apos;t be able to update your documentation after
        submitting.
      </p>
      <p className="mb-4">
        Make sure that your documentation is complete, current, and in your legal name.
      </p>
      <p className="mb-4">
        Once you&apos;ve double-checked your documentation, click below to submit for review.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { content }
      </div>
      { infoCol }
    </div>
  );
};
SubmitPage.propTypes = {
  crumbs: propTypes.arrayOf(propTypes.string).isRequired,
};

export default SubmitPage;
