import { PREFIX } from './prefix';

async function startNewAccreditation(token) {
  return fetch(`${PREFIX}/api/start-new-accreditation`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => null);
}

const noop = async () => {};

export {
  startNewAccreditation,
  noop,
};
