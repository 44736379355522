// We use CSS here because it comes from Stripe directly
import './CheckoutForm.css';
import propTypes from 'prop-types';

import ReactGA from 'react-ga';

import React, { useEffect, useState, useContext } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import AppContext from '../../AppContext';
import {
  setPaymentProcessing,
  unsetPaymentProcessing,
} from '../../api/payment';

import { SELF_PREFIX } from '../../api/prefix';

const CheckoutForm = function ({ email, value }) {
  const [{ token, user }, dispatch] = useContext(AppContext);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Tell the API we're processing a payment for this user
    const { error: setProcessingErr, user: setUser } = await setPaymentProcessing(token);
    if (setProcessingErr) {
      return;
    }

    // Dispatch user update
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${SELF_PREFIX}/status`,
        receipt_email: (user.platformBaseEmail || email),
      },
    });

    // Track google event
    ReactGA.event({
      category: 'User',
      action: 'Completed Payment',
      label: setUser.userType,
    });

    // We have to do this after so that we don't reload the page before
    // hitting the stripe confirmPayment (elements needs to be mounted)
    dispatch({ type: 'SET_USER', user: setUser });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }

    // Tell the API we're no longer processing a payment for this user
    const { error: unsetProcessingErr, user: unsetUser } = await unsetPaymentProcessing(token);
    if (!unsetProcessingErr) {
      dispatch({ type: 'SET_USER', user: unsetUser });
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        type="submit"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner" /> : `Pay ${value}`}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

CheckoutForm.propTypes = {
  email: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};

export default CheckoutForm;
