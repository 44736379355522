import React from 'react';

import { L_COLORS } from '../../AppStyles';

const P_STYLE = 'text-md mb-4';
const OL_STYLE = 'text-md mb-4 ml-10';
const UL_STYLE = 'text-md mb-4 ml-10';
const LI_STYLE = 'text-md mb-4 list-decimal';
const ULI_STYLE = 'text-md mb-4 list-disc';
const B_STYLE = 'font-bold mt-8 mb-4';

const legalDetails = {
  // Individual Legal Notes and instructions
  LETTER_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload a a letter documenting that a qualified professional has verified
        your accreditation status in the last 90 days. The letter must be in your legal
        name.
      </p>

      <p className={`${P_STYLE} `}>
        Your letter must be signed by one of the following:
      </p>
      <ol className={OL_STYLE}>
        <li className={`${LI_STYLE} `}>
          a registered broker-dealer,
        </li>
        <li className={`${LI_STYLE} `}>
          a licensed attorney,
        </li>
        <li className={`${LI_STYLE} `}>
          a certified public-accountant, or
        </li>
        <li
          className={`${LI_STYLE} `}
        >
          a registered investment advisor.
        </li>
      </ol>
      <p className={`${P_STYLE} `}>
        If you do not have a qualifying letter but would like to get one, here is an
        {' '}
        <a
          className={L_COLORS}
          href="/qualified_professional_letter.pdf"
          target="_blank"
        >
          example letter
        </a>
        {' '}
        that you can send to a qualified professional to have them fill
        out.
      </p>
    </div>
  ),
  INCOME_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to verify your income, we will need proof of income by way of tax
        documents from the past two calendar years. You can provide either (1.) the
        individual forms (W-2, 1099, K-1) or (2.) your tax returns.
      </p>
      <p className={`${P_STYLE} `}>
        If uploading your tax returns, you must include at least the first two pages.
      </p>
      <p className={`${P_STYLE} `}>
        You may optionally redact sensitive information as long as you don&apos;t obstruct
        the income amounts or your legal name.
      </p>
      <p className={`${P_STYLE} `}>
        Please be sure that you upload the tax forms for the two most recent tax years
        (currently 2019 and 2020).
      </p>
    </div>
  ),
  INCOME_CHECK: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking this box, you are attesting that you have a reasonable belief that
        you will make more than $200k in this upcoming year.
      </p>
    </div>
  ),
  FINANCE_PROFESSIONAL_CRD: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to qualify under this exemption, you must provide us with your CRD
        number so that we can verify that you are currently a holder of a Series 7,
        Series 65, Series 82 and that you are currently in good standing.
      </p>
    </div>
  ),
  FINANCE_PROFESSIONAL_FIRM_NAME: (
    <div>
      <p className={`${P_STYLE} `}>
        If you qualify through a Series 7 or Series 82, please provide us with the
        name of your sponsoring firm.
      </p>
      <p className={`${P_STYLE} `}>
        If you qualify through a Series 65, we do not need to verify your firm. Simply
        write in &quot;Series 65&quot;.
      </p>
    </div>
  ),
  DIRECTOR_ISSUER_NAME: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to verify that you are a director of the issuing firm, please
        enter the name of the firm below.
      </p>
      <p className={`${P_STYLE} `}>
        Note that the issuer name provided here must match the name of the issuer in the
        documentation you upload.
      </p>
      {' '}

    </div>
  ),
  DIRECTOR_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to verify your status, please provide us with an Officer&apos;s
        Certificate, Employment Contract, Incumbency Certificate, or if applicable,
        Articles of Incorporation naming you as a director, executive, or general
        partner of the issuing firm.
      </p>
      <p className={`${P_STYLE} `}>
        This documentation must be signed by at least one officer of the company other
        than yourself and must be issued in the last 90 days.  It also much include
        the name of the issuer and the title of your position.
      </p>
      <p className={`${P_STYLE} `}>
        Here is a sample of an
        {' '}
        <a
          className={L_COLORS}
          href="/oc_director.pdf"
          target="_blank"
        >
          Officer&apos;s Certificate
        </a>
        {' '}
        that you can
        provide to the officers of your firm. Note that the documentation provided
        must be from the last 90 days.
      </p>
      <p className={`${P_STYLE} `}>
        Please note that your certificate of accreditation will be limited in scope to
        investments issued by the firm in the documentation. You will not be
        accredited for investments not issued by the firm for which you are an
        executive, director, or general partner.
      </p>
    </div>
  ),
  EMPLOYEE_FUND_NAME: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to verify that you are a knowledgeable employee of the deal or the
        issuer of the deal in question, we will need the name of the deal or fund that
        you are qualifying for.
      </p>
      <p className={`${P_STYLE} `}>
        Note that the name provided here must match the name of the fund in the
        documentation you upload.
      </p>
      {' '}

    </div>
  ),
  EMPLOYEE_ISSUER_NAME: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to verify that you are a knowledgeable employee of the deal or the
        issuer of the deal in question, we will need the name of the issuer
        (Often also called the &quot;sponsor&quot; or &quot;general partner&quot;)
        who is
        creating the deal or fund above.
      </p>
      <p className={`${P_STYLE} `}>
        Note that the issuer name provided here must match the name of the issuer in the
        documentation you upload.
      </p>
      {' '}

    </div>
  ),
  EMPLOYEE_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload an Officer&apos;s Certificate, Incumbency Certificate, OR Employment
        Contract dated in the last 90 days.
      </p>
      <p className={`${P_STYLE} `}>
        This documentation must be signed by an officer in the company other than
        yourself and must be dated in the last 90 days. It also much include the name
        of the issuer, the name of the deal, and the title of your position.
      </p>
      <p className={`${P_STYLE} `}>
        Here is a sample of an
        {' '}
        <a
          className={L_COLORS}
          href="/oc_knowledgeable_employee.pdf"
          target="_blank"
        >
          Officer&apos;s Certificate
        </a>
        {' '}
        that you can
        provide to the officers of your firm. Note that the documentation provided
        must be from the last 90 days.
      </p>
      <p className={`${P_STYLE} `}>
        Please note that your certificate of accreditation will be limited in scope to
        the investment and the firm listed in this documentation. You will not be
        accredited for any other investments.
      </p>
    </div>
  ),
  EMPLOYEE_ROLE_DESCRIPTION: (
    <div>
      <p className={`${P_STYLE} `}>
        Please describe your role at the issuing company.
      </p>
      <p className={`${P_STYLE} `}>
        According to the SEC, a &quot;knowledgeable employee&quot; is
        one that is actively involved in making decisions or participating
        in the investment activites of the private fund.
      </p>
      <p className={`${P_STYLE} `}>
        Please describe your role and responsibilities &mdash; especially
        demonstrating that your role includes active management, participation,
        or implementation of the investment activities at the company.
      </p>
    </div>
  ),
  EMPLOYEE_CONTACT: (
    <div>
      <p className={`${P_STYLE} `}>
        We may need to contact your employer to verify the fund details.
        Please provide us with an email address (or phone number) for
        a member of your regulatory or compliance teams.
      </p>
      <p className={`${P_STYLE} `}>
        If your employer is Celsius Network, you can leave this field blank.
      </p>
      <p className={`${P_STYLE} `}>
        Please note that your certificate of accreditation will be limited in scope to
        the investment and the firm listed in this documentation. You will not be
        accredited for any other investments.
      </p>
    </div>
  ),
  NET_WORTH_CREDIT_REPORT: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload a
        {' '}
        <a
          className={L_COLORS}
          href="/faq#credit-report"
          target="_blank"
        >
          credit report
        </a>
        {' '}
        from one of the major US Credit Bureaus from
        within the last 90 days.
      </p>
      <p className={`${P_STYLE} `}>
        We will use this document to verify the amounts of your liabilities. You may
        optionally redact sensitive information so long as you don&apos;t redact your legal
        name, or the the information about your lines of credit (their dates of
        establishment, total outstanding balances, and lenders).
      </p>
      {' '}

    </div>
  ),
  NET_WORTH_ESTIMATED_RESIDENCE_VAL: (
    <div>
      <p className={`${P_STYLE} `}>
        We cannot include the value of your primary residence towards your net worth,
        but we will use it to offset any debt secured against your primary residence
        (mortgages, home-equity lines of credit, etc.)
      </p>
      {' '}

    </div>
  ),
  NET_WORTH_ESTIMATED_RESIDENCE_DEBT: (
    <div>
      <p className={`${P_STYLE} `}>
        Is any debt against your primary residence (eg HELOC, mortgage)
        greater than its estimated value OR was it accrued in the last 60 days?
      </p>
      <p className={`${P_STYLE} `}>
        If either of these are the case, please explain (including the amount of
        debt secured against your primary residence, as well as when that debt
        originated). Otherwise, write &quot;no&quot;.
      </p>
    </div>
  ),
  NET_WORTH_ASSETS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload
        {' '}
        <a
          className={L_COLORS}
          href="/faq#document-net-worth"
          target="_blank"
        >
          evidence of your assets
        </a>
        {' '}
        totaling $1M more in value than your
        total liabilities listed in your credit report.
      </p>
      <p className={`${P_STYLE} `}>
        Note that this listing of assets does not have to be comprehensive so long as
        the total of the documented assets is greater than the total in your
        liabilities by more than $1M.
      </p>
      <p className={`${P_STYLE} `}>
        For example, if your credit report shows liabilities of $500k and you
        have a single brokerage account with a balance of $1.5M dollars, you could
        qualify by uploading documentation of that single brokerage account, without
        documenting any other assets you may have.
      </p>
      <p className={`${P_STYLE} `}>
        For all assets documented below, your legal reviewer may not accept the
        valuation provided if there are questions to its impartiality or authenticity.
      </p>
      <p className={`${P_STYLE} `}>
        Broadly speaking, there are four categories of assets we can verify. In order
        of ease of verification, these are:
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Bank accounts, brokerage accounts, or other security accounts
      </p>
      <p className={`${P_STYLE} `}>
        We can account for cash holdings or investments held in brokerages such as
        CDs, stock and bond holdings, etc.
      </p>
      <p className={`${P_STYLE} `}>
        To verify this, we will need a Financial Statement or Letter from the
        institution that demonstrates your ownership and the total account value. You
        may optionally redact sensitive information (social security number, specific
        holdings, etc.) so long as we can verify your ownership and total account
        value.
      </p>
      <p className={`${P_STYLE} `}>
        If you have any liabilities secured against these assets
        (EG loans, mortgages, or lines of credit secured against the asset)
        , you must provide
        documentation of that.
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Real Estate (other than your primary residence)
      </p>
      <p className={`${P_STYLE} `}>
        We will need 3 pieces of documentation for each piece of real estate:
      </p>
      <ol className={OL_STYLE}>

        <li className={`${LI_STYLE} `}>
          Proof of Ownership (Deed, assessors records)
          in your legal name
        </li>

        <li className={`${LI_STYLE} `}>
          Proof of Value (Recent appraisal, third
          party valuation, or Zillow Estimate if appraisal is unavailable).
        </li>

        <li className={`${LI_STYLE} `}>
          Disclosure of liabilities (as applicable) –
          documentation of any liabilities secured against the piece of property.
        </li>
      </ol>

      <p className={`${P_STYLE} ${B_STYLE} `}>
        Other Securities (e.g. Investments in private companies, unlisted
        securities)
      </p>
      <p className={`${P_STYLE} `}>
        We can consider these securities if you can provide
      </p>
      <ol className={OL_STYLE}>

        <li className={`${LI_STYLE} `}>
          Proof of ownership, and
        </li>

        <li className={`${LI_STYLE} `}>
          Proof of Fair Market Value (formal valuation or
          signed statement of value from the issuer of the security).
        </li>
      </ol>
      <p className={`${P_STYLE} `}>
        If you have any liabilities secured against these assets, you must provide
        documentation of that.
      </p>

      <p className={`${P_STYLE} ${B_STYLE} `}>
        Non-Real Estate Property (eg. Automobiles, Art Holdings, Cryptocurrency
        Holdings)
      </p>
      <p className={`${P_STYLE} `}>
        We will need 3 pieces of documentation for each piece of property:
      </p>
      <ol className={OL_STYLE}>

        <li className={`${LI_STYLE} `}>
          Proof of Ownership (Title, evidence of
          ownership) in your legal name.
        </li>

        <li className={`${LI_STYLE} `}>
          Proof of Value (Recent appraisal or third party
          valuation. Note that Kelley-Blue Book value is acceptable evidence for
          automobiles.
          {' '}
          <a
            className={L_COLORS}
            href="/faq#verify-crypto"
            target="_blank"
          >
            Cryptocurrency holdings
          </a>
          {' '}
          will be assessed by value at the time of
          legal review, not the time of submission).
        </li>

        <li
          className
          Name={`${LI_STYLE} `}
        >
          Disclosure of liabilities (as applicable) –
          documentation of any liabilities secured against the piece of property.
        </li>
      </ol>
    </div>
  ),

  JOINT_LETTER_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload a a letter documenting that a qualified professional has verified
        your accreditation status in the last 90 days. The letter must be in the legal
        names of both you and your spouse.
      </p>

      <p className={`${P_STYLE} `}>
        Your letter must be signed by one of the following:
      </p>
      <ol className={OL_STYLE}>
        <li className={`${LI_STYLE} `}>
          a registered broker-dealer,
        </li>
        <li className={`${LI_STYLE} `}>
          a licensed attorney,
        </li>
        <li className={`${LI_STYLE} `}>
          a certified public-accountant, or
        </li>
        <li
          className={`${LI_STYLE} `}
        >
          a registered investment advisor.
        </li>
      </ol>
      <p className={`${P_STYLE} `}>
        If you do not have a qualifying letter but would like to get one, here is an
        {' '}
        <a
          className={L_COLORS}
          href="/qualified_professional_letter.pdf"
          target="_blank"
        >
          example letter
        </a>
        {' '}
        that you can send to a qualified professional to have them fill
        out.
      </p>

      <p className={`${P_STYLE} `}>
        Please note that your accreditation will expire 90 days from the date of the
        signed letter.
      </p>
    </div>
  ),
  JOINT_INCOME_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        In order to verify your income, we will need proof of income by way of tax
        documents from the past two calendar years. You can provide either (1.) the
        individual forms (W-2, 1099, K-1) or (2.) your tax returns.
      </p>
      <p className={`${P_STYLE} `}>
        If uploading your tax returns, you must include at least the first two pages.
      </p>
      <p className={`${P_STYLE} `}>
        You may optionally redact sensitive information as long as you don&apos;t obstruct
        the income amounts or your legal name.
      </p>
      <p className={`${P_STYLE} `}>
        Since you are filing jointly with a spouse, you must provide the past two
        years of tax information for both you and your spouse. You can either provide
        one set of forms for your joint filing, or provide two sets of forms for each
        of your individual filings.
      </p>
      <p className={`${P_STYLE} `}>
        Please be sure that you upload the tax forms for the two most recent tax years
        (currently 2019 and 2020).
      </p>
      {' '}

    </div>
  ),
  JOINT_INCOME_CHECK: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking this box, you are attesting that you and your spouse have a
        reasonable belief that you will jointly make more than $300k in this upcoming
        year.
      </p>
      {' '}

    </div>
  ),
  JOINT_NET_WORTH_CREDIT_REPORT: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload a
        {' '}
        <a
          className={L_COLORS}
          href="/faq#credit-report"
          target="_blank"
        >
          credit report
        </a>
        {' '}
        from one of the major US Credit Bureaus from
        within the last 90 days.
      </p>
      <p className={`${P_STYLE} `}>
        We will use this document to verify the amounts of your liabilities. You may
        optionally redact sensitive information so long as you don&apos;t redact your legal
        name, or the the information about your lines of credit (their dates of
        establishment, total outstanding balances, and lenders).
      </p>
    </div>
  ),
  JOINT_NET_WORTH_ESTIMATED_RESIDENCE_VAL: (
    <div>
      <p className={`${P_STYLE} `}>
        We cannot include the value of your primary residence towards your net worth,
        but we will use it to offset any debt secured against your primary residence
        (mortgages, home-equity lines of credit, etc.)
      </p>
      {' '}

    </div>
  ),

  JOINT_NET_WORTH_ESTIMATED_RESIDENCE_DEBT: (
    <div>
      <p className={`${P_STYLE} `}>
        Is any debt against your primary residence (eg HELOC, mortgage)
        greater than its estimated value OR was it accrued in the last 60 days?
      </p>
      <p className={`${P_STYLE} `}>
        If either of these are the case, please explain (including the amount of
        debt secured against your primary residence, as well as when that debt
        originated). Otherwise, write &quot;no&quot;.
      </p>
    </div>
  ),
  JOINT_NET_WORTH_ASSETS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload
        {' '}
        <a
          className={L_COLORS}
          href="/faq#document-net-worth"
          target="_blank"
        >
          evidence of your assets
        </a>
        {' '}
        totaling $1M more in value than your
        total liabilities listed in your credit report.
      </p>
      <p className={`${P_STYLE} `}>
        Since you are filing jointly, the assets uploaded may be in your name, your
        spouse&apos;s name, or held jointly.
      </p>
      <p className={`${P_STYLE} `}>
        Note that this listing of assets does not have to be comprehensive so long as
        the total of the documented assets is greater than the total in your
        liabilities by more than $1M.
      </p>
      <p className={`${P_STYLE} `}>
        For example, if your credit report shows liabilities of $500k and you
        have a single brokerage account with a balance of $1.5M dollars, you could
        qualify by uploading documentation of that single brokerage account, without
        documenting any other assets you may have.
      </p>
      <p className={`${P_STYLE} `}>
        For all assets documented below, your legal reviewer may not accept the
        valuation provided if there are questions to its impartiality or authenticity.
      </p>
      <p className={`${P_STYLE} `}>
        Broadly speaking, there are four categories of assets we can verify. In order
        of ease of verification, these are:
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Bank accounts, brokerage accounts, or other security accounts
      </p>
      <p className={`${P_STYLE} `}>
        We can account for cash holdings or investments held in brokerages such as
        CDs, stock and bond holdings, etc.
      </p>
      <p className={`${P_STYLE} `}>
        To verify this, we will need a Financial Statement or Letter from the
        institution that demonstrates your ownership and the total account value. You
        may optionally redact sensitive information (social security number, specific
        holdings, etc.) so long as we can verify your ownership and total account
        value.
      </p>
      <p className={`${P_STYLE} `}>
        If you have any liabilities secured against these assets
        (EG loans, mortgages, or lines of credit secured against the asset)
        , you must provide
        documentation of that.
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Real Estate (other than your primary residence)
      </p>
      <p className={`${P_STYLE} `}>
        We will need 3 pieces of documentation for each piece of real estate:
      </p>
      <ol className={OL_STYLE}>

        <li className={`${LI_STYLE} `}>
          Proof of Ownership (Deed, assessors records)
          in your legal name
        </li>

        <li className={`${LI_STYLE} `}>
          Proof of Value (Recent appraisal, third
          party valuation, or Zillow Estimate if appraisal is unavailable).
        </li>

        <li className={`${LI_STYLE} `}>
          Disclosure of liabilities (as applicable) –
          documentation of any liabilities secured against the piece of property.
        </li>
      </ol>

      <p className={`${P_STYLE} ${B_STYLE} `}>
        Other Securities (e.g. Investments in private companies, unlisted
        securities)
      </p>
      <p className={`${P_STYLE} `}>
        We can consider these securities if you can provide
      </p>
      <ol className={OL_STYLE}>

        <li className={`${LI_STYLE} `}>
          Proof of ownership, and
        </li>

        <li className={`${LI_STYLE} `}>
          Proof of Fair Market Value (formal valuation or
          signed statement of value from the issuer of the security).
        </li>
      </ol>
      <p className={`${P_STYLE} `}>
        If you have any liabilities secured against these assets, you must provide
        documentation of that.
      </p>

      <p className={`${P_STYLE} ${B_STYLE} `}>
        Non-Real Estate Property (eg. Automobiles, Art Holdings, Cryptocurrency
        Holdings)
      </p>
      <p className={`${P_STYLE} `}>
        We will need 3 pieces of documentation for each piece of property:
      </p>
      <ol className={OL_STYLE}>

        <li className={`${LI_STYLE} `}>
          Proof of Ownership (Title, evidence of
          ownership) in your legal name.
        </li>

        <li className={`${LI_STYLE} `}>
          Proof of Value (Recent appraisal or third party
          valuation. Note that Kelley-Blue Book value is acceptable evidence for
          automobiles. Cryptocurrency holdings will be assessed by value at the time of
          legal review, not the time of submission).
        </li>

        <li
          className
          Name={`${LI_STYLE} `}
        >
          Disclosure of liabilities (as applicable) –
          documentation of any liabilities secured against the piece of property.
        </li>
      </ol>
    </div>
  ),
  ENTITY_LETTER_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload a a letter documenting that a qualified professional has
        verified your entity&apos;s accreditation status in the last 90 days. The letter
        must be in the legal name of your entity.
      </p>
      <p className={`${P_STYLE} `}>
        Your letter must be signed by one of the following:
      </p>
      <ol className={OL_STYLE}>
        <li className={`${LI_STYLE} `}>
          a registered broker-dealer,
        </li>
        <li className={`${LI_STYLE} `}>
          a licensed attorney,
        </li>
        <li className={`${LI_STYLE} `}>
          a certified public-accountant, or
        </li>
        <li
          className={`${LI_STYLE} `}
        >
          a registered investment advisor.
        </li>
      </ol>
      <p className={`${P_STYLE} `}>
        If you do not have a qualifying letter but would like to get one, here is an
        {' '}
        <a
          className={L_COLORS}
          href="/qualified_professional_letter.pdf"
          target="_blank"
        >
          example letter
        </a>
        {' '}
        that you can send to a qualified professional to have them fill
        out.
      </p>

      <p className={`${P_STYLE} `}>
        Please note that your accreditation will expire 90 days from the date of the
        signed letter.
      </p>
    </div>
  ),
  ENTITY_NET_WORTH_FINANCIAL_STATEMENTS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please provide a financial statement issued by the entity or a 3rd-party
        appraisal from the last 90 days showing assets greater than $5M in value.
      </p>
      <p className={`${P_STYLE} `}>
        You may redact any confidential or sensitive information from the
        documentation so long as you don&apos;t obstruct the the value of your assets or
        the legal name of your entity.
      </p>
      {' '}

    </div>
  ),
  ENTITY_NET_WORTH_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the entity in question, that the information and documentation provided is
        true and current to the best of your understanding, and that the entity was
        not formed specifically to purchase this security.
      </p>
    </div>
  ),
  ENTITY_FIDUCIARY_EBP_FIDUCIARY_EVIDENCE: (
    <div>
      <p className={`${P_STYLE} `}>
        Qualified Employee benefit plans include any employee benefit plan within the
        meaning of the Employee Retirement Income Security Act of 1974 if the
        investment decision is made by a plan fiduciary, as defined in section 3(21)
        of such act, which is either a bank, savings and loan association, insurance
        company, or registered investment adviser.
      </p>
      <p className={`${P_STYLE} `}>
        To demonstrate that this exemption applies,
        upload documentation showing that investment decisions are
        made by a qualified fiduciary. Examples of pertinent documentation may include
        foundational documents, an Officer&apos;s Certificate, etc.
      </p>
    </div>
  ),
  ENTITY_SELF_DIRECTED_EBP_OFFICERS_CERTIFICATE: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload foundational documents or an officers&apos; certificate specifying
        which individuals are making investment decisions.
      </p>
    </div>

  ),
  ENTITY_ACCREDITED_INDIVIDUALS_LISTING_OF_EQUITY_OWNERS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload foundational documents (EG articles of incorporation)
        demonstrating a full listing of this entity&apos;s equity owners.
      </p>
    </div>
  ),

  ENTITY_STATUS_SELECTION: (
    <div>
      <p className={`${P_STYLE} `}>
        Some entities qualify as accredited by nature of their status alone, and thus
        do not need to provide financial statements. Instead, you will have to
        document the entity status claimed.
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Entities Accredited by their status alone include:
      </p>
      <ul className={UL_STYLE}>

        <li className={`${ULI_STYLE} `}>
          Any bank as defined in section 3(a)(2) of the Act, or any savings and loan
          association or other institution as defined in section 3(a)(5)(A) of the Act
          whether acting in its individual or fiduciary capacity.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any broker or dealer registered pursuant to section 15 of the Securities
          Exchange Act of 1934.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any investment adviser registered pursuant to section 203 of the Investment
          Advisers Act of 1940 or registered pursuant to the laws of a state.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any investment adviser relying on the exemption from registering with the
          Commission under section 203(l) or (m) of the Investment Advisers Act of 1940.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any insurance company as defined in section 2(a)(13) of the Act.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any investment company registered under the Investment Company Act of 1940 or
          a business development company as defined in section 2(a)(48) of that act.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any Small Business Investment Company licensed by the U.S. Small Business
          Administration under section 301(c) or (d) of the Small Business Investment
          Act of 1958.
        </li>
        <li className={`${ULI_STYLE} `}>
          Any Rural Business Investment Company as defined in section 384A of the
          Consolidated Farm and Rural Development Act.
        </li>

        <li className={`${ULI_STYLE} `}>
          Any private business development company as
          defined in section 202(a)(22) of the Investment Advisers Act of 1940.
        </li>
      </ul>
      <p className={`${P_STYLE} `}>
        Select the status of your entity from the below boxes that establishes your
        entity as an accredited investor. Note that if you do not see the relevant
        option below, please select &quot;Other&quot; and write in the exemption that applies in
        the &quot;Notes to your Reviewer&quot; section below.
      </p>
    </div>

  ),

  ENTITY_STATUS_EVIDENCE: (
    <div>
      <p className={`${P_STYLE} `}>
        Upload evidence that you currently hold the entity status claimed in your
        selection above. The specific evidence required varies depending on the status
        you&apos;ve chosen, but common examples may include:
      </p>
      <ul className={UL_STYLE}>

        <li className={`${ULI_STYLE} `}>
          Foundational documents.
        </li>

        <li className={`${ULI_STYLE} `}>
          Licenses or registration documentation with
          relevant regulatory or self-regulatory organizations.
        </li>
      </ul>

      <p className={`${P_STYLE} `}>
        Please explain the documentation provided in the &quot;Notes to your Reviewer&quot;
        section below.
      </p>
    </div>

  ),
  ENTITY_STATUS_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the entity in question, that the information and documentation provided is
        true and current to the best of your understanding, and that the entity was
        not formed specifically to purchase this security.
      </p>
    </div>
  ),
  ENTITY_SPECIAL_SELECTION: (
    <div>
      <p className={`${P_STYLE} `}>
        Some entities qualify by nature of their status in tandem with the total
        assets. These entities must prove their claimed status as well as provide
        financial documentation.
      </p>
      <p className={`${P_STYLE} `}>
        Entities that must qualify with this method include:
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Tax-exempt charitable organizations
      </p>

      <p className={`${P_STYLE} `}>
        Any organization described in section 501(c)(3) of the Internal Revenue Code,
        corporation, Massachusetts or similar business trust, partnership, or limited
        liability company, not formed for the specific purpose of acquiring the
        securities offered, with total assets in excess of $5,000,000
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Employee Benefit Plans with more than $5M in assets
      </p>

      <p className={`${P_STYLE} `}>
        Any Emplyee Benefit plan has total assets in excess of $5,000,000
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Employee benefit Plans established by political entity
      </p>

      <p className={`${P_STYLE} `}>
        Any plan established and maintained by a state, its political subdivisions, or
        any agency or instrumentality of a state or its political subdivisions, for
        the benefit of its employees, if such plan has total assets in excess of
        $5,000,000
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Foreign Entities
      </p>

      <p className={`${P_STYLE} `}>
        Any entity, including Indian tribes, governmental bodies, funds, and entities
        organized under the laws of foreign countries, of a type not listed in the
        above methods, not formed for the specific purpose of acquiring the securities
        offered, owning investments in excess of $5,000,000
      </p>
      <p className={`${P_STYLE} ${B_STYLE} `}>
        Family Offices &amp; Family Clients
      </p>

      <p className={`${P_STYLE} `}>
        &quot;Family Offices&quot; as defined in rule 202(a)(11)(G)-1 under the Investment
        Advisers Act of 1940 (17 CFR 275.202(a)(11)(G)-1) meeting the requirements
        below, and any &quot;family client&quot; of such family office whose prospective
        investment in the issuer is directed by such family office
        With assets under management in excess of $5,000,000,
        That is not formed for the specific purpose of acquiring the securities offered,
        and Whose prospective investment is directed by a person who has such knowledge
        and experience in financial and business matters that such family office is
        capable of evaluating the merits and risks of the prospective investment
      </p>
    </div>
  ),
  ENTITY_SPECIAL_EVIDENCE: (
    <div>
      <p className={`${P_STYLE} `}>
        Upload evidence that you currently hold the entity status being claimed in
        your selection above. The specific evidence required varies depending on the
        status you&apos;ve chosen, but common examples may include:
      </p>

      <ul className={UL_STYLE}>

        <li className={`${ULI_STYLE} `}>
          Foundational documents.
        </li>

        <li className={`${ULI_STYLE} `}>
          Licenses or registration documentation with
          relevant regulatory or self-regulatory organizations.
        </li>
      </ul>
      <p className={`${P_STYLE} `}>
        Please explain the documentation provided in the &quot;Notes to your Reviewer&quot;
        section below.
      </p>
    </div>
  ),
  ENTITY_SPECIAL_FINANCIAL_STATEMENTS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please provide a financial statement issued by the entity or a 3rd-party
        appraisal from the last 90 days showing assets greater than $5M in value.
      </p>
      <p className={`${P_STYLE} `}>
        You may redact any confidential or sensitive information from the
        documentation so long as you don&apos;t obstruct the the value of your assets or
        the legal name of your entity.
      </p>
    </div>
  ),
  ENTITY_SPECIAL_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the entity in question, that the information and documentation provided is
        true and current to the best of your understanding, and that the entity was
        not formed specifically to purchase this security.
      </p>
    </div>
  ),
  TRUST_LETTER_UPLOAD: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload a a letter documenting that a qualified professional has
        verified your trust&apos;s accreditation status in the last 90 days. The letter
        must be in the legal name of your trust.
      </p>
      <p className={`${P_STYLE} `}>
        Your letter must be signed by one of the following:
      </p>
      <ol className={OL_STYLE}>
        <li className={`${LI_STYLE} `}>
          a registered broker-dealer,
        </li>
        <li className={`${LI_STYLE} `}>
          a licensed attorney,
        </li>
        <li className={`${LI_STYLE} `}>
          a certified public-accountant, or
        </li>
        <li
          className={`${LI_STYLE} `}
        >
          a registered investment advisor.
        </li>
      </ol>
      <p className={`${P_STYLE} `}>
        If you do not have a qualifying letter but would like to get one, here is an
        {' '}
        <a
          className={L_COLORS}
          href="/qualified_professional_letter.pdf"
          target="_blank"
        >
          example letter
        </a>
        {' '}
        that you can send to a qualified professional to have them fill
        out.
      </p>

      <p className={`${P_STYLE} `}>
        Please note that your accreditation will expire 90 days from the date of the
        signed letter.
      </p>
    </div>
  ),
  TRUST_NET_WORTH_FINANCIAL_STATEMENTS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please provide a financial statement issued by the trust (EG. a trust
        accounting) or a 3rd-party appraisal from the last 90 days showing assets
        greater than $5M in value.
      </p>
      <p className={`${P_STYLE} `}>
        You may redact any confidential or sensitive information from the
        documentation so long as you don&apos;t obstruct the the value of your assets or
        the legal name of your trust.
      </p>
      {' '}

    </div>
  ),
  TRUST_NET_WORTH_SOPHISTICATION_EVIDENCE: (
    <div>
      <p className={`${P_STYLE} `}>
        As per rule 506(b), we must verify that the person making investment decisions
        for this trust is a &quot;sophisticated person&quot; – someone who has sufficient
        knowledge and experience in financial and business matters and that he or she
        is capable of evaluating the merits and risks of the prospective investment,
        or the issuer reasonably believes immediately prior to making any sale that
        such purchaser comes within this description.&quot;
      </p>
      <p className={`${P_STYLE} `}>
        For the person making investment decisions for this trust, please upload
        evidence of the knowledge and experience in business and financial matters.
        Examples may include but are not limited to formal qualifications and
        licensing, written statements, resumés or CVs, and track record of past
        experiences and positions held.
      </p>

    </div>
  ),
  TRUST_NET_WORTH_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the trust in question, that the information and documentation provided is true
        and current to the best of your understanding, and that the trust was not
        formed specifically to purchase this security.
      </p>
    </div>
  ),
  TRUST_ACCREDITED_INDIVIDUALS_EQUITY_OWNERSHIP_EVIDENCE: (
    <div>
      <p className={`${P_STYLE} `}>
        Please upload foundational documents (EG trust agreement) and a statement from
        a qualified professional demonstrating a full listing of this entity&apos;s equity
        owners. If the trust is revocable, the equity owners are generally the
        grantors.
      </p>
      <p className={`${P_STYLE} `}>
        If the trust is irrevocable, it can be difficult to determine who the equity
        owners are. If you need help with this process, please
        {' '}
        <a
          className={L_COLORS}
          href="/contact"
          target="_blank"
        >
          contact us
        </a>
        .
      </p>
      {' '}

    </div>
  ),
  NONE_OF_THE_ABOVE_UPLOAD: (
    <div>
      <p className={P_STYLE}>
        Please provide supporting documentation that will help your reviewer
        verify your status as an accredited investor.
      </p>
    </div>
  ),
  JOINT_NONE_OF_THE_ABOVE_UPLOAD: (
    <div>
      <p className={P_STYLE}>
        Please provide supporting documentation that will help your reviewer
        verify your status as an accredited investor.
      </p>
    </div>
  ),
  EBP_NONE_OF_THE_ABOVE_UPLOAD: (
    <div>
      <p className={P_STYLE}>
        Please provide supporting documentation that will help your reviewer
        verify your status as an accredited investor.
      </p>
    </div>
  ),
  ENTITY_NONE_OF_THE_ABOVE_UPLOAD: (
    <div>
      <p className={P_STYLE}>
        Please provide supporting documentation that will help your reviewer
        verify your status as an accredited investor.
      </p>
    </div>
  ),
  TRUST_NONE_OF_THE_ABOVE_UPLOAD: (
    <div>
      <p className={P_STYLE}>
        Please provide supporting documentation that will help your reviewer
        verify your status as an accredited investor.
      </p>
    </div>
  ),
  NOTES_TO_REVIEWER: (
    <div>
      <p className={P_STYLE}>
        Please provide your legal reviewer with any
        information you think will be helpful in reviewing your accreditation
        documentation.
      </p>
    </div>
  ),
  TRUST_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS: (
    <div>
      <p className={P_STYLE}>
        Please provide the email addresses for each of the
        equity owners of this trust listed by the documentation
        above.
      </p>
      <p className={P_STYLE}>
        We will need to verify the accreditation of each of these
        equity owners individually. We will send a link to
        each of these emails to get started with their verification
        process.
      </p>
    </div>
  ),
  ENTITY_SELF_DIRECTED_EBP_INDIVIDUAL_ACCREDITATIONS: (
    <div>
      <p className={P_STYLE}>
        Please provide the email addresses for each of the
        individuals making investment decisions for this
        self-directed benefit plan.
      </p>
      <p className={P_STYLE}>
        We will need to verify the accreditation of each of these
        people individually. We will send a link to
        each of these emails to get started with their verification
        process.
      </p>
    </div>
  ),
  ENTITY_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS: (
    <div>
      <p className={P_STYLE}>
        Please provide the email addresses for each of the
        equity owners of the entity listed in the documentation
        above.

      </p>
      <p className={P_STYLE}>
        We will need to verify the accreditation of each of these
        people individually. We will send a link to
        each of these emails to get started with their verification
        process.
      </p>
    </div>
  ),
  // New Types!
  FINANCE_PROFESSIONAL_CONFIRMATION: (
    <div>
      <p className={P_STYLE}>
        I affirm that I am an active financial professional in good standing, that
        I hold a series 7, series 65, or series 82 license that is current, and
        that the CRD and sponsoring firm provided are true and up-to-date.
      </p>
    </div>
  ),
  CONFIRMATION_NO_OTHER_LIABILITIES: (
    <div>
      <p className={P_STYLE}>
        I affirm that I have provided documentation of all of my liabilities,
        including but not limited to personal lines of credit and any
        lines of credit secured against the documented assets.
      </p>
    </div>
  ),
  JOINT_CONFIRMATION_NO_OTHER_LIABILITIES: (
    <div>
      <p className={P_STYLE}>
        I affirm that I have provided documentation of all of my liabilities,
        including but not limited to personal lines of credit and any
        lines of credit secured against the documented assets.
      </p>
    </div>
  ),
  EBP_NET_WORTH_FINANCIAL_STATEMENTS: (
    <div>
      <p className={`${P_STYLE} `}>
        Please provide a financial statement issued by the entity or a 3rd-party
        appraisal from the last 90 days showing assets greater than $5M in value.
      </p>
      <p className={`${P_STYLE} `}>
        You may redact any confidential or sensitive information from the
        documentation so long as you don&apos;t obstruct the the value of your assets or
        the legal name of your entity.
      </p>
      {' '}

    </div>
  ),
  EBP_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the retirement plan in question, that the information and documentation provided is
        true and current to the best of your understanding, and that the retirement plan was
        not formed specifically to purchase this security.
      </p>
    </div>
  ),
  ENTITY_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the entity in question, that the information and documentation provided is
        true and current to the best of your understanding, and that the entity was
        not formed specifically to purchase this security.
      </p>
    </div>
  ),
  TRUST_ATTEST: (
    <div>
      <p className={`${P_STYLE} `}>
        By checking the box below, you are attesting that you are a representative of
        the trust in question, that the information and documentation provided is
        true and current to the best of your understanding, and that the trust was
        not formed specifically to purchase this security.
      </p>
    </div>
  ),

};

export default legalDetails;
