import React, { useReducer, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import ReactGA from 'react-ga';

import AppContext from './AppContext';
import ScrollToTop from './scrollToTop';

// Landing Pages
import Header from './components/Header';
import Landing from './components/LandingPage';
import LandingStatus from './components/LandingStatus';
import LandingVerify from './components/LandingVerify';
import LandingLetter from './components/LandingLetter';
import LandingCertificate from './components/LandingCertificate';
import HowItWorks from './components/HowItWorks';

import Footer from './components/Footer';

import Account from './components/Account';
import Login from './components/Login';
import Signup from './components/Signup';
import EmailLink from './components/EmailLink';
import ConfirmEmail from './components/ConfirmEmail';
import ConfirmationCode from './components/ConfirmationCode';
import ResetPassword from './components/ResetPassword';
import ResetCode from './components/ResetCode';

import Checkout from './components/Checkout';

import FAQ from './components/Content/FAQ';
import Contact from './components/Content/Contact';
import TermsOfService from './components/Content/TermsOfService';
import PrivacyPolicy from './components/Content/PrivacyPolicy';
import About from './components/Content/About';

import AdminDashboard from './components/Admin';
import ReviewQueue from './components/Admin/ReviewQueue';
import UserVerification from './components/Admin/UserVerification';
import UserLookup from './components/Lookup';
import UserView from './components/Admin/UserView';
import AdminBrowse from './components/Admin/Browse';
import DiscountCodes from './components/Admin/DiscountCodes';
import PlatformAccounts from './components/Admin/PlatformAccounts';

// import PlatformNewStart from './components/Platforms/PlatformNewStart';
import PaymentProcessingStatusPage from './components/StatusPages/PaymentProcessing';
import DoneStatusPage from './components/StatusPages/Done';
import WaitingStatusPage from './components/StatusPages/Waiting';
import ApprovedStatusPage from './components/StatusPages/Approved';
import FailedStatusPage from './components/StatusPages/Failed';

import NewPlatform from './components/Portal';

import { fetchUser } from './api/fetchUser';

import { reducer, getInitialState } from './AppReducer';

// Initialize Google Analytics
ReactGA.initialize('UA-219452741-1');

// App and router for the website
const App = function () {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  // Fetch the user from the DB to see how to render
  useEffect(async () => {
    // Track page views
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Update the user
    if (state.token) {
      const { user: newUser, error } = await fetchUser(state.token);
      if (!error) {
        dispatch({ type: 'SET_USER', user: newUser });
      } else {
        dispatch({ type: 'SET_TOKEN', token: null });
        dispatch({ type: 'SET_USER', user: null });
      }
    }
  }, []);

  // Memoize the state
  const value = React.useMemo(() => [state, dispatch], [state]);

  return (
    <AppContext.Provider value={value}>
      <BrowserRouter>
        <ScrollToTop />
        <div className="w-full flex flex-col min-h-screen">
          <Header />

          <div className="flex-grow">
            <Routes>
              <Route path="/other" element={<Oops />} />
              {/* Admin Routes */}
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/admin/queue" element={<ReviewQueue />} />
              <Route path="/verify/:pendingId" element={<UserVerification />} />
              <Route path="/lookup/:email" element={<UserLookup />} />
              <Route path="/admin/view/:userId" element={<UserView />} />
              <Route path="/admin/browse" element={<AdminBrowse />} />
              <Route path="/admin/discount-codes" element={<DiscountCodes />} />
              <Route path="/admin/platforms" element={<PlatformAccounts />} />
              {/* Accounts */}
              <Route path="/account" element={<Account />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/confirmation-code/:code" element={<ConfirmationCode />} />
              <Route path="/confirm" element={<ConfirmEmail />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-code/:code" element={<ResetCode />} />
              {/* The Portal and code-based logins */}
              <Route path="/platform/portal/:portalId" element={<NewPlatform />} />
              <Route path="/platform/new/:portalId" element={<NewPlatform />} />
              <Route path="/email-link/:mlId" element={<EmailLink />} />
              {/* Standalone-only pages */}
              <Route path="/checkout" element={<Checkout />} />
              {/* Status Pages */}
              <Route path="/status/processing" element={<PaymentProcessingStatusPage />} />
              <Route path="/status/done" element={<DoneStatusPage />} />
              <Route path="/status/waiting" element={<WaitingStatusPage />} />
              <Route path="/status/approved" element={<ApprovedStatusPage />} />
              <Route path="/status/failed" element={<FailedStatusPage />} />
              {/* Content Pages */}
              <Route path="/tos" element={<TermsOfService />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/accreditation" element={<Oops />} />
              <Route path="/for-investors" element={<Oops />} />
              <Route path="/for-issuers" element={<Oops />} />
              <Route path="/about" element={<About />} />
              <Route path="/team" element={<Oops />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact" element={<Contact />} />
              {/* Landing Pages */}
              <Route path="/" element={<Landing />} />
              <Route path="/check-accredited-investor-status" element={<LandingStatus />} />
              <Route path="/verify-accredited-investor-status" element={<LandingVerify />} />
              <Route path="/get-accredited-investor-letter" element={<LandingLetter />} />
              <Route path="/get-accredited-investor-certificate" element={<LandingCertificate />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
            </Routes>
          </div>

          <Footer />
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

const Oops = function () {
  return (
    <h2>Uh oh</h2>
  );
};
export default App;
