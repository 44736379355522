import React, { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';

import AppContext from '../AppContext';
import logo from '../assets/Logo.png';

const Header = function () {
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';

  const [{ user }, dispatch] = useContext(AppContext);

  const onSignOutClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_TOKEN', token: null });
    dispatch({ type: 'SET_USER', user: null });
    return <Navigate to="/" />;
  };

  const platformExitLink = (
    returnUrl ? (
      <a className="px-4 cursor-pointer hover:underline hover:text-orange-500 font-semibold" type="button" href={returnUrl}>Save and Exit</a>
    ) : (
      <button className="px-4 cursor-pointer hover:underline hover:text-orange-500 font-semibold" type="button" onClick={onSignOutClick}>Save and Exit</button>
    )
  );
  const platformOnly = [
    <li>{platformExitLink}</li>,
  ];

  const signedOut = [
    <li><a className="px-4 cursor-pointer hover:underline hover:text-orange-500" href="/">Home</a></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/how-it-works">How It Works</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/about">About</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/faq">FAQ</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/contact">Contact</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/login">Log In</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500 font-bold" to="/signup">Sign Up</Link></li>,
  ];

  const signedIn = [
    <li><a className="px-4 cursor-pointer hover:underline hover:text-orange-500" href="/">Home</a></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/how-it-works">How It Works</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/about">About</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/faq">FAQ</Link></li>,
    <li><Link className="px-4 cursor-pointer hover:underline hover:text-orange-500" to="/contact">Contact</Link></li>,
    <li><button className="px-4 cursor-pointer hover:underline hover:text-orange-500" type="button" onClick={onSignOutClick}>Sign Out</button></li>,
    <li><Link className="px-4 cursor-pointer hover:underline font-bold hover:text-orange-500" to="/account">Account</Link></li>,
  ];

  return (
    <div className="sticky top-0 z-50 bg-white w-full h-20 container-fluid px-6 py-2 flex justify-between border-b items-center" id="header">
      <a
        href="/"
        className="text-2xl lg:text-3xl flex flex-row items-center gap-3 font-medium"
      >
        <img className="w-8 h-8 object-cover" src={logo} alt="Carter" />
        Check Investor Status
      </a>
      <div className="hidden lg:block">
        <ul className="inline-flex">
          {
            // eslint-disable-next-line no-nested-ternary
            user ? (
              user.isPlatformUser ? platformOnly : signedIn
            ) : signedOut
          }
        </ul>
      </div>
    </div>
  );
};

export default Header;
