import React from 'react';
import { Link } from 'react-router-dom';

import check from '../../assets/check_secure.svg';

const Hero = function () {
  return (
    <div
      className="pb-12 text-center"
    >
      <div className="container mx-auto px-6">
        <div className="w-3/4 mb-4 bg-white rounded-xl shadow mx-auto py-10 px-5">
          <h3 className="text-xl font-bold mb-16 text-gray-800 mx-auto">
            We make it easy to get verified as an accredited investor.
          </h3>
          <div className="w-full md:w-1/2 mx-auto mb-8">
            <img src={check} alt="Monitoring" />
          </div>
          <h3 className="text-xl mb-8 text-gray-800 w-3/4 mx-auto">
            Get an SEC-compliant accreditation letter from an attorney in 24-hours.
          </h3>
          {/* <h3 className="text-lg mx-auto text-center font-bold mb-4">Pricing</h3> */}
          <div className="container w-3/4 flex flex-row divide-x mx-auto justify-center mb-8">
            <div className="w-1/2 text-center pt-2">
              <h4 className="font-semibold underline">
                Check Investor Status
              </h4>
              <div className="text-4xl text-gray-700 font-semibold mx-auto my-8">
                $50
                <span className="text-sm">/ea</span>
              </div>
              <div className="text-xl text-gray-700 font-bold mx-auto my-8">
                15-minute survey
              </div>
              <div className="text-xl text-gray-700 font-bold mx-auto my-8">
                24-hour review
              </div>
            </div>
            <div className="w-1/2 text-center pt-2">
              <h4 className="font-semibold underline">
                Our Competitors
              </h4>
              <div className="text-4xl text-gray-700 font-semibold mx-auto my-8">
                $60-$70
                <span className="text-sm">/ea</span>
              </div>
              <div className="text-xl text-gray-700 font-bold mx-auto my-8">
                Confusing Instructions
              </div>
              <div className="text-xl text-gray-700 font-bold mx-auto my-8">
                Takes 3+ days
              </div>
            </div>
          </div>

          <Link
            to="/signup"
            className="bg-indigo-500 hover:bg-indigo-600 font-bold text-white rounded-full py-4 px-8 shadow-lg uppercase tracking-wider"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
