import React, { useEffect, useContext, useState } from 'react';
import 'dayjs/locale/es';

import { Navigate } from 'react-router-dom';
import AppContext from '../../AppContext';

import { fetchUser } from '../../api/fetchUser';

import InvestorAccount from './InvestorAccount';

const Account = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);
  const [error, setError] = useState('');

  // Fetch the user from the DB to see how to render
  useEffect(async () => {
    // Update the user

    const { user: newUser, error: newError } = await fetchUser(token);
    if (newError) {
      setError(newError);
    } else {
      dispatch({ type: 'SET_USER', user: newUser });
    }
  }, []);

  if (!token || !user || !user.isConfirmed) {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <div className="container-fluid divide-x divide-gray-200 flex flex-row w-screen">
      <InvestorAccount />
    </div>
  );
};

export default Account;
