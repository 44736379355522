import React, { useState, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { MdEdit } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { IoCloseSharp } from 'react-icons/io5';

import { B_COLORS, DISABLED_B_COLORS } from '../../AppStyles';
import 'react-datepicker/dist/react-datepicker.css';

// import propTypes from 'prop-types';
import AppContext from '../../AppContext';
import {
  fetchPlatforms,
  setPlatform,
} from '../../api/admin';

/*  Fields:
      email,
      readableId,
      name,
      isSandboxPlatform,
      webhookUrl,
     */
const PlatformAccounts = function () {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [platforms, setPlatforms] = useState([]);

  const [modalIdx, setModalIdx] = useState(-1);

  // Current Code State
  const [curEmail, setCurEmail] = useState('example@gmail.com');
  const [curReadableId, setCurReadableId] = useState('example-platform');
  const [curName, setCurName] = useState('Example Platform');
  const [curIsSandbox, setCurIsSandbox] = useState(true);
  const [curWebhookUrl, setCurWebhookUrl] = useState('');

  const [reload, setReload] = useState(0);

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Load in the user from the API
  useEffect(async () => {
    setLoading(true);
    const { error: newError, allPlatforms } = await fetchPlatforms(token);

    // Save the token in localstorage and in the app state
    if (newError) {
      setError(newError);
    }

    // Set not loading anymore
    setPlatforms(allPlatforms);
    setLoading(false);
  }, [reload]);

  // Redirect once we log the user in
  if (!token && !user) {
    return <Navigate to="/" />;
  }
  // Redirect once we log the user in
  if (user.userType !== 'ADMIN') {
    return <Navigate to="/" />;
  }

  const handleClickToEdit = (e, idx) => {
    e.preventDefault();
    // Set the values to the "Cur" values
    const {
      email,
      readableId,
      name,
      isSandboxPlatform,
      webhookUrl,
    } = platforms[idx];
    setCurEmail(email);
    setCurReadableId(readableId);
    setCurName(name);
    setCurIsSandbox(isSandboxPlatform);
    setCurWebhookUrl(webhookUrl);
    setModalIdx(idx);
  };

  const handleUpdatePlatform = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error: newError, platform } = await setPlatform(token, {
      email: curEmail,
      readableId: curReadableId,
      name: curName,
      isSandboxPlatform: !!curIsSandbox,
      webhookUrl: curWebhookUrl,
    });

    setLoading(false);
    setModalIdx(-1);
    if (newError) {
      console.log(newError);
      setError(newError);
    } else {
      console.log('Found a platform');
      console.log(JSON.stringify(platform, null, 2));
      // Trigger a reload
      setReload(reload + 1);
    }
  };

  const handleAddNew = async (e) => {
    e.preventDefault();

    const now = new Date();
    // Create a new default code
    setCurEmail('example@gmail.com');
    setCurReadableId('example-platform');
    setCurName('EXAMPLE PLATFORM');
    setCurIsSandbox(true);
    setCurWebhookUrl('');

    // Add it to list of codes (current)
    const newIdx = platforms.length + 1;
    setPlatforms(platforms.concat([{
      email: 'example@gmail.com',
      readableId: 'example-platform',
      name: 'EXAMPLE PLATFORM',
      isSandboxPlatform: true,
      webhookUrl: '',
    }]));
    setModalIdx(newIdx);
  };

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Something went wrong ...
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        Could not get discount codes from the database. Call Carter.
      </h2>
    </div>
  );

  const modal = (
    <div className="justify-center border border-orange-500 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-1/2 my-auto mx-auto h-1/2">
        <div className="flex flex-col border-0 rounded-lg shadow-lg relative w-full h-full bg-white">
          <div className="flex flex-row">
            <h1 className="font-semibold text-lg pl-5 my-4 mx-auto">Platform Editor</h1>
            <IoCloseSharp
              size={25}
              className="my-4 mx-5 hover:outline hover:outline-blue-500"
              onClick={() => {
                setModalIdx(-1);
                setReload(reload + 1);
              }}
            />
          </div>
          <div className="flex flex-row px-5 w-full mt-8">
            <div className="font-sm font-semibold w-3/12">Email:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="email-name-in"
              value={curEmail}
              onChange={(e) => (setCurEmail(e.target.value))}
            />
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Name:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="name-in"
              value={curName}
              onChange={(e) => setCurName(e.target.value)}
            />
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Readable Id:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="readable-id-in"
              value={curReadableId}
              onChange={(e) => setCurReadableId(e.target.value)}
            />
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Is Sandbox Platform?</div>
            <select
              className="border w-9/12 mb-5 px-1"
              id="type-select"
              name="type-select"
              onChange={(e) => {
                console.log('target val:');
                console.log(e.target.value);
                setCurIsSandbox(!!e.target.value);
              }}
            >
              <option value selected={curIsSandbox === true}>
                Yes
              </option>
              <option value="" selected={curIsSandbox === false}>
                No
              </option>
            </select>
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Webhook Url:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="readable-id-in"
              value={curWebhookUrl}
              onChange={(e) => setCurWebhookUrl(e.target.value)}
            />
          </div>
          <div
            className={`w-1/3 rounded-xl font-semibold my-10 mx-auto text-center text-white py-3 ${B_COLORS}`}
            role="button"
            onClick={handleUpdatePlatform}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            Create or Update
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`container-fluid min-h-screen ${modalIdx >= 0 && 'bg-black/25'}`}>
      { error && errorScreen }
      { modalIdx >= 0 && modal }
      { !loading && !error && (
        <div className={`border border-gray-200 rounded-xl shadow-md w-10/12 mx-auto my-10 py-16 px-20 ${modalIdx >= 0 && 'blur-lg'}`}>
          <div className="flex flex-row">
            <h1 className="text-2xl underline font-bold mb-10 text-center mx-auto">
              Platforms:
            </h1>
            <div
              className="border border-black font-semibold mb-10 p-1 rounded-xl text-xl hover:bg-gray-100"
              onClick={handleAddNew}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              Add New Platform
            </div>

          </div>
          <div className="flex flex-col divide-y gap-5">
            <div className="flex flex-row mt-5">
              <div className="w-4/12 font-md font-semibold">
                Email
              </div>
              <div className="w-1/12 font-md font-semibold">
                Name
              </div>
              <div className="w-2/12 font-md font-semibold">
                Id
              </div>
              <div className="w-1/12 font-md font-semibold">
                Sandbox?
              </div>
              <div className="w-3/12 font-md font-semibold">
                Webhook Url
              </div>
              <div className="w-1/12 font-md font-semibold">
                Edit
              </div>
            </div>
            {
              platforms.map((c, idx) => {
                const {
                  email,
                  readableId,
                  name,
                  isSandboxPlatform,
                  webhookUrl,
                } = c;

                return (
                  <div className="flex flex-row pt-5">
                    <div className="w-4/12 font-sm font-semibold">
                      { email }
                    </div>
                    <div className="w-1/12 font-sm">
                      { name }
                    </div>
                    <div className="w-2/12 font-sm">
                      { readableId }
                    </div>
                    <div className="w-1/12 font-sm">
                      { isSandboxPlatform ? 'Yes' : 'No' }
                    </div>
                    <div className="w-3/12 font-sm">
                      { webhookUrl }
                    </div>
                    <div className="w-1/12 font-sm">
                      <MdEdit
                        size={20}
                        className="hover:outline hover:outline-blue-500"
                        onClick={(e) => handleClickToEdit(e, idx)}
                      />
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      ) }
    </div>
  );
};

export default PlatformAccounts;
