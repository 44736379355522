import { PREFIX } from './prefix';

async function fetchUser(token) {
  return fetch(`${PREFIX}/api/user`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function fetchWaitingProgress(token) {
  return fetch(`${PREFIX}/api/user/waiting-progress`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  fetchUser,
  fetchWaitingProgress,
};
