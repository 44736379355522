import React from 'react';
import { Link } from 'react-router-dom';

import {
  FAQ_P, FAQ_SUBHEADING, FAQ_OL, FAQ_OLI, FAQ_UL, FAQ_ULI, FAQ_B, FAQ_L,
} from '../../AppStyles';

const faqs = [
  {
    heading: 'About Check Investor Status',
    id: 'about-check-investor-status',
    questions: [
      {
        q: 'What is Check Investor Status?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Check Investor Status is a full-fledged investor accreditation
              service. We verify that investors qualify as accredited and issue
              Certificates of Accreditation to both investors and issuers.
            </p>
          </div>),
      },
      {
        q: 'Why did we create this accreditation service?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              The founders of Check Investor Status
              were preparing a real estate securities
              offering and were disappointed by the lack of
              high-quality
              accreditation services.
            </p>
            <p className={`${FAQ_P} `}>
              Every option we found was either clunky and confusing, expensive,
              incomplete, or insecure. Many of the options didn&apos;t seem
              to take privacy or data security seriously.
            </p>
            <p className={`${FAQ_P} `}>
              We started Check Investor Status to blow all other accreditation services
              out of the water. Not only are we building the best product
              and the simplest user experience, we&apos;re offerring it for cheaper than
              our competitors.
            </p>
          </div>),
      },
      {
        q: 'Why should we use your service instead of a competitor?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              We are quickly becoming the industry best-practice by setting
              ourselves out from the existing accreditation services.
            </p>
            <p className={`${FAQ_P} `}>
              There are three reasons why we beat the competition every time:
            </p>
            <div className={`${FAQ_SUBHEADING} `}>
              Simplicity
            </div>
            <p className={`${FAQ_P} `}>
              We believe our responsibility is to handle the complexity of
              investor accreditation behind the scenes. For you (or your investors),
              the process should be easy and obvious.
            </p>
            <p className={`${FAQ_P} `}>
              Complexity leaves room for mistakes. Other services push that
              complexity on to you. We make it simple, fast, and
              bulletproof.
            </p>
            <p className={`${FAQ_P} `}>
              This means no more giant walls of text, no more confusing
              interfaces, and no more trying to understand the details of
              verification methods that don&apos;t apply to you.
            </p>

            <div className={`${FAQ_SUBHEADING} `}>
              Service
            </div>

            <p className={`${FAQ_P} `}>
              We&apos;re committed to actively providing the best possible customer service.
            </p>
            <p className={`${FAQ_P} `}>

              As part of that commitment, we answer all customer support emails
              within two business days, and we issue partial refunds for
              any self-accreditation where
              we are not able to accredit the investor.
            </p>
            <p className={`${FAQ_P} `}>

              If you have a particularly challenging accreditation case, we&apos;ll
              work through it with you one-on-one at no added cost.
            </p>
            <div className={`${FAQ_SUBHEADING} `}>
              Cost

            </div>

            <p className={`${FAQ_P} `}>
              We&apos;ve used cutting-edge automation to make our processes cheaper
              than our competitors, and our pricing reflects that. We charge $50
              for a self-accreditation, or $45 if you buy accreditations in bulk.

            </p>
            <p className={`${FAQ_P} `}>

              <span className={FAQ_B}>
                Be very wary of services claiming to offer &quot;free&quot; investor
                accreditation.
              </span>
              {' '}
              In general, if you
              aren&apos;t paying for a service, then you are the product.
            </p>
            <p className={`${FAQ_P} `}>

              The &quot;free&quot; accreditation services we&apos;ve seen all either lack the
              regulatory legitimacy to hold up in an SEC audit or they offset
              their costs by advertising to you and/or selling your data and
              contact information.
            </p>
          </div>),

      },

    ],
  },
  {
    heading: 'About Accreditation',
    id: 'about-accreditation',
    questions: [
      {
        q: 'Who needs to get accredited?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              If you&apos;re investing in unregistered securities – AKA securities that are
              offered through private placement with an &quot;issuer&quot; or
              &quot;sponsor&quot; – you may
              need to prove your status as an accredited investor in order to invest.

            </p>

            <p className={`${FAQ_P} `}>
              Examples of the types of investments that require accredited status
              include private real estate offerings, private (pre-IPO) company or
              startup investments, and some cryptocurrency STOs or ICOs.

            </p>

            <p className={`${FAQ_P} `}>
              These types of fundraising are offered through the Regulation D. This
              means that the issuer doesn&apos;t have to register with the SEC (aka &quot;Go
              Public&quot;), but it restricts how they raise money and who can invest.

            </p>

          </div>
        ),
      },

      {
        q: 'Can\'t investors just self-certify or take a survey to prove that they are accredited?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Nope. The JOBS act of 2008 requires that issuers take &quot;reasonable steps&quot;
              to verify the accreditation status of their investors if they are using a
              Reg D 506c exemption.

            </p>

            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                Unfortunately, self-certifications (eg checking a box) and simple surveys
                about accreditation do not qualify as reasonable steps.
              </span>
              {' '}
              However,
              collecting Certificates of Accreditation from our site does – we take care
              of the reasonable steps on your behalf.

            </p>

          </div>
        ),
      },

      {
        q: 'Who qualifies as an accredited investor?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              The guidelines for accreditation are complicated and there are a lot of
              edge cases involved.

            </p>

            <p className={`${FAQ_SUBHEADING} `}>
              Individuals (or couples):

            </p>

            <p className={`${FAQ_P} `}>
              If you are an individual, you need to either have more than $200k in
              annual income ($300k if filing joint) or have a net worth of greater than
              $1M (excluding your primary residence).

            </p>

            <p className={`${FAQ_P} `}>
              There are special exceptions, such as individuals who are registered
              financial professionals, or who work for the issuer, or who are working on
              this specific investment.

            </p>

            <p className={`${FAQ_SUBHEADING} `}>
              Business Entities (eg LLCs)

            </p>

            <p className={`${FAQ_P} `}>
              The easiest way for an entity to qualify is to demonstrate that you have
              more than $5M in assets. If you can qualify this way, you should – it is
              the quickest and easiest verification method for entities.

            </p>

            <p className={`${FAQ_P} `}>
              You also may qualify based on your business entity – businesses that are
              (or are run and directed exclusively by) investment companies, insurance
              companies, small Business investment companies, etc. These entity types
              are managed by stricter regulatory organizations (EG FINRA, state
              governments, etc.), and may be qualified automatically regardless of net
              worth.

            </p>

            <p className={`${FAQ_P} `}>
              Finally, you may qualify regardless of assets or business status if you
              can demonstrate that all equity owners of the entity are themselves
              accredited investors.

            </p>

            <p className={`${FAQ_SUBHEADING} `}>
              Trusts

            </p>

            <p className={`${FAQ_P} `}>
              The easiest way for a trust to qualify is to demonstrate that the trust
              has more than $5M in assets and that it is directed by a sophisticated
              person. If you can qualify this way, you should – it is the quickest and
              easiest verification method for trusts.

            </p>

            <p className={`${FAQ_P} `}>
              A trust may also qualify regardless of assets or business status if you
              can demonstrate that all equity owners of the trust are themselves
              accredited investors.

            </p>

            <p className={`${FAQ_P} `}>
              The equity owners of a revocable trust are generally the grantors. It can
              be very challenging to determine the equity owners for an irrevocable
              trust, but we are happy to help you make that determination if you decide
              to get accredited with this approach.
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                Contact us
              </Link>
              {' '}
              for help.

            </p>

          </div>
        ),
      },

      {
        q: 'How does accreditation work?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              We have to verify that you qualify as an &quot;accredited investor&quot;
              under the law. In order to do this, we need to collect information and
              documentation from you that shows that you qualify.

            </p>

            <p className={`${FAQ_P} `}>
              There are only a couple of steps involved, and the whole process should
              only require a couple minutes of your time:

            </p>
            <ol className={`${FAQ_OL} `}>
              <li className={`${FAQ_OLI} `}>You create an account answer a 3-question survey to determine what documentation you&apos;ll need to upload.</li>
              <li className={`${FAQ_OLI} `}>You upload the relevant documentation to prove you qualify as accredited.</li>
              <li className={`${FAQ_OLI} `}>You pay you accreditation fee (if you&apos;re purchasing your own accreditation)</li>
              <li className={`${FAQ_OLI} `}>Legal review & accreditation – our legal team reviews your documentation and issues a Certificate of Accreditation.</li>
            </ol>
            <p className={`${FAQ_P} `}>
              Note that if our legal team hits any problems during review, we will reach
              out and have you re-upload or upload supporting documentation at no added
              cost.

            </p>

          </div>
        ),
      },
      {
        q: 'Can Accreditations be reused? Do I need to renew my accreditation?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Generally,
              {' '}
              <span className={FAQ_B}>
                an accreditation certificate is good for 90 days
              </span>
              {' '}
              from its time
              of issuance and can be used as proof of accredited investor status for any
              number of investments (with any number of issuers).

            </p>
            <p className={`${FAQ_P} `}>
              However, there are a few important exceptions:

            </p>
            <ul className={`${FAQ_UL} `}>
              <li className={`${FAQ_ULI} `}>
                Your accreditation expires after 90 days from the
                {' '}
                <span className="italic">earlier</span>
                {' '}
                of (1.) the date that we issue your accreditation, or
                (2.) the date of the most recent piece of documentation provided to us.
              </li>
              <li className={`${FAQ_ULI} `}>If you qualify as an individual based on your employment in a particular deal, offering or investment, you can qualify as an investor, but only for that particular deal. You will not be qualified for other investments. This will be noted in your certificate of accreditation.</li>
            </ul>
            <p className={`${FAQ_P} `}>
              We will send you an email when your accreditation expires. It is not
              necessary to renew your accreditation to remain invested in past
              issuances, but it is necessary to take on new unregistered investments.

            </p>

          </div>
        ),
      },
      {
        q: 'What are the relevant laws and regulations behind investor accreditation?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                The Securities Act of 1933
              </span>
              {' '}
              requires that issuers either register
              their securities (&quot;go public&quot;) or use an exemption
              in order to raise funds.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                Regulation D
              </span>
              {' '}
              is the most common exemption used by issuers who want to
              raise money without registering with the SEC. This fundraising process is
              often referred to as &quot;private placement&quot;.

            </p>
            <p className={`${FAQ_P} `}>
              There are two common approaches to fundraising with Reg D. Reg D 506(b)
              allows you to raise from a small number of unaccredited investors so long
              as you do not advertise (general solicit) your investment. 506(c) allows
              for general solicitation, but requires that you only raise from accredited
              investors.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                The JOBS act of 2008
              </span>
              {' '}
              requires those raising under 506(c) take &quot;reasonable
              steps&quot; to verify their investors status as accredited investors. This
              means that for accredited-only investments, a self-certification or survey
              is no longer enough to keep issuers in the clear.

            </p>
          </div>
        ),
      },
      {
        q: 'How can I document my cryptocurrency assets?',
        id: 'verify-crypto',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Great question. The best way to document your proof of ownership
              and proof of value of commodities like cryptocurrency is to
              provide a financial statement from a wallet or exchange service
              (such as Coinbase). That financial statement will probably be
              accessible from the &apos;Account&apos; section of their website,
              and must display your legal name as well as your account balance.
            </p>
            <p className={`${FAQ_P} `}>
              If your statement shows value in USD, we&apos;ll use the value in the statement
              so long as the statement is from the last 90 days. Otherwise, we&apos;ll perform
              the conversion based on the value of your crypto assets at the time of legal
              review.
            </p>
            <p className={`${FAQ_P} `}>
              If getting a financial statement isn&apos;t an option (EG if you have your crypto
              assets in a cold wallet),
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                contact us
              </Link>
              {' '}
              and we&apos;ll help you send us a
              cryptographically-signed message using the private keys of any wallet
              whose assets you would like to prove ownership of.
            </p>
          </div>
        ),
      },
      {
        q: 'How can I document my assets to prove my net worth?',
        id: 'document-net-worth',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Documenting your net worth can be fairly involved, so if you are able to
              qualify as an accredited investor by income, we recommend you do that
              instead.
            </p>
            <p className={`${FAQ_P} `}>
              In general, in order for us to count something towards your net worth,
              we need proof that you own that asset and we need an independent
              appraisal (AKA documentation of value).
            </p>
            <p className={`${FAQ_B} text-lg mb-5`}>
              Let&apos;s look at some of the most common examples:
            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                For a stock portfolio, bank account, or retirement account,
              </span>
              {' '}
              all you would need to upload is a financial statement from your
              brokerage that shows your legal name and the overall balance
              of your account.
            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                For real estate,
              </span>
              {' '}
              let&apos;s say you own a rental property with a mortgage on it.
              You could document this entirely by uploading (1.) the property deed or title,
              (2.) a screenshot of the zillow estimate of property value, and (3.) a copy of
              your last monthly mortgage statement.
            </p>
            <p className={`${FAQ_P} `}>
              And remember that
              {' '}
              <span className={FAQ_B}>
                your asset documentation doesn&apos;t need to be comprehensive.
              </span>
              {' '}
              Let&apos;s say your credit report shows $1M in liabilities (debt), and that you have
              a brokerage account worth $2.5M, and a rental property worth $1.5M. In this example,
              you could document just your brokerage account since $2.5M of assets minuse $1M in
              liabilities retults in a net worth greater than $1M. You wouldn&apos;t need to
              document your property, since your brokerage account alone puts you past the $1M
              net worth threshold to qualify as an accredited investor.
            </p>
          </div>
        ),
      },
      {
        q: 'What if I only have paper copies of my documentation?',
        id: 'paper-copies',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              No problem. You can upload images or pdfs as documentation as long as the documents
              are readable.
            </p>
            <p className={`${FAQ_P} `}>
              If you have a paper copy of documentation, you can either (1.) use a scanner to
              scan the documentation and upload the resulting PDF, or (2.) take a picture of
              the documents with your mobile phone.
            </p>
            <p className={`${FAQ_P} `}>
              Note that mobile pictures of documents can often be too poor quality for us to
              verify. Make sure that the documentation is clearly legible if you go with this
              approach. There are
              {' '}
              <a
                className={FAQ_L}
                href="https://www.nytimes.com/wirecutter/reviews/best-mobile-scanning-apps/"
              >

                many mobile apps
              </a>
              {' '}
              that can help you scan high-quality copies
              of your documents with your mobile phone.

            </p>
          </div>
        ),
      },
      {
        q: 'Why can\'t I upload my file?',
        id: 'upload-limitations',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Usually when there is an upload problem, its either because the file type
              is unsupported or the file is too large.
            </p>
            <p className={`${FAQ_P} `}>
              The file types we support for upload are PDFs and most types of images. If your
              documentation is not a pdf file or an image, you will need to convert it in order
              to upload.
            </p>
            <p className={`${FAQ_P} `}>
              We also have a 5MB size limit on all uploads. If your file is larger than this,
              you will either need to split it into multiple uploads, only upload the relevant
              information, or use a service to compress the file. There are
              {' '}
              <a
                className={FAQ_L}
                href="https://imagecompressor.com/"
              >

                many online services
              </a>
              {' '}
              that can help you reduce the size of images of different types.

            </p>
          </div>
        ),
      },

    ],
  },
  {
    heading: 'Our Services',
    id: 'our-services',
    questions: [

      {
        q: 'How much does accreditation cost?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                Self-accreditation costs $50. Ordering accreditations for multiple
                investors at the same time costs $45 per investor.
              </span>
            </p>
            <p className={`${FAQ_P} `}>
              Note that some entities and trusts may be required to verify multiple
              equity owners and will have to pay $45 per accreditation.
            </p>
          </div>
        ),
      },

      {
        q: 'How long does accreditation take?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Providing information and uploading documentation generally only takes a
              few minutes if you have your documentation at hand.

            </p>
            <p className={`${FAQ_P} `}>
              After we process your order (which happens within an hour of checkout), we
              begin legal review.
              <span className={FAQ_B}>
                Legal review generally takes 1-2 business days to complete.
              </span>
              {' '}
              You and your issuer will receive an email when your accreditation
              letter is ready.

            </p>

          </div>
        ),
      },

      {
        q: 'What if I make a mistake in my documentation?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Not a problem! If our legal team hits any problems during legal review, we
              will reach out and have you re-upload or upload supporting documentation.

            </p>
            <p className={`${FAQ_P} `}>
              You will receive an email stating we need more documentation, and when you
              log in, you&apos;ll be taken back to the &quot;upload&quot; stage
              and will see notes from our
              legal team on what else is needed to verify your status.

            </p>
            <p className={`${FAQ_P} `}>
              In the event of re-review, your legal review will be fast-tracked and you
              will not be charged additional fees for re-review after you upload
              supporting documentation.

            </p>

          </div>
        ),
      },

      {
        q: 'What if you can\'t verify my accreditation?',
        id: 'refunds',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              If we are unable to verify your accreditation, we will
              partially refund your
              purchase. You can request a refund at
              by
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                contacting us.
              </Link>

            </p>
          </div>),
      },

      {
        q: 'Do you offer refunds?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              We do offer partial refunds for self-accreditations that we are unable to
              complete. We cannot offer refunds to issuers who purchase in bulk, or for
              accreditations that are successfully completed.
            </p>
            <p className={`${FAQ_P} `}>
              You can request a refund at
              by
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                contacting us.
              </Link>

            </p>
          </div>
        ),
      },

      {
        q: 'I have a request for a new feature',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Excellent – we are in active development and would love to improve our
              product.
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                Reach out to us
              </Link>
              {' '}
              with your feature request, and we&apos;ll keep you in
              the loop if and when we have it implemented.

            </p>
          </div>
        ),
      },

      {
        q: 'I found a problem or a bug',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Please
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                let us know
              </Link>
              {' '}
              what issue you found! For all substantive bugs
              (blocking, broken functionality) we will offer a bug bounty of either a
              free accreditation for investors or a 10% discount for issuers.

            </p>
          </div>
        ),
      },

    ],
  },
  {
    heading: 'For Investors',
    id: 'for-investors',
    questions: [
      {
        q: 'How do I get accredited?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Simply create an account and we&apos;ll walk you through each step. For an
              overview of the process, see &quot;How does Accreditation Work?&quot;

            </p>

          </div>
        ),
      },

      {
        q: 'What is the easiest/fastest method for getting accredited?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              In general, if you already have a letter from a qualified professional
              (Attorney, Registered Investment Adviser, Broker-Dealership, or Certified Accountant)
              stating
              that they have verified your accreditation status in the last 90 days, we
              can accept that without any further documentation. That is the simplest
              approach to accreditation. You can find a
              {' '}
              <Link
                className={FAQ_L}
                to="/qualified_professional_letter.pdf"
              >
                template to have your attorney
                sign here.
              </Link>

            </p>
            <p className={`${FAQ_P} `}>
              Without a letter from a qualifying professional
              {' '}
              <span className={FAQ_B}>
                the easiest methods for
                individuals and couples are income verification.
              </span>
              {' '}
              If you can qualify
              through this approach, we recommend you do.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                Note: for individuals, qualifying by net worth can be cumbersome
              </span>
              {' '}
              – you
              will need a credit report, and proof of ownership and value of your
              assets. That&apos;s a lot of paperwork and it&apos;s subject to easy-to-make
              mistakes.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                For trusts and entities, the easiest method is net worth.
              </span>
              {' '}
              Most entities
              and trusts have financial records at hand that demonstrate the value of
              their assets.

            </p>
            <p className={`${FAQ_P}`}>

              <p className={`${FAQ_P} `}>
                Note: for trusts and entities, qualifying by proving the accreditation
                status of your equity owners can be challenging, can cost more, and can
                take longer.
              </p>
            </p>

          </div>
        ),
      },

      {
        q: 'What information do I have to give you?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              In the first accreditation step, you&apos;ll answer three questions:

            </p>

            <ol className={`${FAQ_OL} `}>
              <li className={`${FAQ_OLI} `}>How are you investing? (individual vs. as a couple vs. as a business entity or trust)</li>
              <li className={`${FAQ_OLI} `}>What is your legal name?</li>
              <li className={`${FAQ_OLI} `}>How do you qualify as accredited (EG by income, by networth, by special circumstance, etc.)</li>
            </ol>

            <p className={`${FAQ_P} `}>
              After we have that information, we&apos;ll know exactly how to go about verifying
              your status as an accredited investor.
            </p>
          </div>
        ),
      },

      {
        q: 'What documentation do I need to upload?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              It depends on how you qualify as accredited. For example, for individuals:

            </p>

            <ul className={`${FAQ_UL} `}>
              <li className={`${FAQ_ULI} `}>If you qualify through income, you&apos;ll need to upload tax documents for the past two years.</li>
              <li className={`${FAQ_ULI} `}>If you qualify through net worth, you&apos;ll need to upload a recent credit report, the equity and liability in your personal residence, and evidence of your assets (proof of ownership and proof of value).</li>
              <li className={`${FAQ_ULI} `}>If you qualify through your financial or professional qualifications, we&apos;ll walk you through the documentation you can use to prove your specific qualification.</li>
            </ul>

            <p className={`${FAQ_P} `}>
              You are welcome to redact sensitive documentation on documents so long as
              you don&apos;t redact the relevant numbers or your legal name.

            </p>

          </div>
        ),
      },

      {
        q: 'What do you do with my information / uploads?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              The only people who see your information and uploads are our legal and
              administrative staff.

            </p>
            <p className={`${FAQ_P} `}>
              If a sponsor pays for your accreditation or if you add an issuer to your
              account, they will see your accreditation status and any certificate of
              accreditation that we issue to you, but they will not have access to the
              documentation you upload.

            </p>
            <p className={`${FAQ_P} `}>
              Your documentation is stored to the cloud
              using end-to-end encryption.
              For the purposes of our own records and compliance with audits,
              we store records and documentation of accreditations for 10 years.
            </p>
          </div>
        ),
      },

      {
        q: 'Is my data secure?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              We follow best-practices in web development and security
              including end-to-end encryption, authentication, data restriction policies,
              and enforced SSL communication.

            </p>
            <p className={`${FAQ_P} `}>
              We also choose third-party integrations that can provide us with security
              we couldn&apos;t otherwise offer on our own.

            </p>

            <ul className={`${FAQ_UL} `}>
              <li className={`${FAQ_ULI} `}>MongoDB – Database Service</li>
              <li className={`${FAQ_ULI} `}>Filestack – File upload, verification, and temporary storage</li>
              <li className={`${FAQ_ULI} `}>AWS S3 and Glacier – Secure file storage and encryption</li>
              <li className={`${FAQ_ULI} `}>Stripe – Payment Processing</li>
            </ul>
          </div>
        ),
      },

      {
        q: 'What if I\'m investing through a retirement or employee-benefit account?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              No Problem – we&apos;ve got you covered. If the owner of the account is an
              individual or couple, you will verify your accreditation status as normal
              (EG an IRA owned by one person needs to have its owner accredited). If the
              owner of the account is an entity, you verify your accreditation status as
              an entity (EG an employee-benefit plan run by a fiduciary).

            </p>
          </div>
        ),
      },

      {
        q: 'Do I need a Credit Report?',
        id: 'credit-report',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Only if you&apos;re an individual or couple who&apos;s accredited by your net
              worth.

            </p>
            <p className={`${FAQ_P} `}>
              If this is the case, you have a few options for getting a credit report:

            </p>
            <ol className={`${FAQ_OL} `}>
              <li className={`${FAQ_OLI} `}>
                You can go to any of the major credit reporting agencies and request your
                jcredit report.
                You are entitled to get
                {' '}
                <a
                  className={FAQ_L}
                  href="https://www.consumer.ftc.gov/articles/free-credit-reports"
                >

                  one credit report for free every year.
                </a>
                {' '}
                If you haven&apos;t done that, you can claim for your free report and
                upload it as a PDF.
              </li>
              <li className={`${FAQ_OLI} `}>
                You can use
                {' '}
                <a
                  className={FAQ_L}
                  href="https://www.experian.com/connect/"
                >
                  Experian Connect
                </a>
                {' '}
                to order a credit report and upload it as a PDF.
              </li>
            </ol>

            <p className={`${FAQ_P} `}>
              At this point, we cannot pull your credit report on your behalf.
              Integrating with credit reporting agencies is a feature that will be
              coming soon.

            </p>
          </div>
        ),
      },

      {
        q: 'How does verification of entity/trust equity owners work?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              We have a special verification process for entities or trusts that need to
              verify the accreditation status of all of their equity owners.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                In general, if you can qualify through a different method
                , it will be cheaper and faster to do so!
              </span>

            </p>
            <p className={`${FAQ_P} `}>
              It&apos;s worth noting that you will have to pay for separate accreditations
              for each of the equity owners, but not for the entity itself – we provide
              that accreditation free of charge once all equity owner accreditations are
              verified.

            </p>
            <p className={`${FAQ_P} `}>
              You also will get the 10% issuer discount automatically applied to your
              purchase so long as you have more than 1 equity owners who needs to be
              accredited.

            </p>

          </div>
        ),
      },

    ],
  },
  {
    heading: 'For Issuers',
    id: 'for-issuers',
    questions: [
      {
        q: 'What is an Issuer?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              An issuer is someone who raises money for an investment. The two most
              common examples of private-security issuers are:

            </p>

            <ul className={`${FAQ_UL} `}>
              <li className={`${FAQ_ULI} `}>real estate sponsors, who raise capital from investors in order to make and run investments.</li>
              <li className={`${FAQ_ULI} `}>startups and companies, who sell equity in their company for money.</li>
            </ul>

            <p className={`${FAQ_P} `}>
              &quot;Issuers&quot; are often referred to as &quot;General
              Partners&quot;, &quot;GPs&quot; or &quot;sponsors&quot;.
              &quot;Investors&quot; are often referred to as &quot;LPs&quot; or
              simply &quot;investors&quot;.

            </p>
          </div>
        ),
      },

      {
        q: 'Can\'t I accredit my investors myself?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              You can, but it&apos;s risky.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                The JOBS act of 2008 requires that issuers take &quot;reasonable steps&quot; to
                verify the accreditation status of their investors if they are using a Reg
                D 506c exemption.
              </span>

            </p>
            <p className={`${FAQ_P} `}>
              Self-certifications (eg checking a box) and simple surveys about
              accreditation do not qualify as reasonable steps. However, collecting
              Certificates of Accreditation from our site does – we take care of the
              reasonable steps on your behalf.

            </p>
            <p className={`${FAQ_P} `}>
              So, unless you feel confident that you can navigate the
              sometimes-complicated edge cases around who does and does not qualify (and
              do all the required due diligence, collect the proper documentation, and verify its
              completeness and authenticity), it&apos;s best to leave accreditation to a
              company that specializes in it.

            </p>
          </div>
        ),
      },

      {
        q: 'What if my investors aren\'t accredited?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              The costs of getting accreditation wrong can be incredibly high – you may
              face civil or criminal charges from the SEC, and you may be
              required to pay damages to your investors or refund the capital you
              raised.

            </p>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                Failing to use best practices in investor accreditation can put issuers in
                serious legal and financial jeopardy.
              </span>

            </p>
          </div>
        ),
      },

      {
        q: 'Can I pay for my investors in advance?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              Yes! we have a specialized investor portal which will allow you to add
              investors, track their status as they proceed through our service, and
              access their certificate of accreditation after it&apos;s issued.
            </p>
            <p className={`${FAQ_P} `}>
              To get started, just
              {' '}
              <Link
                className={FAQ_L}
                to="/signup"
              >
                create an account
              </Link>
              {' '}
              and indicate that you are
              signing up as an issuer.
            </p>
          </div>
        ),
      },

      {
        q: 'Can I track my investors if I don\'t pay for them?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              At this time, no.

            </p>
            <p className={`${FAQ_P} `}>
              However, we do have an option during or after accreditation for
              self-certifying investors to add one or more issuer email addresses to
              their account. If they do this, we will automatically send a copy of their
              accreditation letter to any issuers they add.

            </p>
            <p className={`${FAQ_P} `}>
              If you have questions or concerns about this process,
              {' '}
              <Link
                className={FAQ_L}
                to="/contact"
              >
                contact us
              </Link>
              .
            </p>
          </div>
        ),
      },

      {
        q: 'Do I need to accredit my investors?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              <span className={FAQ_B}>
                If you&apos;re raising funds under Regulation D 506(c),
                the answer is yes.
              </span>
              {' '}
              This is the exemption that
              is limited to accredited investors only.
            </p>
            <p className={`${FAQ_P} `}>
              If you&apos;re raising funds of Regulation D 506(b) – AKA the exemption where
              you can have up to 35 unaccredited investors – then a self-certification
              may be fine. This also means you have to be very strict about general
              solicitation – you cannot share investment information, advertise, or ask
              anyone to invest without having a substantive prior relationship with that
              potential investor.

            </p>
          </div>
        ),
      },

      {
        q: 'Can I discount multiple investors at once?',
        a: (
          <div>
            <p className={`${FAQ_P} `}>
              If you are purchasing more than 1 accreditation at once as an issuer, we
              will automatically apply discounted pricing ($45/investor, discounted 10%
              from the usual rate of $50/investor)

            </p>
          </div>
        ),
      },

    ],
  },
];

export default faqs;
