// TODO(Carter): Loading State
import React, { useState, useContext, useEffect } from 'react';

import { Navigate, Link } from 'react-router-dom';

import { getWaitingState } from '../../api/platforms';
import { SELF_PREFIX } from '../../api/prefix';

import AppContext from '../../AppContext';
import done from '../../assets/done.svg';
import colab from '../../assets/check_doc_colab.svg';

const WaitingStatusPage = function () {
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';
  const retstr = returnUrl ? `?returnUrl=${returnUrl}` : '';

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  const [isDone, setIsDone] = useState(true);
  const [nextUser, setNextUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const { error, signerDone, signingUser } = await getWaitingState(token);

    // Save the token in localstorage and in the app state
    if (!error) {
      if (signerDone) {
        setIsDone(true);
      } else {
        setIsDone(false);
        setNextUser(signingUser);
      }
    } else {
      console.log('Server error');
      console.log(error.toString());
    }

    setLoading(false);
  }, []);

  if (!user || user.userType === 'ADMIN') {
    return (
      <Navigate to={`/${retstr}`} />
    );
  }
  if (user.userStage !== 'WAITING') {
    return <Navigate to={`/${retstr}`} />;
  }

  const handleDone = async (e) => {
    e.preventDefault();
    setLoading(true); // We don't want to redirect too soon
    // Log out the user first
    dispatch({ type: 'SET_USER', user: nextUser });
  };

  const doneButton = (
    <div
      className="flex cursor-pointer flex-row w-11/12 items-center bg-[#5963F4] rounded-[.3REM] py-3 px-6 text-white font-semibold hover:brightness-75 "
      onClick={handleDone}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      <span className="mx-auto">Continue Accreditation</span>
    </div>
  );

  const returnButton = (
    <a
      className="flex cursor-pointer flex-row w-11/12 items-center bg-[#5963F4] rounded-[.3REM] py-3 px-6 text-white font-semibold hover:brightness-75 "
      href={returnUrl}
    >
      <span className="mx-auto">Continue Investing</span>
    </a>
  );

  // First check and see if we have more to do
  const todoContent = (
    <div className="w-11/12 container-fluid mx-auto px-6 py-10 border-blue-500">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        You&apos;re almost done!
      </h2>

      <div className="flex items-center justify-between flex-wrap mb-20">
        <div className="w-full md:w-1/2 p-5">
          <img src={colab} alt="Done" />
        </div>
        <div className="w-full md:w-6/12 px-16">
          <h4 className="text-2xl text-gray-800 font-bold mb-10">
            We need to get you accredited individually.
          </h4>
          <p className="text-lg text-gray-800 mb-5">
            Remember that this time, you&apos;re getting accredited as yourself,
            not on behalf of your entity.
          </p>
          <p className="text-lg text-gray-800 mb-5 font-semibold">
            Click the button below to continue to your personal accreditation portal.
          </p>
          <p className="text-lg text-gray-800 mb-10">
            We&apos;ve emailed you a link to your accreditation portal as well,
            but you might as well just finish up while you&apos;re here.
          </p>
          { doneButton }
        </div>

      </div>
    </div>

  );

  // If so, render a different message
  const doneContent = (
    <div className="w-11/12 xl:w-10/12 container-fluid mx-auto px-6 py-10 border-blue-500">

      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        We&apos;re waiting on your equity owners!
      </h2>

      <div className="flex items-center justify-between flex-wrap mb-20">
        <div className="w-full md:w-1/2 p-5">
          <img src={done} alt="Done" />
        </div>
        <div className="w-full md:w-6/12 px-16">
          <h4 className="text-2xl text-gray-800 font-bold mb-10">
            No further action is required from you.
          </h4>
          <p className="text-lg text-gray-800 mb-5 font-semibold">
            You can close this tab and we&apos;ll handle the rest.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            You&apos;ll get an
            email notification and an accreditation letter
            as soon as we
            verify your accreditation or if we need any additional
            information.
          </p>
          <p className="text-lg text-gray-800 mb-5">
            We&apos;ll automatically let your sponsor know when your accreditation status
            has been verified.
          </p>
          {
            returnUrl ? returnButton : (
              <p className="text-lg text-gray-800 mb-8 font-semibold">
                Happy investing!
              </p>
            )
          }
        </div>

      </div>
    </div>

  );

  return (
    <div className="container-fluid divide-x divide-gray-200 flex flex-row w-screen">
      <div className="container-fluid flex flex-col w-full xl:w-10/12 mx-auto">
        { isDone ? doneContent : todoContent }
      </div>
    </div>
  );
};

export default WaitingStatusPage;
