import React, { useEffect, useContext, useState } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';

import AppContext from '../../AppContext';

import { downloadPdf } from '../../api/downloadPdf';
import { fetchUser } from '../../api/admin';
import { SELF_PREFIX } from '../../api/prefix';

const FileDownload = require('js-file-download');

const UserView = function () {
  const { userId } = useParams();

  const [{ token, user }, dispatch] = useContext(AppContext);
  const [error, setError] = useState('');
  const [viewUser, setViewUser] = useState({});

  const { isPlatformUser } = viewUser;

  // Fetch the pending verifications from the DB
  useEffect(async () => {
    // Search the DB for users
    const { error: newError, user: newUser } = await fetchUser(token, userId);
    if (error) {
      setError(error);
    } else {
      setViewUser(newUser);
    }
  }, []);

  if (!token || !user) {
    return (
      <Navigate to="/" />
    );
  }
  if (user.userType !== 'ADMIN') {
    return (
      <Navigate to="/" />
    );
  }

  const HEADER = 'text-xl font-bold mb-4';
  const LABEL = 'text-lg font-semibold w-1/4';
  const LABEL_LONG = 'text-lg font-semibold w-full';
  const CONTENT = 'text-lg';
  const LINK = 'text-lg font-semibold text-blue-800 hover:underline hover:text-orange-500';

  const {
    // Overview + Platform
    _id: id,
    email,
    platformBaseEmail,
    isTempUser,
    userStage,
    platform,
    portalCode,
    magicCode,
    // Survey
    legalName,
    investorType,
    verificationMethod,
    investingWith,
    // UPLOAD
    verificationEvidence,
    dependents,
    // CHECKOUT
    paymentDate,
    discountCodeApplied,
    platformMetadataTag,
    // Accreditations and failures
    accreditations,
    failures,
  } = viewUser;

  const overviewContent = (
    <div className="flex flex-col">
      <h2 className={HEADER}>User Overview:</h2>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Internal ID:</strong>
        <p className={CONTENT}>{ id }</p>
      </div>
      {
        isPlatformUser && (
          <div className="flex flex-row pl-8 mb-2">
            <strong className={LABEL}>Platform User:</strong>
            <p className={CONTENT}>{ isPlatformUser ? 'Yes' : 'No' }</p>
          </div>
        )
      }
      {
        isTempUser && (
        <div className="flex flex-row pl-8 mb-2">
          <strong className={LABEL}>Temporary User:</strong>
          <p className={CONTENT}>{ isTempUser ? 'Yes' : 'No' }</p>
        </div>
        )
      }
      {
        (isPlatformUser || isTempUser) ? (
          <div className="flex flex-row pl-8 mb-2">
            <strong className={LABEL}>Username:</strong>
            <p className={CONTENT}>{ email }</p>
          </div>
        ) : (
          <div className="flex flex-row pl-8 mb-2">
            <strong className={LABEL}>Email:</strong>
            <p className={CONTENT}>{ email }</p>
          </div>
        )
      }
      {
        (isPlatformUser || isTempUser) && (
          <div className="flex flex-row pl-8 mb-2">
            <strong className={LABEL}>Email:</strong>
            <p className={CONTENT}>{ platformBaseEmail }</p>
          </div>
        )
      }
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>User Stage:</strong>
        <p className={CONTENT}>{ userStage }</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Magic Login Link:</strong>
        <a
          className={LINK}
          href={`${SELF_PREFIX}/email-link/${magicCode}`}
          target="_blank"
          rel="noreferrer"
        >
          {`${SELF_PREFIX}/email-link/${magicCode}`}
        </a>
      </div>
    </div>
  );

  const {
    _id: platformInternalId,
    name: platformName,
    readableId: platformId,
    isSandboxPlatform,
  } = (platform || {});
  const platformInfo = (
    <div className="flex flex-col pt-10">
      <h2 className={HEADER}>Platform Info:</h2>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Platform Name:</strong>
        <p className={CONTENT}>{`${platformName} (${platformId})`}</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Platform Internal Id:</strong>
        <p className={CONTENT}>{platformInternalId}</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Sandbox Platform?</strong>
        <p className={CONTENT}>{ isSandboxPlatform ? 'Yes' : 'No' }</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Platform Metadata Tag:</strong>
        <p className={CONTENT}>{ JSON.stringify(platformMetadataTag) }</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Platform Portal Link</strong>
        <a
          className={LINK}
          href={`${SELF_PREFIX}/platform/portal/${portalCode}`}
          target="_blank"
          rel="noreferrer"
        >
          {`${SELF_PREFIX}/platform/portal/${portalCode}`}
        </a>
      </div>
    </div>
  );

  const surveyInfo = (
    <div className="flex flex-col pt-10">
      <h2 className={HEADER}>Survey Data:</h2>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Legal Name:</strong>
        <p className={CONTENT}>{legalName}</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Investor Type:</strong>
        <p className={CONTENT}>{investorType}</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Verification Method:</strong>
        <p className={CONTENT}>{ verificationMethod }</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Investing With:</strong>
        <p className={CONTENT}>{ investingWith }</p>
      </div>
    </div>
  );

  const getObjectFromUrl = (url) => {
    const req = new XMLHttpRequest();
    req.open('GET', url, false);
    req.send(null);
    const header = req.getResponseHeader('content-type').toLowerCase();
    if (header.includes('image')) {
      return (
        <img className="w-full h-[32rem] mb-10 object-contain" src={url} alt={url} />

      );
    }

    return (
      <object className="w-full h-[32rem] mb-10" data={`${url}#view=FitH`} type="application/pdf">
        <iframe className="w-full h-[32rem] mb-10" title={url} src={`https://docs.google.com/viewer?url=${url}&embedded=true`} />
      </object>
    );
  };
  const evidenceToObject = (evidence) => {
    const {
      type,
      verificationId,
      values,
      reviews,
    } = evidence;

    const reviewToString = (r) => {
      const {
        success,
        date,
        note,
        reviewer,
      } = r;

      return (
        `${(success ? 'Approved by reviewer (' : 'Rejected by reviewer (')
        + reviewer
        }) on ${date} with note: "${note}"`
      );
    };
    return (
      <div className="flex flex-col text-left mx-auto w-full pb-5 px-5 ">
        <h2 className="text-lg font-semibold mt-2 mb-5">{`${verificationId}:`}</h2>
        {
          type === 'ISSUER_CONVERSION' ? (
            (dependents || []).map((dependentId) => (
              <a
                className="px-2 w-1/2 font-semibold border border-gray-200 text-lg mb-2 text-blue-800 hover:underline"
                href={`/admin/view/${dependentId}`}
                target="_blank"
                rel="noreferrer"
              >
                {dependentId}
              </a>
            ))
          ) : (
            (values || []).map((i, idx) => {
              switch (type) {
                case 'INPUT': {
                  return (
                    <div className="">
                      <h3 className="text-lg font-semibold mb-2">User Input:</h3>
                      <p className="px-2 w-1/2 border border-gray-200 text-lg mb-2">
                        {i}
                      </p>
                      {
                    (reviews[idx] && reviews[idx].date) && (
                      <p className="text-lg font-semibold mb-2">
                        {reviewToString(reviews[idx])}
                      </p>
                    )
                  }
                    </div>
                  );
                }
                case 'DROP_DOWN': {
                  return (
                    <div className="">
                      <h3 className="text-lg font-semibold mb-2">User Selection:</h3>
                      <p className="px-2 w-1/2 border border-gray-200 text-lg mb-2">
                        {i}
                      </p>
                      {
                    (reviews[idx] && reviews[idx].date) && (
                      <p className="text-lg font-semibold mb-2">
                        {reviewToString(reviews[idx])}
                      </p>
                    )
                  }
                    </div>
                  );
                }
                case 'LONG_INPUT': {
                  return (
                    <div className="">
                      <h3 className="text-lg font-semibold mb-2">The user wrote the following:</h3>
                      <p className="px-2 w-full border border-gray-200 text-lg mb-2">
                        {i}
                      </p>
                      {
                    (reviews[idx] && reviews[idx].date) && (
                      <p className="text-lg font-semibold mb-2">
                        {reviewToString(reviews[idx])}
                      </p>
                    )
                  }
                    </div>
                  );
                }
                case 'UPLOAD': {
                  return (
                    <div className="">
                      {
                    getObjectFromUrl(`https://cdn.filestackcontent.com/${i}#view=FitH`)
                  }
                      {
                    (reviews[idx] && reviews[idx].date) && (
                      <p className="text-lg font-semibold mb-2">
                        {reviewToString(reviews[idx])}
                      </p>
                    )
                  }
                    </div>
                  );
                }
                case 'CHECK': {
                  return (
                    <div className="">
                      <p className="px-2 w-full border border-gray-200 text-lg mb-2">
                        The investor checked the box indicating their affirmation that the
                        above question is true.
                      </p>
                      {
                    (reviews[idx] && reviews[idx].date) && (
                      <p className="text-lg font-semibold mb-2">
                        {reviewToString(reviews[idx])}
                      </p>
                    )
                  }
                    </div>
                  );
                }
                case 'ISSUER_CONVERSION': {
                  return (
                    <div className="">
                      <h3 className="text-lg font-semibold mb-2">Accredited Entity Owner:</h3>
                      <a
                        className="px-2 w-1/2 font-semibold border border-gray-200 text-lg mb-2 text-blue-800 hover:underline"
                        href={`/lookup/${encodeURIComponent(i)}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {i}
                      </a>
                    </div>
                  );
                }
                case 'NOTES_TO_REVIEWER': {
                  return (
                    <div className="">
                      <h3 className="text-lg font-semibold mb-2">User Notes:</h3>
                      <p className="px-2 w-full border border-gray-200 text-lg mb-2">
                        {i}
                      </p>
                      {
                    (reviews[idx] && reviews[idx].date) && (
                      <p className="text-lg font-semibold mb-2">
                        {reviewToString(reviews[idx])}
                      </p>
                    )
                  }
                    </div>
                  );
                }
                default: return <div>Not supported yet</div>;
              }
            })

          )
      }
      </div>
    );
  };

  const uploadInfo = (
    <div className="flex flex-col pt-10">
      <h2 className={HEADER}>Upload Data:</h2>
      { (verificationEvidence || []).map(evidenceToObject) }
    </div>
  );

  const {
    code,
    type,
    amount,
  } = (discountCodeApplied || {});
  const checkoutInfo = (
    <div className="flex flex-col pt-10">
      <h2 className={HEADER}>Checkout Data:</h2>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Payment Date:</strong>
        <p className={CONTENT}>{ paymentDate }</p>
      </div>
      <div className="flex flex-row pl-8 mb-2">
        <strong className={LABEL}>Discount Code:</strong>
        <p className={CONTENT}>
          {
            code ? `${code} (${amount}, ${type})` : ''
          }
        </p>
      </div>
    </div>
  );

  const handleDownload = async (e, idx) => {
    e.preventDefault();
    // eslint-disable-next-line no-underscore-dangle
    const b = await downloadPdf(token, viewUser._id, idx);
    FileDownload(b, 'accreditation.pdf');
  };
  const mapAccreditationToObject = (acc, idx) => {
    const {
      _id: accreditationId,
      dateCreated,
      expirationDate,
      verificationMethod: accreditationMethod,
    } = acc;

    return (
      <div className="flex flex-col pl-8 mb-2">
        <strong className={LABEL_LONG}>{`${accreditationId} (${accreditationMethod}):`}</strong>
        <p className={`${CONTENT} ml-5`}>
          {`Issued on ${
            (new Date(dateCreated)).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
          }, expires on ${
            new Date(expirationDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
          }. `}
          <span
            className="text-blue-800 font-bold hover:text-orange-500 hover:underline"
            role="button"
            onClick={(e) => handleDownload(e, idx)}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            Download.
          </span>
        </p>
      </div>
    );
  };
  const accreditationInfo = (
    <div className="flex flex-col pt-10">
      <h2 className={HEADER}>Accreditations:</h2>
      { (accreditations || []).map(mapAccreditationToObject) }
    </div>
  );

  const mapFailureToObject = (acc) => {
    const {
      reason,
      date: failureDate,
    } = acc;

    return (
      <div className="flex flex-col pl-8 mb-2">
        <strong className={LABEL_LONG}>
          {`Failed on ${
            (new Date(failureDate)).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
          } with note:`}

        </strong>
        <p className={`${CONTENT} ml-5`}>{reason}</p>
      </div>
    );
  };
  const failureInfo = (
    <div className="flex flex-col pt-10">
      <h2 className={HEADER}>Failures:</h2>
      { (failures || []).map(mapFailureToObject) }
    </div>

  );

  return (
    <div className="container-fluid flex flex-row w-screen justify-center flex-wrap">
      <h1 className="text-2xl font-bold mt-10 mb-2 w-full xl:w-10/12 text-center">
        User with internal ID:
        {' '}
        { userId }
        :
      </h1>
      <div className="border border-gray-100 w-10/12 text-left flex flex-col p-4 gap-10 divide-y">
        { overviewContent }
        { isPlatformUser && platformInfo }
        { surveyInfo }
        { uploadInfo }
        { checkoutInfo }
        { accreditationInfo }
        { failureInfo }
      </div>
    </div>
  );
};

export default UserView;
