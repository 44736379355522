import React, { useEffect, useContext, useState } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';

import AppContext from '../../AppContext';

import { lookupUser } from '../../api/admin';

const UserLookup = function () {
  const { email } = useParams();

  const [{ token, user }, dispatch] = useContext(AppContext);
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);

  // Fetch the pending verifications from the DB
  useEffect(async () => {
    // Search the DB for users
    const { error: newError, users: newUsers } = await lookupUser(token, email);
    if (error) {
      setError(error);
    } else {
      setUsers(newUsers);
    }
  }, []);

  if (!token || !user) {
    return (
      <Navigate to="/" />
    );
  }
  if (user.userType !== 'ADMIN') {
    return (
      <Navigate to="/" />
    );
  }
  console.log(users);

  return (
    <div className="container-fluid flex flex-row w-screen justify-center flex-wrap">
      <h1 className="text-2xl font-bold mt-10 mb-2 w-full xl:w-10/12 text-center">
        Users matching search
        {' '}
        { email }
        :
      </h1>
      <pre className="py-4">
        { JSON.stringify(users, null, 2) }
      </pre>
    </div>
  );
};

export default UserLookup;
