import React from 'react';
import { Link } from 'react-router-dom';

import check from '../../assets/check_secure.svg';

const Hero = function () {
  return (
    <div
      className="min-h-screen py-24 text-center"
      style={{ background: 'linear-gradient(90deg, #667eea 0%, #764ba2 100%)' }}
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-4 text-white">
          Check Investor Status
        </h2>
        <h3 className="text-2xl mb-12 text-gray-200">
          Accreditation for investors that&apos;s quick, easy, and secure.
        </h3>
        <div className="w-3/4 lg:w-1/2 mb-4 bg-white rounded-xl shadow mx-auto py-10 px-5">
          <h3 className="text-xl font-bold mb-16 text-gray-800 mx-auto">
            We take care of accreditation so you can focus on investing.
          </h3>
          <div className="w-full md:w-1/2 mx-auto mb-8">
            <img src={check} alt="Monitoring" />
          </div>
          <h3 className="text-xl mb-8 text-gray-800 w-3/4 mx-auto">
            We&apos;re SEC-compliant and issue accreditation certificates in 1-2 business days.
          </h3>
          {/* <h3 className="text-lg mx-auto text-center font-bold mb-4">Pricing</h3> */}
          <div className="container w-3/4 flex flex-row divide-x mx-auto justify-center mb-8">
            <div className="w-1/2 text-center pt-2">
              <h4 className="font-semibold underline">
                Self-Accreditation
              </h4>
              <div className="text-4xl text-gray-700 font-semibold mx-auto my-8">
                $50
                <span className="text-sm">/ea</span>
              </div>

            </div>
            <div className="w-1/2 text-center pt-2">
              <h4 className="font-semibold underline">
                Multiple Accreditations
              </h4>
              <div className="text-4xl text-gray-700 font-semibold mx-auto my-8">
                $45
                <span className="text-sm">/ea</span>
              </div>
            </div>
          </div>

          <Link
            to="/signup"
            className="bg-indigo-500 hover:bg-indigo-600 font-bold text-white rounded-full py-4 px-8 shadow-lg uppercase tracking-wider"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
