import React, { useState, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { MdEdit } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { IoCloseSharp } from 'react-icons/io5';

import { B_COLORS, DISABLED_B_COLORS } from '../../AppStyles';
import 'react-datepicker/dist/react-datepicker.css';

// import propTypes from 'prop-types';
import AppContext from '../../AppContext';
import {
  fetchDiscountCodes,
  setDiscountCode,
} from '../../api/admin';

const DiscountCodes = function () {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [discountCodes, setDiscountCodes] = useState([]);

  const [modalIdx, setModalIdx] = useState(-1);

  // Current Code State
  const [curCode, setCurCode] = useState('EXAMPLE_CODE');
  const [curAmount, setCurAmount] = useState(10);
  const [curLimit, setCurLimit] = useState(1);
  const [curType, setCurType] = useState('PERCENT');
  const [curExpiration, setCurExpiration] = useState(new Date());
  const [reload, setReload] = useState(0);

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Load in the user from the API
  useEffect(async () => {
    setLoading(true);

    const { error: newError, allCodes: newDiscountCodes } = await fetchDiscountCodes(token);

    // Save the token in localstorage and in the app state
    if (newError) {
      setError(newError);
    }

    // Set not loading anymore
    setDiscountCodes(newDiscountCodes);
    setLoading(false);
  }, [reload]);

  // Redirect once we log the user in
  if (!token && !user) {
    return <Navigate to="/" />;
  }
  // Redirect once we log the user in
  if (user.userType !== 'ADMIN') {
    return <Navigate to="/" />;
  }

  const handleClickToEdit = (e, idx) => {
    e.preventDefault();
    // Set the values to the "Cur" values
    const {
      code,
      amount,
      limit,
      type,
      expiration,
    } = discountCodes[idx];
    setCurCode(code);
    setCurAmount(amount);
    setCurLimit(limit);
    setCurType(type);
    setCurExpiration(new Date(expiration || null));
    setModalIdx(idx);
  };

  const handleUpdateDiscount = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error: newError, discountCode } = await setDiscountCode(token, {
      code: curCode,
      amount: curAmount,
      limit: curLimit,
      type: curType,
      expiration: curExpiration,
    });

    setLoading(false);
    setModalIdx(-1);
    if (newError) {
      console.log(newError);
      setError(newError);
    } else {
      console.log('Found a discount code');
      console.log(JSON.stringify(discountCode, null, 2));
      // Trigger a reload
      setReload(reload + 1);
    }
  };

  const handleAddNew = async (e) => {
    e.preventDefault();

    const now = new Date();
    // Create a new default code
    setCurCode('CODE_NAME');
    setCurAmount(10);
    setCurLimit(1);
    setCurType('PERCENT');
    setCurExpiration(now);

    // Add it to list of codes (current)
    const newIdx = discountCodes.length + 1;
    setDiscountCodes(discountCodes.concat([{
      code: 'CODE_NAME',
      amount: 10,
      limit: 1,
      type: 'PERCENT',
      expiration: now,
    }]));
    setModalIdx(newIdx);
  };

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Something went wrong ...
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        Could not get discount codes from the database. Call Carter.
      </h2>
    </div>
  );

  const modal = (
    <div className="justify-center border border-orange-500 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-1/2 my-auto mx-auto h-1/2">
        <div className="flex flex-col border-0 rounded-lg shadow-lg relative w-full h-full bg-white">
          <div className="flex flex-row">
            <h1 className="font-semibold text-lg pl-5 my-4 mx-auto">Discount Code Editor</h1>
            <IoCloseSharp
              size={25}
              className="my-4 mx-5 hover:outline hover:outline-blue-500"
              onClick={() => {
                setModalIdx(-1);
                setReload(reload + 1);
              }}
            />
          </div>
          <div className="flex flex-row px-5 w-full mt-8">
            <div className="font-sm font-semibold w-3/12">Code:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="code-name-in"
              value={curCode}
              onChange={(e) => (setCurCode(e.target.value))}
            />
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Amount:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="amount-in"
              value={curAmount}
              onChange={(e) => setCurAmount(e.target.value)}
            />
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Limit:</div>
            <input
              className="border mb-5 px-1 w-9/12"
              type="text"
              id="limit-in"
              value={curLimit}
              onChange={(e) => setCurLimit(e.target.value)}
            />
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Discount Type:</div>
            <select
              className="border w-9/12 mb-5 px-1"
              id="type-select"
              name="type-select"
              onChange={(e) => setCurType(e.target.value)}
            >
              <option value="PERCENT" selected={curType === 'PERCENT'}>
                Percent
              </option>
              <option value="DOLLAR_AMOUNT" selected={curType === 'DOLLAR_AMOUNT'}>
                Dollar Amount
              </option>
              <option value="TOTAL" selected={curType === 'TOTAL'}>
                Total Discount
              </option>
            </select>
          </div>
          <div className="flex flex-row px-5 w-full">
            <div className="font-sm font-semibold w-3/12">Expiration Date:</div>
            <div className="border rounded-sm px-2">
              <DatePicker
                selected={curExpiration}
                onChange={
                  (date) => {
                    setCurExpiration(new Date(date));
                  }
                }
              />
            </div>
          </div>
          <div
            className={`w-1/3 rounded-xl font-semibold my-10 mx-auto text-center text-white py-3 ${B_COLORS}`}
            role="button"
            onClick={handleUpdateDiscount}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            Create or Update
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`container-fluid min-h-screen ${modalIdx >= 0 && 'bg-black/25'}`}>
      { error && errorScreen }
      { modalIdx >= 0 && modal }
      { !loading && !error && (
        <div className={`border border-gray-200 rounded-xl shadow-md w-10/12 xl:w-8/12 mx-auto my-10 py-16 px-20 ${modalIdx >= 0 && 'blur-lg'}`}>
          <div className="flex flex-row">
            <h1 className="text-2xl underline font-bold mb-10 text-center mx-auto">
              Discount Codes:
            </h1>
            <div
              className="border border-black font-semibold mb-10 p-1 rounded-xl text-xl hover:bg-gray-100"
              onClick={handleAddNew}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              Add New Code
            </div>

          </div>
          <div className="flex flex-col divide-y gap-5">
            <div className="flex flex-row mt-5">
              <div className="w-5/12 font-md font-semibold">
                Code
              </div>
              <div className="w-2/12 font-md font-semibold">
                Type
              </div>
              <div className="w-1/12 font-md font-semibold">
                Amount
              </div>
              <div className="w-1/12 font-md font-semibold">
                Limit
              </div>
              <div className="w-2/12 font-md font-semibold">
                Expiration
              </div>
              <div className="w-1/12 font-md font-semibold">
                Edit
              </div>
            </div>
            {
              discountCodes.map((c, idx) => {
                const {
                  code,
                  type,
                  amount,
                  limit,
                  expiration,
                } = c;

                return (
                  <div className="flex flex-row pt-5">
                    <div className="w-5/12 font-sm font-semibold">
                      { code }
                    </div>
                    <div className="w-2/12 font-sm">
                      { type }
                    </div>
                    <div className="w-1/12 font-sm">
                      { amount }
                    </div>
                    <div className="w-1/12 font-sm">
                      { limit }
                    </div>
                    <div className="w-2/12 font-sm">
                      { (new Date(expiration)).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) }
                    </div>
                    <div className="w-1/12 font-sm">
                      <MdEdit
                        size={20}
                        className="hover:outline hover:outline-blue-500"
                        onClick={(e) => handleClickToEdit(e, idx)}
                      />
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      ) }
    </div>
  );
};

export default DiscountCodes;
