import { PREFIX } from './prefix';

async function resendConfirmation(token) {
  return fetch(`${PREFIX}/api/resend-confirmation`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function confirmEmail(confirmationCode) {
  return fetch(`${PREFIX}/api/confirm-email`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ confirmationCode }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  resendConfirmation,
  confirmEmail,
};
