import React from 'react';
import { Link } from 'react-router-dom';

const CTA = function () {
  return (
    <div style={{ 'background-color': '#667eea' }} className="w-full container-fluid px-6 justify-center text-center py-20">
      <h2 className="mb-6 text-4xl font-bold text-center text-white">
        Get Accredited
      </h2>
      <h3 className="my-4 text-xl text-white px-1 w-full lg:w-1/2 mx-auto mb-8">
        Whether you&apos;re an issuer or an investor, we can help with all of your accreditation
        needs. The process is quick and easy &mdash; and we&apos;re here to help every
        step of the way.
      </h3>
      <h3 className="my-4 text-xl text-white px-1 w-full lg:w-1/2 mx-auto mb-16">
        Click below to start getting yourself or your investors accredited today.
      </h3>
      <Link
        className="bg-white font-bold rounded-full mt-6 py-4 px-8 shadow-lg uppercase tracking-wider hover:shadow-2xl"
        to="/signup"
      >
        Get Started
      </Link>
    </div>
  );
};

export default CTA;
