import _ from 'lodash';
/*
 * Functions for working with evidence
 */
// Enum of all crumb values
const CRUMBS = {
  NAME: 'NAME', // legalName
  ENTITY_TYPE: 'ENTITY_TYPE', // investorType
  INFO: 'INFO', // verificationMethod
  // Uploads
  'DOCUMENTATION.INCOME': 'DOCUMENTATION.INCOME',
  'DOCUMENTATION.CREDIT_REPORT': 'DOCUMENTATION.CREDIT_REPORT',
  'DOCUMENTATION.ASSETS': 'DOCUMENTATION.ASSETS',
  'DOCUMENTATION.WILDCARD': 'DOCUMENTATION.WILDCARD',
  'DOCUMENTATION.JOINT_INCOME': 'DOCUMENTATION.JOINT_INCOME',
  'DOCUMENTATION.JOINT_CREDIT_REPORT': 'DOCUMENTATION.JOINT_CREDIT_REPORT',
  'DOCUMENTATION.JOINT_ASSETS': 'DOCUMENTATION.JOINT_ASSETS',
  'DOCUMENTATION.EBP_FINANCES': 'DOCUMENTATION.EBP_FINANCES',
  'DOCUMENTATION.FIDUCIARY': 'DOCUMENTATION.FIDUCIARY',
  'DOCUMENTATION.EBP_OFFICERS': 'DOCUMENTATION.EBP_OFFICERS',
  'DOCUMENTATION.ENTITY_FINANCES': 'DOCUMENTATION.ENTITY_FINANCES',
  'DOCUMENTATION.PROOF_OF_OWNERS': 'DOCUMENTATION.PROOF_OF_OWNERS',
  'DOCUMENTATION.STATUS_EVIDENCE': 'DOCUMENTATION.STATUS_EVIDENCE',
  'DOCUMENTATION.SPECIAL_EVIDENCE': 'DOCUMENTATION.SPECIAL_EVIDENCE',
  'DOCUMENTATION.TRUST_FINANCES': 'DOCUMENTATION.TRUST_FINANCES',
  'DOCUMENTATION.BUSINESS_DIRECTOR': 'DOCUMENTATION.BUSINESS_DIRECTOR',
  'DOCUMENTATION.TRUST_DOCS': 'DOCUMENTATION.TRUST_DOCS',

  // Confirmations
  'CONFIRMATION.INCOME': 'CONFIRMATION.INCOME',
  'CONFIRMATION.PROFESSIONAL_CERT': 'CONFIRMATION.PROFESSIONAL_CERT',
  'CONFIRMATION.NO_OTHER_LIABILITIES': 'CONFIRMATION.NO_OTHER_LIABILITIES',
  'CONFIRMATION.JOINT_INCOME': 'CONFIRMATION.JOINT_INCOME',
  'CONFIRMATION.JOINT_NO_OTHER_LIABILITIES': 'CONFIRMATION.JOINT_NO_OTHER_LIABILITIES',
  'CONFIRMATION.EBP': 'CONFIRMATION.EBP',
  'CONFIRMATION.ENTITY': 'CONFIRMATION.ENTITY',
  'CONFIRMATION.TRUST': 'CONFIRMATION.TRUST',

  // Selections
  'SELECTION.STATUS': 'SELECTION.STATUS',
  'SELECTION.SPECIAL': 'SELECTION.SPECIAL',

  // Long Entries
  WILDCARD: 'WILDCARD',
  PERSONAL_RESIDENCE: 'PERSONAL_RESIDENCE', // Joint or individual
  PROFESSIONAL_CERT: 'PROFESSIONAL_CERT',

  // Equity Owners
  'EQUITY_OWNERS.EBP': 'EQUITY_OWNERS.EBP',
  'EQUITY_OWNERS.ENTITY': 'EQUITY_OWNERS.ENTITY',
  'EQUITY_OWNERS.TRUST': 'EQUITY_OWNERS.TRUST',

  NOTES: 'NOTES', // Notes for reviewer
  REVIEW: 'REVIEW', // Review and submit
};

// Bread crumb to the relevant evidenceId (server-friendly)
const crumbToEvidenceId = (user, crumb) => {
  console.log('in crumbToEvidenceId');
  console.log(crumb);
  switch (crumb) {
    case 'DOCUMENTATION.INCOME':
      return 'INCOME_UPLOAD';
    case 'DOCUMENTATION.CREDIT_REPORT':
      return 'NET_WORTH_CREDIT_REPORT';
    case 'DOCUMENTATION.ASSETS':
      return 'NET_WORTH_ASSETS';
    case 'DOCUMENTATION.JOINT_INCOME':
      return 'JOINT_INCOME_UPLOAD';
    case 'DOCUMENTATION.JOINT_CREDIT_REPORT':
      return 'JOINT_NET_WORTH_CREDIT_REPORT';
    case 'DOCUMENTATION.JOINT_ASSETS':
      return 'JOINT_NET_WORTH_ASSETS';
    case 'DOCUMENTATION.EBP_FINANCES':
      return 'EBP_NET_WORTH_FINANCIAL_STATEMENTS';
    case 'DOCUMENTATION.FIDUCIARY':
      return 'ENTITY_FIDUCIARY_EBP_FIDUCIARY_EVIDENCE';
    case 'DOCUMENTATION.EBP_OFFICERS':
      return 'ENTITY_SELF_DIRECTED_EBP_OFFICERS_CERTIFICATE';
    case 'DOCUMENTATION.PROOF_OF_OWNERS':
      return 'ENTITY_ACCREDITED_INDIVIDUALS_LISTING_OF_EQUITY_OWNERS';
    case 'DOCUMENTATION.STATUS_EVIDENCE':
      return 'ENTITY_STATUS_EVIDENCE';
    case 'DOCUMENTATION.SPECIAL_EVIDENCE':
      return 'ENTITY_SPECIAL_EVIDENCE';
    case 'DOCUMENTATION.TRUST_FINANCES':
      return 'TRUST_NET_WORTH_FINANCIAL_STATEMENTS';
    case 'DOCUMENTATION.BUSINESS_DIRECTOR':
      return 'TRUST_NET_WORTH_SOPHISTICATION_EVIDENCE';
    case 'DOCUMENTATION.TRUST_DOCS':
      return 'TRUST_ACCREDITED_INDIVIDUALS_EQUITY_OWNERSHIP_EVIDENCE';
    case 'DOCUMENTATION.WILDCARD': {
      const { investorType } = user;
      if (investorType === 'INDIVIDUAL') {
        return 'NONE_OF_THE_ABOVE_UPLOAD';
      } if (investorType === 'JOINT') {
        return 'JOINT_NONE_OF_THE_ABOVE_UPLOAD';
      } if (investorType === 'ENTITY') {
        return 'ENTITY_NONE_OF_THE_ABOVE_UPLOAD';
      }
      return 'TRUST_NONE_OF_THE_ABOVE_UPLOAD';
    }
    case 'DOCUMENTATION.ENTITY_FINANCES': {
      console.log(user.verificationMethod);
      const { verificationMethod } = user;
      if (verificationMethod === 'ENTITY_NET_WORTH') {
        console.log('returning it');
        return 'ENTITY_NET_WORTH_FINANCIAL_STATEMENTS';
      }
      // Must be special
      return 'ENTITY_SPECIAL_FINANCIAL_STATEMENTS';
    }

    // Confirmation evidenceMappings
    case 'CONFIRMATION.INCOME':
      return 'INCOME_CHECK';
    case 'CONFIRMATION.JOINT_INCOME':
      return 'JOINT_INCOME_CHECK';
    case 'CONFIRMATION.PROFESSIONAL_CERT':
      return 'FINANCE_PROFESSIONAL_CONFIRMATION';
    case 'CONFIRMATION.NO_OTHER_LIABILITIES':
      return 'CONFIRMATION_NO_OTHER_LIABILITIES';
    case 'CONFIRMATION.JOINT_NO_OTHER_LIABILITIES':
      return 'JOINT_CONFIRMATION_NO_OTHER_LIABILITIES';
    case 'CONFIRMATION.EBP':
      return 'EBP_ATTEST';
    case 'CONFIRMATION.ENTITY':
      return 'ENTITY_ATTEST';
    case 'CONFIRMATION.TRUST':
      return 'TRUST_ATTEST';

    // Selections
    case 'SELECTION.STATUS':
      return 'ENTITY_STATUS_SELECTION';
    case 'SELECTION.SPECIAL':
      return 'ENTITY_SPECIAL_SELECTION';

    // Equity Owners
    case 'EQUITY_OWNERS.EBP':
      return 'ENTITY_SELF_DIRECTED_EBP_INDIVIDUAL_ACCREDITATIONS';
    case 'EQUITY_OWNERS.ENTITY':
      return 'ENTITY_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS';
    case 'EQUITY_OWNERS.TRUST':
      return 'TRUST_ACCREDITED_INDIVIDUALS_INDIVIDUAL_ACCREDITATIONS';

    // Special Case Pages
    case 'WILDCARD':
      return 'NONE_OF_THE_ABOVE_EXPLANATION';

    case 'PERSONAL_RESIDENCE':
      return ''; // This is multiple evidences handled one-off

    case 'PROFESSIONAL_CERT':
      return ''; // This is multiple evidences handled one-off

    // NOTES
    case 'NOTES':
      return 'NOTES_TO_REVIEWER';

    // Review and submit
    case CRUMBS.REVIEW:
      return '';

    default:
      return null;
  }
};

const getCurrentReview = (user, crumb) => {
  if (!crumb) {
    return [];
  }
  if (!user || !(user.userStage === 'MORE_INFO')) {
    return [];
  }
  const components = crumb.split('.');
  const crumbRoot = components[0];

  switch (crumbRoot) {
    case CRUMBS.PERSONAL_RESIDENCE: { // A bunch of info about primary residence
      // Find the relevant evidence objects from the API
      const valueMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'NET_WORTH_ESTIMATED_RESIDENCE_VAL');
      const debtMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'NET_WORTH_ESTIMATED_RESIDENCE_DEBT');
      const recencyMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'NET_WORTH_RESIDENCE_DEBT_RECENCY');

      return [valueMatches, debtMatches, recencyMatches].reduce((acc, matches) => {
        if (matches.length) {
          const { reviews } = matches[0];
          if (reviews.length) {
            acc.push(reviews[reviews.length - 1]);
          }
        }
        return acc;
      }, []).filter((r) => r);
    }

    case CRUMBS.PROFESSIONAL_CERT: { // Firm name and CRD number
      // Find the relevant evidence objects from the API
      const firmMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'FINANCE_PROFESSIONAL_FIRM_NAME');
      const crdMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'FINANCE_PROFESSIONAL_CRD');

      return [firmMatches, crdMatches].reduce((acc, matches) => {
        if (matches.length) {
          const { reviews } = matches[0];
          if (reviews.length) {
            acc.push(reviews[reviews.length - 1]);
          }
        }
        return acc;
      }, []).filter((r) => r);
    }

    default: {
      // The standard case
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);

      // Non-evidence crumbs have no evidence Id
      if (!evidenceId) {
        return [];
      }

      // Find matching evidence
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Find most recent review and return it as an array
      if (matches.length) {
        const { reviews } = matches[0];
        if (reviews.length) {
          return [reviews[reviews.length - 1]];
        }
      }
      // If nothing, return an empty array
      return [];
    }
  }
};

const getCurrentVal = (user, crumb) => {
  console.log('crumb: ', crumb);
  if (!crumb) {
    return null;
  }
  const components = crumb.split('.');
  const crumbRoot = components[0];
  const variant = components.length ? components[1] : '';

  switch (crumbRoot) {
    case CRUMBS.NAME: {
      return user.legalName;
    }
    case CRUMBS.ENTITY_TYPE: {
      return user.investorType;
    }
    case CRUMBS.INFO: {
      return user.verificationMethod;
    }
    case 'DOCUMENTATION': { // Uploaded documentation
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Look through each key in the evidence, and map it to the relevant upload
      if (matches.length) {
        const keys = matches[0].values;
        const uploads = user.uploads.filter(({ key }) => keys.includes(key));
        return uploads;
      }
      return [];
    }
    case 'CONFIRMATION': { // A check box
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Look through each key in the evidence, and map it to the relevant upload
      if (matches.length) {
        const strVal = (matches[0].values || [''])[0];
        return strVal === 'true';
      }
      return false;
    }
    case 'SELECTION': { // A drop-down selection
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Look through each key in the evidence, and map it to the relevant upload
      if (matches.length) {
        return (matches[0].values || [''])[0];
      }
      return '';
    }
    case 'EQUITY_OWNERS': { // A drop-down selection
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Look through each key in the evidence, and map it to the relevant upload
      if (matches.length) {
        return (matches[0].dependentInputs || [[]]);
      }
      return [];
    }

    case CRUMBS.WILDCARD: { // Required long-entry
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Look through each key in the evidence, and map it to the relevant upload
      if (matches.length) {
        return (matches[0].values || [''])[0];
      }
      return '';
    }

    case CRUMBS.PERSONAL_RESIDENCE: { // A bunch of info about primary residence
      // Find the relevant evidence objects from the API
      const valueMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'NET_WORTH_ESTIMATED_RESIDENCE_VAL');
      const debtMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'NET_WORTH_ESTIMATED_RESIDENCE_DEBT');
      const recencyMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'NET_WORTH_RESIDENCE_DEBT_RECENCY');

      const value = (valueMatches.length && (valueMatches[0].values || [''])[0]) || '';
      const debt = (debtMatches.length && (debtMatches[0].values || [''])[0]) || '';
      const recency = (recencyMatches.length && (recencyMatches[0].values || [''])[0]) || '';

      return [value, debt, recency];
    }

    case CRUMBS.PROFESSIONAL_CERT: { // Firm name and CRD number
      // Find the relevant evidence objects from the API
      const firmMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'FINANCE_PROFESSIONAL_FIRM_NAME');
      const crdMatches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === 'FINANCE_PROFESSIONAL_CRD');

      const firm = (firmMatches.length && (firmMatches[0].values || [''])[0]) || '';
      const crd = (crdMatches.length && (crdMatches[0].values || [''])[0]) || '';

      return [firm, crd];
    }

    case CRUMBS.NOTES: { // An optional text string with notes
      // Find the relevant evidence object
      const evidenceId = crumbToEvidenceId(user, crumb);
      const matches = user.verificationEvidence
        .filter(({ verificationId }) => verificationId === evidenceId);

      // Look through each key in the evidence, and map it to the relevant upload
      if (matches.length) {
        return (matches[0].values || [''])[0];
      }
      return '';
    }
    case CRUMBS.REVIEW: { // Always good to go
      return true;
    }
    default: {
      return null;
    }
  }
};

// Check based on the the updateVal and the current state
// of the user if we can continue
const crumbIsDone = (user, crumb, updateVal) => {
  if (!crumb) {
    return false;
  }
  let curVal;
  // Do we want to check with a prospective value?
  if (typeof updateVal !== 'undefined') {
    curVal = updateVal;
  } else {
    // If not use the saved one, not the new one
    curVal = getCurrentVal(user, crumb);
  }

  const components = crumb.split('.');
  const crumbRoot = components[0];
  const variant = components.length ? components[1] : '';

  switch (crumbRoot) {
    case CRUMBS.NAME: {
      return !!(curVal);
    }
    case CRUMBS.ENTITY_TYPE: {
      return !!(curVal);
    }
    case CRUMBS.INFO: {
      return !!(curVal);
    }
    case 'DOCUMENTATION': { // Uploaded documentation
      return !!(curVal && curVal.length);
    }
    case 'CONFIRMATION': { // Checkbox
      return !!(curVal);
    }
    case 'SELECTION': { // Drop-Down
      return curVal && curVal.length > 0;
    }
    case 'EQUITY_OWNERS': { // A listing of equity owner information
      return curVal && curVal.length > 0;
    }
    case CRUMBS.WILDCARD: { // Required Long Form
      return curVal && curVal.length > 0;
    }
    case CRUMBS.PERSONAL_RESIDENCE: { // Required Long Form
      if (curVal && curVal.length === 3) {
        return curVal.reduce((acc, cur) => acc && cur.length > 0, true);
      }
      return false;
    }
    case CRUMBS.PROFESSIONAL_CERT: { // Firm name and CRD number
      if (curVal && curVal.length === 2) {
        return curVal.reduce((acc, cur) => acc && cur.length > 0, true);
      }
      return false;
    }
    case CRUMBS.NOTES: { // Reviewer Notes
      return true;
    }
    case CRUMBS.REVIEW: { // Always done with review
      return false;
    }
    default: {
      return false;
    }
  }
};

// Check based on the the updateVal and the current state
// of the user if the update represents a change from the
// current value
const checkUpdateIsNoop = (user, crumb, updateVal) => {
  if (!crumb) {
    return false;
  }
  // Get the "Current" or API-value
  const curVal = getCurrentVal(user, crumb);

  const components = crumb.split('.');
  const crumbRoot = components[0];
  const variant = components.length ? components[1] : '';

  switch (crumbRoot) {
    case CRUMBS.NAME: {
      return curVal === updateVal;
    }
    case CRUMBS.ENTITY_TYPE: {
      return curVal === updateVal;
    }
    case CRUMBS.INFO: {
      return curVal === updateVal;
    }
    case 'DOCUMENTATION': { // Uploaded documentation
      // Compare the list of uploads
      const curKeys = curVal.map(({ key }) => key);
      const updateKeys = updateVal.map(({ key }) => key);

      return (
        _.difference(curKeys, updateKeys).length === 0
        && _.difference(updateKeys, curKeys).length === 0
      );
    }
    case 'CONFIRMATION': { // Check Box
      // Compare the list of uploads
      return (curVal && updateVal);
    }
    case 'SELECTION': { // Drop-down values
      return (curVal === updateVal);
    }
    case CRUMBS.WILDCARD: { // Required Long Form
      return (curVal === updateVal);
    }
    case CRUMBS.PERSONAL_RESIDENCE: { // Info about personal residence
      if (curVal.length !== updateVal.length) {
        return false;
      }
      return curVal.reduce((acc, cur, idx) => {
        const comp = updateVal[idx];
        return acc && cur === comp;
      }, true);
    }
    case CRUMBS.PROFESSIONAL_CERT: { // Firm name and CRD number
      if (curVal.length !== updateVal.length) {
        return false;
      }
      return curVal.reduce((acc, cur, idx) => {
        const comp = updateVal[idx];
        return acc && cur === comp;
      }, true);
    }
    case 'EQUITY_OWNERS': { // A listing of equity owner information
      // TODO(Carter): this is a janky way to do this, but I think it works well enough
      return JSON.stringify(curVal || []) === JSON.stringify(updateVal || []);
    }
    case CRUMBS.NOTES: { // Reviewer notes
      return (curVal === updateVal);
    }
    case CRUMBS.REVIEW: { // Submission should never skip
      return false;
    }
    default: {
      return false;
    }
  }
};

export {
  CRUMBS,
  crumbToEvidenceId,
  getCurrentVal,
  getCurrentReview,
  crumbIsDone,
  checkUpdateIsNoop,
};
