import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';

import AppContext from '../AppContext';
import { B_COLORS, DISABLED_B_COLORS } from '../AppStyles';

import { checkResetCode, resetPassword } from '../api/resetPassword';

const ResetCode = function () {
  const { code } = useParams();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState('');

  const [password, setPassword] = useState();
  const [passwordVerification, setPasswordVerification] = useState();

  // Use Token State
  const [{ token, user }, dispatch] = useContext(AppContext);

  // Handle Submission of the form
  useEffect(async () => {
    const { error: newError } = await checkResetCode(code);

    // Save the token in localstorage and in the app state
    if (newError) {
      setError(newError);
    }
    // Set not loading anymore
    setLoading(false);
  }, []);

  // Redirect once we log the user in
  if (token && user) {
    return <Navigate to="/" />;
  }

  // Handle Submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const {
      error: newError,
      token: newToken,
      user: newUser,
    } = await resetPassword(code, password);

    // Save the token in localstorage and in the app state
    if (!error) {
      dispatch({ type: 'SET_TOKEN', token: newToken });
      dispatch({ type: 'SET_USER', user: newUser });
    } else {

      // TODO(carter): Handle second error state
    }
    setLoading(false);
  };

  const loadScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Confirming your password reset ...
      </h2>
    </div>
  );

  const errorScreen = (
    <div className="flex flex-col items-center w-screen py-48">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
        Something went wrong ...
      </h2>
      <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
        That password reset link is invalid or expired.
      </h2>
      <p className="text-xl font-bold">
        Try
        {' '}
        <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/reset-password">
          resending your password reset code
        </Link>
        , or
        {' '}
        <Link className="text-blue-800 font-semibold hover:text-orange-500 hover:underline" to="/contact">
          contact support
        </Link>
        {' '}
        if the issue persists.
      </p>
    </div>
  );

  const resetScreen = (
    <div className="flex flex-col w-2/3 lg:w-1/2 xl:w-5/12 mx-auto my-10 py-8 px-24 lg:px-36 2xl:px-48 border border-gray-200 rounded-xl shadow-lg">
      <h1 className="text-2xl pb-8 text-center">Choose a New Password</h1>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-between gap-10 w-full mb-5">
          <label className="flex-grow" htmlFor="password-in">
            <p className="text-sm font-bold">New Password</p>
            <input
              className="border border-gray-200 w-full px-1 mt-1"
              type="password"
              id="password-in"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div className="flex flex-row justify-between gap-10 w-full mb-8">
          <label className="flex-grow" htmlFor="password-verification-in">
            <p className="text-sm font-bold">Verify New Password</p>
            <input
              className="border border-gray-200 w-full px-1 mt-1"
              type="password"
              id="password-verification-in"
              onChange={(e) => setPasswordVerification(e.target.value)}
            />
          </label>
        </div>
        <div className="flex flex-row items-center justify-between mx-auto w-full mb-5">
          <div className="py-2 w-full">
            <button
              className={`py-1 w-full font-semibold rounded border ${B_COLORS}`}
              type="submit"
            >
              Set Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div className="container-fluid">
      { loading && loadScreen }
      { error && errorScreen }
      { !loading && !error && resetScreen }
    </div>
  );
};

export default ResetCode;
