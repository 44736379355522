import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';
import { requiredDocumentation, getVerificationOptions } from './content';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Which of these best describes your situation?
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-6">
    What you select will determine what documentation we require you to upload. Be sure
    to review the documentation requirements for your selection before confirming.
  </p>
);

const InfoPage = function (props) {
  const {
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, []);

  const [{ user, token }, dispatch] = useContext(AppContext);

  const clickOption = (e, newVal) => {
    e.preventDefault();
    setVal(newVal);
    onChange(newVal);
  };

  // Get qualification options based on user state.
  const options = getVerificationOptions(user.investorType);

  const optionSelects = options.map(({ title, description, id }, idx) => (
    <div
      className={`border cursor-pointer border-[#DCE1E7] rounded-[.3REM] w-full flex flex-row pt-3 pb-1 ${
        val === id && 'border-[#5963F4] bg-[#EFF0FE]'

      }`}
      onClick={(e) => clickOption(e, id)}
      onKeyDown={() => {}}
      tabIndex={idx}
      role="option"
      aria-selected={false}
    >
      {/* Radio Button */}
      <div
        className={(
          `flex flex-row items-center justify-around w-6 h-6 mx-4 rounded-full border-2  flex-shrink-0 ${
            val === id ? 'border-[#5963F4]' : 'border-[#A1A5AD]'}`
          )}
      >
        {
          val === id && (
            <div className="w-3 h-3 rounded-full mx-auto my-auto bg-[#5963F4] flex-shrink-0" />
          )
        }
      </div>
      {/* Title and description */}
      <div className="flex flex-col">
        <h3 className={`mb-2 font-regular ${val === id && 'font-bold'}`}>
          { title }
        </h3>
        { val === id && (
          <p className="">
            { description }
          </p>
        )}

      </div>
    </div>
  ));
  const optionSelector = (
    <div className="flex flex-col gap-4">
      { optionSelects }
    </div>
  );

  const infoCol = (
    val
      ? (
        <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
          <h1 className="font-bold mb-4">Required Documentation</h1>
          <p className="text-black mb-5">
            To verify your accreditation status, you will need to upload the following
            documentation:
          </p>
          <ul className="mx-3 gap-5 flex flex-col mb-5">
            {requiredDocumentation[val].map((text) => (
              <li className="flex flex-row">
                <div className="my-3 h-[3px] w-[3px] flex-shrink-0 rounded-full bg-black" />
                <div className="text-md ml-4">
                  {text}
                </div>
              </li>
            ))}
          </ul>
          <p className="text-black mb-4">
            You can optionally redact or black-out non-essential, sensitive information from
            uploaded documentation.
          </p>
          <p className="text-black mb-4">
            If you do not have all of this documentation, then you should select a different
            qualification.
          </p>
        </div>
      ) : (
        <div className="w-4/12 py-4 px-4" />
      )
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { optionSelector }
      </div>
      { infoCol }
    </div>
  );
};
InfoPage.propTypes = {
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
InfoPage.defaultProps = {
  passedVal: '',
};

export default InfoPage;
