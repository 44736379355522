import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { Navigate, Link } from 'react-router-dom';
import { B_COLORS } from '../../AppStyles';

const EvidenceReviewer = function ({
  evidence, requiredDocumentation, success, failure, idx, onSuccess, onFailure,
}) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState('');

  const {
    type,
    verificationId,
    values,
  } = evidence;

  const {
    description,
    reviewInstructions,
    reviewChecklist,
  } = requiredDocumentation;

  const done = success || failure;
  const evidenceDescription = (
    <div className="flex flex-col text-left mx-auto w-full pb-5 px-5">
      <h2 className="text-xl font-bold mt-2 mb-5">Evidence Description:</h2>
      <p className="text-lg mb-2">
        { description }
      </p>
    </div>
  );

  // the check list
  const checklist = (
    <ul className="mx-5 my-4">
      { (reviewChecklist).map((item) => (
        <li className="list-disc list-inside mb-3 font-semibold text-md">{ item }</li>
      ))}
    </ul>
  );

  const reviewingInstructions = (
    <div className="flex flex-col text-left mx-auto w-full pb-5 px-5">
      <h2 className="text-xl font-bold mt-2 mb-5">Legal Review Instructions:</h2>
      {
        reviewInstructions.map((i) => (
          <p className="text-lg mb-2">
            {i}
          </p>
        ))

      }
      <h2 className="text-lg font-bold mt-5">Please affirm each of the following requirements:</h2>
      { checklist }
    </div>
  );

  const getObjectFromUrl = (url) => {
    const req = new XMLHttpRequest();
    req.open('GET', url, false);
    req.send(null);
    const header = req.getResponseHeader('content-type').toLowerCase();
    if (header.includes('image')) {
      return (
        <img className="w-full h-[32rem] mb-10 object-contain" src={url} alt={url} />

      );
    }

    return (
      <object className="w-full h-[32rem] mb-10" data={`${url}#view=FitH`} type="application/pdf">
        <iframe className="w-full h-[32rem] mb-10" title={url} src={`https://docs.google.com/viewer?url=${url}&embedded=true`} />
      </object>
    );
  };
  const userUpload = (
    <div className="flex flex-col text-left mx-auto w-full pb-5 px-5 border border-indigo-600">
      <h2 className="text-xl font-bold mt-2 mb-5">User-Provided Evidence:</h2>
      {
        values.map((i) => {
          switch (type) {
            case 'INPUT': {
              return (
                <div className="">
                  <h3 className="text-lg font-semibold mb-2">The user input the following:</h3>
                  <p className="px-2 w-1/2 border border-gray-200 text-lg mb-2">
                    {i}
                  </p>
                </div>
              );
            }
            case 'DROP_DOWN': {
              return (
                <div className="">
                  <h3 className="text-lg font-semibold mb-2">The user selected the following option:</h3>
                  <p className="px-2 w-1/2 border border-gray-200 text-lg mb-2">
                    {i}
                  </p>
                </div>
              );
            }
            case 'LONG_INPUT': {
              return (
                <div className="">
                  <h3 className="text-lg font-semibold mb-2">The user wrote the following:</h3>
                  <p className="px-2 w-full border border-gray-200 text-lg mb-2">
                    {i}
                  </p>
                </div>
              );
            }
            case 'UPLOAD': {
              return (
                <div className="">
                  {
                    getObjectFromUrl(`https://cdn.filestackcontent.com/${i}#view=FitH`)
                  }
                </div>
              );
            }
            case 'CHECK': {
              return (
                <div className="">
                  <p className="px-2 w-full border border-gray-200 text-lg mb-2">
                    The investor checked the box indicating their affirmation that the
                    above question is true.
                  </p>
                </div>
              );
            }
            case 'ISSUER_CONVERSION': {
              return (
                <div className="">
                  <h3 className="text-lg font-semibold mb-2">Accredited Entity Owner:</h3>
                  <a
                    className="px-2 w-1/2 font-semibold border border-gray-200 text-lg mb-2 text-blue-800 hover:underline"
                    href={`/lookup/${encodeURIComponent(i)}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i}
                  </a>
                </div>
              );
            }
            case 'NOTES_TO_REVIEWER': {
              return (
                <div className="">
                  <h3 className="text-lg font-semibold mb-2">The user left the following notes for your review:</h3>
                  <p className="px-2 w-full border border-gray-200 text-lg mb-2">
                    {i}
                  </p>
                </div>
              );
            }
            default: return <div>Not supported yet</div>;
          }
        })
      }
    </div>
  );

  const confirm = (
    <div className="flex flex-col text-left mx-auto w-full my-5 pb-5 px-5">
      <h2 className="text-lg font-bold mt-5">Click &quot;Affirm&quot; if the evidence meets all of the following requirements:</h2>
      { checklist }
      <p className="text-lg mb-2">
        If the evidence does not meet all of the listed requirements,
        please click &quot;Reject&quot; below. If you reject a piece of
        documentation, please write a 1-2 sentence description of why
        the evidence was rejected and what the user must upload
        in its place to be verified.
      </p>
      <p className="text-lg mb-2">
        Note that any explanation provided will be shown directly to
        the user.
        {' '}
        <span className="font-bold">Please be complete and polite</span>
        .
      </p>

    </div>

  );

  const noteInputter = (
    <div className="flex flex-row flex-wrap ml-10">
      <p className="text-md">
        Add any notes you have about this piece of documentation. These will be saved for our
        records. Notes are only required to reject evidence as they are shown to the user
        so the user can reupload.
        You can also record any notes you&apos;d like to keep if you&apos;re affirming the
        evidence.
      </p>
      <textarea
        className="border my-2 w-11/12 h-[8rem] px-2 py-1"
        type="text"
        id="legal-name-in"
        placeholder="Your notes for the investor about what's wrong with their upload and how to fix it."
        onChange={(e) => setNote(e.target.value)}
      />
    </div>
  );

  const canReject = (!done && note);
  const canAccept = (!done);
  const rejectMaybeDisabled = canReject ? 'opacity-100' : 'opacity-30';
  const maybeDoneDisable = canAccept ? 'opacity-100' : 'opacity-30';

  const buttons = (
    <div className="container flex flex-row justify-around my-5 pb-5 px-5">
      <div
        className={`w-1/2 md-1/3 xl:w-1/4 rounded-xl font-semibold mx-5 text-center text-white py-3 bg-red-600 hover:bg-red-700 ${rejectMaybeDisabled}`}
        role="button"
        onClick={canReject ? (e) => onFailure(e, idx, note) : () => {}}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Reject Evidence
      </div>
      <div
        className={`w-1/2 md-1/3 xl:w-1/4 rounded-xl font-semibold mx-5 text-center py-3 ${B_COLORS} ${maybeDoneDisable}`}
        role="button"
        onClick={canAccept ? (e) => onSuccess(e, idx, note) : () => {}}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Affirm Evidence
      </div>
    </div>
  );

  let titleColor;
  if (success) {
    titleColor = 'text-green-800';
  } else if (failure) {
    titleColor = 'text-red-800';
  } else {
    titleColor = 'text-black';
  }
  return (
    <div className="w-full">
      <h2 className={`text-xl underline font-bold my-5 mb-5 ${titleColor}`}>
        Evidence #
        {idx + 1}
        :
        {' '}
        {verificationId}
        {' '}
        (
        {type}
        )
      </h2>
      <div>
        { evidenceDescription }
        { reviewingInstructions }
        { userUpload }
        { confirm }
        { noteInputter }
        { buttons }
        { done && (
          <div className="flex flex-row items-center mx-auto justify-around -pt-10 -mt-4 pb-10">
            {
              success ? (
                <p className="text-md font-semibold text-green-800">
                  This evidence has been approved. Refresh to start over.
                </p>
              ) : (
                <p className="text-md font-semibold text-red-800">
                  This evidence has been denied. Refresh to start over.
                </p>
              )
            }
          </div>
        )}
      </div>
    </div>
  );
};

EvidenceReviewer.propTypes = {
  evidence: propTypes.objectOf(propTypes.any).isRequired,
  requiredDocumentation: propTypes.objectOf(propTypes.any).isRequired,
  success: propTypes.bool.isRequired,
  failure: propTypes.bool.isRequired,
  idx: propTypes.number.isRequired,
  onSuccess: propTypes.func.isRequired,
  onFailure: propTypes.func.isRequired,
};

export default EvidenceReviewer;
