import { PREFIX } from './prefix';

async function requestPasswordReset(email) {
  return fetch(`${PREFIX}/api/request-reset`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function checkResetCode(code) {
  return fetch(`${PREFIX}/api/check-reset`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function resetPassword(code, password) {
  return fetch(`${PREFIX}/api/reset-password`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code, password }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  requestPasswordReset,
  checkResetCode,
  resetPassword,
};
