import { PREFIX } from './prefix';

/*
 * API endpoints for admin functionality
 */

async function fetchPending(token) {
  return fetch(`${PREFIX}/api/pending-verifications`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function fetchUser(token, userId) {
  return fetch(`${PREFIX}/api/fetch-user/${userId}`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function rejectAccreditation(token, userId, notes, successes) {
  return fetch(`${PREFIX}/api/reject-accreditation`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({ userId, notes, successes }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function confirmAccreditation(token, userId, notes, successes, expirationDate) {
  return fetch(`${PREFIX}/api/confirm-accreditation`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({
      userId, notes, successes, expirationDate,
    }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function lookupUser(token, query) {
  return fetch(`${PREFIX}/api/lookup/${query}`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function archiveUser(token, userId) {
  return fetch(`${PREFIX}/api/archive-user`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({
      userId,
    }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function failAccreditation(token, userId, failureNote) {
  return fetch(`${PREFIX}/api/fail-user`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({
      userId,
      failureNote,
    }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function adminQuery(token, queryParams) {
  return fetch(`${PREFIX}/api/admin/query`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify(queryParams),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function adminSearch(token, queryStr) {
  return fetch(`${PREFIX}/api/admin/search`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify({ queryStr }),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function setDiscountCode(token, discountCode) {
  return fetch(`${PREFIX}/api/admin/discount-code`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify(discountCode),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function fetchDiscountCodes(token) {
  return fetch(`${PREFIX}/api/admin/discount-codes`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function setPlatform(token, platform) {
  return fetch(`${PREFIX}/api/admin/set-platform`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
    body: JSON.stringify(platform),
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

async function fetchPlatforms(token) {
  return fetch(`${PREFIX}/api/admin/all-platforms`, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json',
      'X-ACCESS-TOKEN': token,
    },
  })
    .then((data) => data.json())
    .catch((err) => ({ error: err }));
}

export {
  fetchPending,
  fetchUser,
  rejectAccreditation,
  confirmAccreditation,
  lookupUser,
  archiveUser,
  failAccreditation,
  // Admin query and search
  adminQuery,
  adminSearch,
  // Admin Logistics
  fetchDiscountCodes,
  setDiscountCode,
  fetchPlatforms,
  setPlatform,
};
