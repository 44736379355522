import React from 'react';

const STRONG_STYLE = 'font-bold my-4';
const B_STYLE = 'font-semibold my-4';
const P_STYLE = 'mb-2 ml-5';
const LI_STYLE = 'ml-10 list-disc';

const TermsOfService = function () {
  return (
    <div className="flex flex-col text-lg px-10 w-9/12">
      <strong className={STRONG_STYLE}>TERMS OF SERVICE</strong>
      <strong className={STRONG_STYLE}>
        Last updated January 17,
        2022

      </strong>
      <h1 className="text-4xl mb-4">TABLE OF CONTENTS</h1>
      <div className="ml-10 my-4 flex flex-col gap-2">
        <a
          data-custom-class="link"
          href="#agreement"
        >
          1. AGREEMENT TO TERMS
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#ip"
        >
          2. INTELLECTUAL PROPERTY RIGHTS
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#userreps"
        >
          3. USER REPRESENTATIONS
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#userreg"
        >
          4. USER REGISTRATION
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#prohibited"
        >
          5. PROHIBITED ACTIVITIES
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#ugc"
        >
          6. USER GENERATED CONTRIBUTIONS
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#license"
        >
          7. CONTRIBUTION LICENSE
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#submissions"
        >
          8. SUBMISSIONS
        </a>
        {' '}
        <a
          data-custom-class="link"
          href="#thirdparty"
        >
          9. THIRD-PARTY WEBSITE AND
          CONTENT
        </a>
        {' '}
        <a data-custom-class="link" href="#advertisers">
          10.
          ADVERTISERS
        </a>
        {' '}
        <a data-custom-class="link" href="#sitemanage">
          {' '}
          11. SITE
          MANAGEMENT
          {' '}
        </a>

        <a data-custom-class="link" href="#privacypolicy1"> 12. PRIVACY POLICY </a>

        <a data-custom-class="link" href="#terms"> 13. TERM AND TERMINATION </a>

        <a data-custom-class="link" href="#modifications">
          {' '}
          14. MODIFICATIONS AND
          INTERRUPTIONS
          {' '}

        </a>

        <a data-custom-class="link" href="#law"> 15. GOVERNING LAW </a>

        <a data-custom-class="link" href="#disputes"> 16. DISPUTE RESOLUTION </a>

        <a data-custom-class="link" href="#corrections"> 17. CORRECTIONS </a>

        <a data-custom-class="link" href="#disclaimer"> 18. DISCLAIMER </a>

        <a data-custom-class="link" href="#liability">
          {' '}
          19. LIMITATIONS OF LIABILITY
        </a>

        <a data-custom-class="link" href="#indemnification">
          {' '}
          20. INDEMNIFICATION
        </a>

        <a data-custom-class="link" href="#userdata"> 21. USER DATA </a>

        <a data-custom-class="link" href="#electronic">
          {' '}
          22. ELECTRONIC
          COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          {' '}

        </a>

        <a data-custom-class="link" href="#california">
          {' '}
          23. CALIFORNIA USERS AND
          RESIDENTS
          {' '}

        </a>

        <a data-custom-class="link" href="#misc"> 24. MISCELLANEOUS </a>

        <a data-custom-class="link" href="#contact"> 25. Fees </a>

        <a data-custom-class="link" href="#contact"> 26. CONTACT US </a>
      </div>
      <strong className={STRONG_STYLE}> 1. AGREEMENT TO TERMS </strong>

      <p className={P_STYLE}>
        These Terms of Use constitute a legally binding agreement made between you,
        whether personally or on behalf of an entity (“you”) and Check Investor
        Status, LLC (&quot;
        {' '}
        <strong className={STRONG_STYLE}> Company </strong>
        {' '}
        ,&quot;  &quot;
        {' '}
        <strong className={STRONG_STYLE}> we </strong>
        {' '}
        ,&quot;  &quot;
        <strong className={STRONG_STYLE}> us </strong>
        {' '}
        ,&quot; or  &quot;
        {' '}
        <strong className={STRONG_STYLE}> our </strong>
        {' '}
        &quot;), concerning your
        access to and use of the
        {' '}
        <a
          href="http://www.checkinvestorstatus.com"
          target="_blank"
          data-custom-class="link"
          rel="noreferrer"
        >
          {' '}
          http://www.checkinvestorstatus.com
        </a>
        {' '}
        website as well as any other media form, media channel, mobile website
        or mobile application related, linked, or otherwise connected thereto
        (collectively, the “Site”).  We are registered in Washington, Unifkkted
        States and have our registered office at 612 W. Main Ave, Spokane, WA 99204.
        You agree that by accessing the Site, you have read, understood, and agreed
        to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
        THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE
        AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>
      <p className={P_STYLE}>
        Supplemental terms and conditions or documents that may be posted on the
        Site from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes or
        modifications to these Terms of Use at any time and for any reason. We will
        alert you about any changes by updating the “Last updated” date of these
        Terms of Use, and you waive any right to receive specific notice of each
        such change. Please ensure that you check the applicable Terms every time
        you use our Site so that you understand which Terms apply. You will be
        subject to, and will be deemed to have been made aware of and to have
        accepted, the changes in any revised Terms of Use by your continued use of
        the Site after the date such revised Terms of Use are posted.
      </p>
      <p className={P_STYLE}>
        The information provided on the Site is not intended for distribution to or
        use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which would
        subject us to any registration requirement within such jurisdiction or
        country. Accordingly, those persons who choose to access the Site from other
        locations do so on their own initiative and are solely responsible for
        compliance with local laws, if and to the extent local laws are applicable.
      </p>
      <p className={P_STYLE}>
        The Site is not tailored to comply with industry-specific regulations
        (Health Insurance Portability and Accountability Act (HIPAA), Federal
        Information Security Management Act (FISMA), etc.), so if your interactions
        would be subjected to such laws, you may not use this Site. You may not use
        the Site in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
      </p>
      <p className={P_STYLE}>
        The Site is intended for users who are at least 18 years old. Persons under
        the age of 18 are not permitted to use or register for the Site.
      </p>

      <p className={P_STYLE}>
        The service provided by this website is designed to help verify whether an
        potential investor is an accredited investor, pursuant to Rule 501 of
        Regulation D of the Securities Act of 1933.
      </p>
      <p className={B_STYLE}>
        Investors or Investing Parties
      </p>
      <p className={P_STYLE}>
        As a user of the service provided by this site you are a “Potential Investor”,
        “Investing Party”, or simply “Investor” if you use the site to verify the
        accredited investor status of yourself, or you and your spouse or spousal
        equivalent, of an entity with which you have a material or representative
        relationship, or of a trust with which you have a material or representative
        relationship.
      </p>
      <p className={P_STYLE}>
        By engaging this service as an issuer, you enter into these terms and agree
        that all information provided directly or indirectly through your to the
        service or to its reviewers (defined below) is true and accurate. You also
        attest that all evidence and documentation is accurate and has not been edited
        to misrepresent the facts.
      </p>
      <p className={B_STYLE}>
        Issuers or Issuing Parties
      </p>
      <p className={P_STYLE}>
        As a user of the service provided by this site, you are an “Issuing party” or
        simply “Issuer” if you use the site to verify the accreditation status of any
        investor party. Any natural or legal persons that is issuing or intends ot
        issue securities in the future may engage the service as an issuer.
      </p>
      <p className={P_STYLE}>
        By engaging with this service, you enter into these terms and agree that by
        providing the contact information of a potential investor, you (i) have their
        permission to engage our services on their behalf, (ii) have a reasonable
        belief that they are an accredited investor according to Rule 501 of
        Regulation D or the Securities Act of 1933, and (iii) have a preexisting
        relationship with the potential investor and have received positive
        confirmation of their intent to buy securities issued by you or the legal
        persons you represent. You also attest that you have the right and legal
        authority to represent the legal persons issuing the securities in question.
      </p>
      <p className={P_STYLE}>
        Unless otherwise stated, all terms and conditions apply to investors, issuers,
        and any other general user of the website or service. If you access the
        website on behalf of any other natural or legal person, that person is a
        “represented person”.
      </p>
      <p className={P_STYLE}>
        By providing information to the site on a represented person’s behalf or
        entering into these terms on behalf of a represented person, you represent and
        warrant that you have the right and authority to represent the represented
        person and act on their behalf.
      </p>
      <p className={P_STYLE}>
        By entering into the terms below you acknowledge that you understand the
        following limitations on the behaviors and representation the company does and
        does not make:
      </p>
      <p className={B_STYLE}>
        The company does ensure a review of information provided by investing parties.
      </p>
      <p className={P_STYLE}>
        Every review is conducted by a staff member of the company or a licensed
        third-party attorney engaged by the company. Any such person conducting a
        review of information provided by investors is considered a “Reviewer.”
      </p>
      <p className={B_STYLE}>
        The company does issue “Certificates of Accreditation.”
      </p>
      <p className={P_STYLE}>
        Certificates of Accreditation are provided to investors who, upon providing
        information to the Service and undergoing review by a Reviewer, both (i) have
        demonstrated meeting the definition of Accredited Investor and (ii) have
        provided information and evidence sufficient for the reviewer to undergo
        reasonable steps towards verifying that the potential investor’s status as an
        accredited investor.
      </p>
      <p className={B_STYLE}>
        The company does not guarantee the issuance of Certificates of Accreditation.
      </p>
      <p className={P_STYLE}>
        Check Investor Status cannot guarantee the investor’s ability to demonstrate
        meeting the definition of Accredited Investor, nor our ability to undergo
        reasonable steps towards verification of any investor’s status.
      </p>
      <p className={B_STYLE}>
        The company does not independently verify the identity or the relationships
        claimed by issuers or investors.
      </p>
      <p className={B_STYLE}>
        The company does not independently confirm the accuracy of the information
        provided by issuers or investors.
      </p>
      <p className={P_STYLE}>
        The company and its reviewers retain the right to reject any evidence or
        claims made by investors or issuers for any reason including suspicion of
        misrepresentation or suspicion of evidence inauthenticity. The company does
        not claim to confirm the accuracy of claims or authenticity of evidence or
        documentation provided. See section 19 on limitations of liability with
        regards to intentional or unintentional misrepresentation by investors or
        issuers.
      </p>
      <p className={B_STYLE}>
        The company does not issue legal or professional opinion or advice ot
        investors or issuers.
      </p>
      <p className={P_STYLE}>
        Check Investor Status, LLC does not issue legal, accounting, or other
        professional opinions or advice. Licensed professionals may separately provide
        legal, accounting or other professional opinions or advice independent of the
        service. No attorney-client relationship is formed between investors or
        issuers and the service or its reviewers. The reviewers owe no obligation to
        investors or issuers. The company does not advise issuers on issuing
        securities.
      </p>
      <p className={B_STYLE}>
        The company is not a broker-dealer and does not undergo broker-dealership
        activity.
      </p>
      <p className={B_STYLE}>
        The company is not an investment advisor and does not make representations
        about the suitability of investments.
      </p>
      <p className={B_STYLE}>
        The company does not act as an agent or representative of any investing party
        or issuing party.
      </p>

      <strong className={STRONG_STYLE}> 2. INTELLECTUAL PROPERTY RIGHTS </strong>

      <p className={P_STYLE}>

        Unless otherwise indicated, the Site is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained therein
        (the “Marks”) are owned or controlled by us or licensed to us, and are
        protected by copyright and trademark laws and various other intellectual
        property rights and unfair competition laws of the United States,
        international copyright laws, and international conventions. The Content and
        the Marks are provided on the Site “AS IS” for your information and personal
        use only. Except as expressly provided in these Terms of Use, no part of the
        Site and no Content or Marks may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission.Provided that you are eligible to use the Site, you are granted a
        limited license to access and use the Site and to download or print a copy
        of any portion of the Content to which you have properly gained access
        solely for your personal, non-commercial use. We reserve all rights not
        expressly granted to you in and to the Site, the Content and the Marks.
      </p>

      <strong className={STRONG_STYLE}>3. USER REPRESENTATIONS </strong>

      <p className={P_STYLE}>

        By using the Site, you represent and warrant that: (1) all registration
        information you submit will be true, accurate, current, and complete; (2)
        you will maintain the accuracy of such information and promptly update such
        registration information as necessary; (3) you have the legal capacity and
        you agree to comply with these Terms of Use; (4) you are not a minor in the
        jurisdiction in which you reside; (5) you will not access the Site through
        automated or non-human means, whether through a bot, script, or otherwise;
        (6) you will not use the Site for any illegal or unauthorized purpose; and
        (7) your use of the Site will not violate any applicable law or regulation.

      </p>
      <p className={P_STYLE}>

        If you provide any information that is untrue, inaccurate, not current, or
        incomplete, we have the right to suspend or terminate your account and
        refuse any and all current or future use of the Site (or any portion
        thereof).
      </p>

      <strong className={STRONG_STYLE}> 4. USER REGISTRATION </strong>

      <p className={P_STYLE}>
        You may be required to register with
        the Site. You agree to keep your password confidential and will be
        responsible for all use of your account and password. We reserve the right
        to remove, reclaim, or change a username you select if we determine, in our
        sole discretion, that such username is inappropriate, obscene, or otherwise
        objectionable.

      </p>
      <strong className={STRONG_STYLE}>5. PROHIBITED ACTIVITIES </strong>

      <p className={P_STYLE}>
        You may not access or use the Site for any purpose other than that for which
        we make the Site available. The Site may not be used in connection with any
        commercial endeavors except those that are specifically endorsed or approved
        by us.
      </p>

      <p className={P_STYLE}>
        As a user of the Site, you agree not to:
      </p>

      <ul>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Systematically retrieve data or other content from the
          Site to create or compile, directly or indirectly, a collection,
          compilation, database, or directory without written permission from us.
        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Trick, defraud, or mislead us and other users,
          especially in any attempt to learn sensitive account information such as
          user passwords.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Circumvent, disable, or otherwise interfere with
          security-related features of the Site, including features that prevent or
          restrict the use or copying of any Content or enforce limitations on the use
          of the Site and/or the Content contained therein.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Disparage, tarnish, or otherwise harm, in our opinion,
          us and/or the Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Use any information obtained from the Site in order to
          harass, abuse, or harm another person.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Make improper use of our support services or submit
          false reports of abuse or misconduct.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Use the Site in a manner inconsistent with any
          applicable laws or regulations.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Engage in unauthorized framing of or linking to the
          Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Upload or transmit (or attempt to upload or to transmit)
          viruses, Trojan horses, or other material, including excessive use of
          capital letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the Site or
          modifies, impairs, disrupts, alters, or interferes with the use, features,
          functions, operation, or maintenance of the Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Engage in any automated use of the system, such as using
          scripts to send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Delete the copyright or other proprietary rights notice
          from any Content.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Attempt to impersonate another user or person or use the
          username of another user.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Upload or transmit (or attempt to upload or to transmit)
          any material that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as “spyware” or “passive collection
          mechanisms” or “pcms”).
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Interfere with, disrupt, or create an undue burden on
          the Site or the networks or services connected to the Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Harass, annoy, intimidate, or threaten any of our
          employees or agents engaged in providing any portion of the Site to you.
        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Attempt to bypass any measures of the Site designed to
          prevent or restrict access to the Site, or any portion of the Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Copy or adapt the Site’s software, including but not
          limited to Flash, PHP, HTML, JavaScript, or other code.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Except as permitted by applicable law, decipher,
          decompile, disassemble, or reverse engineer any of the software comprising
          or in any way making up a part of the Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Except as may be the result of standard search engine or
          Internet browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat utility,
          scraper, or offline reader that accesses the Site, or using or launching any
          unauthorized script or other software.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Use a buying agent or purchasing agent to make purchases
          on the Site.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Make any unauthorized use of the Site, including
          collecting usernames and/or email addresses of users by electronic or other
          means for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Use the Site as part of any effort to compete with us or
          otherwise use the Site and/or the Content for any revenue-generating
          endeavor or commercial enterprise.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Sell or otherwise transfer your profile.
          {' '}

        </li>

      </ul>

      <strong className={STRONG_STYLE}>6. USER GENERATED CONTRIBUTIONS </strong>

      <p className={P_STYLE}>
        The Site does not offer users to submit or post content. We may provide you
        with the opportunity to create, submit, post, display, transmit, perform,
        publish, distribute, or broadcast content and materials to us or on the
        Site, including but not limited to text, writings, video, audio,
        photographs, graphics, comments, suggestions, or personal information or
        other material (collectively, &quot;Contributions&quot;). Contributions may be
        viewable by other users of the Site and through third-party websites. As
        such, any Contributions you transmit may be treated in accordance with the
        Site Privacy Policy. When you create or make available any Contributions,
        you thereby represent and warrant that:
      </p>

      <ul>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          The creation, distribution, transmission, public
          display, or performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights, including
          but not limited to the copyright, patent, trademark, trade secret, or moral
          rights of any third party.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          You are the creator and owner of or have the necessary
          licenses, rights, consents, releases, and permissions to use and to
          authorize us, the Site, and other users of the Site to use your
          Contributions in any manner contemplated by the Site and these Terms of Use.
        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          You have the written consent, release, and/or permission
          of each and every identifiable individual person in your Contributions to
          use the name or likeness of each and every such identifiable individual
          person to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Use.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions are not false, inaccurate, or
          misleading.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions are not unsolicited or unauthorized
          advertising, promotional materials, pyramid schemes, chain letters, spam,
          mass mailings, or other forms of solicitation.
          {' '}

        </li>
        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions are not obscene, lewd, lascivious,
          filthy, violent, harassing, libelous, slanderous, or otherwise objectionable
          (as determined by us).
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions do not ridicule, mock, disparage,
          intimidate, or abuse anyone.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions are not used to harass or threaten
          (in the legal sense of those terms) any other person and to promote violence
          against a specific person or class of people.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions do not violate any applicable law,
          regulation, or rule.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions do not violate the privacy or
          publicity rights of any third party.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions do not violate any applicable law
          concerning child pornography, or otherwise intended to protect the health or
          well-being of minors.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions do not include any offensive comments
          that are connected to race, national origin, gender, sexual preference, or
          physical handicap.
          {' '}

        </li>

        <li
          className={LI_STYLE}
          data-custom-class="body_text"
        >
          {' '}
          Your Contributions do not otherwise violate, or link to
          material that violates, any provision of these Terms of Use, or any
          applicable law or regulation.
          {' '}

        </li>

      </ul>

      <p className={P_STYLE}>
        {' '}
        Any use of the Site in violation of the foregoing violates these Terms
        of Use and may result in, among other things, termination or suspension of
        your rights to use the Site.
      </p>

      <strong className={STRONG_STYLE}> 7. CONTRIBUTION LICENSE </strong>

      <p className={P_STYLE}>
        You and the Site agree that we may access, store, process, and use any
        information and personal data that you provide following the terms of the
        Privacy Policy and your choices (including settings).
      </p>
      <p className={P_STYLE}>
        By submitting suggestions or other feedback regarding the Site, you agree
        that we can use and share such feedback for any purpose without compensation
        to you.
      </p>
      <p className={P_STYLE}>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property rights
        or other proprietary rights associated with your Contributions. We are not
        liable for any statements or representations in your Contributions provided
        by you in any area on the Site. You are solely responsible for your
        Contributions to the Site and you expressly agree to exonerate us from any
        and all responsibility and to refrain from any legal action against us
        regarding your Contributions.
      </p>

      <strong className={STRONG_STYLE}> 8. SUBMISSIONS </strong>

      <p className={P_STYLE}>
        You acknowledge and agree that any questions, comments, suggestions, ideas,
        feedback, or other information regarding the Site (&quot;Submissions&quot;) provided
        by you to us are non-confidential and shall become our sole property. We
        shall own exclusive rights, including all intellectual property rights, and
        shall be entitled to the unrestricted use and dissemination of these
        Submissions for any lawful purpose, commercial or otherwise, without
        acknowledgment or compensation to you. You hereby waive all moral rights to
        any such Submissions, and you hereby warrant that any such Submissions are
        original with you or that you have the right to submit such Submissions. You
        agree there shall be no recourse against us for any alleged or actual
        infringement or misappropriation of any proprietary right in your
        Submissions.
      </p>

      <strong className={STRONG_STYLE}> 9. THIRD-PARTY WEBSITE AND CONTENT </strong>

      <p className={P_STYLE}>
        The Site may contain (or you may be sent via the Site) links to other
        websites (&quot;Third-Party Websites&quot;) as well as articles, photographs, text,
        graphics, pictures, designs, music, sound, video, information, applications,
        software, and other content or items belonging to or originating from third
        parties (&quot;Third-Party Content&quot;). Such Third-Party Websites and Third-Party
        Content are not investigated, monitored, or checked for accuracy,
        appropriateness, or completeness by us, and we are not responsible for any
        Third-Party Websites accessed through the Site or any Third-Party Content
        posted on, available through, or installed from the Site, including the
        content, accuracy, offensiveness, opinions, reliability, privacy practices,
        or other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-Party Content does not
        imply approval or endorsement thereof by us. If you decide to leave the Site
        and access the Third-Party Websites or to use or install any Third-Party
        Content, you do so at your own risk, and you should be aware these Terms of
        Use no longer govern. You should review the applicable terms and policies,
        including privacy and data gathering practices, of any website to which you
        navigate from the Site or relating to any applications you use or install
        from the Site. Any purchases you make through Third-Party Websites will be
        through other websites and from other companies, and we take no
        responsibility whatsoever in relation to such purchases which are
        exclusively between you and the applicable third party. You agree and
        acknowledge that we do not endorse the products or services offered on
        Third-Party Websites and you shall hold us harmless from any harm caused by
        your purchase of such products or services. Additionally, you shall hold us
        harmless from any losses sustained by you or harm caused to you relating to
        or resulting in any way from any Third-Party Content or any contact with
        Third-Party Websites.
      </p>

      <strong className={STRONG_STYLE}> 10. ADVERTISERS </strong>

      <p className={P_STYLE}>
        We allow advertisers to display their advertisements and other information
        in certain areas of the Site, such as sidebar advertisements or banner
        advertisements. If you are an advertiser, you shall take full responsibility
        for any advertisements you place on the Site and any services provided on
        the Site or products sold through those advertisements. Further, as an
        advertiser, you warrant and represent that you possess all rights and
        authority to place advertisements on the Site, including, but not limited
        to, intellectual property rights, publicity rights, and contractual rights.
        We simply provide the space to place such advertisements, and we have no
        other relationship with advertisers.
      </p>

      <strong className={STRONG_STYLE}> 11. SITE MANAGEMENT </strong>

      <p className={P_STYLE}>
        We reserve the right, but not the obligation, to: (1) monitor the Site for
        violations of these Terms of Use; (2) take appropriate legal action against
        anyone who, in our sole discretion, violates the law or these Terms of Use,
        including without limitation, reporting such user to law enforcement
        authorities; (3) in our sole discretion and without limitation, refuse,
        restrict access to, limit the availability of, or disable (to the extent
        technologically feasible) any of your Contributions or any portion thereof;
        (4) in our sole discretion and without limitation, notice, or liability, to
        remove from the Site or otherwise disable all files and content that are
        excessive in size or are in any way burdensome to our systems; and (5)
        otherwise manage the Site in a manner designed to protect our rights and
        property and to facilitate the proper functioning of the Site.
      </p>

      <strong className={STRONG_STYLE}> 12. PRIVACY POLICY </strong>

      <p className={P_STYLE}>
        We care about data privacy and security. Please review our Privacy Policy:
        <b>

          <a
            href="http://www.checkinvestorstatus.com/privacy"
            target="_blank"
            data-custom-class="link"
            rel="noreferrer"
          >
            {' '}
            http://www.checkinvestorstatus.com/privacy
            {' '}

          </a>

        </b>
        {' '}
        . By using the Site, you agree to be bound by our Privacy Policy, which
        is incorporated into these Terms of Use. Please be advised the Site is
        hosted in the United States. If you access the Site from any other region of
        the world with laws or other requirements governing personal data
        collection, use, or disclosure that differ from applicable laws in the
        United States, then through your continued use of the Site, you are
        transferring your data to the United States, and you agree to have your data
        transferred to and processed in the United States.
      </p>

      <strong className={STRONG_STYLE}> 13. TERM AND TERMINATION </strong>

      <p className={P_STYLE}>
        These Terms of Use shall remain in full force and effect while you use the
        Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE
        THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
        ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
        ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
        BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS
        OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
        PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
        INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
        DISCRETION.
      </p>
      <p className={P_STYLE}>

        If we terminate or suspend your account for any reason, you are prohibited
        from registering and creating a new account under your name, a fake or
        borrowed name, or the name of any third party, even if you may be acting on
        behalf of the third party. In addition to terminating or suspending your
        account, we reserve the right to take appropriate legal action, including
        without limitation pursuing civil, criminal, and injunctive redress.
      </p>
      <strong className={STRONG_STYLE}> 14. MODIFICATIONS AND INTERRUPTIONS </strong>

      <p className={P_STYLE}>
        We reserve the right to change, modify, or remove the contents of the Site
        at any time or for any reason at our sole discretion without notice.
        However, we have no obligation to update any information on our Site. We
        also reserve the right to modify or discontinue all or part of the Site
        without notice at any time. We will not be liable to you or any third party
        for any modification, price change, suspension, or discontinuance of the
        Site.
      </p>
      <p className={P_STYLE}>

        We cannot guarantee the Site will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Site, resulting in interruptions, delays, or
        errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Site at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever for
        any loss, damage, or inconvenience caused by your inability to access or use
        the Site during any downtime or discontinuance of the Site. Nothing in these
        Terms of Use will be construed to obligate us to maintain and support the
        Site or to supply any corrections, updates, or releases in connection
        therewith.
      </p>

      <strong className={STRONG_STYLE}> 15. GOVERNING LAW  </strong>
      <p className={P_STYLE}>
        {' '}
        These Terms of Use and your use of the
        Site are governed by and construed in accordance with the laws of the State
        of Washington applicable to agreements made and to be entirely performed
        within the State of Washington, without regard to its conflict of law
        principles.
      </p>

      <strong className={STRONG_STYLE}> 16. DISPUTE RESOLUTION </strong>

      <p className={P_STYLE}>
        <strong className={STRONG_STYLE}> Informal Negotiations </strong>
        {' '}
        To expedite resolution and control
        the cost of any dispute, controversy, or claim related to these Terms of Use
        (each &quot;Dispute&quot; and collectively, the &quot;Disputes&quot;) brought by
        either you or
        us (individually, a “Party” and collectively, the “Parties”), the Parties
        agree to first attempt to negotiate any Dispute (except those Disputes
        expressly provided below) informally for at least thirty (30) days before
        initiating arbitration. Such informal negotiations commence upon written
        notice from one Party to the other Party.
      </p>
      <p className={P_STYLE}>
        <strong className={STRONG_STYLE}> Binding Arbitration </strong>
        {' '}
        If the Parties are unable to resolve
        a Dispute through informal negotiations, the Dispute (except those Disputes
        expressly excluded below) will be finally and exclusively resolved through
        binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
        HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall
        be commenced and conducted under the Commercial Arbitration Rules of the
        American Arbitration Association (&quot;AAA&quot;) and, where appropriate, the AAA&apos;s
        Supplementary Procedures for Consumer Related Disputes (&quot;AAA Consumer
        Rules&quot;), both of which are available at the AAA website:
        {' '}
        <a
          data-custom-class="link"
          href="http://www.adr.org"
          rel="noopener noreferrer"
          target="_blank"
        >
          {' '}
          www.adr.org
        </a>
        {' '}
        . Your arbitration fees and your share of
        arbitrator compensation shall be governed by the AAA Consumer Rules and,
        where appropriate, limited by the AAA Consumer Rules. The arbitration may be
        conducted in person, through the submission of documents, by phone, or
        online. The arbitrator will make a decision in writing, but need not provide
        a statement of reasons unless requested by either Party. The arbitrator must
        follow applicable law, and any award may be challenged if the arbitrator
        fails to do so. Except where otherwise required by the applicable AAA rules
        or applicable law, the arbitration will take place in Spokane, Washington.
        Except as otherwise provided herein, the Parties may litigate in court to
        compel arbitration, stay proceedings pending arbitration, or to confirm,
        modify, vacate, or enter judgment on the award entered by the arbitrator.If
        for any reason, a Dispute proceeds in court rather than arbitration, the
        Dispute shall be commenced or prosecuted in the state and federal courts
        located in Spokane, Washington, and the Parties hereby consent to, and waive
        all defenses of lack of personal jurisdiction, and forum non conveniens with
        respect to venue and jurisdiction in such state and federal courts.
        Application of the United Nations Convention on Contracts for the
        International Sale of Goods and the Uniform Computer Information Transaction
        Act (UCITA) is excluded from these Terms of Use.In no event shall any
        Dispute brought by either Party related in any way to the Site be commenced
        more than one (1) years after the cause of action arose. If this provision
        is found to be illegal or unenforceable, then neither Party will elect to
        arbitrate any Dispute falling within that portion of this provision found to
        be illegal or unenforceable, and such Dispute shall be decided by a court of
        competent jurisdiction within the courts listed for jurisdiction above, and
        the Parties agree to submit to the personal jurisdiction of that court.
      </p>

      <p className={P_STYLE}>
        <strong className={STRONG_STYLE}> Restrictions </strong>
        {' '}
        The Parties agree that any arbitration shall
        be limited to the Dispute between the Parties individually. To the full
        extent permitted by law, (a) no arbitration shall be joined with any other
        proceeding; (b) there is no right or authority for any Dispute to be
        arbitrated on a class-action basis or to utilize class action procedures;
        and (c) there is no right or authority for any Dispute to be brought in a
        purported representative capacity on behalf of the general public or any
        other persons.
      </p>

      <p className={P_STYLE}>
        <strong className={STRONG_STYLE}>
          Exceptions to Informal Negotiations and Arbitration
        </strong>
        {' '}
        The
        Parties agree that the following Disputes are not subject to the above
        provisions concerning informal negotiations and binding arbitration: (a) any
        Disputes seeking to enforce or protect, or concerning the validity of, any
        of the intellectual property rights of a Party; (b) any Dispute related to,
        or arising from, allegations of theft, piracy, invasion of privacy, or
        unauthorized use; and (c) any claim for injunctive relief. If this provision
        is found to be illegal or unenforceable, then neither Party will elect to
        arbitrate any Dispute falling within that portion of this provision found to
        be illegal or unenforceable and such Dispute shall be decided by a court of
        competent jurisdiction within the courts listed for jurisdiction above, and
        the Parties agree to submit to the personal jurisdiction of that court.
      </p>

      <strong className={STRONG_STYLE}> 17. CORRECTIONS </strong>

      <p className={P_STYLE}>
        There may be information on the Site that
        contains typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other information. We
        reserve the right to correct any errors, inaccuracies, or omissions and to
        change or update the information on the Site at any time, without prior
        notice.
      </p>

      <strong className={STRONG_STYLE}> 18. DISCLAIMER </strong>
      <p className={P_STYLE}>
        THE SITE IS PROVIDED ON AN AS-IS AND
        AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES
        WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
        DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
        YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
        MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF
        THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE
        WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
        THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
        ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
        THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
        SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
        TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS
        OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
        KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
        OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
        GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
        OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
        WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING,
        AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
        ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
        SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
        IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
      </p>

      <strong className={STRONG_STYLE}> 19. LIMITATIONS OF LIABILITY </strong>
      <p className={P_STYLE}>
        IN NO EVENT WILL WE OR OUR
        DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
        DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
        DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
        ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
        CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
        REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE
        AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL
        LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
        LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
        THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
        ADDITIONAL RIGHTS.
      </p>
      <p className={B_STYLE}>
        THIRD PARTY CONDUCT
      </p>
      <p className={P_STYLE}>
        YOU ACKNOWLEDGE THAT THE COMPANY AND ITS REPRESENTATIVES ARE NOT LIABLE, AND
        YOU AGREE NOT TO SEEK TO HOLD THE COMPANY OR ITS REPRESENTATIVES LIABLE, FOR
        THE CONDUCT OF THIRD PARTIES.
      </p>
      <p className={B_STYLE}>
        MISREPRESENTATION BY ISSUERS OR INVESTORS
      </p>
      <p className={P_STYLE}>
        YOU ACKNOWLEDGE THAT THE COMPANY AND ITS REPRESENTATIVES ARE NOT LIABLE, AND
        YOU AGREE NOT TO SEEK TO HOLD THE COMPANY OR ITS REPRESENTATIVES LIABLE, FOR
        DAMAGES CAUSED BY MISREPRESENTATIONS MADE BY ISSUERS, INVESTORS, OR THIRD
        PARTIES. YOU AGREE NOT TO SEEK TO HOLD THE COMPANY OR ITS REPRESENTATIVES
        LIABLE FOR MISREPRESENTATIONS MADE BY THE PARTIES NAMED ABOVE REGARDLESS OF
        WHETHER THE MISREPRESENTATION WAS MADE DELIBERATELY OR INTENTIONALLY BY THE
        MISREPRESENTING PARTY.
      </p>
      <p className={B_STYLE}>
        LIMITATIONS ON LIABILITY
      </p>
      <p className={P_STYLE}>
        THESE LIMITATIONS ON LIABILITY ARE NOT APPLICABLE TO LIABILITY WHICH CANNOT BE
        LEGALLY LIMITED LAW. SUCH LIMITATIONS INCLUDED LIABILITY FOR DEATH OR PERSONAL
        INJURY STEMMING CAUSED BY NEGLIGENCE, FRAUD, FRAUDULENT MISREPRESENTATION, AND
        BREACH OF TERMS.
      </p>
      <strong className={STRONG_STYLE}> 20. INDEMNIFICATION </strong>
      <p className={P_STYLE}>
        You agree to defend, indemnify, and hold
        us harmless, including our subsidiaries, affiliates, and all of our
        respective officers, agents, partners, and employees, from and against any
        loss, damage, liability, claim, or demand, including reasonable attorneys’
        fees and expenses, made by any third party due to or arising out of: (1) use
        of the Site; (2) breach of these Terms of Use; (3) any breach of your
        representations and warranties set forth in these Terms of Use; (4) your
        violation of the rights of a third party, including but not limited to
        intellectual property rights; or (5) any overt harmful act toward any other
        user of the Site with whom you connected via the Site. Notwithstanding the
        foregoing, we reserve the right, at your expense, to assume the exclusive
        defense and control of any matter for which you are required to indemnify
        us, and you agree to cooperate, at your expense, with our defense of such
        claims. We will use reasonable efforts to notify you of any such claim,
        action, or proceeding which is subject to this indemnification upon becoming
        aware of it.
      </p>

      <strong className={STRONG_STYLE}> 21. USER DATA </strong>
      <p className={P_STYLE}>
        We will maintain certain data that you
        transmit to the Site for the purpose of managing the performance of the
        Site, as well as data relating to your use of the Site. Although we perform
        regular routine backups of data, you are solely responsible for all data
        that you transmit or that relates to any activity you have undertaken using
        the Site. You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>

      <strong className={STRONG_STYLE}>
        22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
      </strong>

      <p className={P_STYLE}>
        Visiting the Site, sending us emails, and completing online forms constitute
        electronic communications. You consent to receive electronic communications,
        and you agree that all agreements, notices, disclosures, and other
        communications we provide to you electronically, via email and on the Site,
        satisfy any legal requirement that such communication be in writing. YOU
        HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
        OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
        OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby
        waive any rights or requirements under any statutes, regulations, rules,
        ordinances, or other laws in any jurisdiction which require an original
        signature or delivery or retention of non-electronic records, or to payments
        or the granting of credits by any means other than electronic means.
      </p>
      <strong className={STRONG_STYLE}> 23. CALIFORNIA USERS AND RESIDENTS </strong>
      <p className={P_STYLE}>
        If any complaint with us
        is not satisfactorily resolved, you can contact the Complaint Assistance
        Unit of the Division of Consumer Services of the California Department of
        Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112,
        Sacramento, California 95834 or by telephone at (800) 952-5210 or (916)
        445-1254.
      </p>

      <strong className={STRONG_STYLE}> 24. MISCELLANEOUS </strong>
      <p className={P_STYLE}>
        These Terms of Use and any policies or
        operating rules posted by us on the Site or in respect to the Site
        constitute the entire agreement and understanding between you and us. Our
        failure to exercise or enforce any right or provision of these Terms of Use
        shall not operate as a waiver of such right or provision. These Terms of Use
        operate to the fullest extent permissible by law. We may assign any or all
        of our rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act caused
        by any cause beyond our reasonable control. If any provision or part of a
        provision of these Terms of Use is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed severable
        from these Terms of Use and does not affect the validity and enforceability
        of any remaining provisions. There is no joint venture, partnership,
        employment or agency relationship created between you and us as a result of
        these Terms of Use or use of the Site. You agree that these Terms of Use
        will not be construed against us by virtue of having drafted them. You
        hereby waive any and all defenses you may have based on the electronic form
        of these Terms of Use and the lack of signing by the parties hereto to
        execute these Terms of Use.
      </p>

      <strong className={STRONG_STYLE}> 25. Fees </strong>

      <p className={P_STYLE}>
        You agree to pay all fees and any applicable taxes incurred by you or anyone
        using the service on your behalf. CheckInvestorStatus my change the pricing
        for the service at any time and is under no obligation to provide you with the
        service at a specific price previously listed.
      </p>
      <p className={P_STYLE}>
        You represent to the company and our third party payment processor that you
        have the right to use any credit card or other means of payment that you
        provide to us and that all billing information you provide is truthful and
        accurate. You assume exclusive liability for any and all taxes, tariffs, fees,
        duties, withholdings or like charges, whether domestic or foreign, now imposed
        or hereafter becoming effective related to the Service and your purchases from
        CheckInvestorStatus. These include but are not limited to federal, provincial,
        state and local Taxes, value-added Taxes, goods and services Taxes, stamp,
        documentary, excise or property Taxes, duties and other governmental charges.
        You shall pay for the Service at the current prices. You hereby authorize us
        to charge your credit card account for all payments when due. In the event any
        such credit card charges are rejected, dishonored, or reversed, you agree to
        promptly pay the applicable charges upon demand.
      </p>
      <p className={B_STYLE}>
        REFUNDS
      </p>
      <p className={P_STYLE}>
        YOU ACKNOWLEDGE THAT THE COMPANY IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY
        REASON AND THAT YOU WILL NOT RECEIVE MONEY OR COMPENSATION FOR UNUSED
        SERVICES.
      </p>
      <p className={P_STYLE}>
        The company may provide refunds at the sole discretion of the staff of the
        company. Refunds are not guaranteed. All refunds issued may be issued less
        applicable costs and incurred fees, at the discretion of the company.
      </p>
      <strong className={STRONG_STYLE}> 26. CONTACT US </strong>
      <p className={P_STYLE}>
        In order to resolve a complaint regarding the
        Site or to receive further information regarding use of the Site, please
        contact us at:
        {' '}
      </p>

      <strong className="font-bold mb-1 my-4"> Check Investor Status, LLC </strong>
      <strong className="font-bold mb-1"> 612 W. Main Ave </strong>

      <strong className="font-bold mb-1"> Spokane, WA </strong>

      <strong className="font-bold mb-1"> 99201 </strong>

      <strong className="font-bold mb-1"> United States </strong>

      <strong className="font-bold mb-10"> support@checkinvestorstatus.com </strong>

    </div>
  );
};

export default TermsOfService;
