import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import AppContext from '../../AppContext';
import { docTypeEnum, requiredDocumentation } from '../_Upload/uploadContent';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Which special case applies?
  </h1>
);

const pageDescription = (
  <p className="text-black text-md mb-8">
    Select the special status, exemption,
    or designation that applies to your business entity.
  </p>
);

const SelectionPage = function (props) {
  const {
    variant,
    onChange,
    passedVal,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, [variant]);

  const [{ user, token }, dispatch] = useContext(AppContext);

  // CAUTION(Carter): This relies on there only being a single drop down documentation type!
  const { options } = requiredDocumentation[user.verificationMethod].filter(
    ({ type }) => type === docTypeEnum.DROP_DOWN,
  )[0];
  const updateSelection = (e) => {
    e.preventDefault();
    const newVal = e.target.value;
    setVal(newVal);
    onChange(newVal);
  };

  // Drop Down for selection val
  const selectionDropDown = (
    <div className="flex flex-col">
      <label className="flex-grow w-11/12 mb-8" htmlFor="value-name-in">
        <p className="text-md font-semibold">My entity is a:</p>
        <div className="my-2">
          <select
            className="border w-full"
            id="selection-dropdown"
            name="selection-dropdown"
            onChange={updateSelection}
          >
            <option value="none" disabled hidden selected={!val}>Select an option</option>
            { options.map((oText) => (
              <option value={oText} selected={val === oText}>
                {oText}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>
  );

  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      <p className="text-black mb-4">
        Select the specially-regulated entity type that applies to your business.
        These types of entities are regulated differently than traditional
        business entities and have different requirements for accreditation.
      </p>
      <p className="text-black mb-4">
        If you aren&apos;t sure if one of the listed designations
        applies to you, it probably doesn&apos;t.
        These statuses require explicit registration with
        the U.S. Government.
      </p>
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { selectionDropDown }
      </div>
      { infoCol }
    </div>
  );
};
SelectionPage.propTypes = {
  variant: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  passedVal: propTypes.string,
};
SelectionPage.defaultProps = {
  passedVal: '',
};

export default SelectionPage;
