/*
 * Landing Page for our App
 */
import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import Hero from './Hero';
import Features from './Features';
import Founders from './Founders';
import CTA from './CTA';
import AppContext from '../../AppContext';

const Landing = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl') || '';
  const retstr = returnUrl ? `?returnUrl=${returnUrl}` : '';

  if (token && user) {
    if (user.userType === 'ADMIN') {
      return <Navigate to="/admin" />;
    }
    switch (user.userStage) {
      case 'CONFIRMATION': {
        return <Navigate to="/confirm" />;
      }
      case 'SURVEY': {
        return <Navigate to={`/platform/portal/${user.portalCode}${retstr}`} />;
      }
      case 'UPLOAD': {
        return <Navigate to={`/platform/portal/${user.portalCode}${retstr}`} />;
      }
      case 'MORE_INFO': {
        return <Navigate to={`/platform/portal/${user.portalCode}${retstr}`} />;
      }
      case 'CHECKOUT': {
        return <Navigate to="/checkout" />;
      }
      // TODO(CArter): Fix the processing payment page
      case 'PROCESSING_PAYMENT': {
        return <Navigate to={`/status/processing${retstr}`} />;
      }
      case 'WAITING': {
        return <Navigate to={`/status/waiting${retstr}`} />;
      }
      case 'IN_REVIEW': {
        return <Navigate to={`/status/done${retstr}`} />;
      }
      case 'APPROVED': {
        return <Navigate to={`/status/approved${retstr}`} />;
      }
      case 'FAILED': {
        return <Navigate to={`/status/failed${retstr}`} />;
      }
      default: {
        return <Navigate to="/other" />;
      }
    }
  }

  return (
    <div>
      <Hero />
      <Features />
      <Founders />
      <CTA />
    </div>

  );
};

export default Landing;
