/*
 * Landing Page for our App
 */
import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import Features from './LandingPage/Features';
import CTA from './LandingPage/CTA';
import AppContext from '../AppContext';

const HowItWorks = function () {
  const [{ token, user }, dispatch] = useContext(AppContext);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  if (token && user) {
    if (user.userType === 'ADMIN') {
      return <Navigate to="/admin" />;
    }
    if (user.isPlatformUser) {
      switch (user.userStage) {
        case 'SURVEY': {
          return <Navigate to="/platform/survey" />;
        }
        case 'UPLOAD': {
          return <Navigate to="/platform/upload" />;
        }
        case 'WAITING': {
          return <Navigate to="/platform/done" />;
        }
        case 'IN_REVIEW': {
          return <Navigate to="/platform/done" />;
        }
        case 'APPROVED': {
          return <Navigate to="/platform/approved" />;
        }
        case 'FAILED': {
          return <Navigate to="/platform/failed" />;
        }
        case 'MORE_INFO': {
          return <Navigate to="/platform/upload" />;
        }
        default: {
          return <Navigate to="/other" />;
        }
      }
    }
    switch (user.userStage) {
      case 'CONFIRMATION': {
        return <Navigate to="/confirm" />;
      }
      case 'SURVEY': {
        return <Navigate to="/survey" />;
      }
      case 'UPLOAD': {
        return <Navigate to="/upload" />;
      }
      case 'CHECKOUT': {
        return <Navigate to="/checkout" />;
      }
      case 'PROCESSING_PAYMENT': {
        return <Navigate to="/status" />;
      }
      case 'WAITING': {
        return <Navigate to="/status" />;
      }
      case 'IN_REVIEW': {
        return <Navigate to="/status" />;
      }
      case 'APPROVED': {
        return <Navigate to="/status" />;
      }
      case 'FAILED': {
        return <Navigate to="/status" />;
      }
      case 'MORE_INFO': {
        return <Navigate to="/upload" />;
      }
      default: {
        return <Navigate to="/other" />;
      }
    }
  }

  return (
    <div>
      <Features />
      <CTA />
    </div>

  );
};

export default HowItWorks;
