/*
 * Carter TODOs:
 *   - Error state
 *   - Loading state
 *   - Does stomping on values post-review cause issue?
 */
import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';

import { FiUploadCloud } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';

import * as filestack from 'filestack-js';

import AppContext from '../../AppContext';
import legalDetails from '../_Upload/legalDetailsContent';
import { crumbToEvidenceId } from './evidence-helpers';

// Static content for the page
const pageHeading = (
  <h1 className="font-bold text-2xl mb-4">
    Upload Documentation
  </h1>
);

const variantToDescription = {
  INCOME: 'Upload the last two years of either your tax forms (W2, K1, 1099) or your tax returns.',
  CREDIT_REPORT: 'Upload a credit report from the last 90 days.',
  ASSETS: 'Upload proof of your assets totalling more than $1,000,000 in value.',
  WILDCARD: 'Upload supporting documentation of your accreditation status and extenuating circumstances.',
  JOINT_INCOME: 'Upload the last two years of either your tax forms (W2, K1, 1099) or your tax returns (for you and your spouse).',
  JOINT_CREDIT_REPORT: 'Upload credit reports from the last 90 days for you and your spouse.',
  JOINT_ASSETS: 'Upload proof of your assets totalling more than $1,000,000 in value.',
  EBP_FINANCES: 'Upload a financial statement documenting more than $5,000,000 in assets managed by your plan.',
  FIDUCIARY: 'Upload evidence that the retirement plan is managed and directed by a qualified fiduciary.',
  EBP_OFFICERS: 'Upload documentation that lists the officers or owners of the retirement plan.',
  ENTITY_FINANCES: 'Upload a financial statement documenting more than $5,000,000 in assets under management.',
  PROOF_OF_OWNERS: 'Upload documentation listing every equity owners of this entity.',
  STATUS_EVIDENCE: 'Upload evidence of the business type or status selected.',
  SPECIAL_EVIDENCE: 'Upload evidence of the business type or status selected.',
  TRUST_FINANCES: 'Upload a financial statement documenting more than $5,000,000 in assets under management.',
  BUSINESS_DIRECTOR: 'Upload evidence of the trust director\'s business qualifications.',
  TRUST_DOCS: 'Upload formation documents showing the structure and owners of the trust.',
};

const DocumentationUploadPage = function (props) {
  const {
    onChange,
    passedVal,
    variant,
  } = props;

  // The verificationMethod for the user
  const [val, setVal] = useState(passedVal);
  useEffect(() => {
    setVal(passedVal);
  }, [variant]);
  console.log(val);

  const [{ user, token }, dispatch] = useContext(AppContext);

  const pageDescription = (
    <p className="text-black text-md mb-6">
      { variantToDescription[variant] }
    </p>
  );

  // Upload filepicker logic
  const client = filestack.init('AZJcCAYAJQcaROtvBDFHAz');

  const onUploadDone = async ({ filesUploaded }) => {
    // Get the new list of keys
    const newFiles = filesUploaded.map((u) => ({
      url: u.url,
      key: u.handle,
      mimetype: u.mimetype,
      fname: u.filename,
    }));
    const newVals = (val || []).concat(newFiles);

    console.log('new');
    console.log(newVals);
    setVal(newVals);
    await onChange(
      newVals,
    );
  };

  const deleteDoc = async (e, delUrl) => {
    e.preventDefault();
    const newVals = val.filter(({ url }) => url !== delUrl);
    setVal(newVals);
    await onChange(
      newVals,
    );
  };

  const handleOpen = (e) => {
    e.preventDefault();
    try {
      const picker = client.picker(
        {
          accept: ['.pdf', 'image/*'],
          maxSize: 1024 * 1024 * 5,
          maxFiles: 10,
          fromSources: ['local_file_system', 'dropbox', 'googledrive'],
          onUploadDone, // TODO: Error handling? And loading?
          // onClose: () => {
          //   // setLoading(false);
          // },
        },
      );
      picker.open();
      // setLoading(true);
    } catch (err) {
      // setError(err);
    }
  };

  const uploadWidget = (
    <div
      id="upload-box"
      className="w-full border border-[#A1A5AD] border-dashed rounded-[.3REM] h-36 bg-[#F7F8F9] flex flex-col justify-center items-center gap-3 mb-6"
    >
      <FiUploadCloud
        size={40}
        color="#6A6E74"
        style={{ 'stroke-width': '1.2px' }}
      />
      <p
        className="text-black"
      >
        Drop your file(s) here or
        {' '}
        <span
          className="text-[#5862F2] cursor-pointer"
          role="button"
          onClick={handleOpen}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          browse your computer
        </span>
      </p>
    </div>
  );

  const preview = (
    <div className="flex flex-row items-start flex-wrap gap-6">
      {
        val.map(({ url, mimetype }) => (
          <div className="container w-[200px] border">
            <MdOutlineCancel
              className="stroke-0 hover:stroke-1 absolute ml-1 mt-1 cursor-pointer"
              size={20}
              onClick={async (e) => {
                deleteDoc(e, url);
              }}
            />
            {
              (mimetype === 'application/pdf') ? (
                <object className="-z-40 w-full object-contain h-[260px]" data={`${url}#view=FitH`} type="application/pdf">
                  <iframe className="w-full -z-30 object-contain h-[260px]" title={url} src={`https://docs.google.com/viewer?url=${url}&embedded=true`} />
                </object>
              ) : (
                <img className="-z-40 w-full object-contain" src={url} alt={url} />
              )
            }
          </div>
        ))
      }
    </div>
  );

  const evidenceId = crumbToEvidenceId(user, `DOCUMENTATION.${variant}`);
  const infoCol = (
    <div className="flex flex-col w-4/12 border border-[#DCE1E7] rounded-md py-6 pl-6 pr-10 shadow-[0_1px_3px_rgb(0,0,0,.15)]">
      <h1 className="font-bold mb-4">Legal Notes and Instructions</h1>
      { legalDetails[evidenceId] }
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-between py-4 items-start">
      <div className="flex flex-col w-6/12">
        { pageHeading }
        { pageDescription }
        { uploadWidget }
        { preview }
      </div>
      { infoCol }
    </div>
  );
};
DocumentationUploadPage.propTypes = {
  onChange: propTypes.func.isRequired,
  variant: propTypes.string.isRequired,
  // TODO(Carter): This is probably bad but it is important
  // eslint-disable-next-line react/forbid-prop-types
  passedVal: propTypes.any,
};
DocumentationUploadPage.defaultProps = {
  passedVal: '',
};

export default DocumentationUploadPage;
